import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';


function DetailsTopBar({ title,editLink, btnText,btnAction,onDelete,deleteBtn }) {
  
  return (
    <div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center">
      <div className="fw-bold font-size-24 mb-4 mb-sm-3 text-black">{title}</div>
      <div className="d-flex align-items-center mb-3 mb-sm-0" style={{ marginTop: '-1.2rem' }}>
          {editLink&&<Link to={editLink} className={`${!deleteBtn?"pe-2":""}`}>
          <i className="uil-pen font-size-20 cursor-pointer text-black"  id='edittooltip_edit'/>
          <UncontrolledTooltip placement="bottom" target="edittooltip_edit">
                          Edit
        </UncontrolledTooltip>
          </Link> }     
          
          {deleteBtn&& <button type='button' 
           className='border-0 bg-transparent '
           onClick={onDelete}>
           <i className="uil-trash-alt font-size-20 cursor-pointer text-black mx-2" id='deletetooltip_Del'/>
          <UncontrolledTooltip placement="bottom" target="deletetooltip_Del">
                          Delete
                        </UncontrolledTooltip>
            </button>} 
         
      {btnText&&  <button className="font-size-14 cancel-button bg-transparent"
        onClick={btnAction}
        >{btnText}</button>}
      </div>
    </div>
  );
}

export default DetailsTopBar;
