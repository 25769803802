import React, {useEffect,useState} from 'react'
import api from 'api'
import Endpoints from 'api/endpoints'
import queryParamBuilder from 'api/queryParamBuilder'
import { toastError } from 'components/Common/toastify'
import MyTournamentCard from 'pages/Users/components/MyTournamentCard'
import { Link } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown ,Col,Row} from 'reactstrap'
import MatchesSkelton from 'components/MatchesSkelton'

const tournamentStatusOptions = [
    {
      title:"All",
      status:"all",
    },
    {
      title:"Up coming",
      status:"upcoming" ,
    },
    {
      title:"Open",
      status:"applicationOpen" ,
    },
    {
      title:"Start",
      status:"start" ,
    },
    {
      title:"Cancel",
      status:"cancel" ,
    },
     
    {
      title:"Delete",
      status:"deleted" ,
    },
  ]

const TournamentsWraper = () => {
    const [tournamentStatus,setTournamentStatus]=useState(tournamentStatusOptions[0].status)
    const [tournament,setTournament]=useState([])
    const [isLoading,setIsLoading]=useState(false)

    useEffect(()=>{
        const controller = new AbortController();
        const fetchTournaments=async ()=>{
          setIsLoading(true)
            const allTournaments=queryParamBuilder({
                endpoint:Endpoints.allTournaments,
                status:tournamentStatus,
                limit:"4",
              })
              const signal=controller.signal
            try{
              
                const res= await api.get(allTournaments,{signal})
                if(res.status===200){
                    setTournament(res?.data?.data)
                    setIsLoading(false)
                }
            }catch(err){
                toastError(err?.response?.error?.message)
                setIsLoading(false)
            }
        }
        fetchTournaments()
        return ()=>{
            controller.abort()
        }
    },[tournamentStatus])
  return (
    <Col xl={12} className="pt-0 ">
    <div className="d-flex justify-content-between mb-2">
      <span className="font-size-16 fw-bold text-black d-flex ">Tournaments
      <UncontrolledDropdown>
        <DropdownToggle caret   className='bg-transparent text-muted  text-nowrap   border-0 font-size-12 px-2 pt-1 text-capitalize'>
        {tournamentStatus}
        <i className='bx bx-caret-down ' />
        </DropdownToggle>
        <DropdownMenu end>
          {
            tournamentStatusOptions?.map((el,ind)=>(
              <DropdownItem key={ind} onClick={()=>setTournamentStatus(el.status)} className="text-capitalize font-size-12">{el.title}</DropdownItem>
            ))
          }
          
        </DropdownMenu>
      </UncontrolledDropdown>
      </span>
      <Link className="text-blue font-size-12" to="/tournaments-listing">
        View All
      </Link>
    </div>
    <Row className='px-1'>
      {
       isLoading?(Array.from({length:4}).map((_,ind)=><MatchesSkelton key={ind}/>)): ( tournament?.length>0)?(
         tournament?.map((el,index)=>(
           <Col lg={6} className={` pt-3   ${index===1||index===3?"ps-lg-4":""}`} key={index}>
             <MyTournamentCard 
              //  tournament={el}
              data={el} 
              key={index+el?._id}
              //  tournamentStatus={dataState?.tournamentStatus?.tournament[index].status} 
             />
           </Col>
         ))
       ):(
        <Col className='text-center bg-white  p-5 rounded-3 '>
          No Tournament Found
        </Col>
       )
      }
     
    </Row>
  </Col>
  )
}

export default TournamentsWraper