import React, { useState } from 'react';
import { Input, InputGroup, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import AutoComplete from './AutoCompleteLocation';
import debounceHandler from 'utils/debounceHandler';

const SearchComponent = ({ setFilters, filters }) => {
  // const [filterOption, setFilterOption] = useState('name'); // Default filter option
  const [searchType, setSearchType] = useState('user');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleFilterChange = (option) => {
    setSearchType(option);
  }

  return (
    <div>
      <div className='d-flex flex-row outlined-button ' style={{width:"fit-content"}}>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle caret className='bg-transparent border-0 '>
            <div className="mb-0 mx-1">
              <i
                class={`${searchType == 'user'
                    ? 'fa-light fa-user'
                    : 'fa-light fa-location-dot'
                  } grey`}
                size="10px" />
              <i className="bx bx-caret-down grey" />
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleFilterChange('user')}>Search by name</DropdownItem>
            <DropdownItem onClick={() => handleFilterChange('location')}>Search by location</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {searchType == 'user' && (
          <input
            type="text"
            className="w-100 bg-transparent border-0   topbar-search font-size-14"
            placeholder="Search by name..."
            // value={filters.searchValue}
            onChange={e => {
              e.preventDefault();
              debounceHandler(
                setFilters,
                'searchValue',
                e.target.value,
              );
            }}
          />
        )}

        {searchType == 'location' && (
          <>
            <AutoComplete setSelected={setFilters} />
          </>
        )}

        {/* <Button color="primary">Search</Button> */}
      </div>
    </div>
  );
}

export default SearchComponent;
