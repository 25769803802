import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTable, useGlobalFilter, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from 'react-table';
import { Table, Row, Col, Button } from 'reactstrap';
import PaginationButton from 'components/Common/PaginationButton';

function FaqTable({
  columns,
  data,
  tableData,
  totalPages,
  currentPage,
  setCurrentPage,
  isGlobalFilter,
  isAddOrder,
  isAddTableWithoutBorderStrap,
  handleOrderClicks,
  isAddUsers,
  handleUserClicks,
  isAddTableBorderStrap,
  isAddInvoice,
  handleAdd,
  topHeading,
  addBtn,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const renderCellContent = (cell) => {
    if (typeof cell.value === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: cell.value }} />;
    }
    return cell.render('Cell');
  };
// console.log("total pages", totalPages)

  return (
    <>
      <Row className="mb-3">
        {isGlobalFilter && <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />}
        {isAddUsers && (
          <Col sm={7}>
            <div className="text-sm-end">
              <button type="button" color="success" className="primary-gradient text-white text-capitalize font-size-14" onClick={handleUserClicks}>
                <i className="mdi mdi-plus me-1" />
                Add New
              </button>
            </div>
          </Col>
        )}
        {/* {isAddInvoice && (
          
        )} */}
        {isAddOrder && (
          <Col sm={7}>
            <div className="text-sm-end">
              <Button type="button" color="success" className="waves-effect waves-light mb-3 btn btn-success" onClick={handleOrderClicks}>
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
      </Row>

      {isAddTableWithoutBorderStrap && (
        <div className="table-responsive">
          <Table bordered hover {...getTableProps()} className="react_table">
            <thead className="table-nowrap">
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id}>
                      <div className="d-flex align-items-center" {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      {isAddTableBorderStrap && (
        <div className="table-responsive">
          <Table className="table-centered datatable dt-responsive nowrap table-card-list react_table" {...getTableProps()}>
            <thead className="table-nowrap">
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id} className='ps-2'>
                      <div className="d-flex align-items-center" {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        // console.log('cels', cell);

                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {renderCellContent(cell)}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      <Row className="justify-content-md-end justify-content-center align-items-center">
      <div className="d-flex justify-content-center justify-content-sm-between align-items-center flex-column flex-sm-row ">
          <span className="col-12 col-sm-6 font-size-14 text-muted text-center text-sm-start">
            Showing {page.length > 0 ? 1 : 0} to {page.length} of {totalPages||1} entries
          </span>
          <div className='col-12 col-sm-6' >
            <div className=" d-flex justify-content-center justify-content-sm-end  mt-2 ">
              
                <PaginationButton 
                currentPage={currentPage}
                 setCurrentPage={setCurrentPage} 
                visiblePageButtons={3}
                totalPages={totalPages} />
              
             
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {

  return <></>;
}

FaqTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default FaqTable;
