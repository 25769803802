import React, {useEffect, useMemo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

//import components
import {Col, Row, UncontrolledTooltip, Input} from 'reactstrap';
import Listing from 'components/Common/Listing';
import Endpoints from 'api/endpoints';
import api from 'api';
import {toastError} from 'components/Common/toastify';
import moment from 'moment/moment';

import {toastSuccess} from 'components/Common/toastify';
import queryParamBuilder from 'api/queryParamBuilder';
import banner from 'assets/images/default-img.svg';
import {tournament} from 'utils/tournamentStatus';
import DeleteModal from 'components/Common/DeleteModal';

const TournamentListing = () => {
  document.title = ' Tournaments Listing';

  const tabNames = [
    {title: 'All', status: 'all'},
    {title: 'Up coming', status: 'upcoming '},
    {title: 'Start', status: 'start'},
    {title: 'Closed', status: 'closed'},
    //  {title:'Refund Request',status:""}
  ];

  // List tabes
  const [activeTab, setActiveTab] = useState(tabNames.at(0));

  const [fetchList, setFetchlist] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [tournamentID, setTournamentID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const handleCloseModal = () => setShowEdit(false);
  const handleShowModal = () => setShowEdit(true);

  // paginaion states
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({
    searchValue: '',
    location: {},
  });
  const [totalPages, setTotalPages] = useState(null);

  // fetching data
  const fetchListHandler = async () => {
    setIsLoading(true);
    const url = queryParamBuilder({
      page: currentPage,
      endpoint: Endpoints.allTournaments,
      limit: 10,
      status: activeTab.status,
      search: filters.searchValue,
      home: true,
    });

    try {
      const resp = await api.get(url);

      setFetchlist(resp?.data?.data);
      setTotalPages(resp?.data?.totalPages);
    } catch (error) {
      toastError();
    }
    setIsLoading(false);
  };

  const clearFiltersHandler = () => {
    setSearchValue('');
    setFilters({
      searchValue: '',
      location: {
        lat: 0,
        long: 0,
      },
    });
  };
  const onSearch = e => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    fetchListHandler();
  }, [
    currentPage,
    filters.searchValue,
    filters.location,
    searchValue,
    activeTab,
  ]);

  //delete user

  const handleDeleteTournament = async tournamentID => {
    try {
      await api.delete(`${Endpoints.tournament}/${tournamentID}`);
      toastSuccess('Deleted');
      fetchListHandler();
    } catch (error) {
      toastError();
      console.log(error);
    }
  };

  const onClickDelete = async tournamentID => {
    try {
      const res = await api.delete(
        `${Endpoints.superAdminDeleteTournament}/${tournamentID}`,
      );

      if (res.status === 200) {
        toastSuccess('User Deleted');
        fetchListHandler();
      }
    } catch (err) {
      toastError();
    }
    setDeleteModal(false);
  };

  // data destruction

  const tData = useMemo(() => {
    if (fetchList) {
      return fetchList?.map((el, ind) => ({
        // checkbox: (
        //     <input type="checkbox" className="form-check-input" id="customCheck1" />
        // ),
        title: (
          <Link
            to={`/tournament-details/${el?._id}`}
            className="d-flex flex-row align-items-center text-center">
            <img
              src={el.bannerImage || banner}
              className="avatar-xs rounded-circle me-2"
            />
            <span className="ms-md-2 text-nowrap ">{el?.name || '--'}</span>
          </Link>
        ),
        //   name: "John Doe",
        category: el.isSanctioned ? 'Sanctioned' : 'Not Sanctioned',

        type: tournament(el.format),
        privacy: el.isPrivate ? 'Private' : 'Public',
        location: (
          <span
            className=" text-truncate d-inline-block"
            style={{maxWidth: '100px'}}>
            {el.location?.description || '--'}
          </span>
        ),

        //   userSince: `${moment(el?.createdAt).format('MMMM DD, YYYY')||"--"}`,
        date: moment(el.date).format('MMMM DD, YYYY'),
        time: el.time ? el.time : 'No time',
        court: <span className="ms-3">{el.court.length || '-'}</span>,
        id: el._id,

        action: (
          <div className="d-flex flex-nowrap users align-items-center justify-content-center">
            <ul className="list-inline font-size-20 contact-links mb-0">
              <li className="list-inline-item ">
                <Link
                  to={`/tournament-details/${el._id}`}
                  className="text-primary "
                  onClick={() => {
                    //   const users = cellProps.row.original
                    //   handleUserClick(users)
                  }}>
                  <i
                    className="uil uil-eye font-size-18"
                    id={`edittooltip_view_${ind}`}
                  />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`edittooltip_view_${ind}`}>
                    view
                  </UncontrolledTooltip>
                </Link>
              </li>
              {/* <li className="list-inline-item">
                                <Link
                                     to="#"
                                     className="text-primary ms-3"
                                     onClick={() => {
                                       handleShowModal();
                                       setTournamentID(el._id);
                                     }}
                   
                                >
                                    <i className="uil uil-pen font-size-18" id={`edittooltip_edit_${ind}`} />
                                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${ind}`}>
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li> */}
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    setDeleteModal(true);
                    setTournamentID(el?._id);
                  }}>
                  <i
                    className="uil uil-trash-alt font-size-18 text-danger"
                    id={`deletetooltip_del_${ind}`}
                  />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`deletetooltip_del_${ind}`}>
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li className="list-inline-item "></li>
            </ul>
          </div>
        ),
      }));
    }
  }, [fetchList]);

  const rows = useMemo(() => {
    return tData?.map(el => ({
      // checkbox: el.checkbox,
      title: el.title,
      category: el.category,
      type: el.type,
      privacy: el.privacy,
      location: el.location,
      date: el.date,
      time: el.time,
      court: el.court,
      id: el.id,
      action: el.action,
    }));
  }, [tData]);

  const columns = useMemo(
    () => [
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      // },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Category',
        accessor: 'category',
        hasDescription: true,
      },
      {
        Header: 'Format',
        accessor: 'type',
        hasDescription: true,
      },
      {
        Header: 'Privacy',
        accessor: 'privacy',
        hasDescription: true,
      },
      {
        Header: 'Location',
        accessor: 'location',
        hasDescription: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        hasDescription: true,
      },
      {
        Header: 'Time',
        accessor: 'time',
        hasDescription: true,
      },
      {
        Header: 'No. Courts',
        accessor: 'court',
        hasDescription: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  const listFilters = [
    // {
    //     title: "Location Filter",
    //     options: ["Sans Francisco", "New York"]
    // },
    // {
    //     title: "Category Filter",
    //     options: ["Sans Francisco", "New York"]
    // },
    // {
    //     title: "Type Filter",
    //     options: ["Single", "Double","Round Robbin"]
    // },
    // {
    //     title: "Court Filter",
    //     options: ["Single", "Double","Round Robbin"]
    // },
    // {
    //     title:"Privacy Filter",
    //     options:["Private","Public",]
    // }
  ];

  return (
    <React.Fragment>
      {/* {showEdit && <EditModal show={showEdit} handleClose={handleCloseModal} tournamentId={tournamentID} />} */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => onClickDelete(tournamentID)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <div className="mb-3">
            <h4>Tournaments Listing</h4>
          </div>
          <Row>
            <Col xs="12">
              <Listing
                searchBar={true}
                tabNames={tabNames}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                topBtn={''}
                topBtnLink={'#'}
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUsers={true}
                isAddTableBorderStrap={true}
                //  handleUserClicks={handleUserClicks}
                addPagination={true}
                topBar={listFilters}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onSearch={onSearch}
                isLoading={isLoading}
                filters={filters}
                setFilters={setFilters}
                clearFilters={clearFiltersHandler}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
TournamentListing.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TournamentListing;
