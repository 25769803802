import React, { useState, useEffect } from "react";

import api from "api";
import queryParamBuilder from "api/queryParamBuilder";
import TableSkeleton from "components/Common/TableSekeloton";

import { Link } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";
import { matchStatus } from "utils/matchStatus";
import banner from "assets/images/default-img.svg";
import Endpoints from "api/endpoints";
import TableContainer from "components/Common/TableContainer";
import TableSkeletonSm from "components/Common/TableSeketonSm";

const ReportedBy = ({ userId }) => {
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = async (userId) => {
    setIsLoading(true)
    try {
      const res = await api.get(
        `${Endpoints.reportByUsers}?page=${currentPage}&limit=10&userId=${userId}`
      );

      if(res?.status===200){
        setTotalPages(res?.data?.totalPages);
        // console.log("user reported",res?.data)
        setData(res?.data?.data);
        setIsLoading(false)
      }
        
    } catch (e) {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData(userId);
    } else return;
  }, [userId, currentPage, searchValue]);

  const rows = data?.map((el, ind) => ({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
    // name: (
    //   <Link to={`/user-profile/${el?.userId?._id}`} className="d-flex flex-nowrap  align-items-center text-center">
    //     <img src={el?.userId?.additionalInfo?.profileImage||banner} className="avatar-xs rounded-circle me-2" />
    //     <span className="ms-md-2 text-capitalize  ">{el?.userId?.firstName||"-"} {el?.userId?.lastName||"-"}</span>
    //   </Link>
    // ),
    email: el?.blockedBy?.email,
    reportedBy: (
      <Link
        to={`/user-profile/${el?.blockedBy?._id}`}
        className="d-flex flex-nowrap  align-items-center text-center"
      >
        <img
          src={el?.blockedBy?.additionalInfo?.profileImage || banner}
          className="avatar-xs rounded-circle me-2"
        />
        <span className="ms-md-2 text-capitalize  ">
          {el?.blockedBy?.firstName || "-"} {el?.blockedBy?.lastName || "-"}
        </span>
      </Link>
    ),
    // location:<p className='text-truncate' style={{maxWidth:"120px"}}>{el?.userId?.additionalInfo?.location?.description}</p>,
    reason: el?.blockDetail,

    status: (
      <div className="d-flex flex-nowrap align-items-center">
        <span className={`${matchStatus("blocked")} match-status`}>
          Blocked
        </span>
      </div>
    ),
    rating: ` S:${
      el?.blockedBy?.additionalInfo?.matchDetails?.single?.rating || "-"
    }/D:${el?.blockedBy?.additionalInfo?.matchDetails?.double?.rating || "-"}`,
    //    action: (
    //     <div className="d-flex gap-3 users">
    //       <ul className="list-inline font-size-20 contact-links mb-0">
    //       <li className="list-inline-item">
    //       <Link
    //         to={`/user-profile/${el?.userId?._id}`}
    //         className="text-primary"
    //         onClick={() => {
    //         //   const users = cellProps.row.original
    //         //   handleUserClick(users)
    //         }}
    //       >
    //         <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?.userId?._id}`} />
    //         <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?.userId?._id}`}>
    //           view
    //         </UncontrolledTooltip>
    //       </Link>
    //     </li>
    //     <li className="list-inline-item">
    //       <Link
    //         to="#"
    //         className="text-danger"
    //         onClick={() => {
    //         //   const users = cellProps.row.original
    //         // setDeleteModal(true);
    //         // setUser(el?._id)
    //         }}
    //       >
    //         <i
    //           className="uil uil-trash-alt font-size-18"
    //           id={`deletetooltip_del_${el?.userId?._id}`}
    //         />
    //         <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?.userId?._id}`} >
    //           Delete
    //         </UncontrolledTooltip>
    //       </Link>
    //     </li>

    //       </ul>
    //     </div>
    //   )
  }));
  const cols = [
    // {
    //   Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //   accessor: 'checkbox',
    // },
    // {
    //   Header: 'Name',
    //   accessor: 'name',
    // },
    {
      Header: "Reported by",
      accessor: "reportedBy",
      hasDescription: true,
    },
    {
      Header: "Email",
      accessor: "email",
      hasDescription: true,
    },
    // {
    //   Header:'Location',
    //   accessor: 'location',
    //   hasDescription: true,
    // },

    {
      Header: "Reason",
      accessor: "reason",
      hasDescription: true,
    },
    {
      Header: "Status",
      accessor: "status",
      hasDescription: true,
    },

    {
      Header: "Rating",
      accessor: "rating",
      hasDescription: true,
    },
  ];

  return (
    <Card className="section-card overflow-hidden  ">
      <CardBody className="pb-2 ">
        <input
          type="text"
          className="bg-transparent border-0   topbar-search font-size-14"
          placeholder="Search by name..."
          // value={filters.searchValue}
          onChange={(e) => {
            e.preventDefault();
           setSearchValue(e.target.value)
          }}
        />
        <Row className="py-1  overflow-auto" style={{ height: "300px" }}>
          {!isLoading ? (
            <TableContainer
              columns={cols}
              // rows={rows}
              data={rows}
              isGlobalFilter={true}
              isAddUsers={true}
              isAddTableBorderStrap={true}
              // handleUserClicks={handleUserClicks}
              addPagination={true}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              // topBar={topBar}
            />
          ) : (
            <TableSkeletonSm entries={5} />
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default ReportedBy;
