import HtmlHead from "components/html-head/HtmlHead";
import { Col, Container, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from "reactstrap";
import PointIcon from "assets/icons/PointIcon";
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "api";
import Endpoints from "api/endpoints";
import { toastSuccess, toastError } from "components/Common/toastify";
import banner from "assets/images/default-img-2.png";
import moment from "moment/moment";
import { challenges } from "utils/challengeStatus";
import Skeleton from "react-loading-skeleton";
import challengeBanner from "assets/images/default-banner-3.png"
import PlayerSkeleton from "./components/PlayerSkeleton";
import ScoreCardSkeleton from "./components/ScoreCardSkeleton";

const MatchDetails = () => {
  const { pathname } = useLocation();
  const lastPoint = pathname.split("/")[1];

  const title = `${
    lastPoint === "challenge-details" ? "Challenge Details" : "Match Details"
  }`;
  const description = `${
    lastPoint === "challenge-details" ? "Challenge page" : "Match page"
  }`;

  const [challenge, setChallenge] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const [showBtn, setShowBtn] = useState(false);

  const toggle = () => {
    if(lastPoint==="challenge-details"){
      setModal(!modal)
    }
  };

  const { id } = useParams();

  useEffect(() => {
    const controller = new AbortController();

    const getChallengeDetails = async () => {
      setIsLoading(true);
      const signal = controller.signal;
      try {
        const res = await api.get(
          `${
            Endpoints[lastPoint === "challenge-details" ? "challenge" : "match"]
          }/${id}`,
          { signal }
        );
       const data=res?.data?.data;
        if(lastPoint !="challenge-details"){
          const matchData={
            challengeBanner:"",
            date:data?.createdAt,
            time:data?.updatedAt,
            tournamentName:data?.tournamentId?.name,
            location:data?.court?.courtId?.location?.description,
            court:data?.court?.courtId?.name,
            teamA:data?.teamA?.users,
            teamB:data?.teamB?.users,
            winner:data?.winner?.users,
            matchScore:data?.matchScore,
            status:data?.status,
            teamAId:data?.teamA?._id,
            teamBId:data?.teamB?._id,

          }
          setChallenge(matchData)
          // setChallenge(res?.data?.data);
        }
        else {
          const challengeData={
            challengeBanner:data?.challengeBanner,
            date:data?.challengeData,
            time:data?.challengeTime,
            tournamentName:"",
            location:data?.challengeCourt?.at(0)?.courtId?.location?.description,
            court:data?.challengeCourt?.at(0)?.courtId?.name,
            teamA:data?.teamA,
            teamB:data?.teamB,
            teamAId:data?.teamA?._id,
            teamBId:data?.teamA?._id,
            winner:data?.winner,
            matchScore:data?.matchScore,
            status:data?.status

          }
          setChallenge(challengeData)
        }
        setIsLoading(false);
      } catch (err) {
        if (!err.name === "AbortError") {
          toastError(err?.message);
          setIsLoading(false);
        }
      }

      return () => controller.abort();
    };
    getChallengeDetails();
  }, [id]);



  return (
    <>
      <HtmlHead title={title} description={description} />
      <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Challenge Profile</ModalHeader>
            <ModalBody className="d-flex justify-content-center">

          <img  src={challenge?.challengeBanner?.at(0) || challengeBanner} className="mx-auto  object-fit-contain img-fluid" />
            </ModalBody>
        
       
      </Modal>
      <div className="page-content">
        <Container fluid>
          <div className="fw-bold font-size-24 mb-4 mb-sm-3 text-black">
            {title}
          </div>
          <Row className="mt-4">
            <Col className="col-12 mb-4">
              <div className="court-fee-wrap bg-white">
                <Row className="">
                  <Col className="col-12 col-xl-4">
                    <div
                      className={`match-detail-img ${lastPoint==="challenge-details"?"cursor-pointer":""}`}
                      style={{
                        backgroundImage: `url(${
                          challenge?.challengeBanner?.at(0) || challengeBanner
                        })`,
                        backgroundPosition:"center"
                      }}
                      onClick={toggle}
                      onMouseEnter={()=>setShowBtn(true)}
                      onMouseLeave={()=>setShowBtn(false)}
                      // id={`edittooltip_view_2`}
                    >
                    
                     {(showBtn&&lastPoint==="challenge-details")&& <i className="uil uil-eye font-size-18 text-white ms-2" id={`edittooltip_view_2`} onClick={toggle} />}
                    {/* <UncontrolledTooltip placement="bottom" target={`edittooltip_view_2`}>
                      view image
                    </UncontrolledTooltip> */}
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-6 col-xl-4  ps-4 ps-sm-0 ms-xl-0 ps-sm-4 ">
                    <div className="d-flex text-black">
                      <div className="py-3 font-size-14">
                        <div className="mb-2 d-flex align-items-center">
                          <PointIcon
                            name="location_on"
                            height="20"
                            width="20"

                            // fill="#054465"
                            // style={{
                            // }}
                          />
                          <span className="ms-2 fw-sb">Location:</span>
                          <span
                            className="ms-2 ms-sm-2 text-nowrap text-truncate d-inline-block  "
                            style={{ maxWidth: "100px" }}
                          >
                            {challenge?.location || "N/A"}
                          </span>
                        </div>

                        <div className="text-nowrap d-flex align-items-center mb-2">
                          <PointIcon
                            name="court"
                            height="20px"
                            width="20px"
                            className="court-icon"
                          />
                          <span className="ms-2 text-nowrap fw-sb">Court:</span>
                          <span className="ms-2 ms-lg-3 text-nowrap d-inline-block w-75">
                            {challenge?.court ||
                              "N/A"}
                          </span>
                        </div>
                        <div className="text-nowrap d-flex align-items-center text-truncate mb-2">
                          <PointIcon
                            name="calender"
                            height="20"
                            width="20"
                            className="group-icon"
                          />

                          <span className="ms-2 fw-sb">Date:</span>
                          <span className="ms-2 ms-lg-3 text-nowrap">
                            {moment(challenge?.date).format(
                              "MMMM DD, YYYY"
                            ) || "--"}
                          </span>
                        </div>
                        <div className="text-nowrap d-flex align-items-center text-truncate ">
                          <PointIcon name="time" height="20px" width="20px" />
                          <span className="ms-2 fw-sb">Time:</span>
                          <span className="ms-2 ms-lg-3 text-nowrap">
                            {moment(challenge?.time).format("hA") ||
                              "--"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-6 col-xl-4 ps-4 ps-sm-0 ms-xl-0">
                    <div className="d-flex text-black h-100">
                      <div className="py-3 font-size-14">
                        <div className="mb-2 d-flex align-items-center">
                          <PointIcon
                            name="tournamentTrophy"
                            height="20px"
                            width="20px"
                          />
                          <span className="ms-2 fw-sb">Tournament Name:</span>
                          <span className="ms-2 ms-sm-3 text-nowrap">
                            {challenge?.tournamentName || "N/A"}
                          </span>
                        </div>

                        <div className="text-nowrap mb-2 d-flex align-items-center">
                          <PointIcon name="status" height="20px" width="20px" />
                          <span className="ms-2 text-nowrap fw-sb">
                            Status:
                          </span>
                          <span className="ms-2 ms-lg-3 text-nowrap">
                            {challenges(challenge?.status)}
                          </span>
                        </div>
                        <div className="text-nowrap text-truncate d-flex align-items-center">
                          <PointIcon name="winner" height="20px" width="20px" />

                          <span className="ms-2 fw-sb">Match Winner:</span>
                          <span className="ms-2 ms-lg-3 text-nowrap">
                            {challenge?.winner?.length === 0
                              ? "To be announced"
                              : challenge?.winner?.length > 1
                              ? `${challenge?.winner?.at(0)?.firstName}&${
                                  challenge?.winner?.at(1)?.firstName
                                }`
                              : `${challenge?.winner?.at(0)?.firstName}`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-xxl-6">
              <Link to={`${lastPoint==="challenge-details"?"#":`/team-detail/${challenge?.teamAId}`}`}>
             {
              isLoading?(<PlayerSkeleton/>):( <div className="court-fee-wrap d-flex flex-column p-4 ">
              <div className="text-white d-flex justify-content-between">
                
                <div className="text-white font-size-18 fw-sb d-flex justify-content-between team-title-constainer flex-nowrap w-100 ">
                  {" "}
                  {challenge?.teamA?.every(
                    (user,ind) => user?._id === challenge?.winner?.at(ind)?._id
                  ) && (
                    <div>
                      <img src="/img/tournaments/king.svg" alt="" />
                    </div>
                  )}{" "}
                <span className="ms-auto">Team 1</span>
                </div>
              </div>
              {challenge?.teamA?.map((user, ind) => (
                <div className={`d-flex flex-column ${ind>0?"mt-2":""}  border-1 `}>
                  <Link to={`${lastPoint==="challenge-details"? `/user-profile/${user?._id}`:"#"}`}  className="text-white d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                    <img src={user?.additionalInfo?.profileImage||banner} alt="player" className={`  img-skelton-sm rounded-circle `}/>
                      <div className="ms-2 flex-column d-flex">
                        <span className="font-size-14 fw-sb">{user?.firstName} {user?.lastName}</span>
                        <span className="font-size-12">{user?.additionalInfo?.tagLine||"N/A"}</span>
                      </div>
                    </div>
                    {
                      challenge?.teamA?.length>1&&<div className="text-white font-size-18 fw-sb">
                      Player {ind+1}
                    </div>
                    }
                  </Link>
                  <div className="text-white d-flex align-content-center  justify-content-between flex-wrap row-cols-2 row-cols-sm-4 row-cols-ms-4 mt-3 ">
                    {/* <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12">Location</span>
                      <span className="font-size-16">{user?.additionalInfo?.location?.description}</span>
                    </div> */}
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12">Local Ranking</span>
                      <span className="font-size-16">{user?.additionalInfo?.ranking?.local||0}</span>
                    </div>
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12">Matches Played</span>
                      <span className="font-size-16">S:{(user?.additionalInfo?.matchDetails?.single?.win||0)+(user?.additionalInfo?.matchDetails?.single?.lose||0)}/D:{(user?.additionalInfo?.matchDetails?.double?.win||0)+(user?.additionalInfo?.matchDetails?.double?.lose||0)}</span>
                    </div>
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12">Wins</span>
                      <span className="font-size-16">S:{(user?.additionalInfo?.matchDetails?.single?.win||0)}/D:{(user?.additionalInfo?.matchDetails?.single?.win||0)}</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="font-size-12">Losses</span>
                      <span className="font-size-16">S:{(user?.additionalInfo?.matchDetails?.single?.lose||0)}/D:{(user?.additionalInfo?.matchDetails?.single?.lose||0)}</span>
                    </div>
                  </div>
                </div>
              ))}
             
            </div>)
             }
              </Link>
         
            </Col>
            <Col className="col-12 col-lg-6 col-xxl-6 mt-3 mt-lg-0">
          {
            isLoading?(<PlayerSkeleton />):(  <Link to={`${lastPoint==="challenge-details"?"#":`/team-detail/${challenge?.teamBId}`}`}>
            <div className="court-fee-wrap d-flex flex-column p-4 ">
                <div className="text-white d-flex justify-content-between">
                  
                  <div className="text-white font-size-18 fw-sb d-flex justify-content-between team-title-constainer flex-nowrap w-100 ">
                    {" "}
                    {challenge?.teamB?.some(
                      (user) => user?._id === challenge?.winner?.at(0)?._id
                    ) && (
                      <div>
                        <img src="/img/tournaments/king.svg" alt="" />
                      </div>
                    )}{" "}
                  <span className="ms-auto">Team 2</span>
                  </div>
                </div>
                {challenge?.teamB?.map((user, ind) => (
                  <div className={`d-flex flex-column ${ind>0?"mt-2":""}`}>
                    <Link to={`${lastPoint==="challenge-details"? `/user-profile/${user?._id}`:"#"}`} className="text-white d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                      <img src={user?.additionalInfo?.profileImage||banner} alt="player" className={`  img-skelton-sm rounded-circle `}/>
                        <div className="ms-2 flex-column d-flex">
                          <span className="font-size-14 fw-sb">{user?.firstName} {user?.lastName}</span>
                          <span className="font-size-12">{user?.additionalInfo?.tagLine||"N/A"}</span>
                        </div>
                      </div>
                      {
                        challenge?.teamB?.length>1&&<div className="text-white font-size-18 fw-sb">
                        Player {ind+1}
                      </div>
                      }
                    </Link>
                    <div className="text-white d-flex align-content-center  justify-content-between flex-wrap row-cols-2 row-cols-sm-4 row-cols-ms-4 mt-3 ">
                      {/* <div className="d-flex flex-column mb-3 mb-md-0">
                        <span className="font-size-12">Location</span>
                        <span className="font-size-16">{user?.additionalInfo?.location?.description}</span>
                      </div> */}
                      <div className="d-flex flex-column mb-3 mb-md-0">
                        <span className="font-size-12">Local Ranking</span>
                        <span className="font-size-16">{user?.additionalInfo?.ranking?.local||0}</span>
                      </div>
                      <div className="d-flex flex-column mb-3 mb-md-0">
                        <span className="font-size-12">Matches Played</span>
                        <span className="font-size-16">S:{(user?.additionalInfo?.matchDetails?.single?.win||0)+(user?.additionalInfo?.matchDetails?.single?.lose||0)}/D:{(user?.additionalInfo?.matchDetails?.double?.win||0)+(user?.additionalInfo?.matchDetails?.double?.lose||0)}</span>
                      </div>
                      <div className="d-flex flex-column mb-3 mb-md-0">
                        <span className="font-size-12">Wins</span>
                        <span className="font-size-16">S:{(user?.additionalInfo?.matchDetails?.single?.win||0)}/D:{(user?.additionalInfo?.matchDetails?.single?.win||0)}</span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="font-size-12">Losses</span>
                        <span className="font-size-16">S:{(user?.additionalInfo?.matchDetails?.single?.lose||0)}/D:{(user?.additionalInfo?.matchDetails?.single?.lose||0)}</span>
                      </div>
                    </div>
                  </div>
                ))}
                
              </div>
            </Link>)
          }
            </Col>
            <div className="font-size-18 fw-sb text-black my-4">Score Card</div>
            <Col className="col-12 mb-3">
             {
              isLoading?(<ScoreCardSkeleton/>):challenge?.matchScore?.length==0?<div className="p-3 text-center rounded-3 bg-white">To be announced</div>:(
                <>
                {
                  challenge?.matchScore?.map((score,ind)=>(
                    <div className="score-card-bottom px-4 py-4 py-xl-3 bg-white mb-3">
                    <div className="d-flex flex-xl-row flex-column text-black justify-content-xl-between align-items-center">
                      <div className="font-size-18 fw-sb mb-4 mb-xl-0">Round {ind+1}</div>
                      <div
                        className={`fw-medium font-size-14 rounded px-3 py-1 mb-4 mb-xl-0 
                        ${(score?.teamA>score?.teamB)?"light-green green":"light-red red"}
                        `}
                      >
                       {(score?.teamB>score?.teamA)?"Round Loser":"Round Winner"}
                      </div>
                      <div className="d-flex align-items-center mb-4 mb-xl-0">
                        {
                          challenge?.teamA?.map((user,ind)=>(
                            <img src={user?.additionalInfo?.profileImage||banner} className={`img-skelton-sm rounded-circle ${ind>0?"second-img":""}`} />
                          ))
                        }
                        {
                          isLoading||challenge?.teamA?.length===0?(<>
                        
                          <Skeleton circle={true} className="img-skelton-sm rounded-circle"/>
                          <Skeleton circle={true} className="img-skelton-sm rounded-circle second-img"/>
                       
                          </>):("")
                        }
                        <div className="ms-2 flex-column d-flex">
                          <span className="font-size-14 fw-sb">{challenge?.teamA?.length>1?`${challenge?.teamA?.at(0)?.firstName} & ${challenge?.teamA?.at(1)?.firstName}`:`${challenge?.teamA?.at(0)?.firstName}`}</span>
                        </div>
                      </div>
                      <div className="fw-sb font-size-24 match-ratio p-3 mb-4 mb-xl-0">
                        {score?.teamA||0}:{score?.teamB||0}
                      </div>
                      <div className="d-flex align-items-center mb-4 mb-xl-0">
                      {
                          challenge?.teamB?.map((user,ind)=>(
                            <img src={user?.additionalInfo?.profileImage||banner} className={`img-skelton-sm rounded-circle ${ind>0?"second-img":""}`} />
                          ))
                        }
                        {
                          isLoading||challenge?.teamB?.length===0?(<>
                        
                          <Skeleton circle={true} className="img-skelton-sm rounded-circle"/>
                          <Skeleton circle={true} className="img-skelton-sm rounded-circle second-img"/>
                       
                          </>):("")
                        }
                        <div className="ms-2 flex-column d-flex">
                        <span className="font-size-14 fw-sb">{challenge?.teamB?.length>1?`${challenge?.teamB?.at(0)?.firstName} & ${challenge?.teamB?.at(1)?.firstName}`:`${challenge?.teamB?.at(0)?.firstName}`}</span>
                        </div>
                      </div>
                      <div
                        className={`fw-medium font-size-14 rounded px-3 py-1  ${(score?.teamB>score?.teamA)?"light-green green":"light-red red"}
                        )}`}
                      >
                        {(score?.teamA>score?.teamB)?"Round Loser":"Round Winner"}
                      </div>
                    </div>
                  </div>
                  ))
                }
                </>
              )
             }
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MatchDetails;
