import React from 'react';
import { CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

// import dummy images

import teamA from "assets/images/teamA.svg"
import teamB from "assets/images/teamB.svg"


// dummy id
 const uid="65084d75c2b8ed4b578eb363"

function MatchCard({userId=uid}) {
  return (
    <Link to={`/tournament-details/${userId}`} className="cursor-pointer card" style={{ borderRadius: '8px', boxShadow: 'none' }}>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <img src={teamA} alt="team img" />
            &nbsp;
            <img src={teamB} alt="team img" />
          </div>
          <div className="d-flex flex-column">
            <span className="text-muted font-size-10 text-end">Aug 27</span>
            <span className="font-size-12 fw-bold text-black">05:30 pm</span>
          </div>
        </div>
        <div className="my-3">
          <div className="font-size-10 text-muted">Single Elimination</div>
          <div className="font-size-14 fw-medium text-black">Tournament Title</div>
        </div>
        <div className="text-muted font-size-10 d-flex align-items-start match-location">
          <div>
          <i class="uil-location-point"></i>
          </div>
          &nbsp;
          <div>Court Jersey 01, Madison Ave, New York.</div>
        </div>
        <div className="d-flex justify-content-between match-fee-collection">
          <div className="d-flex flex-column">
            <span className="font-size-10 text-muted">Entry Fee</span>
            <span className="font-size-14 fw-medium text-black">$25.00</span>
          </div>
          <div className="d-flex flex-column">
            <span className="font-size-10 text-muted">Total Collection</span>
            <span className="font-size-14 fw-medium text-black">$255.00k</span>
          </div>
        </div>
      </CardBody>
    </Link>
  );
}

export default MatchCard;
