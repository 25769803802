import React from "react";
import Skeleton from "react-loading-skeleton";

const ScoreCardSkeleton = () => {
  return (
    <div className="score-card-bottom px-4 py-4 py-xl-3 bg-white mb-3">
      <div className="d-flex flex-xl-row flex-column text-black justify-content-xl-between align-items-center">
        <div className="font-size-18 fw-sb mb-4 mb-xl-0"><Skeleton width={100} /></div>
        <div
          className={`fw-medium font-size-14 rounded px-3 py-1 mb-4 mb-xl-0 
        
        `}
        >
                <Skeleton width={100} />
        </div>
        <div className="d-flex align-items-center mb-4 mb-xl-0">
          
             <Skeleton
            className="img-skeleton-sm rounded-circle"
            width={40}
            height={40}
            animation="wave"
           
      
          />
     
        
          <div className="ms-2 flex-column d-flex">
            <span className="font-size-14 fw-sb">
            <Skeleton width={100} />
            </span>
          </div>
        </div>
        <div className="fw-sb font-size-24 match-ratio p-3 mb-4 mb-xl-0">
        <Skeleton width={100} />
        </div>
        <div className="d-flex align-items-center mb-4 mb-xl-0">
        <Skeleton
            className="img-skeleton-sm rounded-circle"
            width={40}
            height={40}
            animation="wave"
           
      
          />
        
          <div className="ms-2 flex-column d-flex">
            <span className="font-size-14 fw-sb">
            <Skeleton width={100} />
            </span>
          </div>
        </div>
        <div
          className={`fw-medium font-size-14 rounded px-3 py-1  `}
        >
      <Skeleton width={100} />
        </div>
      </div>
    </div>
  );
};

export default ScoreCardSkeleton;
