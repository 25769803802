import api from 'api'
import Endpoints from 'api/endpoints'
import Loader from 'components/Common/Loader'
import TableContainer from 'components/Common/TableContainer'
import { toastError } from 'components/Common/toastify'
import React from 'react'
import { useEffect } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import { Card, CardBody, CardTitle, Input, Row, UncontrolledTooltip } from 'reactstrap'
import banner from "assets/images/default-img.svg"
import TableSkeleton from 'components/Common/TableSekeloton'

const TournamentParticipants = ({ tournamentId = null ,setParticipantsCount=null}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [participants, setParticipants] = useState([]);

  const getParticipants = async (tournamentId) => {
    setIsLoading(true);

    try {
      // console.log("url ",`${Endpoints.participants}/${tournamentId}?page=1&limit=5`)

      const res = await api.get(
        `${Endpoints.participants}/${tournamentId}?page=1&limit=5`
      );
      // res?.data?.data?.forEach(element => {
      //     element?.users?.forEach(user => (users.push(user)))
      // });
      setParticipants(res?.data?.data);
     const participantCount= res?.data?.data?.reduce((acc,team)=>{
       return acc+=team?.users.length
      },0)
      setParticipantsCount(participantCount)
    

      setIsLoading(false);
    } catch (err) {
      toastError();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getParticipants(tournamentId);
  }, [tournamentId]);

  const tData = useMemo(() => {
    if (participants.length > 0) {
      return participants.map((team, ind) => ({
        // checkbox: (
        //   <input
        //     type="checkbox"
        //     className="form-check-input"
        //     id="customCheck1"
        //   />
        // ),
        participant: team?.users?.map((user,ind) => (
          <>
            <Link 
            to={`/team-detail/${team?._id}`} 
            
            
            className={`d-flex flex-nowrap align-items-center text-center ${(team?.users?.length>1&&ind===0)?"ms-3":""}`}>
              <img
                src={user?.additionalInfo?.profileImage || banner}
                className="avatar-xs rounded-circle me-2"
              />
              <span className="ms-md-2 d-inline-block">
                {user?.firstName} {user?.lastName}{" "}
              </span>
              {/* <sup>{team?.isTeamLead === user?._id ?  "(Ct)" : ""}</sup> */}
            </Link>
          </>
        )),
        //   name: "John Doe",
        // rank: (
        //   <div className="d-flex">
        //     <Rating
        //       // onClick={handleRating}
        //       size={15}
        //       initialValue={4.5}
        //       readonly={true}
        //     />
        //     <div className="ms-2 text-muted" style={{ marginTop: "3px" }}>
        //       {4.5}
        //     </div>
        //   </div>
        // ),
        matchesPlayed: (<span className='text-center w-50 d-inline-block'>{team?.wins + team?.loses}</span>),
        wins: (<span className='text-center d-inline-block w-50'>{team?.wins}</span>),
        loses: (<span className='text-center d-inline-block w-50'>{team?.loses}</span>),
        point: (<span className='text-center d-inline-block w-50'>{team?.pointDiff}</span>),
        action: (
          <div className="d-flex  users justify-content-center ">
            <ul className="list-inline font-size-20 contact-links mb-0">
              <li className="list-inline-item">
                <Link
                   to={`/team-detail/${team?._id}`}
          
                  className="text-primary"
                  onClick={() => {
                    //   const users = cellProps.row.original
                    //   handleUserClick(users)
                  }}
                >
                  <i
                    className="uil uil-eye font-size-18"
                    id={`edittooltip_view_${ind}`}
                  />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`edittooltip_view_${ind}`}
                  >
                    view
                  </UncontrolledTooltip>
                </Link>
              </li>
              {/* <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    //   const users = cellProps.row.original
                    //   onClickDelete(users)
                  }}
                >
                  <i
                    className="uil uil-trash-alt font-size-18"
                    id={`deletetooltip_${ind}`}
                  />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`deletetooltip_${ind}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li> */}
            </ul>
          </div>
        ),
      }));
    } else {
      return [];
    }
  }, [participants]);

  const rows = useMemo(
    () =>
      tData.map((el) => ({
        // checkbox: el.checkbox,
        participant: el.participant,
        matchesPlayed: el.matchesPlayed,
        location: el.location,
        // rank: el.rank,
        wins: el.wins,
        loses: el.loses,
        point: el.point,
        action: el.action,
        // id: el._id,
      })),
    [tData]
  );

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <Input
      //       type="checkbox"
      //       className="form-check-input"
      //       id="customCheck1"
      //     />
      //   ),
      //   accessor: "checkbox",
      // },
      {
        Header: "Participants",
        accessor: "participant",
      },
      // {
      //   Header: "Rank",
      //   accessor: "rank",
      //   hasDescription: true,
      // },
      {
        Header: "Matches Played",
        accessor: "matchesPlayed",
        hasDescription: true,
      },
      {
        Header: "Points",
        accessor: "point",
        hasDescription: true,
      },
      {
        Header: "Wins",
        accessor: "wins",
        hasDescription: true,
      },
      {
        Header: "Loses",
        accessor: "loses",
        hasDescription: true,
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  return (
    <Card className="section-card ">
      <CardBody className="pb-2 ">
    
          <>
            <CardTitle className="h4">
              <div className="d-flex justify-content-between">
                <span className="text-black fw-bold font-size-16">
                  Participants
                </span>
                <Link
                  to={`/participants-list/${tournamentId}`}
                  className="text-blue font-size-12 fw-normal"
                >
                  View all
                </Link>
              </div>
            </CardTitle>
            <Row className="py-1  overflow-auto" style={{ height: "300px" }}>
             {
              isLoading?(<TableSkeleton/>):( <TableContainer
                columns={columns}
                // rows={rows}
                data={rows}
                isGlobalFilter={true}
                isAddUsers={true}
                isAddTableBorderStrap={true}
                // handleUserClicks={handleUserClicks}
                addPagination={false}
                // topBar={topBar}
              />)
             }
            </Row>
          </>
       
      </CardBody>
    </Card>
  );
};

export default TournamentParticipants;
