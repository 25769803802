import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Row, Table } from 'reactstrap';
import HtmlHead from '../html-head/HtmlHead';
import queryParamBuilder from 'api/queryParamBuilder';
import api from 'api';
import Endpoints from 'api/endpoints';
import { toastError } from './toastify';

import Matches from 'components/Matches';
import MyTournamentCard from 'pages/Users/components/MyTournamentCard';
import ChallengeCard from 'components/ChallengeCard';
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect,
  } from "react-table";
import PaginationButton from './PaginationButton';
import TableContainer from './TableContainer';

const challengeOptionsByCourt= [
    { title: "All", status: "all" },
    { title: "Upcoming Matches", status: "upcoming " },
    { title: "Accepted", status: "accepted" },
    { title: "Rejected", status: "rejected" },
    { title: "Verified Score", status: "verifiedScore" },
    { title: "Verified Score Rejected", status: "verifiedScoreRejected" },
    { title: "Change Request", status: "changeRequested" },
    { title: "Closed", status: "closed" },
  //   upcoming | rejected | verifiedScore  | verifiedScoreRejected | closed | all | accepted | changeRequested
  ]
  const matchOptionsByCourt= [
    { title: "All", status: "all" },
    { title: "Played", status: "played " },
    { title: "Up coming", status: "queued" },
    
  ]



const CourtsMatchListing=()=> {

  const param=useParams();
  const {pathname} = useLocation()
  const lastPoint=pathname.split("/")[2]
  const {courtId }= param;

  const title =  `Court ${lastPoint[0].toUpperCase() + lastPoint.slice(1)}`;
  const description = `Court ${lastPoint[0].toUpperCase() + lastPoint.slice(1)} page`;
  const defaultStatus=lastPoint==="challenge"?challengeOptionsByCourt[0]:matchOptionsByCourt[0]
  const statusOptions=lastPoint==="challenge"?challengeOptionsByCourt:matchOptionsByCourt
//   data states
const [isLoading,setIsLoading]=useState(false)
const [currentPage,setCurrentPage] =useState(1);
const [totalPages,setTotalPages] = useState(null);
const [filters,setFilters]=useState({
  status:defaultStatus,
  searchValue:""
})

const [fetchedData,setFetchedData] = useState([]);
// const [searchValue,setSearchValue] = useState("")





const fetchDatahandler=async (courtId)=>{

   
      try{
        let path=''
        if(lastPoint==="challenges"){
            path=`${Endpoints.challengesByCourt}?courtId=${courtId}&status=${filters?.status?.status}&page=${filters?.searchValue?1:currentPage}&limit=10`
        }else {
            path=`${Endpoints.courtMatch}?courtId=${courtId}&status=${filters?.status?.status}`
        }
        setIsLoading(true);
        const res =   await api.get(path)
        setTotalPages(res?.data?.totalPages)
       
        setFetchedData(res?.data?.data)
        setIsLoading(false)
      }catch(err) {
        toastError()
        
        setIsLoading(false)
      }
  
}



const clearFilters=()=>{
  setFilters({
    status:defaultStatus,
    searchValue:""
  })
}


useEffect(()=>{
    fetchDatahandler(courtId)
},[courtId,currentPage,filters.searchValue,filters.status])



//   table data
 
const tData=fetchedData?.map((el,ind)=>lastPoint==="challenge"?<ChallengeCard data={el} key={ind} />:<Matches data={el} key={ind} />)


//   const rows = useMemo(() => {

//     if(tData.length > 0) {
//        return tData?.map((el) => ({
//             card: el,
//           }));
//     } else return []
  
//   }, [tData]);

//   columns
const rows=[]
  const columns =[
    {
      Header: 'Card',
      accessor: 'card',
    },
  ];



  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <div className="fw-bold font-size-24 mb-3 text-black">{title}</div>
          <div className="mb-4">
        <TableContainer 
          columns={columns}
          data={rows}
          isGlobalFilter={true}
          isAddUsers={true}
          isAddTableBorderStrap={true}
          // handleUserClicks={handleUserClicks}
          isLoading={isLoading}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          addPagination={true}
          totalPages={totalPages}
        />
    
          </div>
        </Container>
      </div>
    </>
  );
}

export default CourtsMatchListing;
