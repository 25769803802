import React from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor, sagaMiddleware } from "./store";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";


import { PersistGate } from "redux-persist/integration/react";
import rootSaga from "store/sagas";
// import "react-range-slider-input/dist/style.css";
sagaMiddleware.run(rootSaga);
if ('serviceWorker' in navigator) {
  // navigator.serviceWorker
  //   .register('/firebase-messaging-sw.js')
  //   .then((registration) => {
  //     console.log('Service Worker registered with scope:', registration.scope);
  //   })
  //   .catch((error) => {
  //     console.error('Error registering service worker:', error);
  //   });
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

serviceWorker.unregister();
