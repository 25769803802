import React from 'react';
import { Col, Row } from 'reactstrap';

function EarningsCard({ title, data }) {
  return (
    <div className="earnings-wrap">
      <div className="p-3">
        <h4 className="font-size-16 text-black mb-3">{title}</h4>
        <Row className="row-cols-2 row-col-sm-auto d-flex justify-content-between">
          {data?.map((el, ind) => (
            <Col className={`col-sm-4 ${ind>1?"mt-3 mt-sm-0 ":""}`} key={ind}>
              <div className="border-left ps-2 ps-sm-3">
                <div className="text-muted text-nowrap font-size-12">{el.title}</div>
                <div className="font-size-24 fw-bold earning">{el.val}</div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default EarningsCard;
