import React from "react";
import moment from "moment/moment";
import banner from 'assets/images/default-banner-3.png'

const ProfileSidebar = ({ data }) => {
  return (
    <aside className="card-layout    bg-blue py-3 px-3 profile-sideBar font-size-16 fw-bold pb-5">
      <div className="top-info p-4 d-flex justify-content-center align-items-center text-white flex-column">
        <div className="pic">
          <img
            src={data?.additionalInfo?.profileImage||banner}
            alt="avtr"
            className=" img-fluid h-100 object-fit-cover "
          />
        </div>
        <span className="mt-2">
          {data?.firstName} {data?.lastName}
        </span>
        <span className="mt-2">
          {/* <img src="/img/users/trophy.svg" alt="trophy" /> */}
          <i className="uil-trophy text-layout font-size-18" />
          <span className="text-layout ms-2">
            # S:{data?.additionalInfo?.matchDetails?.single?.rating} D:
            {data?.additionalInfo?.matchDetails?.double?.rating} Ratings
          </span>
        </span>
      </div>
      <div className="text-white d-flex flex-column mt-4">
        <span>Personal Information</span>
        <span className="text-layout my-2">
          {/* <img src="/img/users/gender.svg" alt="gender" /> */}
          <i className="mdi mdi-gender-male-female font-size-20" />
          <span className="ms-2 text-capitalize ">
            {data?.additionalInfo?.gender}
          </span>
        </span>
        <span className="text-layout d-flex align-items-center">
          {/* <img src="/img/users/dob.svg" alt="dob" /> */}
          <i className="mdi mdi-cake-layered font-size-20"></i>
          <span className="ms-2">
            {moment(data?.createdAt).format("MMMM DD, YYYY") || "--"}
          </span>
        </span>
      </div>
      <div className="text-white d-flex flex-column mt-4 pb-5">
        <span>Contact Information</span>
        <span className="text-layout my-2">
          {/* <img src="/img/users/gender.svg" alt="gender" /> */}
          <i className="uil-envelope font-size-20" />
          <span className="ms-2">{data?.email}</span>
        </span>
        <span className="text-layout d-flex align-items-start">
          {/* <img src="/img/users/dob.svg" alt="dob" /> */}
          <i className="uil-location-point font-size-20" />

          <span className="ms-2">
            {data?.additionalInfo?.location?.description}
          </span>
        </span>
      </div>
    </aside>
  );
};

export default ProfileSidebar;
