import React from "react";
import { challengeStatus, challenges } from "utils/challengeStatus";
import banner from "assets/images/default-img.svg";

import moment from "moment/moment";
import PointIcon from "assets/icons/PointIcon";
import { statusText } from "utils/matchStatus";
import { matchStatus } from "utils/matchStatus";
import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

function Matches({ data, round = null }) {
  let teamApoint = 0;
  let teamBpoint = 0;
  data?.matchScore?.forEach((match) =>
    match?.teamA > match?.teamB ? teamApoint++ : teamBpoint++
  );

  return data != null ? (
    <Link to={`/match-details/${data?._id}`}>
    <Row className=" justify-content-evenly matches-wrap bg-white p-3 align-items-center mb-3">
      {/* data & time */}

      {/* <div className="pt-5 pt-md-0 ">
        <div className="d-flex flex-column align-items-center align-items-md-start">
          <div className="fw-bold font-size-14 text-black  text-nowrap ">{moment(data?.challengeTime).format(' hA')}</div>
          <div className="font-size-12 text-muted">{moment(data?.challengeDate).format('MMMM DD')||"--"}</div>
        </div>
      </div> */}
      {/* Team A */}
      <Col className="col-4 col-sm-2 col-md-3 col-lg-2">
      <div className="d-flex flex-column ">
          {data?.teamA?.users?.length >0 ? (
            data?.teamA?.users?.map((mem, ind) => {
              return (
                <div
                  className={`d-flex align-items-center flex-column flex-md-row ${
                    ind < 1 ? "ms-md-3" : ""
                  } ${ind > 0 ? "d-none d-md-flex" : ""} winner-team`}
                >
                  {/* {mem?._id===data?.winner?.at(ind)&&<img src="/img/global/batch.svg" className='winner-label' alt="batch" />} */}
                  <img
                    src={mem?.additionalInfo?.profileImage || banner}
                    className="header-profile-user rounded-circle"
                    alt=""
                  />
                  <div className="ms-2">
                    <div className="font-size-14 fw-bold text-black  text-center text-md-start">
                      {mem?.firstName}
                      {/* {data?.teamA[0]?.lastName} */}
                    </div>
                    <span className="text-nowrap">
                      <i className="uil-trophy text-muted font-size-14" />
                      <span className="ms-1 text-muted font-size-12 text-nowrap ">
                        #S:{mem?.additionalInfo?.matchDetails?.single?.rating}{" "}
                        D:
                        {mem?.additionalInfo?.matchDetails?.double?.rating}{" "}
                        Rating
                      </span>
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="d-flex flex-column  ">
              <Row className="align-items-center  justify-content-md-around " >
                  <Col className="col-12 col-md-2">
                    <Skeleton circle={true}  className="img-skelton-sm"  />
                  </Col>
                  <Col className="col-md-7 col-lg-10 ps-3">
                   
                    <Skeleton count={1} className="match-skelton-t" height={"10px"} />
                    <Skeleton count={1} className=" match-skelton-b" height={"10px"}/>
                  </Col>
                </Row>
           
            </div>
          )}
          {}
        </div>
      </Col>
      {/* points */}
      <Col className="col-4 col-sm-2 col-md-1 ">
        <div
          className="font-size-14 fw-bold match-score-container mx-auto  d-flex align-items-center justify-content-center text-black   "
          style={{
            backgroundImage: "url(/img/global/score-bg.svg)",
          }}
        >{`${teamApoint}:${teamBpoint}`}</div>
        {/* <div className="d-flex flex-column align-items-center mt-5 mt-sm-0  ">
          {
            data?.matchScore?.map((score,ind)=>{
              return <span key={ind} className={`fw-sb font-size-16 match-ratio px-2 ${ind>0?"mt-1":""}`}>{score?.teamA}/{score.teamB}</span>
            })
          }
        </div> */}
      </Col>

      {/* Team B*/}
      <Col className="col-4 col-sm-2 col-md-3 col-lg-3">
        <div className="d-flex flex-column ">
          {data?.teamB?.users?.length >0 ? (
            data?.teamB?.users?.map((mem, ind) => {
              return (
                <div
                  className={`d-flex align-items-center flex-column flex-md-row ${
                    ind < 1 ? "ms-md-3" : ""
                  } ${ind > 0 ? "d-none d-md-flex" : ""} winner-team`}
                >
                  {/* {mem?._id===data?.winner?.at(ind)&&<img src="/img/global/batch.svg" className='winner-label' alt="batch" />} */}
                  <img
                    src={mem?.additionalInfo?.profileImage || banner}
                    className="header-profile-user rounded-circle"
                    alt=""
                  />
                  <div className="ms-2">
                    <div className="font-size-14 fw-bold text-black  text-center text-md-start">
                      {mem?.firstName}
                      {/* {data?.teamA[0]?.lastName} */}
                    </div>
                    <span className="text-nowrap">
                      <i className="uil-trophy text-muted font-size-14" />
                      <span className="ms-1 text-muted font-size-12 text-nowrap ">
                        #S:{mem?.additionalInfo?.matchDetails?.single?.rating}{" "}
                        D:
                        {mem?.additionalInfo?.matchDetails?.double?.rating}{" "}
                        Rating
                      </span>
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="d-flex flex-column  ">
               <Row className="align-items-center  justify-content-md-around " >
                  <Col className="col-12 col-md-2">
                    <Skeleton circle={true}  className="img-skelton-sm"  />
                  </Col>
                  <Col className="col-md-7 col-lg-10">
                   
                    <Skeleton count={1} className="match-skelton-t" height={"10px"} />
                    <Skeleton count={1} className=" match-skelton-b" height={"10px"}/>
                  </Col>
                </Row>
              
            </div>
          )}
          {}
        </div>
      </Col>

      {/* location */}
      <Col className=" col-lg-2 d-none d-lg-block ">
        <div className="d-none d-md-flex flex-column align-items-center align-items-xl-start ">
          <div className="font-size-12 text-muted  mb-1">Location</div>
          <div
            className="font-size-12 text-black  text-lg-center text-xl-start text-truncate  "
            style={{ maxWidth: "100px" }}
          >
            {data?.court?.courtId?.location?.description}
          </div>
        </div>
      </Col>

      {/* court */}
      <Col className="col-6 col-sm-2 col-lg-1">
        <div className="d-flex flex-column  align-items-start align-items-md-start ">
          <div className="font-size-12 text-muted mb-1">Court</div>
          <div
            className="font-size-12 text-black text-truncate "
            style={{ maxWidth: "100px" }}
          >
            {data?.court?.courtId?.name}
          </div>
        </div>
      </Col>

      {/* status */}
      <Col className="col-6 col-sm-2 col-lg-2 text-end">
        <span
          className={`${matchStatus(
            data?.status
          )}  mt-3 mt-md-0 px-2 py-1 rounded-3  font-size-12  d-inline-block text-truncate`}
        >
          {statusText(data?.status)}
        </span>
      </Col>
    </Row>
    </Link>
  ) : (
    <>
      <div className=" my-3  matches-wrap bg-white p-3  text-center ">
        To be decided {round}
      </div>
    </>
  );
}

export default Matches;
