import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Common/withRouter";

// users
import banner from "assets/images/default-img.svg"
import UserContext from "globalContext/userContext";
import api from "../../../api";
import Endpoints from "api/endpoints";
import { useDispatch } from "react-redux";
import { logout } from "store-point/slices/authSlice";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const dispatch = useDispatch();
  const navigate=useNavigate()
  // console.log("userNew: ", currentUser);

  const userId = localStorage.getItem("user");

  const getUser = async () => {
    if(userId !==null){
     try{
      const res = await api.get(`${Endpoints.getUserProfile}/${userId}`);
      if (res.data.success) {
        setCurrentUser(res.data.data);
      }
     }catch(e){

     }
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const logoutHandler=()=>{
   
    dispatch(logout())
    localStorage.clear()
    navigate("/login")
  }
  
  // console.log("currentUser: ", user);

  // const [username, setusername] = useState("Admin");

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.displayName);
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       if (obj.username) {
  //         setusername(obj.username);
  //       } else {
  //         setusername(obj.name);
  //       }
  //     }
  //   }
  // }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={currentUser?.additionalInfo?.profileImage||banner}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
            {currentUser?.firstName}
          </span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a">
            <Link to={`/user-profile/${currentUser?._id}`}>
              <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
              {props.t("View Profile")}{" "}
            </Link>
            {/* <DropdownItem tag="a" href=""> */}{" "}
          </DropdownItem>
          <DropdownItem tag="a">
            <Link to={`/edit-user/${currentUser?._id}`}>
              <i className="uil-pen font-size-18 align-middle text-muted me-1"></i>
             Edit Profile
            </Link>
            {/* <DropdownItem tag="a" href=""> */}{" "}
          </DropdownItem>
          <DropdownItem tag="a" >
          <Link to={`/update-password`}>
              <i className="uil-lock-alt font-size-18 align-middle text-muted me-1"></i>
                Update Password
            </Link>
          </DropdownItem>
          {/* <DropdownItem tag="a" href="#">
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Settings")}
            <span className="badge bg-soft-success rounded-pill mt-1 ms-2">
              03
            </span>
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <button to="#" onClick={logoutHandler} className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
