import React, {useEffect, useMemo, useState} from 'react';

import {Container} from 'reactstrap';

import queryParamBuilder from 'api/queryParamBuilder';
import api from 'api';
import Endpoints from 'api/endpoints';
import ChallengeCard from 'components/ChallengeCard';
import UserMatchListingTable from 'components/Common/UserMatchListingTable';
import HtmlHead from 'components/html-head/HtmlHead';
import {toastError} from 'components/Common/toastify';

// "all",
// "upcoming",
// "received",
// "verify",
// "post",
// "homeFeed",
// "post",
// "delete",
const challengesStatusOptions = [
  {
    title: 'All',
    status: 'all',
  },
  {
    title: 'Up coming',
    status: 'upcoming',
  },
  {
    title: 'Received',
    status: 'received',
  },
  {
    title: 'Verify',
    status: 'verify',
  },
  {
    title: 'Post',
    status: 'post',
  },
  {
    title: 'Home Feed',
    status: 'homeFeed',
  },
  {
    title: 'Delete',
    status: 'delete',
  },
];

const Challenges = () => {
  //   const param=useParams();
  //   const {pathname} = useLocation()
  //   const lastPoint=pathname.split("/")[1]
  //   const userId = param.userId;

  //   const title =  `My ${lastPoint[0].toUpperCase() + lastPoint.slice(1)}`;
  //   const description = `My ${lastPoint[0].toUpperCase() + lastPoint.slice(1)} page`;
  const title = `Challenges`;
  const description = 'Challenges Description';
  //   data states
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({
    searchValue: '',
    location: {},
    status: challengesStatusOptions[0].status,
  });

  const fetchDatahandler = async () => {
    const getAllChallenges = queryParamBuilder({
      endpoint: Endpoints.getChallengesDashboard,
      // userId:userId,
      limit: 10,
      page: currentPage,
      search: filters.searchValue,
      status: filters.status,
      location: filters.location,
    });
    try {
      setIsLoading(true);
      const res = await api.get(getAllChallenges);
      setTotalPages(res?.data?.totalPages);
      setFetchedData(res?.data?.data);
      setIsLoading(false);
    } catch (err) {
      toastError();

      setIsLoading(false);
    }
  };

  const searchValueHandler = e => {
    e.preventDefault();
    setSearchValue(e.target.value);
    fetchedData();
  };
  // clear filters

  const clearFilters = () => {
    setSearchValue('');
    setFilters({
      searchValue: '',
      location: {},
      status: challengesStatusOptions[0]?.status,
    });
  };

  useEffect(() => {
    fetchDatahandler();
  }, [currentPage, filters.status, filters.searchValue, filters.location]);

  //   table data

  const tData = fetchedData?.map((el, ind) => (
    <ChallengeCard data={el} key={ind} />
  ));

  const rows = useMemo(() => {
    return tData?.map(el => ({
      card: el,
    }));
    return [];
  }, [tData]);

  //   columns
  const columns = useMemo(
    () => [
      {
        Header: 'Card',
        accessor: 'card',
      },
    ],
    [rows],
  );

  // tables top bar

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <div className="fw-bold font-size-24 mb-3 text-black">{title}</div>
          <div className="mb-4">
            <UserMatchListingTable
              columns={columns}
              data={rows}
              isGlobalFilter
              isAddInvoice
              isAddTableBorderStrap
              //   handleAdd={handleAdd}
              tableData={tData}
              // topBar={topBar}
              createShow={false}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              onSearch={searchValueHandler}
              isLoading={isLoading}
              filters={filters}
              setFilters={setFilters}
              statusOptions={challengesStatusOptions}
              clearFilters={clearFilters}
              // addBtn="FAQ"
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Challenges;
