import axios from "axios";
import { toastError } from "components/Common/toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Use `useNavigate` instead of importing `Navigate` directly
// import { logout } from '../store/slices/authSlice';


const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  withCredentials: false,
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
     
   
    }
    return config;
  },
  (error) => {
    
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("request error:",error?.response?.status)
    if (error?.response?.status === 401) {
      localStorage.clear();
      // const dispatch = useDispatch();
      // dispatch(logout());
      // await dispatch(logout());
      // const navigate = useNavigate();
      // navigate("/login");
      window.location.href=`${process.env.REACT_APP_LOCAL_SERVER}login`
    } else if (error.code==="ERR_NETWORK"){
      toastError("Network Error")
    }
    return Promise.reject(error);
  }
);

export default api;
