import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import api from 'api';
import Endpoints from 'api/endpoints';
import DeleteModal from 'components/Common/DeleteModal';
import TableContainer from 'components/Common/TableContainer';
import { Card, CardBody, Col, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, UncontrolledTooltip } from 'reactstrap';
import PointIcon from 'assets/icons/PointIcon';
import HtmlHead from 'components/html-head/HtmlHead';
import { toastError } from 'components/Common/toastify';
import { toastSuccess } from 'components/Common/toastify';
import FaqTable from './components/FaqTable';



function Faqs() {
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalpages,setTotalPages]=useState(3)
  const [faqID, setFaqID] = useState(null);
  const [faqById,setFaqById]=useState({})
  const [isLoading, setIsLoading] = useState(true);
  const [faqData, setFaqData] = useState([]);

  const title = 'FAQs';
  const description = 'FAQs Page';

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Please Enter Your FAQ Title'),
    description: Yup.string().required('Please Enter Your FAQ Description'),
  });

  // fetch faqs

  const getFaqs = async () => {
    try {
      const res = await api.get(`${Endpoints.faq}?page=${pageNum}&limit=${pageLimit}`);
      setIsLoading(false);
      setFaqData(res?.data);
      
      setTotalPages(res?.data?.totalPages)
    } catch (error) {
      toastError();
      console.log(error.message);
    }
  };

  useEffect(() => {
    getFaqs();
  }, []);

  const onSubmit = async (values) => {
    // edit faq
    if (isEdit) {
      setIsEdit(false)
      try {
        await api.put(`${Endpoints.faq}/${faqID}`, {
          title: values.title,
          description: values.description,
        });
        setShowModal(false);
        
        toastSuccess('Edited');
        setFaqById({})
        getFaqs();
       
      } catch (error) {
        toastError();
        console.log(error.message);
      }
    } else {
      // create faq
      try {
        await api.post(Endpoints.faq, {
          title: values.title,
          description: values.description,
        });
        setShowModal(false);
        toastSuccess('Created');
        getFaqs();
      } catch (error) {
        toastError();
        console.log(error.message);
      }
    }
  };

  const rows = useMemo(() => {
    if (faqData?.data) {
      return faqData.data.map((el) => ({
        title: el.title,
        description: el.description,
        id: el._id,
      }));
    }
    return [];
  }, [faqData]);


  const getFaqById=async (id)=>{
    try{
        const rep=await api.get(`${Endpoints.faq}/${id}`);
        setFaqById(rep?.data?.data)
    }catch(error){

    }
  }

  const onClickDelete = () => setDeleteModal(true);

  // delete faq
  const handleDeleteFaq = async () => {
    try {
      await api.delete(`${Endpoints.faq}/${faqID}`);
      toastSuccess('Deleted');
      getFaqs();
    } catch (error) {
      // toastError();
      console.log(error);
    }
    setDeleteModal(false);
  };

  const handleAdd = () => setShowModal(true);

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Description',
        accessor: 'description',
        hasDescription: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              
                <Link
                  to="#"
                  className="text-primary"
                  onClick={() => {
                    const faqList = cellProps.row.original;
                    setFaqID(faqList.id);
                    setIsEdit(true);
                    getFaqById(faqList.id)
                    setShowModal(true);
                  }}
                  id={`edit-tooltip-${cellProps.row.original.id}`}
                >
                  {/* <i className="uil uil-pen font-size-18" /> */}
                  <PointIcon name="pen" height="20px" width="20px" />
                </Link>
                  <UncontrolledTooltip target={`edit-tooltip-${cellProps.row.original.id}`} placement='bottom' >Edit</UncontrolledTooltip>
              
             
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const faqList = cellProps.row.original;
                    setFaqID(faqList.id);

                    onClickDelete();
                  }}
                >
                  <i className="uil uil-trash-alt font-size-18" id="delete-tooltip"/>
                  <UncontrolledTooltip placement='bottom' target="delete-tooltip" >Delete</UncontrolledTooltip>
                </Link>
           
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      {/* <ToastContainer /> */}
      <HtmlHead title={title} description={description} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteFaq} onCloseClick={() => setDeleteModal(false)} />

      <div className="page-content">
        <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
            <h5 className="fw-bold">FAQs List</h5>
          
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
              <Spinner />
            </div>
          ) : (
            <>
             <Card>
            
              <CardBody>
              <div className="text-start">
              <buton type="button" color="primary" className="primary-gradient text-white font-size-14 p-2 pe-3" onClick={handleAdd}>
                <i className="mdi mdi-plus me-1" />
                Add FAQ
              </buton>
            </div>
              <Row>
                <Col xs={12}>
                  <FaqTable
                    columns={columns}
                    data={rows}
                    isGlobalFilter
                    isAddInvoice
                    isAddTableBorderStrap
                    handleAdd={handleAdd}
                    tableData={faqData}
                    topHeading="FAQs"
                    addBtn="FAQ"
                    currentPage={pageNum}
                    setCurrentPage={setPageNum}
                    totalPages={totalpages}
                  />
                </Col>
              </Row>
              </CardBody>
             </Card>
              <Modal isOpen={showModal} toggle={() => {
                setShowModal(false)
                setFaqById({})
                setIsEdit(false)
                }}>
                <ModalHeader closeButton toggle={() => {
                  
                  setShowModal(false)
                  setFaqById({})
                  setIsEdit(false)
                  }}>{isEdit ? 'Edit FAQ' : 'Add FAQ'}</ModalHeader>
                <ModalBody>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      title: `${faqById?.title||""}`,
                      description: `${faqById?.description||""}`,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="text-secondary" for="title">Title</Label>
                                <Input
                                  type="text"
                                  name="title"
                                  value={values?.title}
                                  placeholder={"Faq Title"}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage name="title" component="div" className="d-block invalid-tooltip" />
                              </FormGroup>
                            </div>
                            <div className="mb-3">
                              <FormGroup>
                                <Label className="text-secondary" for="description">Description</Label>
                                <Input
                                  type="textarea"
                                  name="description"
                                  value={values?.description}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder={"Faq description"}
                                ></Input>
                                <ErrorMessage name="description" component="div" className="d-block invalid-tooltip" />
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button type="submit" className="btn btn-success save-user" disabled={isSubmitting}>
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </ModalBody>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Faqs;
