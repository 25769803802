import React from 'react'
import { Spinner } from 'reactstrap'

const Loader = ({height}) => {
  return (
    <div className={`d-flex align-items-center  justify-content-center  w-100  ${height?height:"h-100 "}`}>
        <Spinner/>
    </div>
  )
}

export default Loader