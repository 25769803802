import React, {useEffect, useMemo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Link, useParams} from 'react-router-dom';
import banner from 'assets/images/default-img.svg';

//import components

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import DeleteModal from '../../components/Common/DeleteModal';
import Listing from 'components/Common/Listing';
import Endpoints from 'api/endpoints';
import api from 'api';
import {toastError} from 'components/Common/toastify';
import moment from 'moment/moment';
import {Rating} from 'react-simple-star-rating';
import {ToastContainer} from 'react-toastr';
import {toastSuccess} from 'components/Common/toastify';
import queryParamBuilder from 'api/queryParamBuilder';
import HtmlHead from 'components/html-head/HtmlHead';
import PointIcon from 'assets/icons/PointIcon';
import {matchStatus} from 'utils/matchStatus';

const tabNames = [
  {
    title: 'All Users',
    // endpoint: Endpoints.getAll,
    endpoint: Endpoints.userRank,
    type: '',
  },
  {
    title: 'Reported Users',
    endpoint: Endpoints.getBlockedUser,
    type: 'report',
  },
  {
    title: 'Blocked Users',
    endpoint: Endpoints.getBlockedUser,
    type: 'block',
  },
];

function UsersListing() {
  const title = 'Users Listing';
  const description = 'Users Page';

  const {report} = useParams();

  // List tabes
  const [activeTab, setActiveTab] = useState(
    report === 'report' ? tabNames.at(1) : tabNames.at(0),
  );

  const [fetchList, setFetchlist] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // paginaion states
  const [currentPage, setCurrentPage] = useState(1);
  // const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({
    searchValue: '',
    location: {},
    rating: {},
  });

  const [totalPages, setTotalPages] = useState(null);
  const [rankFilterValues, setRankFilterValues] = useState({
    gender: 'male',
    minAge: 0,
    maxAge: 0,
    minRating: 0,
    maxRating: 0,
    type: 'single',
    range: 0,
  });

  // fetching data
  const fetchListHandler = async () => {
    setIsLoading(true);
    const url = queryParamBuilder({
      page: filters.searchValue ? 1 : currentPage,
      endpoint: activeTab.endpoint,
      limit: 10,
      type: activeTab.type,
      search: filters.searchValue,
      location: filters.location,
    });

    const updUrl = `${Endpoints.getAll}?page=${
      filters.searchValue ? 1 : currentPage
    }&limit=10&search=${filters.searchValue}&gender=${
      rankFilterValues?.gender
    }&minAge=${rankFilterValues?.minAge}&maxAge=${
      rankFilterValues?.maxAge
    }&minRating=${rankFilterValues?.minRating}&maxRating=${
      rankFilterValues?.maxRating
    }&type=${rankFilterValues?.type}&range=${
      rankFilterValues?.range
    }&longitude=${filters?.location?.long || 0}&latitude=${
      filters?.location?.lat || 0
    }`;

    try {
      const resp = await api.get(
        activeTab?.title === 'All Users' ? updUrl : url,
      );

      setFetchlist(resp?.data?.data);
      setTotalPages(resp?.data?.totalPages);
    } catch (error) {
      toastError();
    }
    setIsLoading(false);
  };

  // const onSearch = (e) => {
  //   e.preventDefault();
  //   setSearchValue(e.target.value)

  // }

  const clearFilterHandler = () => {
    setFilters({
      searchValue: '',
      location: {},
      rating: {},
    });
    setRankFilterValues({
      gender: 'male',
      minAge: 0,
      maxAge: 0,
      minRating: 0,
      maxRating: 0,
      type: 'single',
      range: 0,
    });
  };

  useEffect(() => {
    fetchListHandler();
  }, [
    currentPage,
    filters.searchValue,
    activeTab,
    filters.location,
    filters.rating,
    rankFilterValues,
  ]);

  //delete user
  const [deleteModal, setDeleteModal] = useState(false);
  const [user, setUser] = useState(null);

  const onClickDelete = async userId => {
    try {
      const res = await api.delete(`${Endpoints.deleteUser}/${userId}`);

      if (res.status === 200) {
        toastSuccess('User Deleted');
        fetchListHandler();
      }
    } catch (err) {
      toastError();
    }
    setDeleteModal(false);
  };

  // data destruction
  const tData = useMemo(() => {
    if (fetchList) {
      return activeTab.title === 'All Users'
        ? fetchList?.map((el, ind) => {
            return {
              // checkbox: (
              //   <input type="checkbox" className="form-check-input" id="customCheck1" />
              // ),
              name: (
                <Link
                  to={`/user-profile/${el?._id}`}
                  className="d-flex flex-row align-items-center text-center">
                  <img
                    src={el?.additionalInfo?.profileImage || banner}
                    className="avatar-xs rounded-circle me-2"
                  />
                  <span className="ms-md-2 text-nowrap ">
                    {el?.firstName || '-'} {el?.lastName}
                  </span>
                </Link>
              ),
              //   name: "John Doe",
              email: `${el?.email || '--'}`,
              location: (
                <span
                  className=" text-truncate d-inline-block"
                  style={{maxWidth: '100px'}}>
                  {el?.additionalInfo?.location?.description || '--'}
                </span>
              ),
              rating: (
                <span className="text-nowrap">
                  S:{el?.additionalInfo?.matchDetails?.single?.rating}/D:
                  {el?.additionalInfo?.matchDetails?.double?.rating}
                </span>
              ),
              winLose: `${
                el?.additionalInfo?.matchDetails?.single?.win +
                  el?.additionalInfo?.matchDetails?.double?.win || '-'
              }/${
                el?.additionalInfo?.matchDetails?.single?.loss +
                  el?.additionalInfo?.matchDetails?.double?.loss || '-'
              }`,
              userSince: `${
                moment(el?.createdAt).format('MMMM DD, YYYY') || '--'
              }`,

              court: `${el?.additionalInfo?.tagLine || '--'}`,
              action: (
                <div className="d-flex flex-nowrap users">
                  <ul className="list-inline font-size-20 contact-links mb-0">
                    <li className="list-inline-item">
                      <Link
                        to={`/user-profile/${el?._id}`}
                        className="text-primary"
                        onClick={() => {
                          //   const users = cellProps.row.original
                          //   handleUserClick(users)
                        }}>
                        <i
                          className="uil uil-eye font-size-18"
                          id={`edittooltip_view_${ind}`}
                        />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={`edittooltip_view_${ind}`}>
                          view
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to={`/edit-user/${el?._id}`}
                        className="text-primary"
                        onClick={() => {
                          //   const users = cellProps.row.original
                          //   handleUserClick(users)
                        }}>
                        <PointIcon
                          name="pen"
                          height="20px"
                          width="20px"
                          className=""
                          id={`edittooltip_edit_${ind}`}
                        />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={`edittooltip_edit_${ind}`}>
                          Edit
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="text-danger"
                        onClick={() => {
                          //   const users = cellProps.row.original
                          setDeleteModal(true);
                          setUser(el?._id);
                        }}>
                        <i
                          className="uil uil-trash-alt font-size-18 text-danger "
                          id={`deletetooltip_del_${ind}`}
                        />
                        <UncontrolledTooltip
                          placement="bottom"
                          target={`deletetooltip_del_${ind}`}>
                          Delete
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                    <li className="list-inline-item "></li>
                  </ul>
                </div>
              ),
            };
          })
        : fetchList?.map((el, ind) => ({
            // checkbox: (
            //     <input type="checkbox" className="form-check-input" id="customCheck1" />
            //   ),
            name: (
              <Link
                to={`/user-profile/${el?.userId?._id}`}
                className="d-flex flex-nowrap  align-items-center text-center">
                <img
                  src={el?.userId?.additionalInfo?.profileImage || banner}
                  className="avatar-xs rounded-circle me-2"
                />
                <span className="ms-md-2 text-capitalize  ">
                  {el?.userId?.firstName || '-'} {el?.userId?.lastName || '-'}
                </span>
              </Link>
            ),
            // email:el?.blockedUsersDetails?.email||"--",
            reportedBy: (
              <Link
                to={`/user-profile/${el?.blockedBy?._id}`}
                className="d-flex flex-nowrap  align-items-center text-center">
                <img
                  src={el?.blockedBy?.additionalInfo?.profileImage || banner}
                  className="avatar-xs rounded-circle me-2"
                />
                <span className="ms-md-2 text-capitalize  ">
                  {el?.blockedBy?.firstName || '-'}{' '}
                  {el?.blockedBy?.lastName || '-'}
                </span>
              </Link>
            ),
            // location:<p className='text-truncate' style={{maxWidth:"120px"}}>{el?.userId?.additionalInfo?.location?.description}</p>,
            reason: el?.blockDetail || '--',

            status: (
              <div className="d-flex flex-nowrap align-items-center">
                <span className={`${matchStatus('blocked')} match-status`}>
                  Blocked
                </span>
              </div>
            ),
            rating: ` S:${
              el?.userId?.additionalInfo?.matchDetails?.single?.rating || '-'
            }/D:${
              el?.userId?.additionalInfo?.matchDetails?.double?.rating || '-'
            }`,
            action: (
              <div className="d-flex gap-3 users">
                <ul className="list-inline font-size-20 contact-links mb-0">
                  <li className="list-inline-item">
                    <Link
                      to={`/user-profile/${el?.userId?._id}`}
                      className="text-primary"
                      onClick={() => {
                        //   const users = cellProps.row.original
                        //   handleUserClick(users)
                      }}>
                      <i
                        className="uil uil-eye font-size-18"
                        id={`edittooltip_view_${el?.userId?._id}`}
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`edittooltip_view_${el?.userId?._id}`}>
                        view
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => {
                        //   const users = cellProps.row.original
                        // setDeleteModal(true);
                        // setUser(el?._id)
                      }}>
                      <i
                        className="uil uil-trash-alt font-size-18"
                        id={`deletetooltip_del_${el?.userId?._id}`}
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`deletetooltip_del_${el?.userId?._id}`}>
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                </ul>
              </div>
            ),
          }));
    } else {
      return [];
    }
  }, [fetchList, rankFilterValues]);

  const columns =
    activeTab.title === 'All Users'
      ? [
          // {
          //   Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
          //   accessor: 'checkbox',
          // },
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Email',
            accessor: 'email',
            hasDescription: true,
          },
          {
            Header: 'Location',
            accessor: 'location',
            hasDescription: true,
          },
          {
            Header: 'Rating',
            accessor: 'rating',
            hasDescription: true,
          },
          {
            Header: 'Win/Lose',
            accessor: 'winLose',
            hasDescription: true,
          },
          {
            Header: 'User Since',
            accessor: 'userSince',
            hasDescription: true,
          },
          {
            Header: 'Action',
            accessor: 'action',
          },
        ]
      : [
          // {
          //   Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
          //   accessor: 'checkbox',
          // },
          {
            Header: 'Name',
            accessor: 'name',
          },
          // {
          //   Header: 'Email',
          //   accessor: 'email',
          //   hasDescription: true,
          // },
          // {
          //   Header:'Location',
          //   accessor: 'location',
          //   hasDescription: true,
          // },

          {
            Header: 'Reported by',
            accessor: 'reportedBy',
            hasDescription: true,
          },
          {
            Header: 'Reason',
            accessor: 'reason',
            hasDescription: true,
          },
          {
            Header: 'Status',
            accessor: 'status',
            hasDescription: true,
          },

          {
            Header: 'Rating',
            accessor: 'rating',
            hasDescription: true,
          },
          {
            Header: 'Action',
            accessor: 'action',
          },
        ];

  const listFilters = [
    // {
    //   title: "Ratings Filter",
    // }
  ];

  return (
    <React.Fragment>
      <HtmlHead title={title} description={description} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => onClickDelete(user)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <div className="mb-3">
            <h4>Users Listing</h4>
          </div>
          <Row>
            <Col xs="12">
              <Listing
                title="Users Listing"
                searchBar={true}
                tabNames={tabNames}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                topBtn={'create'}
                topBtnLink={'/create-user'}
                columns={columns}
                data={tData}
                isGlobalFilter={true}
                isAddUsers={true}
                isAddTableBorderStrap={true}
                //  handleUserClicks={handleUserClicks}
                addPagination={true}
                topBar={listFilters}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                // onSearch={onSearch}
                filters={filters}
                setFilters={setFilters}
                clearFilters={clearFilterHandler}
                isLoading={isLoading}
                rankingFilter={activeTab?.title === 'All Users'}
                setRankFilterValues={setRankFilterValues}
                rankFilterValues={rankFilterValues}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
UsersListing.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default UsersListing;
