import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import point from "../../assets/images/point-white.png";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../api";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import Endpoints from "api/endpoints";
import { toastSuccess } from "components/Common/toastify";
import { toastError } from "components/Common/toastify";

const ForgetPasswordPage = (props) => {
  document.title =
    " Recover Password | Point Admin Dashboard";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      // setSubmitting(true);
      const body = { ...values };
      body.email = values.email.trim().toLowerCase();
      try {
        const forgotPassword = await api.post(Endpoints.forgotPassword, body);
        if (forgotPassword?.error?.response?.data?.error) {
          toastError(forgotPassword?.error?.response?.data?.error);
          // showToast(toast, forgotPassword?.error.response.data.error, "error");
        }
        if (forgotPassword?.data?.success) {
          toastSuccess("Reset Password requested");
          navigate("/reset-password");
          // showToast(toast, forgotPassword?.data.message, 'success');
          // navigation.navigate(ROUTES.CODEVERIFICATION, {
          //   email: values.email.trim(),
          // });
        }
      } catch (error) {
   
        toastError(
          error?.response?.status === 404
            ? "This email address is not found"
            : ""
        );

        // showToast(toast, error?.response?.data?.error, "error");
      } finally {
        // setSubmitting(false);
      }

      // dispatch(userForgetPassword(values, props.history));
    },
  });

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const selectForgotPasswordState = (state) => state.ForgetPassword;
  const ForgotPasswordProperties = createSelector(
    selectForgotPasswordState,
    (forgetPassword) => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    })
  );

  const { forgetError, forgetSuccessMsg } = useSelector(
    ForgotPasswordProperties
  );

  return (
    <React.Fragment>
      <div className=" ">
        <div className="account-pages ">
          <Container fluid className="bg-image">
            <div className="d-flex flex-column justify-content-center h-100 align-items-center">
              <div className="text-center mb-4">
                <Link to="/" className="d-block  mb-3 ">
                  <img
                    src={point}
                    alt=""
                    height="70"
                    className="logo logo-dark"
                  />
                  <img
                    src={point}
                    alt=""
                    height="70"
                    className="logo logo-light"
                  />
                </Link>
              </div>
              <Card className="col-12 col-sm-9 col-md-9 col-lg-8 col-xl-6 col-xxl-4">
                {/* <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to Point.
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot password?
                            </Link>
                          </div>
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                        

                        
                      </Form>
                    </div>
                  </CardBody> */}
                <CardBody className="p-2 p-md-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Reset Password</h5>
                  </div>

                  <div className="p-2 mt-4">
                    <div
                      className="alert alert-success text-center mb-4"
                      role="alert"
                    >
                      Enter your Email and instructions will be sent to you!
                    </div>
                    {forgetError && forgetError ? (
                      <Alert
                        color="danger"
                        className="text-center mb-4"
                        style={{ marginTop: "13px" }}
                      >
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert
                        color="success"
                        className="text-center mb-4"
                        style={{ marginTop: "13px" }}
                      >
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-0">
                        <Col xs={12} className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Remember It ?{" "}
                          <Link to="/login" className="fw-medium text-primary">
                            {" "}
                            Signin{" "}
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2  text-center">
                <p className="cl-white mb-0 ">
                  © {new Date().getFullYear()} Point. Design & Develop by
                  Pixarsart Studios
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
};

export default withRouter(ForgetPasswordPage);
