import api from 'api';
import Endpoints from 'api/endpoints';
import moment from 'moment/moment';
import React, { memo, useEffect, useMemo, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import Loader from './Common/Loader';

const ChallengesReport = ({id='', series = [],}) => {

    const [filter, setFilter] = useState("week");
    const { pathname } = useLocation();
    const lastPoint = pathname.split("/")[1];
  const [isLoading,setIsLoading] = useState(false);
  const [count, setCount] = useState([]);
  const [type, setType] = useState([]);

    const getChallengeReport= async()=>{
        setIsLoading(true)
        try{
            const url=lastPoint === "court-details"?`${Endpoints.challengesStatsByCourt}?type=${filter}&${id}`:`${Endpoints.challengesStatsByUser}?type=${filter}&${id}`
            const res= await api.get(url);
        
            let created={
                name:"Created",
                data:[],
                type:[]
            }
            let completed={
                name:"Completed",
                data:[],
                type:[]
            }
            res?.data?.data?.Requested?.forEach((el) => {
                created?.data.push(el?.count);
                created?.type.push(
                  moment(el?.date).format(
                    `${filter === "week" ? "ddd" : "MMM"}`
                  ) || "--"
                );
              });
            res?.data?.data?.Closed?.forEach((el) => {
                completed?.data.push(el?.count);
                completed?.type.push(
                  moment(el?.date).format(
                    `${filter === "week" ? "ddd" : "MMM"}`
                  ) || "--"
                );
              });
              setCount([
                {
                  name: created?.name,
                  data: created?.data,
                },
                {
                  name: completed?.name,
                  data: completed?.data,
                },
              ]);
              setType([
                ...created?.type?.map((el, ind) => (el === completed?.type[ind] ? el : "")),
              ]);
              setIsLoading(false);

        }catch(e){

        }
    }
useEffect(()=>{
    if(id){
        getChallengeReport()
    }
},[id,filter])

    const options = useMemo(
        () => ({
          colors: ["#AA34FE", "#F69132"],
          chart: {
            height: 350,
            type: "area",
            toolbar: {
              show: true,
              tools: {
                download: false, // <== line to add
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              },
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            // labels: [point1, point2],
            marker: {
              height: 10,
              width: 10,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          xaxis: {
            type: "category",
            categories:
              type.length > 0 ? type : ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        }),
        [type]
      );
    
      return (
        <Card className="section-card card-height">
          <CardBody>
            <div className="d-flex justify-content-between">
              <CardTitle className="mb-2 h4 d-flex">
                <div className="d-flex ">
                  <span className="font-size-16 fw-bold text-black">Challenges Report</span>
                  <UncontrolledButtonDropdown
                    // toggle={function noRefCheck(){}}
                    direction="down"
                  >
                    <DropdownToggle className="bg-transparent text-muted  text-nowrap   border-0 font-size-12 px-2  text-capitalize pt-1 ">
                      {filter}
                      <i className="bx bx-caret-down " />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className="font-size-12 text-capitalize "
                        onClick={() =>
                          setFilter(filter === "week" ? "month" : "week")
                        }
                      >
                        {filter === "week" ? "month" : "week"}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
              </CardTitle>
              {/* <DateRangePicker /> */}
            </div>
    
            {/* 
            <ul className="list-unstyled d-flex flex-wrap">
              <li className="font-size-12 text-muted">
                <i className="fas fa-circle font-size-10 cursor-pointer me-2 my-2" style={{ color: '#F69132' }} />
                <span className="font-size-14">{point1}</span>
              </li>
              <li className="font-size-12 text-muted ms-3">
                <i className="fas fa-circle font-size-10 cursor-pointer me-2 my-2" style={{ color: '#AA34FE' }} />
                <span className="font-size-14">{point2}</span>
              </li>
            </ul> */}
            {isLoading ? (
              <Loader />
            ) : (
              <ReactApexChart
                options={options}
                series={series.length > 0 ? series : count}
                type="area"
                height={255}
              />
            )}
          </CardBody>
        </Card>
      );
    };
    
    export default memo(ChallengesReport);