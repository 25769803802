import React from "react";
import { Navigate } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Notifications

import AllNotifications from "pages/Notfications/AllNotifications";

// Groups

import Groups from "pages/Groups";
import GroupsListing from "pages/Groups/GroupsListing";
import GroupDetails from "pages/Groups/GroupDetails";
import GroupTournamentList from "pages/Groups/components/GroupTournamentList";
import GroupMemberListing from "pages/Groups/components/GroupMemberListing";

// Tournaments
import Tournaments from "pages/Tournaments";
import TournamentListing from "pages/Tournaments/TournamentListing";
import TournamentDetail from "pages/Tournaments/TournamentDetail";
import ParticipantsListing from "pages/Tournaments/ParticipantsListing";
import TeamDetail from "pages/Tournaments/TeamDetail";

// Courts

import Courts from "pages/Courts";
import AddEditCourt from "pages/Courts/AddEditCourt";
import CourtsListing from "pages/Courts/CourtsListing";
import CourtDetails from "pages/Courts/CourtDetails";

//users
import UserDasboard from "pages/Users/UserDasboard";

import UsersListing from "pages/Users/UsersListing";

import CreateUserForm from "pages/Users/components/CreateUserForm";
import UserProfile from "pages/Users/UserProfile/UserProfile";
import DynamicUserListing from "pages/Users/components/DynamicUserListing";

//Pages
import Challenges from "pages/Challenges/ChallengesDashboard";
import ChallengesListing from "pages/Challenges/ChallengesListings";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Ui

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Profile
// import UserProfile from "../pages/Authentication/user-profile";

// Matches

import MatchesListing from "components/Common/MatchesListing";

import Faqs from "pages/FAQs";
import MatchDetails from "pages/Tournaments/MatchDetails";
import ResetPassword from "pages/Authentication/ResetPassword";
import CourtsMatchListing from "components/Common/CourtsMatchListing";
import UpdatePassword from "pages/Authentication/UpdatePassword";

// import ChallengeDetails from "pages/Tournaments/ChallengeDetails";


const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/all-notifications", component: <AllNotifications /> },
  { path: "/faqs", component: <Faqs /> },

  // { path: "/create-user", component: <EmailRead /> },
  { path: "/create-user", component: <CreateUserForm /> },
  { path: "/edit-user/:id", component: <CreateUserForm /> },
  { path: "/update-password", component: <UpdatePassword /> },
  // Groups

  { path: "/groups", component: <Groups /> },
  { path: "/groups-listing", component: <GroupsListing /> },
  { path: "/group-details/:id", component: <GroupDetails /> },
  { path: "/group-tournament/:id", component: <GroupTournamentList /> },
  { path: "/group-members/:id", component: <GroupMemberListing /> },

  // Tournaments
  { path: "/tournaments", component: <Tournaments /> },
  { path: "/tournaments-listing", component: <TournamentListing /> },
  { path: "/tournament-details/:id", component: <TournamentDetail /> },
  {
    path: "/participants-list/:tournamentId",
    component: <ParticipantsListing />,
  },
  { path: "/team-detail/:id", component: <TeamDetail /> },
  { path: "/match-details/:id", component: <MatchDetails /> },
  { path: "/challenge-details/:id", component: <MatchDetails/> },

  // Courts

  { path: "/courts", component: <Courts /> },
  { path: "/courts-listing", component: <CourtsListing /> },
  { path: "/add-court", component: <AddEditCourt /> },
  { path: "/edit-court/:id", component: <AddEditCourt /> },
  { path: "/court-details/:id", component: <CourtDetails /> },
  // challengs
  {path:"/challenges-listings",component:<ChallengesListing/>},
  {path:"/challenges",component:<Challenges/>},
  // Contacts----> users
  { path: "/users", component: <UserDasboard /> },

  { path: "/users-listing", component: <UsersListing /> },
  { path: "/users-listing/:report", component: <UsersListing /> },
  // user profile
  { path: "/user-profile/:userId", component: <UserProfile /> },
  
  {path:"/:endpoint",component:<DynamicUserListing/>},

  // My Challenges List

  { path: "/challenge/:userId", component: <MatchesListing /> },

  // My Tournaments

  { path: "/tournament/:userId", component: <MatchesListing /> },
  //courts chllanges and matches
  { path: "/court/:name/:courtId", component: <CourtsMatchListing /> },
  { path: "/court/:name/:courtId", component: <CourtsMatchListing /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/reset-password", component: <ResetPassword /> },
 

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
];

export { userRoutes, authRoutes };
