import React from 'react'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
// import banner from "assets/images/default-img-2.png"
import cardBanner from "assets/images/default-banner-3.png"
import banner from 'assets/images/default-img.svg'
import { tournamentStatus,tournament } from 'utils/tournamentStatus';
import { Col, Row } from 'reactstrap'
import PointIcon from 'assets/icons/PointIcon';


const MyTournamentCard = ({ userId, data }) => {
    
    return (
        <Link 
        to={`/tournament-details/${data?._id}`}
        
        
        className='matches-wrap  rounded-3 '>
            {/* tournament banner container */}
           <Row className='d-flex flex-wrap    justify-content-between '>
           <Col className="col-12  col-sm-2 col-lg-3 p-0 ">
            
            <img src={data?.bannerImage||cardBanner} alt="tournament image" className='tournament-banner img-fluid  ' 
            />
       
        </Col>
        {/* tournment short details container */}
        <Col className='col-12 col-sm-10 col-lg-9 bg-white'>
       <Row className='  d-flex py-2   align-items-center    h-100 px-2'>
         {/* tournament name */}
         <div className='d-flex  align-items-center  col-12  '  >
            <span className='fw-bold font-size-14 text-black   text-nowrap text-capitalize ' >{data?.name}</span>
            
            
        </div>
        {/* Group name */}
        <div className='d-flex flex-nowrap align-items-center col-6 col-sm-3 '>
            <PointIcon name="groups" height="30" width="30" className="group-icon mt-2" />
            <span className=' ps-1 font-size-12 text-muted  text-nowrap d-inline-block w-75 text-truncate  ' >{data?.groupId?.name}</span>
        </div>
       
        {/* privacy */}
        <div className='d-flex align-items-center col-6 col-sm-3'>
             <PointIcon name="lock" height="35" width="35" className="mt-2"/>   
            <span className='font-size-12 text-muted' >{data?.isPrivate ? "Private" : "Public"}</span>
        </div>
        {/* date and time */}
        <div className='d-flex align-items-center col-6 col-sm-3'>
            <PointIcon name="calender" height="25" width="25" className="group-icon"/>
        <span className='font-size-12 text-muted   ' >{moment(data?.date).format('MMM Do ')}</span>
        </div>
         {/* tournament created by */}
         <div className='d-flex align-items-center col-6 px-0 text-truncate  col-sm-3'>
        {/* <h3 className='fw-bold font-size-14 text-black   text-nowrap mt-3'>Created by:</h3> */}
        <div className='d-flex flex-row flex-nowrap  align-items-center' >
            <img src={data?.createdBy?.additionalInfo?.profileImage||banner} alt='tournaments' className='header-profile-user rounded-circle'/>
            <span className='font-size-14  text-muted   text-truncate  d-inline-block ' style={{maxWidth:"60px"}}>{data?.createdBy?.firstName}</span>
        </div>
        </div>
         {/* Location  */}
         <div className='d-flex align-items-center col-12 col-sm-6'>
          
                    
          {/* <h3 className='font-size-12 text-muted  text-nowrap'>Location/Court</h3> */}
          <PointIcon name="location" height='30' width="30" className="group-icon"/>
          <span className='font-size-14 text-black text-center d-inline-block w-75  text-truncate ' >{((data?.court?.length>0)?(data?.court[0]?.courtId?.name):(data?.location?.description))||"-"} {(data?.court[0]?.courtAvailability)|| "-" } {  (data?.court[0]?.totalNumber)||"-"}</span>
  
  
</div>
        {/* Tournament Status */}
        <div className= "col-sm-6 text-end"><span className={`${tournamentStatus(data?.status)}     px-2 py-1 text-nowrap  rounded-3  font-size-14`}>{tournament(data?.status)}</span></div>
       
       </Row>
        </Col>
           </Row>

        </Link>
    )
}

export default MyTournamentCard