import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";

import HtmlHead from "components/html-head/HtmlHead";

//Import Components
import MiniWidget from "../../components/mini-widget";

import UsersList from "../../components/UsersList";
import UsersTable from "components/UsersTable";
import MatchCard from "components/MatchCard";
import Users from "components/Users";
import ScoreCard from "components/ScoreCard";
import Earnings from "components/Earnings";
import ByCities from "components/ByCities";
import TournamentsReport from "components/TournamentsReport";
import PieChart from "components/PieChart";
import TournamentPrivacy from "components/TournamentPrivacy";
import TournamentCompletion from "components/TournamentCompletion";
import Groups from "components/Groups";
import Challenges from "components/Challenges";
import Endpoints from "api/endpoints";
import api from "api";
import queryParamBuilder from "api/queryParamBuilder";
import { toastError } from "components/Common/toastify";
import moment from "moment/moment";
import UserInfoCard from "pages/Users/components/UserInfoCard";
import TournamentList from "pages/Tournaments/components/TournamentList";
import MostUsedCourts from "pages/Courts/components/MostUsedCourts";
import Courts from "pages/Courts/components/Courts";
import TournamentsWraper from "pages/Tournaments/components/TournamentsWraper";

const socialPlatforms = [
  {
    text: "Facebook",
    color: "#2171CC",
    series: 55,
  },
  {
    text: "Twitter",
    color: "#B8DD00",
    series: 44,
  },
  {
    text: "Instagram",
    color: "#7AB500",
    series: 67,
  },
  {
    text: "TikTok",
    color: "#4B7EB9",
    series: 75,
  },
];

const Dashboard = () => {
  const title = "Dashboard";
  const description = "Dashboard Page";



  // const [users,setUsers]=useState([])
  const [isLoading, setIsLoading] = useState({
    userCount: false,
    groupStats: false,
    tournamentStats:false,
    challengeStats: false,
    courtStats: false,
    tournamentStatus: false,
    userRanking: false,
    mostProfileView: false,
    mostFriends: false,
    getAll: false,
    mostTournamentCreated: false,
    mostTournamentPlayed: false,
    courtsByMostTournaments: false,
    courtsByMostChallenges: false,
    mostMatchesPlayed: false,
  });
  const [dataState, setDataState] = useState({
    userCount: {},
    groupStats: {},
    tournamentStats: {},
    challengeStats: {},
    courtStats: {},
    tournamentStatus: [],
    userRanking: [],
    mostProfileView: [],
    mostFriends: [],
    getAll: [],
    mostTournamentCreated: [],
    mostTournamentPlayed: [],
    courtsByMostTournaments: [],
    courtsByMostChallenges: [],
    mostMatchesPlayed: [],
  });

 

  useEffect(() => {
    const fetchData = async () => {

      setIsLoading({
        userCount: true,
        groupStats: true,
        tournamentStats:true,
        challengeStats: true,
        courtStats: true,
        tournamentStatus: true,
        userRanking: true,
        mostProfileView: true,
        mostFriends: true,
        getAll: true,
        mostTournamentCreated: true,
        mostTournamentPlayed: true,
        courtsByMostTournaments: true,
        courtsByMostChallenges: true,
        mostMatchesPlayed: true,
      })
      const controller = new AbortController();

    

      const requests = Object.keys(dataState);

      try {
        let n = 0;

        const apiHitHandler = async (x) => {
          if (n < requests.length) {
            const keyToUpdate = Object.keys(dataState)[x];
            const signal = controller.signal;
           
            try {
              const res = await api.get(`${Endpoints[requests[x]]}`, {
                signal,
              });

              if (res?.status === 200) {
               

                // Check if the component is still mounted before updating the state
                if (!controller.signal.aborted) {
                  setDataState((prevDataState) => ({
                    ...prevDataState,
                    [keyToUpdate]: res?.data?.data,
                  }));
                  setIsLoading((prevDataState) => ({
                    ...prevDataState,
                    [keyToUpdate]: false,
                  }))
                }

                // Recursive call for the next request
                await apiHitHandler((n += 1));
              }
            } catch (err) {
             
              if (err.name === "AbortError") {
                // console.log("Request aborted due to component unmount");
              } else {
                // toastError(err?.response?.message,);
             
                return
                
              }
            }
          }
        };

        // Initial call to apiHitHandler
        await apiHitHandler(n);
      } catch (err) {
        console.error("Error during data fetching:", err);
      } finally {
        controller.abort(); // Abort any ongoing requests when component unmounts
        setIsLoading({
          userCount: false,
    groupStats: false,
    tournamentStats:false,
    challengeStats: false,
    courtStats: false,
    tournamentStatus: false,
    userRanking: false,
    mostProfileView: false,
    mostFriends: false,
    getAll: false,
    mostTournamentCreated: false,
    mostTournamentPlayed: false,
    courtsByMostTournaments: false,
    courtsByMostChallenges: false,
    mostMatchesPlayed: false,
        })
      }
    };

    fetchData();


   
  }, []);
  // const {userCount,groupStats,tournamentStats,challengeStats,courtStats}=dataState;

  // Top counts cards
  const reportState = useMemo(() => {
    const statsArray = [
      dataState?.userCount,
      dataState?.groupStats,
      dataState?.tournamentStats,
      dataState?.challengeStats,
      dataState?.courtStats,
    ];
    const reportsStat = [];
    statsArray?.forEach((stats) => {
      for (const [key, value] of Object.entries(stats)) {
        reportsStat.push({
          title: key,
          value: value,
        });
      }
    });
    
    return reportsStat;
  }, [
    dataState?.userCount,
    dataState?.groupStats,
    dataState?.tournamentStats,
    dataState?.challengeStats,
    dataState?.courtStats,
  ]);

  // tournament completion

  const tournamentCompletionSeries = useMemo(() => {
    return {
      totalTournemnts: dataState?.tournamentStatus?.tournament?.reduce(
        (acc, cur) => (acc += cur?.count),
        0
      ),
      series: dataState?.tournamentStatus?.tournament?.map((el) =>
        Math.round(
          el?.count /
            dataState?.tournamentStatus?.tournament?.reduce(
              (acc, cur) => (acc += cur?.count),
              0
            )
        )
      ),
    };
  }, [dataState?.tournamentStatus]);
  // tournament Entry fee chart data
  const tournamentEntry = useMemo(
    () => [
      {
        text: "Free",
        color: "#4B7EB9",
        series: Math.round(
          ((dataState?.tournamentStats["Free Tournaments"] || 0) /
            (dataState?.tournamentStats["Total Tournaments"] || 0)) *
            100
        )||0,
      },
      {
        text: "Paid",
        color: "#82A6CE",
        series: Math.round(
          ((dataState?.tournamentStats["Paid Tournaments"] || 0) /
            (dataState?.tournamentStats["Total Tournaments"] || 0)) *
            100
        )||0,
      },
    ],
    [dataState?.tournamentStats]
  );

  // users destruction
  const userSData = useMemo(
    () =>
      dataState?.getAll?.map((el) => ({
        id: el._id,
        img: el?.additionalInfo?.profileImage,
        email: el?.email,
        name: `${el?.firstName} ${el?.lastName}`,
        rating: ` S:${el?.additionalInfo?.matchDetails?.single?.rating}/D:${el?.additionalInfo?.matchDetails?.double?.rating}`,
        location: el?.additionalInfo?.location?.description,
        winLose: `${
          el?.additionalInfo?.matchDetails?.single?.win +
            el?.additionalInfo?.matchDetails?.double?.win || "-"
        }/${
          el?.additionalInfo?.matchDetails?.single?.loss +
            el?.additionalInfo?.matchDetails?.double?.loss || "-"
        }`,
        userSince: `${moment(el?.createdAt).format("MMMM DD, YYYY") || "--"}`,
      })),
    [dataState?.getAll]
  );

 

  return (
    <React.Fragment>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <div className="fw-bold font-size-24 mb-3 text-black  ">{title}</div>
          {/* uppers cards */}
          <Row className=" row-cols-xl-5">
            <MiniWidget reports={reportState.length===0?[[],[],[],[],[]]:reportState} />
          </Row>

          <Row>
            {/* <Col xl={6} xxl={4}>
              <Earnings />
            </Col> */}

            <Col lg={6} xl={4} xxl={4}>
              <Users title="New Users" endpoint={"getUserByDay"} />
            </Col>
            <Col lg={6} xl={4} xxl={4}>
              <Users title="New Courts" endpoint={"newCourtChart"} />
            </Col>
            <Col lg={12} xl={4} xxl={4}>
              <Users title="New Groups" endpoint={"newGroupChart"} />
            </Col>
           
            {/* <Col xl={12} xxl={4}>
              <ScoreCard />
            </Col> */}
          </Row>

          {/* Tournaments card */}
          <Row className="mb-3">
            {/* <Col className="col-12">
            <div className="d-flex justify-content-between mb-3">
                <span className="font-size-16 fw-bold text-black">Tournaments</span>
                 <Link className="text-blue font-size-12" to="/tournaments-listing">
                  View all
                </Link> 
              </div>

            </Col>
            {
              Array.from({length:6}).map((_,idx) =>(
                <Col 
                key={idx}
                className="col-12 col-sm-6 col-lg-4 col-xxl-2">
                <MatchCard />
              </Col>

              ))
            } */}
            <TournamentsWraper />
          </Row>
          <Row>
            <Col xl={6}>
              <ByCities
                cityText="Users"
                link="/users-listing"
                endpoint={"getUserByCity"}
              />
            </Col>
            <Col xl={6}>
              <ByCities
                cityText="Groups"
                link="/groups-listing"
                endpoint={"groupByCity"}
              />
            </Col>
            {/* <Col xl={6}>
              <CourtsCard />
            </Col> */}
          </Row>
          <Row>
            <Col md={12} lg={12} xl={12} xxl={12}>
              <TournamentsReport
                endpoint="tournamentReport"
                point1="Unsanction"
                point2="Sanction"
                title="Tournaments Report"
              />
            </Col>
            {/* <Col md={12} lg={12}  xl={4}>
              <Challenges />
            </Col> */}
          </Row>
          <Row>
            <Col  xl={4}>
              <PieChart title="Tournaments Entry Type" data={tournamentEntry} />
            </Col>
            {/* <PieChart title="Social Media Platform Posts" data={socialPlatforms} /> */}
            <Col  xl={4}>
              <TournamentPrivacy />
            </Col>
            <Col className="col-12 col-xl-4">
              <TournamentCompletion
                title={"Tournament Status"}
                series={tournamentCompletionSeries.series}
                totalPlayed={tournamentCompletionSeries.totalTournemnts}
                labels={["Open", "Start", "Closed", "Cancel"]}
              />
            </Col>
          </Row>

          <Row>
            {/* <Col className="col-12 col-xxl-4">
              <NotVerifiedScore />
            </Col> */}
            <Col className="col-12">
              <UsersList data={userSData} title="Users" />
            </Col>
            {/* Top players */}
            <Col className="col-12 col-lg-6 col-xl-6 col-xxl-6">
              <UserInfoCard
                title="Top Players"
                // endpoint={Endpoints.userRanking}
                data={dataState?.userRanking}
                isLoading={isLoading?.userRanking}
                btnNavigat={"userRanking"}
                cardData={"userRanking"}
                filter="world"
              />
            </Col>
            {/* Most profile viewed users */}
            <Col className="col-12 col-lg-6 col-xl-6 col-xxl-6">
              <UserInfoCard
                title="Most Viewed Profile"
                endpoint={""}
                data={dataState?.mostProfileView}
                isLoading={isLoading?.mostProfileView}
                btnNavigat={"mostProfileView"}
                cardData={"mostProfileViewed"}
              />
            </Col>
            {/* Most Friends*/}
            <Col className="col-12 col-lg-6 col-xl-6 col-xxl-6">
              <UserInfoCard
                title="Most Friends"
                endpoint={""}
                data={dataState?.mostFriends}
                isLoading={isLoading?.mostFriends}
                btnNavigat={"mostFriends"}
                cardData={"mostFriends"}
              />
            </Col>
            {/* Most Played matches */}
            <Col className="col-12 col-lg-6 col-xl-6 col-xxl-6">
              <UserInfoCard
                title="Most Matches Played"
                endpoint={""}
                data={dataState?.mostMatchesPlayed}
                isLoading={isLoading?.mostMatchesPlayed}

                btnNavigat={"mostMatchesPlayed"}
                cardData={"mostMatchesPlayed"}
              />
            </Col>
            {/* <Col className="col-12 col-xxl-4">
              <UsersTable title="Fastest Climb Ranked" />
            </Col> */}

            <Col className="col-12">
              <Groups title="Groups" link="groups" />
            </Col>
            <Col className="col-12 col-lg-6 ">
              <UserInfoCard
                title="Most Tournament Created"
                endpoint={""}
                data={dataState?.mostTournamentCreated}
                isLoading={isLoading?.mostTournamentCreated}
                btnNavigat={"mostTournamentCreated"}
                cardData={"mostTournamentCreated"}
              />
            </Col>
            {/* Most Tournaments Played*/}
            <Col className="col-12 col-lg-6 ">
              <UserInfoCard
                title="Most Tournaments Played"
                endpoint={""}
                data={dataState?.mostTournamentPlayed}
                isLoading={isLoading?.mostTournamentPlayed}
                btnNavigat={"mostTournamentPlayed"}
                cardData={"mostTournamentPlayed"}
              />
            </Col>
            <Col className="col-12">
              <TournamentList />
            </Col>
            <Col className="col-12 col-xl-6">
              <MostUsedCourts
                title="Courts With Most Challenges"
                data={dataState?.courtsByMostChallenges}
                isLoading={isLoading?.courtsByMostChallenges}
              />
            </Col>
            <Col className="col-12 col-xl-6">
              <MostUsedCourts
                title="Courts With Most Tournament"
                data={dataState?.courtsByMostTournaments}
                isLoading={isLoading?.courtsByMostTournaments}
              />
            </Col>
            <Col className="col-12">
              <Courts />
            </Col>
            {/* <Col xl={6} xxl={4}>
              <PieChart title="Social Media Platform Posts" data={socialPlatforms} />
            </Col> */}
          </Row>

          {/* <UsersList title="Users" data={users} isLoading={isLoading} /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
