import React, { useState, useEffect } from "react";
import api from "api";
import Endpoints from "api/endpoints";
import DetailsTopBar from "components/Common/DetailTop";
import { toastError } from "components/Common/toastify";
import HtmlHead from "components/html-head/HtmlHead";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import FinancialStats from "./components/FinancialStats";
import EarningsCard from "components/Common/EarningsCard";
import Loader from "components/Common/Loader";
// import Matches from 'components/Matches';
import TournamentParticipants from "./components/TournamentParticipants";
import DeleteModal from "components/Common/DeleteModal";
import { toastSuccess } from "components/Common/toastify";
import queryParamBuilder from "api/queryParamBuilder";
import Matches from "components/Matches";
import MatchBracket from "./components/MatchBrackets";
import { tournament } from "utils/tournamentStatus";
import banner from "assets/images/default-img.svg";
import tournamentBanner from "assets/images/default-banner-3.png";

const tournamentFormat = {
  single_elimination: "Single Elimination",
  double_elimination: "Double Elimination",
  round_robin: "Round Robin",
};

const TournamentDetail = () => {
  const [tournamentInfo, setTournamentInfo] = useState({});
  const [participants, setParticipants] = useState(0);
  const [winnerTeam, setWinnerTeam] = useState({});
  const [winnerBracket, setWinnerBracket] = useState([]);
  const [loserBracket, setLoserBracket] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const title = "Tournament Details";
  const description = "Tournament Details Page";

  const { id } = useParams();

  const getTournamentByID = async () => {
    setIsLoading(true);
    //    const req1=api.get(`${Endpoints.tournament}/${id}`);
    //    const req2= api.get(`${Endpoints.match}?page=1&limit=10&tournamentId=65049df3a5f1bf9147888e4c&status=all`)

    //    Promise.all([req1,req2]).then((res)=>{
    //     setTournamentInfo(res?.at(0)?.data?.data);
    //                 if (res?.at(0)?.data?.data?.first) {
    //             const res2 = await api.get(`${Endpoints.team}/${res?.at(0)?.data?.data?.first.first?._id}`)
    //             setWinnerTeam(res2?.data?.data)
    //         }

    //    }).catch(()=>{})
    try {
      await api.get(`${Endpoints.tournament}/${id}`).then((res) => {
        setTournamentInfo(res?.data?.data);
        if (res?.data?.data?.first) {
          api
            .get(`${Endpoints.team}/${res?.data?.data?.first?._id}`)
            .then((res) => setWinnerTeam(res?.data?.data))
            .catch((err) => toastError());
        }
        api
          .get(
            `${Endpoints.match}?page=1&limit=10&tournamentId=${id}&status=all`
          )
          .then((res) => {
            setWinnerBracket(res?.data?.data?.winnerBracket);
            setLoserBracket(res?.data?.data?.loserBracket);
          })
          .catch((err) => toastError());
      });

      setIsLoading(false);
      //   console.log('tournament info', res.data);
    } catch (err) {
      toastError();
      setIsLoading(false);
      //   console.log(err);
    }
    // api
    //   .get(
    //     `${Endpoints.match}?page=1&limit=10&tournamentId=${id}&status=all`
    //   )
    //   .then((res) => {
    //     setMatches(res.data?.data);

    //     // console.log("Teournament match",res.data?.data)
    //   })
    //   .catch((err) => toastError());
  };

  useEffect(() => {
    getTournamentByID();
  }, []);
  //  cancel tournament api

  const onCancelClick = () => {
    setDeleteModal(true);
    try {
      const res = api.put(`${Endpoints.tournament}/${id}`);

      toastSuccess("Canceled Tournament");
    } catch (err) {
      toastError();
    }
    setDeleteModal(false);
  };
  const earningCard = [
    {
      title: "Court Fee",
      val: tournamentInfo?.courtsFee ? `$${tournamentInfo?.courtsFee}` : "N/A",
    },
    {
      title: "Referee Fee",
      val: tournamentInfo?.refereesFee
        ? `$${tournamentInfo?.refereesFee}`
        : "N/A",
    },
    {
      title: "Creater Fee",
      val: tournamentInfo?.creatorFee
        ? `$${tournamentInfo?.creatorFee}`
        : "N/A",
    },
  ];

  //  const winMatches =[]
  // console.log("winner Matches",winMatches)

  return (
    <>
      <HtmlHead title={title} description={description} />
      <DeleteModal
        show={deleteModal}
        onDelete={""}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
        deleteBtn={false}
        btnAction={() => {
          onCancelClick(id);
        }}
        btnText={"Cancel"}
      />
      <div className="page-content">
        <Container fluid>
          <DetailsTopBar
            title={title}
            id={id}
            // editLink={`/edit-user/${id}`}
            // btnText="Cancel Tournament"
            btnAction={() => {
              setDeleteModal(true);
            }}
          />
          <Row>
            {/* short details card  */}
            <Col className="col-12 col-md-8">
              <div
                className="tournament-detail py-3 ps-3"
                style={{
                  backgroundImage: `url(${
                    tournamentInfo?.bannerImage || tournamentBanner
                  })`,
                }}
              >
                <div className="d-flex justify-content-between flex-column h-100 tournament-detail-content">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                      <span className="font-size-14 text-muted mb-1">
                        Match Created by:
                      </span>
                      <span className="font-size-14 text-white">
                        <img
                          className="listing-banner pic"
                          src={
                            tournamentInfo?.createdBy?.additionalInfo
                              ?.profileImage || banner
                          }
                          alt={tournamentInfo?.createdBy?.username}
                        />
                        &nbsp;&nbsp;
                        {`${tournamentInfo?.createdBy?.firstName || "-"} ${
                          tournamentInfo?.createdBy?.lastName || "-"
                        }`}
                      </span>
                    </div>
                    <span className="text-muted fw-bold font-size-14 ranked-tournament p-2 text-capitalize ">
                      {/* {`${tournamentInfo?.isSanctioned ? 'Sanctioned' : 'Not Sanctioned'}`} */}
                      {tournament(tournamentInfo?.status)}
                    </span>
                  </div>
                  <div>
                    <h6 className="text-white font-size-16 fw-bold mb-2 text-capitalize ">
                      {tournamentInfo?.name}
                    </h6>
                    <span className="text-white font-size-10 transparent">
                      {tournamentFormat[tournamentInfo?.format]}
                    </span>
                    <span className="text-white font-size-10 transparent ms-2">{`${
                      tournamentInfo?.isSanctioned
                        ? "Sanctioned"
                        : "Not Sanctioned"
                    }`}</span>
                    <div className="font-size-14 text-white mt-3">
                      <div className="mb-2">
                        {/* <img src="/img/tournaments/Location.svg" alt="location" /> */}
                        <i className="uil-location-point font-size-18" />
                        &nbsp;&nbsp;
                        {tournamentInfo?.court?.at(0)?.courtId?.name ||
                          tournamentInfo?.location?.description ||
                          "-"}
                      </div>
                      <span className="me-3 ">
                        {/* <img src="/img/tournaments/calendar_month.svg" alt="calendar_month" /> */}
                        <i className="mdi mdi-calendar-month-outline font-size-18" />
                        &nbsp;&nbsp;
                        {moment(tournamentInfo?.date).format("MMMM DD, YYYY")}
                      </span>
                      {tournamentInfo?.time && (
                        <span>
                          {/* <img src="/img/tournaments/pace.svg" alt="calendar_month" /> */}
                          <i className="mdi mdi-av-timer font-size-18" />
                          &nbsp;&nbsp;
                          {moment(tournamentInfo?.time).format("hh:mma")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* winner detail card  */}
            <Col className="col-12 col-md-4 mt-3 mt-md-0">
              <>
                <div className="winner-wrap position-relative winner p-3">
                  <h6 className="font-size-16 fw-bold text-black mb-3">
                    Winner
                  </h6>
                  {/* short info-card of winners  */}
                  {tournamentInfo?.first ? (
                    <>
                      {winnerTeam?.users?.map((user) => (
                        <div className="d-flex justify-content-between">
                          <div
                            className="d-flex align-items-start"
                            key={user?._id}
                          >
                            <img
                              src={user?.additionalInfo?.profileImage}
                              className="pic"
                              alt="winner"
                            />
                            <div className="d-flex flex-column ms-3">
                              <div className="font-size-14 text-black">
                                {user?.firstName}{" "}
                                {user?.lastName ? user?.lastName : ""}
                              </div>
                              <div className="text-muted font-size-12">
                                Tagline {`(${user?.additionalInfo?.tagLine})`}
                              </div>
                            </div>
                          </div>
                          <div>
                            <Link
                              to={`/user-profile/${user?._id}`}
                              className="winner-view-profile font-size-12 fw-bold mb-0 cursor-pointer"
                            >
                              View profile
                            </Link>
                          </div>
                        </div>
                      ))}
                      <div className="font-size-18 fw-bold d-flex align-items-center mt-1">
                        {winnerTeam?.pointDiff}{" "}
                        <div className="font-size-12 text-muted fw-normal ms-2">
                          Points earned
                        </div>
                      </div>
                      <div className="mt-4">
                        <span className="font-size-14 fw-bold text-black">
                          Matches Description
                        </span>
                        <ul className="list-unstyled mt-2">
                          <li className="d-flex justify-content-between px-2 matches-description pb-2 mb-2">
                            <span className="font-size-12 text-muted">
                              Matches Played:
                            </span>
                            <span className="font-size-12 text-black">
                              {winnerTeam?.wins + winnerTeam?.loses}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between px-2 matches-description pb-2 mb-2">
                            <span className="font-size-12 text-muted">
                              Wins:
                            </span>
                            <span className="font-size-12 text-black">
                              {winnerTeam?.wins}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between px-2 matches-description pb-2 mb-2">
                            <span className="font-size-12 text-muted">
                              Loses:
                            </span>
                            <span className="font-size-12 text-black">
                              {winnerTeam?.loses}
                            </span>
                          </li>
                          {/* <li className="d-flex justify-content-between px-2 matches-description pb-2 mb-2">
                                                        <span className="font-size-12 text-muted">Draw:</span>
                                                        <span className="font-size-12 text-black">01</span>
                                                    </li> */}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <div className="h-75 w-100  d-flex align-items-center justify-content-center ">
                      {" "}
                      <span className="text-muted font-size-14 ">
                        To bo announced
                      </span>{" "}
                    </div>
                  )}
                  {/* <span className="font-size-14 text-muted announce">To be announced</span> */}
                </div>
              </>
            </Col>
            {/* <div>
                  <div className="text-nowrap">
                    <img src="/img/global/Payments.svg" alt="payments" />
                    <i className="mdi mdi-cash text-white font-size-18" />
                    <span className="ms-2 text-muted text-nowrap ">
                      Entry fee:
                    </span>
                    <span className="ms-2 ms-lg-3 text-nowrap  text-white">
                      $28.00
                    </span>
                  </div>
                  <div className="text-nowrap text-truncate ">
                    <img src="/img/global/Groups.svg" alt="groups" />
                    <span className="ms-2 text-muted">Group:</span>
                    <span className="ms-2 ms-lg-3 text-nowrap  text-white">
                      {tournamentInfo?.groupId?.name}
                    </span>
                  </div>
                  <div className="text-nowrap text-truncate ">
                    <img src="/img/global/Privacy.svg" alt="privacy" />
                    <span className="ms-2 text-muted">Privacy:</span>
                    <span className="ms-2 ms-lg-3 text-nowrap  text-white">
                      {tournamentInfo?.isPrivate ? "Private" : "Public"}
                    </span>
                  </div>
                </div> */}
          </Row>
          <Row className="mt-3">
            <Col className="col-12">
              <MatchBracket
                data={{
                  winnerBracket: tournamentInfo?.winnerBracket,
                  loserBracket: tournamentInfo?.loserBracket,
                }}
              />
            </Col>
          </Row>
          {/* location & financial stats */}
          <Row className="mt-4">
            {/* location,group and privacy section */}
            <Col className="col-12  col-md-4">
              <div className="court-fee-wrap d-flex gap-2 align-items-center">
                <div className="ps-3 py-3 font-size-14 col-7 ">
                  <div className="d-flex align-items-center flex-nowrap ">
                    <img src="/img/global/Courts.svg" alt="courts" />
                    <span className="ms-2 text-muted">Court:</span>
                    <span className="ms-2 ms-sm-3 text-nowrap  text-white text-truncate w-50 d-inline-block ">
                      {tournamentInfo?.court?.at(0)?.courtId?.name}
                    </span>
                  </div>

                  <div className="text-nowrap">
                    {/* <img src="/img/global/Payments.svg" alt="payments" /> */}
                    <i className="mdi mdi-cash text-white font-size-18" />
                    <span className="ms-2 text-muted text-nowrap ">
                      Entry fee:
                    </span>
                    <span className="ms-2 ms-lg-3 text-nowrap  text-white">
                      {tournamentInfo?.price
                        ? `$${tournamentInfo?.price || 0}`
                        : "N/A"}
                    </span>
                  </div>
                  <div className="text-nowrap text-truncate ">
                    <img src="/img/global/Groups.svg" alt="groups" />
                    <span className="ms-2 text-muted">Group:</span>
                    <span className="ms-2 ms-lg-3 text-nowrap  text-white">
                      {tournamentInfo?.groupId?.name}
                    </span>
                  </div>
                  <div className="text-nowrap text-truncate ">
                    <img src="/img/global/Privacy.svg" alt="privacy" />
                    <span className="ms-2 text-muted">Privacy:</span>
                    <span className="ms-2 ms-lg-3 text-nowrap  text-white">
                      {tournamentInfo?.isPrivate ? "Private" : "Public"}
                    </span>
                  </div>
                </div>

                <div className="details-wrap col-5 participants-no d-flex flex-column justify-content-center align-items-center overflow-hidden ">
                  <span className="text-white font-size-24 fw-bold ">
                    {participants || 0}
                  </span>
                  <span className="text-muted font-size-14 text-capitalize ">
                    participants
                  </span>
                </div>
              </div>
            </Col>
            {/* Financial Stats card */}
            <Col className="col-12  col-md-8 mt-4 mt-md-0">
              {/* <FinancialStats/> */}
              <EarningsCard title="Expenses" data={earningCard} />
            </Col>
            {/* Expenses card */}
            {/* <Col className='col-12 mt-4'>
                                    <EarningsCard title="Expenses" data={earningCard}/>
                        </Col> */}
          </Row>
          <Row className="mt-4">
            <Col className="col-12">
              <TournamentParticipants
                tournamentId={id}
                setParticipantsCount={setParticipants}
              />
            </Col>
          </Row>

          {/* tournament matches */}
          <Row className="px-2 gap-3 ">
            <div className="matches-wrapper mt-3">
              <div className="d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row">
                <div className="d-flex flex-nowrap  align-items-center">
                  <h4 className="font-size-16 fw-bold text-black mb-0 mt-3 mt-md-0">
                    Matches
                  </h4>
                </div>

                <ul className="list-unstyled d-flex align-items-start text-nowrap  align-items-md-center mb-0 justify-content-end flex-column flex-md-row">
                  {/* <li className="ms-md-2 mt-3 mt-md-0">
                                        <Link className="text-blue font-size-12 view-all-btn"
                                        // to={`/challenge/${userId}`}
                                        >
                                            View all
                                        </Link>
                                    </li> */}
                </ul>
              </div>
            </div>
            <div className="mb-3 px-0 ">
              {isLoading ? (
                <Loader />
              ) : winnerBracket?.length > 0 ? (
                <>
                  {winnerBracket?.map((round, index) =>
                    round?.map((match, ind) => (
                      <Matches
                        data={match}
                        key={ind}
                        round={`winer${index + 1}`}
                      />
                    ))
                  )}
                  {loserBracket?.length > 0 &&
                    loserBracket?.map((round) =>
                      round?.map((match, ind) => (
                        <Matches data={match} key={ind + match?.id} />
                      ))
                    )}
                </>
              ) : (
                <div className="text-center mx-1 p-3 mb-3 rounded-4 bg-white font-size-12 text-muted ">
                  No record found
                </div>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TournamentDetail;
