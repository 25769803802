import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

function LinkedAccounts({ socialIcons }) {
    return (
        <div className="section-card p-3">
            <div className="font-size-16 fw-bold text-black">Linked Accounts</div>
            <ul className="list-unstyled">
                {socialIcons.map((el, ind) => (
                    <li className="d-flex justify-content-between mt-3" key={ind}>
                        <div className='d-flex align-items-center'>
                            <img src={`/img/global/${el.icon}.svg`} className='' alt="fb" />
                            <span className='text-start font-size-14 ps-2 text-muted '>{el.title}</span>
                        </div>
                        <i
                            className="uil uil-trash-alt font-size-18"
                            id={`deletetooltip_linkAccounts_${el.id}`}
                        />
                        <UncontrolledTooltip placement="bottom" target={`deletetooltip_linkAccounts_${el.id}`} >
                            Delete
                        </UncontrolledTooltip>
                       
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default LinkedAccounts;
