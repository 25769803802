export const matchStatus = (arg) =>
  ({
    Completed: 'light-green green',
    win: 'light-green green',
    Active: 'light-green green',
    Canceled: 'light-red red',
    lose: 'light-red red',
    blocked: 'light-red red',
    active:'light-green green',
    blocked:'light-red red',
    queued:'light-green green'
  })[arg] || 'light-grey grey';
export const statusText=(arg)=>({
  active: 'Active',
  blocked: 'Banned',
  win:"Win",
  lose:"Lose",
  queued:"Up comming",
  played:"Played",
}[arg])