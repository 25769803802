import React, {useEffect,useState} from 'react'
import api from 'api'
import Endpoints from 'api/endpoints'
import queryParamBuilder from 'api/queryParamBuilder'
import { toastError } from 'components/Common/toastify'
import MyTournamentCard from 'pages/Users/components/MyTournamentCard'
import { Link, useLocation } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown ,Col,Row} from 'reactstrap'
import ChallengeCard from 'components/ChallengeCard'

const challengeStatusOptions = [
    // "all" ,"upcoming"  ,"received" ,"verify" ,"post" ,"homeFeed" ,"post" ,"delete"
    { title: "All", status: "all" },
    { title: "Upcoming Matches", status: "upcoming " },
    { title: "Received", status: "received" },
    { title: "verify", status: "verify" },
    { title: "Post", status: "post" },
    { title: "Home Feed", status: "homeFeed" },
    { title: "Deleted", status: "delete" },
    // { title: "Closed", status: "closed" },
  ]
 const challengeOptionsByCourt= [
    { title: "All", status: "all" },
    { title: "Upcoming Matches", status: "upcoming " },
    { title: "Accepted", status: "accepted" },
    { title: "Rejected", status: "rejected" },
    { title: "Verified Score", status: "verifiedScore" },
    { title: "Verified Score Rejected", status: "verifiedScoreRejected" },
    { title: "Change Request", status: "changeRequested" },
    { title: "Closed", status: "closed" },
  //   upcoming | rejected | verifiedScore  | verifiedScoreRejected | closed | all | accepted | changeRequested
  ]

const ChallengesWraper = ({id=null,link=""}) => {

  const { pathname } = useLocation();
  const lastPoint = pathname.split("/")[1];

    const [challengeStatus,setchallengeStatus]=useState(lastPoint === "court-details"?challengeOptionsByCourt[0].status:challengeStatusOptions[0].status)
    const [challenge,setChallenge]=useState([])


    useEffect(()=>{
        const controller = new AbortController();
        const fetchTournaments=async ()=>{
            const allTournaments=queryParamBuilder({
                endpoint:Endpoints.getChallengesDashboard,
                status:challengeStatus,
                limit:"4",
              })
              const signal=controller.signal
            try{
                const res= lastPoint === "court-details"?(await api.get(
                  `${Endpoints.challengesByCourt}?courtId=${id}&status=${challengeStatus}&page=${1}&limit=10`
                )):await api.get(allTournaments,{signal})
                if(res.status===200){
                    setChallenge(res?.data?.data)
                }
            }catch(err){
                toastError()
            }
        }
        fetchTournaments()
        return ()=>{
            controller.abort()
        }
    },[challengeStatus])
  return (
    <Col xl={12} className="pt-0 mb-3">
    <div className="d-flex justify-content-between mb-2">
      <span className="font-size-16 fw-bold text-black d-flex ">Challenges
      <UncontrolledDropdown>
        <DropdownToggle caret   className='bg-transparent text-muted  text-nowrap   border-0 font-size-12 px-2 pt-1 text-capitalize'>
        {challengeStatus}
        <i className='bx bx-caret-down ' />
        </DropdownToggle>
        <DropdownMenu right>
          {
                 lastPoint === "court-details"?(    challengeOptionsByCourt?.map((el,ind)=>(
                  <DropdownItem key={ind} onClick={()=>setchallengeStatus(el.status)} className="text-capitalize font-size-12">{el.title}</DropdownItem>
                ))):(    challengeStatusOptions?.map((el,ind)=>(
                  <DropdownItem key={ind} onClick={()=>setchallengeStatus(el.status)} className="text-capitalize font-size-12">{el.title}</DropdownItem>
                )))
          }
          
        </DropdownMenu>
      </UncontrolledDropdown>
      </span>
      <Link className="text-blue font-size-12" to={link?link:"/challenges-listings"}>
        View All
      </Link>
    </div>
    <Row className='px-2'>
      {
       ( challenge?.length>0)?(
         challenge?.map((el,index)=>(
           <Col  className={`col-12 pt-3   `} key={index}>
             <ChallengeCard
              //  challenge={el}
              data={el} 
              key={index+el?._id}
              //  challengeStatus={dataState?.challengeStatus?.challenge[index].status} 
             />
           </Col>
         ))
       ):(
        <Col className='text-center bg-white  p-5 rounded-3 '>
          No challenge Found
        </Col>
       )
      }
     
    </Row>
  </Col>
  )
}

export default ChallengesWraper