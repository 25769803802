import React,{useState,useCallback,useEffect, memo} from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import DateRangePicker from './Common/DatePicker';
import Endpoints from 'api/endpoints';
import api from 'api';
import { toastError } from './Common/toastify';
import shortNumber from 'short-number';

function TournamentPrivacy() {
  // const series = [60, 40];
  const [filter,setFilter]=useState("week");
  const [series,setSeries]=useState([0,0])
  const [total,setTotal]=useState(0)


  const getData=useCallback(async()=>{
 

   

  

    try{
      
      let isPublic=0;
      let isPrivate=0;
    
      
      const res = await api.get(`${Endpoints.tournamentPrivacy}`)
      if(res?.status===200){
       res?.data?.data?.tournament?.forEach(el=>el?.private?isPrivate+=el?.count:isPublic+=el?.count)

       setTotal(isPrivate+isPublic)
       setSeries([
        (Math.round((isPublic)/(isPrivate+isPublic)*100)),
        (Math.round((isPrivate)/(isPrivate+isPublic)*100)),

       ])
       
  
      
     }
    
    }catch(erer){
      toastError()
    }


 
},[filter])


useEffect(()=>{
  getData()
},[filter,getData])



  const options = {
    // colors: ['#4B7EB9', '#82A6CE'],
    labels: ['Public', 'Private'],
    legend: {
      show: !0,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: !1,
      fontSize: '14px',
      offsetX: 0,
      markers: {
        size: 0,
        width:10,
        height:10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  };

  return (
    <Card className="section-card card-height">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-2 h4 d-flex">
            <div className="d-flex align-items-center">
              <span className="font-size-16 fw-bold text-black">Tournament Privacy</span>
              {/* <UncontrolledDropdown
                                                        
                                                        // toggle={function noRefCheck(){}}
                                                        direction="down"

                                                    >
                                                        <DropdownToggle


                                                            className='bg-transparent text-muted  text-nowrap   border-0 font-size-12 px-2 pt-1  text-capitalize'
                                                        >
                                                           {filter}
                                                            <i className='bx bx-caret-down ' />
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                        <DropdownItem
                                                                          
                                                                            className='font-size-12 text-capitalize'
                                                                            onClick={() => (setFilter(filter==="week"?"month":"week"))}
                                                                            >
                                                                            {filter==="week"?"month":"week"}
                                                                        </DropdownItem>
                                                       
                                                        </DropdownMenu>
                                                        </UncontrolledDropdown> */}
            </div>
          </CardTitle>
          {/* <DateRangePicker /> */}
        </div>
        <div className="d-flex align-items-center justify-content-center circle-chart flex-column">
          <div className="mt-3 d-flex flex-column align-items-center position-relative">
            <span
              className="position-absolute chart-total-val font-size-12 fw-normal text-muted"
              style={{
                top: '44%',
                left: '50%',
                transform: 'translate(-50%, -44%)',
              }}
            >
              Total {shortNumber(total)}
            </span>
            <ReactApexChart options={options} series={series} type="donut" height="270" className="apex-charts" />
          </div>
          {/* <ul className="list-unstyled text-muted font-size-14 circle-chart-points mt-2 mt-md-0 d-flex flex-wrap pt-1">
            <li className="me-3">
              <i className="fas fa-circle cursor-pointer me-2" style={{ color: '#4B7EB9', fontSize: '10px' }} />
              Public
            </li>
            <li>
              <i className="fas fa-circle cursor-pointer me-2" style={{ color: '#82A6CE', fontSize: '10px' }} />
              Private
            </li>
          </ul> */}
        </div>
      </CardBody>
    </Card>
  );
}

export default memo(TournamentPrivacy);
