import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import { map } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from 'classnames';



// import charts
import { getUserProfile } from "../../../store/actions";
import HtmlHead from "components/html-head/HtmlHead";
import ProfileSidebar from "../components/ProfileSidebar";
import TopCard from "../components/TopCard";
import TournamentsReport from "components/TournamentsReport";
import LinkedAccounts from "../components/LinkedAccounts";
import SubscriptionPlan from "../components/SubscriptionPlan";
import TournamentCompletion from "components/TournamentCompletion";

import TournamentsCategory from "components/TournamentsCategory";
import { toastError } from "components/Common/toastify";
import Endpoints from "api/endpoints";
import api from "api";
import queryParamBuilder from "api/queryParamBuilder";
import Loader from "components/Common/Loader";
import MyTournamentCard from "../components/MyTournamentCard";
import DetailsTopBar from "components/Common/DetailTop";
import DeleteModal from "components/Common/DeleteModal";
import { toastSuccess } from "components/Common/toastify";
import ChallengeCard from "components/ChallengeCard";
import ReportedBy from "./components/ReportedBy";
import ChallengesReport from "components/ChallengesReport";

const ContactsProfile = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [myChallenges, setMyChallenges] = useState([]);
  const [myTournaments, setMYTournaments] = useState([]);
  const [myStats, setMyStats] = useState({});
  
  // Delete user states
  const [deleteModal, setDeleteModal] = useState(false)
  const [user, setUser] = useState(null)
  const [modalBtnText, setModalBtnText] = useState("Delete")

  const title = 'User Profile';
  const description = 'User Profile Page';

  const { userId } = useParams();
  const navigate=useNavigate()

  // fetching data from server
  const fetchDatahandler = async (userId) => {
    setIsLoading(true);
    const challenges = queryParamBuilder({
      userId: userId,
      limit: 3,
      endpoint: Endpoints.getAllChellenges,
      status: "all",
    })
    const tournaments = queryParamBuilder({
      userId: userId,
      limit: 3,
      endpoint: Endpoints.tournament,
      home: true,
      status: "myTournaments"
    })
    const req1 = api.get(challenges);
    const req2 = api.get(tournaments);
    const req3 = api.get(`${Endpoints.getUserPersonalStats}/${userId}`)
    const req4 = api.get(`${Endpoints.getUserProfile}/${userId}`)
    Promise.all([req1, req2, req3, req4]).then((resp )=> {
  
      setMyChallenges(resp[0]?.data?.data);
      setMYTournaments(resp[1]?.data?.data);
      setMyStats(resp[2]?.data?.data);
      setUserProfile(resp[3]?.data?.data);
      setIsLoading(false)

    }).catch((err) => {
      toastError(err.message);

      setIsLoading(false)

    })



  }

  useEffect(() => {
   if(userId){
    fetchDatahandler(userId)
   }
   else return 
  }, [userId])

  // Delete a user
  const onClickDelete = userId => {

    try {
      const res = api.delete(`${Endpoints.deleteUser}/${userId}`)

      if(res.status===200){
        toastSuccess(res?.data?.message)
        navigate("/users-listing");
      }

    } catch (err) {
      toastError()

    }
    setDeleteModal(false)
  }
  // Disable  a user
  const onClickDisable = async (userId) => {
    
    try {
      // const res = userProfile?.dataStatus ==="active"? await api.delete(`${Endpoints.userSoftDelete}/${userId}`):await api.put(`${Endpoints.unblockUser}`,{userId: userId})
    const res =await api.post(`${Endpoints.userDisable}/${userId}`,{
      status:userProfile?.dataStatus ==="inactive"?"active": "inactive",
    })
      
      if(res.status===200){
       
        toastSuccess(res?.data?.message)
        setUserProfile(res?.data?.data)
        // navigate("/users-listing");
      }

    } catch (err) {
      toastError()

    }
    setDeleteModal(false)
  }

//  data destruction for graphs

// Tournament category Graph
const tournamentCategory=[myStats?.singleTournament||0,myStats?.doubleTournament||0, myStats?.robinTournament||0]

//  Win vs Loses graph
const totalSinglePlayed=(myStats?.singleWins)+(myStats?.singleLosses);
  const totalDoublePlayed=(myStats?.doubleWins)+(myStats?.doubleLosses);
  // totalPlayed
  const totalPlayed=(totalSinglePlayed||0+totalDoublePlayed||0);
  // single wins and losses
  const singleWinsRatio= Math.round(((myStats?.singleWins/totalSinglePlayed)*100))
  const singleLossesRatio= Math.round(((myStats?.singleLosses/totalSinglePlayed)*100))
  // double wins and losses
  const doubleWinsRatio= Math.round(((myStats?.doubleWins/totalDoublePlayed)*100))
  const doubleLossesRatio= Math.round(((myStats?.doubleLosses /totalDoublePlayed)*100))
  const winLoses = [singleWinsRatio||0, singleLossesRatio||0,doubleWinsRatio||0,doubleLossesRatio||0];
  const socialIcons = [{
    icon: 'fb',
    title: "FaceBook"
  }, { icon: 'tiktok', title: "TikTok" }, { icon: 'insta', title: "Instagram" }, { icon: 'twitter', title: "Twitter" }];

  const topBar = [
    {
      textContent: 'Court filter',
      img: '/img/tournaments/Drop Down.svg',
    },
    {
      textContent: 'Location filter',
      img: '/img/tournaments/Drop Down.svg',
    },
    {
      textContent: 'Status filter',
      img: '/img/tournaments/Drop Down.svg',
    },
  ];

  const series = [
    {
      name: 'Sanctioned',
      data: [0],
    },
    {
      name: 'Unsanctioned',
      data: [0],
    },
  ];

  const options = {
    colors: ['#F69132', '#AA34FE'],
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      type: 'category',
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };
  const topCard = [
    {
      id: "tc1",
      icon: "/img/users/tournaments.svg",
      desc: "Tournaments Matches",
      num: `${myStats?.playedTournament || '0'}`,
    },
    {
      id: "tc2",
      icon: "/img/users/Challenges.svg",
      desc: "Challenges Matches",
      num: myStats?.challenges || '0',
    },
    {
      id: "tc3",
      icon: "/img/users/Rating.svg",
      desc: " Single Ratings",
      num: `${myStats?.singleRating || '1.0'}`,
    },
    {
      id: "tc4",
      icon: "/img/users/Rank.svg",
      desc: "Double Ratings",
      num: `${myStats?.doubleRating || "1.0"}`,
    }
  ]




  return (
    <React.Fragment>
      <HtmlHead title={title} description={description} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => modalBtnText !== "Delete" ? onClickDisable(user) :onClickDelete(user) }
        onCloseClick={() => {
          setModalBtnText("Delete");
          setDeleteModal(false)

        }}
        btnText={modalBtnText}
        deleteBtn={true}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="Profile" /> */}
          <DetailsTopBar title={title}
            id={userId}
            editLink={`/edit-user/${userId}`}
            deleteBtn={true}
            btnText={userProfile?.dataStatus ==="inactive"?"Activate": "Disable"}
            // btnText={userProfile?.isBlocked?"Activate": "Disable"}
            btnAction={() => {
              setModalBtnText(userProfile?.dataStatus ==="inactive"?"Activate": "Disable")
              // setModalBtnText(userProfile?.isBlocked?"Activate": "Disable")
              setUser(userId)
              setDeleteModal(true)
            }}
            onDelete={() => {
              setUser(userId);
              setDeleteModal(true)
            }}
          />
          <Row className="mb-4">
            {
              isLoading ? (<Loader height="vh-100" />) : (<>
                {/* profile sidebar  */}
                <Col className="col-12 col-md-4 col-xxl-3 position-relative " >



                  <ProfileSidebar data={userProfile} isLoading={isLoading} />

                </Col>

                <Col className="col-12 col-md-8 col-xxl-9">
                  {/* top cards */}
                  <Row className='px-1 row-cols-sm-3  row-cols-xl-4'>
                    {
                      topCard.map((el, ind) => (
                        <Col className={`px-2  px-xl-2  mt-3  mt-md-0 ${ind > 2 ? "mt-md-2 mt-lg-0" : ""}`} key={ind}>
                          <TopCard {...el} isLoading={isLoading} />
                        </Col>
                      ))
                    }

                  </Row>
                  {/* 2nd row  */}
                  <Row className='px-1'>
                    <Col className="col-12 col-xl-7  px-1 ">
                      <TournamentsReport  options={options} series={series} title="Tournaments Played" point1="Sanctioned" point2="Unsanctioned" />
                    </Col>
                    <Col className="col-12 col-xl-5  px-1">
                      <TournamentsCategory
                        // isLoading={isLoading}
                        title="Tournaments Played"
                        series ={tournamentCategory}
                        p1="Single" p2="Double" p3="Round Robin" col1="#FCE2C9" col2="#F9C08A" col3="#F69132" />
                    </Col>
                    <Col className="col-12">
                    <ChallengesReport id={`userId=${userId}`} className="px-1"/>
                    </Col>
                    <Col className="col-12 col-md-8 px-1 col-xxl-5">
                      {/* win / loses */}
                      <TournamentCompletion
                        isLoading={isLoading}
                        totalPlayed={totalPlayed}
                        series={winLoses}
                        title={"Wins vs Loses"}
                        labels={['Single Wins', 'Single Loses', 'Double Wins', 'Double Loses']}
                      />
                    </Col>
                    <Col className="col-md-4 px-1 col-xxl-3 col-12">
                      <LinkedAccounts socialIcons={socialIcons} />
                    </Col>
                    <Col className="col-12 px-1 col-xxl-4 mt-3 mt-md-0">
                      <SubscriptionPlan />
                    </Col>
                  </Row>
                  {/* My challenges tab */}
                  <Row className="px-2 gap-3 mt-3">
                    <div className="matches-wrapper">
                      <div className="d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row">
                        <div className='d-flex flex-nowrap  align-items-center'>
                          <h4 className="font-size-16 fw-bold text-black mb-0 mt-3 mt-md-0">Challenges</h4>
                        </div>

                        <ul className="list-unstyled d-flex align-items-start text-nowrap  align-items-md-center mb-0 justify-content-end flex-column flex-md-row">

                          <li className="ms-md-2 mt-3 mt-md-0">
                            <Link className="text-blue font-size-12 view-all-btn" to={`/challenge/${userId}`}>
                              View all
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {
                      isLoading ? (<Loader />) : myChallenges.length > 0 ? (myChallenges?.map((challenge, index) => (
                        <ChallengeCard data={challenge} key={index + challenge?._id} />))) : (<div className="text-center mx-1 p-3 mb-3 rounded-4 bg-white font-size-12 text-muted matches-wrapper">
                          No record found
                        </div>)

                    }


                  </Row>
                  {/* My Tournments  tab */}
                  <Row className="px-2 mt-3 gap-3">
                    <div className="matches-wrapper">
                      <div className="d-flex align-items-md-center align-items-start justify-content-between flex-column flex-md-row">
                        <div className='d-flex flex-nowrap  align-items-center'>
                          <h4 className="font-size-16 fw-bold text-black mb-0 mt-3 mt-md-0">Tournaments</h4>
                        </div>

                        <ul className="list-unstyled d-flex align-items-start text-nowrap  align-items-md-center mb-0 justify-content-end flex-column flex-md-row">

                          <li className="ms-md-2 mt-3 mt-md-0">
                            <Link className="text-blue font-size-12 view-all-btn" to={`/tournament/${userId}`}>
                              View All
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {
                      isLoading ? (<Loader />) : myChallenges.length > 0 ? (myTournaments?.map((tournament, index) => (
                        <MyTournamentCard data={tournament} key={index} userId={userId} />))) : (<div className="text-center mx-1 p-3 mb-3 rounded-4 bg-white font-size-12 text-muted matches-wrapper">
                          No record found
                        </div>)

                    }


                  </Row>

                  <Row>
                  <Col className="px-0">
                  <div className='d-flex flex-nowrap  align-items-center'>
                          <h4 className="font-size-16 fw-bold text-black  my-3 ">Reported By</h4>
                        </div>
                   <ReportedBy userId={userId}/> 
                  </Col>
                    
                    </Row>    

                </Col>

              </>)
            }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile));
