import React, { useEffect, useState,useMemo } from 'react';
import { Card, CardBody, Table, CardTitle, Label, Input, Row, Col, UncontrolledTooltip, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Endpoints from 'api/endpoints';
import queryParamBuilder from 'api/queryParamBuilder';
// import ViewEditDelete from './ViewEditDelete';
import { toastError, toastSuccess } from 'components/Common/toastify';

import TableContainer from './Common/TableContainer';
import api from 'api';
import DeleteModal from './Common/DeleteModal';
import TableSkeleton from './Common/TableSekeloton';
// import api from 'api';

function Groups({ title, link }) {

  const [deleteModal,setDeleteModal] = useState(false)
  const [groupId,setGrouId]=useState("")


  const [groupsData, setGroupsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  const getGroups = async () => {
    const url=queryParamBuilder({
        endpoint:Endpoints.group,
        page:1,
        limit:10,
       
    })
    try {
      const res = await api.get(url);
      setIsLoading(false);
      setGroupsData(res?.data?.data);
    } catch (error) {
      setIsLoading(false);
      
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  const handleDeleteGroup = async (id) => {
    try {
      await api.delete(`${Endpoints.group}/${id}`);
      toastSuccess('Deleted');
      getGroups();
    } catch (error) {
     
    }
  };
  const data=groupsData?.map((el)=>{

    return {
      //  checkbox: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
         title: (
            <Link to={`/group-details/${el._id}`} className='text-truncate d-inline-block w-50 '>
              <span >{el?.name||"--"}</span>
            </Link>
         ),
         //   name: "John Doe",
         members: (<span className='d-inline-block w-50 text-center'>{el?.users.length||"--"}</span>),
         privacy: (<span className='d-inline-block w-50 text-center'>{el?.users.length||"--"}</span>),
         location: (<span className=' text-truncate ' style={{maxWidth:"100px"}}>{el?.location?.description||"--"}</span>),
        
         action:(<>
         <div className="d-flex gap-1 justify-content-center pe-5">
                   <Link
                   to={`/group-details/${el._id}`} 
                  
                   
                   className=" bg-transparent border-0">
                   <i className="uil-eye font-size-18" id={`edittooltip_${el?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                   </Link>
                
                
                   {/* <button 
                   onClick={()=>{
                     setGrouId(el._id)
                     setDeleteModal(true)
                   }}
                   className="text-danger ms-2 bg-transparent border-0">
                   <i
                      className="uil uil-trash-alt font-size-18"
                      id={`deletetooltip_${el?._id}`}
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_${el?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                   </button> */}
               </div>
         </>)
     
    }}
    )
       
     const rows=data.map(el=>(
       {
             checkbox: el.checkbox,
             title: el.title,
             members: el.members,
             location: el.location,
            privacy:el.privacy,
             action:el.action,
             // id: el._id,
           }
     ))
   
     const columns = useMemo(
       () => [
        //  {
        //    Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
        //    accessor: 'checkbox',
        //  },
         {
           Header: 'Title',
           accessor: 'title',
         },
         {
           Header: 'Members',
           accessor: 'members',
          //hasDescription: true,
         },
         {
            Header: 'Privacy',
            accessor: 'privacy',
           //hasDescription: true,
          },
         {
           Header: 'Location',
           accessor: 'location',
          //hasDescription: true,
         },
        
        
         {
           Header: 'Action',
           accessor: 'action',
           
         },
       ],
       []
     );
 


  return (
   <>
    <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteGroup(groupId)}
        onCloseClick={() => setDeleteModal(false)}
      />
    <Row>
      
      <Col lg={12}>
        <Card className="section-card ">
          <CardBody>
            <CardTitle className="h4 mb-2 text-dark">
              <div className="d-flex justify-content-between">
                <CardTitle className="mb-2 h4 d-flex">
                  <span className="font-size-16 fw-bold text-black">{title}</span>
                </CardTitle>
                <Link className="text-blue font-size-12 fw-normal" to={`/${link}-listing`}>
                  View All
                </Link>
              </div>
            </CardTitle>
            <Row className='overflow-auto' style={{height:"280px"}}>
            {isLoading ? (
            <TableSkeleton/>
            ) : (
            <TableContainer
            columns={columns}
            // rows={rows}
            data={rows}
            isGlobalFilter={true}
            isAddUsers={true}
            isAddTableBorderStrap={true}
            // handleUserClicks={handleUserClicks}
            addPagination={false}
            // topBar={topBar}
            
            />
            )}
            </Row>
            
          </CardBody>
        </Card>
      </Col>
    </Row>
   </>
  );
}

export default Groups;
