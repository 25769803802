import React, {useEffect, useMemo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
// import { isEmpty } from "lodash"

import {Col, Input, Row, UncontrolledTooltip} from 'reactstrap';
import DeleteModal from 'components/Common/DeleteModal';
import Listing from 'components/Common/Listing';
import Endpoints from 'api/endpoints';
import api from 'api';
import {toastError} from 'components/Common/toastify';
import moment from 'moment/moment';

import {toastSuccess} from 'components/Common/toastify';
import queryParamBuilder from 'api/queryParamBuilder';
import HtmlHead from 'components/html-head/HtmlHead';
import PointIcon from 'assets/icons/PointIcon';

function CourtsListing() {
  const title = 'Courts Listing';
  const description = 'Courts Page';

  const tabNames = [
    {
      title: 'All Courts',
      endpoint: Endpoints.court,
      type: '',
    },
    // {
    //   title: 'Disabled Courts',
    //   endpoint: Endpoints.getBlockedUsers,
    //   type: "report"
    // },
  ];

  // List tabes
  const [activeTab, setActiveTab] = useState(tabNames.at(0));

  const [fetchList, setFetchlist] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // paginaion states
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({
    searchValue: '',
    location: {
      lat: 0,
      long: 0,
    },
  });
  const [totalPages, setTotalPages] = useState(null);

  // filters
  const [courtFilterValues, setCourtFilterValues] = useState({
    indoor: '',
    membership: 'False',
    cost: 'paid',
  });


  // fetching data
  const fetchListHandler = async () => {
    setIsLoading(true);
    // const url = queryParamBuilder({
    //   page: currentPage,
    //   endpoint: activeTab.endpoint,
    //   limit: 10,
    //   type: activeTab.type,
    //   search: filters.searchValue,
    //   location: filters.location,
    // });

    const route = `${Endpoints.courtFilters}?page=${filters.searchValue===""?currentPage:1}&limit=10&search=${filters.searchValue}&indoor=${courtFilterValues?.indoor}&membership=${courtFilterValues?.membership}&cost=${courtFilterValues?.cost}&latitude=${filters?.location?.lat}&longitude=${filters?.location?.long}`;

    try {
      const resp = await api.get(route);

      setFetchlist(resp?.data?.data);
      setTotalPages(resp?.data?.totalPages);
    } catch (error) {
      toastError();
    }
    setIsLoading(false);
  };

  // const onSearch = (e) => {
  //   e.preventDefault();
  //   setSearchValue(e.target.value)

  // }

  // clear filters
  const clearFiltersHandler = () => {
    setSearchValue('');
    setFilters({
      searchValue: '',
      location: {
        lat: 0,
        long: 0,
      },
    });
    setCourtFilterValues({
      indoor: '',
      membership: 'False',
      cost: 'paid',
    });
  };

  useEffect(() => {
    fetchListHandler();
  }, [
    currentPage,
    filters.searchValue,
    filters.location,
    activeTab,
    courtFilterValues,
  ]);

  //delete court
  const [deleteModal, setDeleteModal] = useState(false);
  const [courtId, setCourtId] = useState(null);

  const onClickDelete = async courtId => {
    try {
      const res = await api.delete(`${Endpoints.court}/${courtId}`);

      if (res.status === 200) {
        toastSuccess('Court Deleted');
        fetchListHandler();
      }
    } catch (err) {
      toastError();
    }
    setDeleteModal(false);
  };

  // data destruction
  const tData = useMemo(() => {
    if (fetchList?.length > 0) {
      return fetchList?.map((el, ind) => ({
        // checkbox: (
        //     <input type="checkbox" className="form-check-input" id="customCheck1" />
        // ),
        name: (
          <Link  to={`/court-details/${el?._id}`} className=" text-truncate " style={{maxWidth: '100px'}}>
            {el?.name}
          </Link>
        ),
        //   name: "John Doe",
        location: (
          <p className=" text-truncate " style={{maxWidth: '100px'}}>
            {el?.location?.description}
          </p>
        ),

        matchesPlayed: el?.matchCount,
        price: <span className=" text-capitalize ">{el?.price}</span>,
        playLocation: (
          <span className=" text-capitalize ">{el?.playLocation}</span>
        ),

        noCourt: el?.noCourt,
        // businessHours:(<p>{`${el?.startTime?moment(el?.startTime).format("LT"):"-" }`}-{`${el?.endTime?moment(el?.startTime).format("LT"):"-" }`}</p>),
        businessHours: (
          <p>
            {el?.startTime || '-'}-{el?.endTime || '-'}
          </p>
        ),
        addedOn: `${moment(el?.updatedAt).format('MMMM DD, YYYY') || '--'}`,

        action: (
          <div className="d-flex gap-3 users">
            <ul className="list-inline font-size-20 contact-links mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/court-details/${el?._id}`}
                  className="text-primary"
                  onClick={() => {
                    //   const users = cellProps.row.original
                    //   onClickDelete(users)
                  }}
                  id={`deletetooltip_${el?._id}`}>
                  <i className="uil uil-eye font-size-18" />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`deletetooltip_${el?._id}`}>
                    view
                  </UncontrolledTooltip>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to={`/edit-court/${el?._id}`}
                  className="text-primary"
                  onClick={() => {
                    //   const users = cellProps.row.original
                    //   handleUserClick(users)
                  }}
                  id={`edittooltip_${el?._id}`}>
                  <PointIcon
                    name="pen"
                    height="20px"
                    width="20px"
                    className=""
                  />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`edittooltip_${el?._id}`}>
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    setDeleteModal(true);
                    setCourtId(el?._id);
                  }}>
                  <i
                    className="uil uil-trash-alt font-size-18 text-danger "
                    id={`deletetooltip_del_${el?._id}`}
                  />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`deletetooltip_del_${el?._id}`}>
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          </div>
        ),
      }));
    } else {
      return [];
    }
  }, [fetchList]);

  const rows = useMemo(
    () =>
      tData.map(el => ({
        // checkbox: el.checkbox,
        name: el.name,
        price: el.price,
        playLocation: el.playLocation,

        noCourt: el.noCourt,
        location: el.location,

        matchesPlayed: el.matchesPlayed,
        addedOn: el.addedOn,
        businessHours: el.businessHours,

        action: el.action,
        // id: el._id,
      })),
    [tData],
  );

  const cols = useMemo(
    () => [
      // {
      //           Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //           accessor: 'checkbox',
      //       },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Location',
        accessor: 'location',
        hasDescription: true,
      },
      {
        Header: 'Matches Played',
        accessor: 'matchesPlayed',
        hasDescription: true,
      },

      {
        Header: 'No of Court',
        accessor: 'noCourt',
        hasDescription: true,
      },
      {
        Header: 'Price',
        accessor: 'price',
        hasDescription: true,
      },
      {
        Header: 'Play Location',
        accessor: 'playLocation',
      },
      {
        Header: 'Business Hours',
        accessor: 'businessHours',
        hasDescription: true,
      },
      {
        Header: 'Added on',
        accessor: 'addedOn',
        hasDescription: true,
      },

      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [rows],
  );

  const listFilters = [
    // {
    //     title: "Most Matches Played",
    //     options: ["Sans Francisco", "New York"]
    //   },
  ];

  return (
    <React.Fragment>
      <HtmlHead title={title} description={description} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => onClickDelete(courtId)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <div className="mb-3">
            <h4>Courts Listing</h4>
          </div>
          <Row>
            <Col xs="12">
              <Listing
                title="Users Listing"
                searchBar={true}
                tabNames={tabNames}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                topBtn={'Add Court'}
                topBtnLink={'/add-court'}
                columns={cols}
                data={rows}
                isGlobalFilter={true}
                isAddUsers={true}
                isAddTableBorderStrap={true}
                //  handleUserClicks={handleUserClicks}
                addPagination={true}
                topBar={listFilters}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                // onSearch={onSearch}
                filters={filters}
                setFilters={setFilters}
                clearFilters={clearFiltersHandler}
                isLoading={isLoading}
                courtsFilter={true}
                setCourtFilterValues={setCourtFilterValues}
                courtFilterValues={courtFilterValues}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
CourtsListing.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default CourtsListing;
