import React from 'react';
import { Card, CardBody, CardTitle,  Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { matchStatus,statusText } from 'utils/matchStatus';
import TableContainer from 'components/Common/TableContainer';
import tableDataGenerater from './tableDataGenerater';
import TableSkeletonSm from 'components/Common/TableSeketonSm';
import api from 'api';




function UserInfoCard({ cardData,title,data=[],btnNavigat ,isLoading}) {

 

    const tData=tableDataGenerater(btnNavigat,data)

  return (
    <Card className="section-card overflow-hidden  ">
      <CardBody className='pb-2 '>
        <CardTitle className="h4">
          <div className="d-flex justify-content-between">
            <span className="text-black fw-bold font-size-16">{title}</span>
            <Link to={`/${btnNavigat}`} className="text-blue font-size-12 fw-normal">View All</Link>
          </div>
        </CardTitle>
        <Row className="py-1  overflow-auto" style={{height:"300px"}}>
        {
          !isLoading?(<TableContainer
            columns={tData.cols}
            // rows={rows}
            data={tData.rows}
            isGlobalFilter={true}
            isAddUsers={true}
            isAddTableBorderStrap={true}
            // handleUserClicks={handleUserClicks}
            addPagination={false}
            // topBar={topBar}
          />):(<TableSkeletonSm entries={5}/>)
        }
        </Row>
       
      </CardBody>
    </Card>
  );
}

export default UserInfoCard;
