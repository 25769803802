export const tournamentStatus = (arg) =>
  ({
    applicationOpen: 'light-green green',
    start: 'light-green green',

    cancel: 'light-red red',
    
    closed: 'light-red red',
  })[arg] || 'light-grey grey';
export const tournament = (arg) =>
  ({
    applicationOpen: 'Open',
    start: 'In Progress...',

    cancel: 'Cancelled',
    
    closed: 'Closed',
    double_elimination:'Double',
    single_elimination:'Single',
    round_robin:'Round Robbin',
  })[arg] ; 