import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import banner from "assets/images/default-img.svg";
import bellImg from "assets/images/notification.png";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { useEffect } from "react";
import api from "api";
import Endpoints from "api/endpoints";
import moment from "moment/moment";
import { onMessageListener } from "../../../firebase";
import Skeleton from "react-loading-skeleton";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getNotifications = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `${Endpoints.notification}/?filter=${filter}&page=${currentPage}&limit=10`
      );
      if (res.status === 200) {
        // const fetchedNotifications=res?.data?.data?.filter((el)=>el?.readStatus===false)
       
        setNotificationCount(res?.data?.data?.notificationCount)
        setNotifications(
          filter ? (st) => (st.length>0?[...st, ...res?.data?.data]:res?.data?.data) : res?.data?.data
        );
        setTotalPages(res?.data?.totalPages);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const singleMarkAsRead = async (id) => {
    try {
      const res = await api.put(`${Endpoints.notification}`, {
        notificationId: id,
      });
      if (res?.status === 200) {
        getNotifications();
      }
    } catch (err) {}
  };
  const allMarkAsRead = async () => {
    try {
      const res = await api.put(`${Endpoints.markedAllNotification}`);
      if (res?.status === 200) {
        getNotifications();
      }
    } catch (err) {}
  };

  onMessageListener()
    .then(() => {
      getNotifications();
      // setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    getNotifications();
  }, [currentPage, filter]);

  const redirectionPoint = (str) => {
    const word = str?.includes("_")
      ? str?.split("_")[0]?.toLowerCase()
      : str?.toLowerCase();
    return word
      ?.split("")
      ?.filter((ch, ind) => {
        if (ind === word.length - 1 && ch === "s") {
          return;
        } else return ch;
      })
      .join("");
  };
  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="uil-bell"></i>
          <span className="badge bg-green text-white rounded-pill">
            {notificationCount}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {" "}
                  {props.t("Notifications")}{" "}
                </h6>
              </Col>
              <div className="col-auto">
                <Link to="#!" onClick={() => allMarkAsRead()} className="small">
                  {" "}
                  Mark all as read
                </Link>
              </div>
            </Row>
            <Row>
              <Col className="col-6 text-center">
                <Link
                  to="#!"
                  className={`small ${!filter ? "active-notification" : ""}`}
                  onClick={() => {
                    setFilter(false);
                  }}
                >
                  New
                </Link>
              </Col>
              <Col className="col-6 text-center">
                <Link
                  to="#!"
                  className={`small ${filter ? "active-notification" : ""}`}
                  onClick={() => {
                    setFilter(true);
                  }}
                >
                  Earlier
                </Link>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "320px" }}>
            {isLoading ? (
              Array.from({ length: 10 }).map((_, i) => (
                <Row className="h-100 justify-content-around align-items-center">
                  <Col className="col-1">
                    <Skeleton
                      circle={true}
                      className="img-skelton-sm mx-auto"
                    />
                  </Col>
                  <Col className="col-10">
                    <Skeleton className="" />
                    <Skeleton className="w-75" />
                    <Skeleton className="w-50" />
                  </Col>
                </Row>
              ))
            ) : notifications?.length < 1 ? (
              <div className="d-flex flex-column justify-content-center  mt-5  h-100 align-items-center">
                <img src={bellImg} alt="bell" className="img-fluid" />
                No notification found
              </div>
            ) : (
              notifications?.map((notification, ind) => {
                return (
                  <Link
                    to={`/${redirectionPoint(notification?.type)}-details/${
                      notification?.data
                    }`}
                    key={notification?._id + ind}
                    onClick={() => singleMarkAsRead(notification?._id)}
                    className="text-dark notification-item"
                  >
                    <div className="d-flex align-items-start pb-0 ">
                      <img
                        src={
                          notification?.sender?.additionalInfo?.profileImage ||
                          banner
                        }
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-1">
                        <span
                          className="badge bg-green text-white font-size-12 pt-1 text-truncate  rounded-pill ms-auto"
                          style={{ maxWidth: "100px" }}
                        >
                          {notification?.type
                            ?.split("_")
                            ?.map(
                              (str, ind) =>
                                str.charAt(0) +
                                str
                                  .slice(1)
                                  .split("")
                                  .map((chr) => chr.toLowerCase())
                                  .join("")
                            )
                            ?.join(" ")}
                        </span>
                        <h6 className="mt-0 mb-1">
                          {notification?.title || "-"}
                        </h6>

                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{notification?.message}</p>
                          <p className="mb-0  ">
                            <i className="mdi mdi-clock-outline me-1" />
                            {/* {props.t("1 hours ago")}{" "} */}
                            {moment(notification?.createdAt)
                              .startOf("hour")
                              .fromNow()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })
            )}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <button
              disabled={currentPage === totalPages}
              className="btn btn-sm btn-link font-size-14 text-center"
              // to="/all-notifications"
              onClick={() =>
                setCurrentPage(currentPage <= totalPages ? currentPage + 1 : "")
              }
            >
              <i className="uil-arrow-circle-right me-1"></i>{" "}
              {/* {props.t("View all")}{" "} */}
              Load More
            </button>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
