import React, { useState,useEffect } from 'react'
import api from 'api';
import Endpoints from 'api/endpoints';
import { Row,Col } from 'reactstrap'
import ChallengeCard from 'components/ChallengeCard';
import MatchCard from 'components/MatchCard';
import Matches from 'components/Matches';
import { useLocation } from 'react-router-dom';





const MatchesWraper = ({id=null}) => {
  

  const { pathname } = useLocation();
  const lastPoint = pathname.split("/")[1];
  const tabNames =lastPoint==="court-details"?[ { title: "All", status: "all" },
  { title: "Upcoming Matches", status: "queued " },
  { title: "Played", status: "played" },]: [
    { title: "All", status: "all" },
    { title: "Upcoming Matches", status: "upcoming" },
    { title: "Won Matches", status: "winner" },
    { title: "Lost Matches", status: "loser" },
 
  ]
      const [activeTab, setActiveTab] = useState(tabNames.at(0));
      const [matches,setMatches]=useState([]);
      const [isLoading,setIsLoading]=useState(false)


      const getTeamDetails=async()=>{
        setIsLoading(true)
        try{
          const res=lastPoint==="court-details"?await api.get(`${Endpoints.courtMatch}?courtId=${id}&status=${activeTab?.status}`):await api.get(`${Endpoints.teamMatch}?teamId=${id}&status=${activeTab?.status}`)
      //     console.log(`${Endpoints.teamMatch}`,{
            
      //       teamId:id,
      //       status:activeTab?.status // upcoming | all | winner | loser
        
      // })
          setMatches(res?.data?.data)
          setIsLoading(false)
        }catch(e){
          setIsLoading(false)
        }
      }
    
      useEffect(()=>{
        if(id){
            getTeamDetails()
        }
      },[activeTab])

  return (
    <Row className='justify-content-center pe-0 '>
         <ul className="list-unstyled listing-menu d-flex flex-wrap">
              {tabNames.map((tabName, ind) => (
                <li
                  key={tabName.title}
                  className={`${
                    ind > 0 ? "" : ""
                  } pb-1 px-2 text-muted cursor-pointer text-nowrap  ${
                    activeTab.title === tabName.title
                      ? "active active-black"
                      : ""
                  }`}
                  onClick={() => setActiveTab(tabName)}
                >
                  {tabName.title}
                </li>
              ))}
            </ul>
            <Row className='justify-content-center'>
      {
       ( matches?.length>0)?(
         matches?.map((el,index)=>(
           <Col  className={`col-12 pt-3   `} key={index}>
             <Matches
              //  tournament={el}
              data={el} 
              key={index+el?._id}
              //  tournamentStatus={dataState?.tournamentStatus?.tournament[index].status} 
             />
           </Col>
         ))
       ):(
        <Col className='text-center bg-white  p-5 rounded-3 '>
          No match found
        </Col>
       )
      }
     
    </Row>
    </Row>
  )
}

export default MatchesWraper