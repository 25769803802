import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardTitle } from 'reactstrap';
import DatePicker from 'components/Common/DatePicker';
import Loader from './Common/Loader';

const TournamentsCategory=({ title, p1, p2, p3, col1, col2, col3,series,isLoading })=> {

  let single=0
  let double=0
  let round=0

  series?.tournament?.map(el=> el?.format==="single_elimination"?single+=el?.count:el?.format==="double_elimination"?double+=el?.count:round+=el?.count)

  const data=[
    (Math.round((single)/(single+double+round)*100)),
    (Math.round((double)/(single+round+double)*100)),
    (Math.round((round)/(single+double+round)*100)),
  ]
  
  // console.log("Tournament category: ", series)
  // const series=[3,1,0]
  const options = {
    chart:{
        height:230,
    },
    labels: [p1, p2, p3],
    colors: [col1, col2, col3],
    legend: {
      show: false,
      position: 'right',
    //   horizontalAlign: 'center',
    //   verticalAlign: 'middle',
      floating: false,
      fontSize: '12px',
    //   offsetX: "12px",
      markers: {
        width: 10,
        height: 10,
        // strokeWidth: 0,
        // strokeColor: '#fff',
        fillColors: [col1, col2, col3],
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
    },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 230,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  };

  return (
    <Card className="section-card card-height">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-2 h4 d-flex">
            <span className="font-size-16 fw-bold text-black">{title}</span>
            {/* <span className="text-muted ms-3 fw-normal text-nowrap mb-2 me-2 me-sm-0 mb-sm-0 font-size-14">
              weekly
              <i className="mdi mdi-chevron-down ms-1" />
            </span> */}
          </CardTitle>
          {/* <DatePicker /> */}
        </div>
        <div className="d-flex align-items-center justify-content-center circle-chart flex-wrap flex-sm-nowrap">
          <div className="mt-2 mt-sm-3">
            {
            
            isLoading ?(<Loader/>):(<ReactApexChart options={options} series={data} 
              type="pie" 
              height={230}  className="apex-charts" />)
            }
          </div>
          <ul className="list-unstyled pb-1 pb-sm-0  text-muted font-size-14 circle-chart-points d-flex flex-row flex-sm-column  justify-content-start">
            <li className="font-size-14 text-muted mt-2 text-nowrap ">
              {' '}
              <i className="fas fa-circle font-size-12 cursor-pointer mx-2 mx-md-0 me-md-2" style={{ color: col1 }} />
              {p1}
            </li>
            <li className="font-size-14 text-muted mt-2 mx-xxl-3 mx-md-0 point-li text-nowrap ">
              {' '}
              <i className="fas fa-circle font-size-12 cursor-pointer mx-2 mx-md-0 me-md-2" style={{ color: col2 }} />
              {p2}
            </li>
            {p3 && (
              <li className="font-size-14 text-muted mt-2 text-nowrap ">
                {' '}
                <i className="fas fa-circle font-size-12 cursor-pointer mx-2 mx-md-0 me-md-2" style={{ color: col3 }} />
                {p3}
              </li>
            )}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
}

export default TournamentsCategory;
