import React from 'react';
import Skeleton from 'react-loading-skeleton';


function PlayerSkeleton() {
  return (
    <div className="court-fee-wrap d-flex flex-column p-4">
      <div className="text-white d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Skeleton
            className="img-skeleton-sm rounded-circle"
            width={40}
            height={40}
            animation="wave"
            baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"
      
          />
          <div className="ms-2 flex-column d-flex">
            <span className="font-size-14 fw-sb">
              <Skeleton width={100} baseColor="#f6f6f696"
            highlightColor="#f6f6f62e" />
            </span>
            <span className="font-size-12">
              <Skeleton width={80} baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"/>
            </span>
          </div>
        </div>
        <div className="text-white font-size-18 fw-sb">Player <Skeleton width={10} baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"/></div>
      </div>
      <div className="text-white d-flex align-content-center justify-content-between justify-content-md-around flex-wrap row-cols-2 row-cols-sm-4 row-cols-md-5 row-cols-ms-4 mt-3 ">
        <div className="d-flex flex-column mb-3 mb-md-0">
          <span className="font-size-12"><Skeleton width={70}  baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"/></span>
          <span className="font-size-16">
            <Skeleton width={100} baseColor="#f6f6f696"
            highlightColor="#f6f6f62e" />
          </span>
        </div>
        <div className="d-flex flex-column mb-3 mb-md-0">
          <span className="font-size-12"><Skeleton width={70}  baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"/></span>
          <span className="font-size-16">
            <Skeleton width={100} baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"/>
          </span>
        </div>
        <div className="d-flex flex-column mb-3 mb-md-0">
          <span className="font-size-12"><Skeleton width={70}  baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"/></span>
          <span className="font-size-16">
            <Skeleton width={100} baseColor="#f6f6f696"
            highlightColor="#f6f6f62e" />
          </span>
        </div>
        <div className="d-flex flex-column mb-3 mb-md-0">
          <span className="font-size-12"><Skeleton width={70}  baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"/></span>
          <span className="font-size-16">
            <Skeleton width={100} baseColor="#f6f6f696"
            highlightColor="#f6f6f62e" />
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="font-size-12"><Skeleton width={70}  baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"/></span>
          <span className="font-size-16">
            <Skeleton width={100}  baseColor="#f6f6f696"
            highlightColor="#f6f6f62e"/>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PlayerSkeleton;
