import React,{useMemo,useState,useEffect} from 'react'
import PointIcon from 'assets/icons/PointIcon';
import TableContainer from 'components/Common/TableContainer';
import TableSkeleton from 'components/Common/TableSekeloton';
import { debounce } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import Endpoints from 'api/endpoints';
import api from 'api';
import { toastError } from 'components/Common/toastify';
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import moment from 'moment';
import queryParamBuilder from 'api/queryParamBuilder';
import banner from "assets/images/default-img.svg"

const GroupTournamentList = () => {

    const title = "Group Tournament";
    const description = "Group Page";

    const {id}=useParams();

    const [grouptournamentData, setGroupTournamentData] = useState([]);
    const [searchValue, setSearchValue] = useState();
    const [currentPage,setCurrentPage]=useState(1)
    const [totalPages,setTotalPages]=useState()
    const [isLoading, setIsLoading] = useState(true);
    
    
      useEffect(() => {
        const getGroupTournament = async () => {
            const url = queryParamBuilder({
              endpoint: Endpoints.tournament,
              page: 1,
              limit: 10,
              search:searchValue
            });
            try {
              const res = await api.get(`${url}&groupId=${id}`);
              setGroupTournamentData(res?.data?.data);
              setTotalPages(res?.data?.totalPages);
              setIsLoading(false);
            } catch (error) {
              toastError(error?.message);
             
            }
          };
          getGroupTournament()
        // debounce(getGroupTournament,500)
      }, [searchValue]);
      const tData = useMemo(() => {
        if (grouptournamentData) {
          return grouptournamentData?.map((el, ind) => ({
            // checkbox: (
            //   <input
            //     type="checkbox"
            //     className="form-check-input"
            //     id="customCheck1"
            //   />
            // ),
            title: (
              <Link
                to={`/tournament-details/${el?._id}`}
                className="d-flex flex-row align-items-center text-center"
              >
                <img
                  src={el.bannerImage||banner}
                  className="avatar-xs rounded-circle me-2"
                />
                <span className="ms-md-2 text-nowrap ">{el?.name || "--"} </span>
              </Link>
            ),
            //   name: "John Doe",
            category: el.isSanctioned ? "Sanctioned" : "Not Sanctioned",
            type: el.status,
            privacy: el.isPrivate ? "Private" : "Public",
            location: (
              <span
                className=" text-truncate d-inline-block"
                style={{ maxWidth: "100px" }}
              >
                {el.location?.description || "--"}
              </span>
            ),
    
            //   userSince: `${moment(el?.createdAt).format('MMMM DD, YYYY')||"--"}`,
            date: moment(el.date).format("MMMM DD, YYYY"),
            time: el.time ? el.time : "No time",
            court: <span className="ms-3">{el.court.length || "-"}</span>,
            id: el._id,
    
            action: (
              <div className="d-flex flex-nowrap users">
                <ul className="list-inline font-size-20 contact-links mb-0">
                  <li className="list-inline-item">
                    <Link
                      to={`/tournament-details/${el._id}`}
                      className="text-primary"
                      onClick={() => {
                        //   const users = cellProps.row.original
                        //   handleUserClick(users)
                      }}
                    >
                      <i
                        className="uil uil-eye font-size-18"
                        id={`edittooltip_view_${ind}`}
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`edittooltip_view_${ind}`}
                      >
                        view
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  {/* <li className="list-inline-item">
                                <Link
                                     to="#"
                                     className="text-primary ms-3"
                                     onClick={() => {
                                       handleShowModal();
                                       setTournamentID(el._id);
                                     }}
                   
                                >
                                    <i className="uil uil-pen font-size-18" id={`edittooltip_edit_${ind}`} />
                                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${ind}`}>
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li> */}
                  {/* <li className="list-inline-item">
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => {
                        handleDeleteTournament(el._id);
                      }}
                    >
                      <i
                        className="uil uil-trash-alt font-size-18 text-danger"
                        id={`deletetooltip_del_${ind}`}
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`deletetooltip_del_${ind}`}
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </li> */}
                  <li className="list-inline-item "></li>
                </ul>
              </div>
            ),
          }));
        }
      }, [grouptournamentData]);
    
      const rows = useMemo(() => {
        return tData?.map((el) => ({
          // checkbox: el.checkbox,
          title: el.title,
          category: el.category,
          type: el.type,
          privacy: el.privacy,
          location: el.location,
          date: el.date,
          time: el.time,
          court: el.court,
          id: el.id,
          action: el.action,
        }));
      }, [tData]);
    
      const columns = useMemo(
        () => [
          // {
          //   Header: (
          //     <Input
          //       type="checkbox"
          //       className="form-check-input"
          //       id="customCheck1"
          //     />
          //   ),
          //   accessor: "checkbox",
          // },
          {
            Header: "Title",
            accessor: "title",
          },
          {
            Header: "Category",
            accessor: "category",
            hasDescription: true,
          },
          {
            Header: "Status",
            accessor: "type",
            hasDescription: true,
          },
          {
            Header: "Privacy",
            accessor: "privacy",
            hasDescription: true,
          },
          {
            Header: "Location",
            accessor: "location",
            hasDescription: true,
          },
          {
            Header: "Date",
            accessor: "date",
            hasDescription: true,
          },
          {
            Header: "Time",
            accessor: "time",
            hasDescription: true,
          },
          {
            Header: "No. Courts",
            accessor: "court",
            hasDescription: true,
          },
          {
            Header: "Action",
            accessor: "action",
          },
        ],
        []
      );
  return (
    <>
     <HtmlHead title={title} description={description} />
    <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <div className="mb-3">
            <h4>Group Tournament Listing</h4>
          </div>
          <Row>
            <Col xs="12">
             
              <Card>
                <CardBody>
                  <Row>
                    <div className="d-flex ms-1  align-items-center ">
                      <button className={`border-0 bg-transparent pt-1`}>
                        <PointIcon
                          height="18px"
                          width="18px"
                          name="search"
                          className="mb-2"
                        />
                      </button>
                      <input
                        type="text"
                        className="bg-transparent border-0   topbar-search font-size-14"
                        placeholder="Search by name..."
                        // value={filters.searchValue}
                        onChange={(e) => {
                          e.preventDefault();
                          setSearchValue(e.target.value);
                        //   debounceHandler(
                        //     setFilters,
                        //     "searchValue",
                        //     e.target.value
                        //   );
                        }}
                      />
                    </div>
                  </Row>
                  {isLoading ? (
                    <TableSkeleton entries={10} />
                  ) : (
                    <>
                      <TableContainer
                        columns={columns}
                        data={rows}
                        isGlobalFilter={true}
                        isAddUsers={true}
                        isAddTableBorderStrap={true}
                        // handleUserClicks={handleUserClicks}
                        isLoading={isLoading}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        addPagination={true}
                        totalPages={totalPages}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default GroupTournamentList