import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage"; // def
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["Login"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
let persistor = persistStore(store);

export { store, persistor, sagaMiddleware };
