import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Container, Row, Spinner } from 'reactstrap';
// import AutoComplete from 'react-google-autocomplete';



import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Endpoints from 'api/endpoints';
import { toastError } from 'components/Common/toastify';
import { toastSuccess } from 'components/Common/toastify';
import api from 'api';
import HtmlHead from 'components/html-head/HtmlHead';
import AutoComplete from 'components/Common/AutoCompleteLocation';


const validationSchema = Yup.object().shape({
  courtName: Yup.string().required('Please enter the court name'),
  startTime: Yup.string().required('This field is required'),
  endTime: Yup.string().required('This field is required'),
  noCourt:Yup.number()
  .positive('Please enter a valid number'),
  // ownerNumber: Yup.string().required('This field is required'),
  // matchesPlayed: Yup.string().required('This is required'),
  courtLighting: Yup.string().required('This field is required'),
  location: Yup.object().required('Please Select Your Location'),
 website: Yup.string()
  .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
  ),
  // image: Yup.mixed().required('This filed is required'),
});

function CourtForm() {


  // const [googleLocation, setGoogleLocation] = useState(null);
  const [singleCourt, setSingleCourt] = useState();
  const location=useLocation()
  const { id } = useParams();

  const title = location.pathname === '/add-court' ? 'Add Court' : 'Edit Court';
  const description = `${title} Page`;

  const getCourtById = async () => {
    try {
      const res = await api.get(`${Endpoints.court}/${id}`);
      setSingleCourt(res?.data?.data);
    } catch (err) {
     
    }
  };

  useEffect(() => {
  if(id){
    getCourtById()
  }
  else return
  }, [id]);

  const navigate = useNavigate();

  const initialValues = {
    courtName: singleCourt?.name || "",
    startTime: singleCourt?.startTime || "",
    endTime: singleCourt?.endTime || "",
    ownerName: singleCourt?.ownerName || "",
    ownerNumber: singleCourt?.ownerNumber || "",
    courtLighting: singleCourt?.lighting || "",
    website:singleCourt?.website || "",
    shortDescription:singleCourt?.description||"",
   
    noCourt:singleCourt?.noCourt||"",
    // description:description||"",
  location: id ? singleCourt?.location:"",
    // ? {
    //     description: singleCourt?.location?.description,
    //     details: singleCourt?.location?.details,
    //   }
    // : '',
    image: singleCourt?.image,
  };

  // const locationObject = {
  //   location: {
  //     description: googleLocation?.formatted_address,
  //     details: googleLocation,
  //   },
  // };
  const handleSubmit = async (values) => {


    if (location.pathname !== '/add-court') {
      try {
        await api.put(`${Endpoints.court}/${id}`, {
          name: values.courtName,
          location: values.location,
          startTime: values.startTime,
          endTime: values.endTime,
          ownerName: values.ownerName,
          ownerNumber
          : values.ownerNumber,
          // matchesPlayed: values.matchesPlayed,
          lighting: values.courtLighting,
          noCourt:values.noCourt,
          website:values.website,
          shortDescription:values.shortDescription,
          // description:values.description,
          image:values.image,
        });
        toastSuccess('Edited');
        navigate('/courts-listing');
        // setIsEdit(false);
      } catch (error) {
        // toastError();
        console.log(error.message);
      }
    } else {
      // create court
      try {
        await api.post(Endpoints.court, {
          name: values.courtName,
          location: values.location,
          noCourt: values.noCourt,
          startTime: values.startTime,
          endTime: values.endTime,
          ownerName: values.ownerName,
          ownerNumber: values.ownerNumber,
          lighting: values.courtLighting,
          image: values.image,
          website:values.website,
          shortDescription:values.shortDescription,
          // description:values.description,
        });
        toastSuccess('Created');
        navigate('/courts-listing');
      } catch (error) {
        // toastError();
        console.log(error?.response?.data?.message);
      }
    }
  
  };

  // upload media 
  const uploadProfile = async (file,setFieldValue) => {
    const formData = new FormData();
    formData.append('file', file);
    
    try {
      const res = await api.post(Endpoints.uploadSingleMedia, formData);
      if (res.status === 200) {
        setFieldValue('image', res?.data?.url);
        // toastSuccess("")
      }
    } catch (error) {
     
    }
  };

  const lightOptions = ['Yes', 'No'];
 //
//  const momentDate = moment(prevDOB);
//  const formattedDate = momentDate.format('YYYY-MM-DD');
 //
  return (
    <>
    <HtmlHead title={title} description={description} />
    <div className="page-content create-tournament-wrapper">
      <Container fluid>
        <div className="fw-bold font-size-24 mb-3 text-black">{title}</div>
        <Row>
          <Col className="col-lg-8 h-75">
            {(singleCourt && location.pathname !== '/add-court') || location.pathname === '/add-court' ? (
    <Row className="create-user-form p-3 mb-4">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ setFieldValue, values }) => (
          <Form >
            <div className="fw-bold font-size-16 text-black" id="uploadContainer">
              Upload Court Image
              <Col className="col-lg-3">
              {}
              {values?.image ? (
                <Row>
                  <Col className="col-lg-6">
                    <div className="text-center">
                      <img src={values?.image} height="150px" width="150px" alt="upload" />
                    </div>
                    <i
                      onClick={() => {
                        setFieldValue('profileImage', '');
                      }}
                      size={100}
                      height="80px"
                      width="80px"
                      className="uil-expand-arrows"
                      style={{ color: 'red', position: 'absolute', top: 0, right: 0, height: 80, width: 80 }}
                    />
                  </Col>
                </Row>
              ) : (
                  <>
                 
                    <label htmlFor="imageInput" className="upload-img d-flex flex-column justify-content-center align-items-center mt-3 cursor-pointer">
                      <div>
                        <img src="/img/upload.svg" alt="upload" />
                      </div>
                      <span className="font-size-14 text-muted fw-normal mt-2">Upload image</span>
                    </label>
                
                  <input
                    type="file"
                    id="imageInput"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={(e) => uploadProfile(e.target.files[0], setFieldValue)}
                  />
              </>
              )}
                <ErrorMessage name="image" component="div" className="text-danger" />
              </Col>
              <h4 className="fw-bold font-size-16 text-black mt-3 mb-0">Court Details</h4>
            </div>
            <Row>
              <Col className="col-lg-6 mt-2">
                <div className="d-flex flex-column">
                  <label htmlFor="courtName" className="fw-normal font-size-12 text-muted">
                    Court Name
                    <span className="text-danger font-size-12">*</span>
                  </label>
                  <Field type="text" name="courtName" id="courtName" className="info" placeholder="Court name" />
                  <ErrorMessage name="courtName" component="div" className="text-danger" />
                </div>
              </Col>
              <Col className="col-lg-6 mt-2">
                <div className="d-flex flex-column">
                  <label htmlFor="location" className="fw-normal font-size-12 text-muted">
                    Location
                    <span className="text-danger font-size-12">*</span>
                  </label>
                  {/* <AutoComplete
                      name="location"
                      apiKey={process.env.REACT_APP_GOOGLE_PLACES}
                      className="info"
                      placeholder="Provide your location"
                      // options={{
                      //   type:
                      // }}
                      defaultValue={values?.location?.description}
                      onPlaceSelected={(place) => {
                        if (id) {
                          setFieldValue('location', {
                            description: place?.formatted_address,
                            details: place,
                          });
                        } else {
                          setFieldValue('location', place);
                        }
                        // setGoogleLocation(place);
                      }}
                    /> */}
                     <AutoComplete setFieldValue={setFieldValue} className="info" form={true} defaultValue={values.location?.description} />
                  <ErrorMessage name="location" component="div" className="text-danger" />
                </div>
              </Col>
              <Col className="col-lg-6 mt-4 mt-md-2 mt-lg-4">
                <div className="d-flex flex-column">
                  <label htmlFor="website" className="fw-normal font-size-12 text-muted">
                   Web Site
                  </label>
                  <Field type="url" name="website" id="website" className="info" placeholder="https://example.com" />
                  <ErrorMessage name="website" component="div" className="text-danger" />
                </div>
              </Col>
              <Col className="col-lg-6 mt-4 mt-md-2 mt-lg-4">
                <div className="d-flex flex-column">
                  <label htmlFor="startTime" className="fw-normal font-size-12 text-muted">
                    Start Time
                  </label>
                  <Field type="time" name="startTime" id="startTime" className="info" placeholder="Start time" />
                  <ErrorMessage name="startTime" component="div" className="text-danger" />
                </div>
              </Col>
              <Col className="col-lg-6 mt-4 mt-md-2 mt-lg-4">
                <div className="d-flex flex-column">
                  <label htmlFor="endTime" className="fw-normal font-size-12 text-muted">
                    End Time
                  </label>
                  <Field type="time" name="endTime" id="endTime" className="info" placeholder="End time" />
                  <ErrorMessage name="endTime" component="div" className="text-danger" />
                </div>
              </Col>
              <Col className="col-lg-6 mt-4">
                <div className="d-flex flex-column">
                  <label htmlFor="shortDescription" className="fw-normal font-size-12 text-muted">
                   Short Description
                    <span className="text-danger font-size-12">*</span>
                  </label>
                  <Field type="text" name="shortDescription" id="description" className="info" placeholder="Simple text" />
                  <ErrorMessage name="shortDescription" component="div" className="text-danger" />
                </div>
              </Col>
              <Col className="col-lg-6 mt-4 mt-md-4">
                <div className="d-flex flex-column">
                  <label htmlFor="ownerName" className="fw-normal font-size-12 text-muted">
                    Owner Name(optional)
                  </label>
                  <Field type="text" name="ownerName" id="ownerName" list="owners" className="info" placeholder="Owner name" />
                </div>
              </Col>
              <Col className="col-lg-6 mt-4">
                <div className="d-flex flex-column">
                  <label htmlFor="ownerNumber" className="fw-normal font-size-12 text-muted">
                    Owner Number
                    <span className="text-danger font-size-12">*</span>
                  </label>
                  <Field type="tel" name="ownerNumber" id="ownerNumber" className="info" placeholder="Owner Number" min={1} />
                  <ErrorMessage name="ownerNumber" component="div" className="text-danger" />
                </div>
              </Col>
             
              {/* <Col className="col-lg-6 mt-4">
                <div className="d-flex flex-column">
                  <label htmlFor="description" className="fw-normal font-size-12 text-muted">
                   Description
                    <span className="text-danger font-size-12">*</span>
                  </label>
                  <Field type="text" name="description" id="description" className="info" placeholder="Simple text" />
                  <ErrorMessage name="description" component="div" className="text-danger" />
                </div>
              </Col> */}
              <Col className="col-lg-6 mt-4">
                <div className="d-flex flex-column">
                  <label htmlFor="noCourt" className="fw-normal font-size-12 text-muted">
                   No of Courts
                    <span className="text-danger font-size-12">*</span>
                  </label>
                  <Field type="number" name="noCourt" id="noCourt" className="info" placeholder="No of Courts" />
                  <ErrorMessage name="noCourt" component="div" className="text-danger" />
                </div>
              </Col>
              <Col className="col-lg-6 mt-4">
                <div className="d-flex flex-column">
                  <label htmlFor="courtLighting" className="fw-normal font-size-12 text-muted">
                    Court Lighting
                    <span className="text-danger font-size-12">*</span>
                  </label>
                  <Field type="text" list="lightings" name="courtLighting" id="courtLighting" className="info" placeholder="Select lighting" />
                  <datalist id="lightings">
                    {lightOptions.map((el) => (
                      <option value={el} key={el}>
                        {el}
                      </option>
                    ))}
                  </datalist>
                  <ErrorMessage name="courtLighting" component="div" className="text-danger" />
                </div>
              </Col>

              <div className="d-flex justify-content-end mt-4">
                <Button type="submit" className="btn font-size-14 fw-medium text-white create primary-gradient">
                  {location.pathname === '/add-court' ? 'Add Court' : 'Edit Court'}
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </Row>
  ) : (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Spinner />
    </div>
  )}
          </Col>
        </Row>
      </Container>
    </div>
  </>


  )
}

export default CourtForm;
