import React from 'react'
import svgs from './svgs'

const PointIcon = ({height,width,name,className,id=null,style=null,fill="none"}) => {
    // return svgs(name)(height,width,className)
    return <svg xmlns="http://www.w3.org/2000/svg" 
        id={id}
        
        width={width}
        className={className}
        height={height} 
        viewBox="0 0 20 20"
         fill={fill}
         style={{...style}}
         >
       {svgs(name)}
      </svg>
}

export default PointIcon