 const upperCardStyle = (ind) => {
    const gradients = [
      'linear-gradient(135deg, #FAC594 0%, #F59235 100%)',
      'linear-gradient(135deg, #B9E401 0%, #83A201 100%)',
      'linear-gradient(135deg, #EBD700 0%, #B2A400 100%)',
      'linear-gradient(135deg, #4DD5E5 0%, #1AA3B3 100%)',
      'linear-gradient(135deg, #D499FF 0%, #AA34FE 100%)',
      'linear-gradient(135deg, #E67F98 0%, #DA4367 100%)',
      'linear-gradient(135deg, #4DD5E5 0%, #1AA3B3 100%)',
      'linear-gradient(135deg, #9FA4DF 0%, #535BC6 100%)',
      'linear-gradient(135deg, #77CAB7 0%, #378D78 100%)',
      'linear-gradient(135deg, #DF9FDD 0%, #C653C1 100%)',
      'linear-gradient(135deg, #A7C0DD 0%, #4B7EB9 100%)',
      'linear-gradient(135deg, #D8C09C 0%, #B08545 100%)',
      'linear-gradient(135deg, #9CBFD8 0%, #5390BC 100%)',
      'linear-gradient(135deg, #D8C09C 0%, #B08545 100%)',
      'linear-gradient(135deg, #9CBFD8 0%, #5390BC 100%)',
      'linear-gradient(135deg, #FAC594 0%, #F59235 100%)',
      'linear-gradient(135deg, #B9E401 0%, #83A201 100%)',
      'linear-gradient(135deg, #EBD700 0%, #B2A400 100%)',
      'linear-gradient(135deg, #4DD5E5 0%, #1AA3B3 100%)',
      'linear-gradient(135deg, #D499FF 0%, #AA34FE 100%)',
      'linear-gradient(135deg, #E67F98 0%, #DA4367 100%)',
      'linear-gradient(135deg, #4DD5E5 0%, #1AA3B3 100%)',
      'linear-gradient(135deg, #9FA4DF 0%, #535BC6 100%)',
      'linear-gradient(135deg, #77CAB7 0%, #378D78 100%)',
      'linear-gradient(135deg, #DF9FDD 0%, #C653C1 100%)',
      'linear-gradient(135deg, #A7C0DD 0%, #4B7EB9 100%)',
      'linear-gradient(135deg, #D8C09C 0%, #B08545 100%)',
      'linear-gradient(135deg, #9CBFD8 0%, #5390BC 100%)',
      'linear-gradient(135deg, #D8C09C 0%, #B08545 100%)',
      'linear-gradient(135deg, #9CBFD8 0%, #5390BC 100%)',
      'linear-gradient(135deg, #FAC594 0%, #F59235 100%)',
      'linear-gradient(135deg, #B9E401 0%, #83A201 100%)',
      'linear-gradient(135deg, #EBD700 0%, #B2A400 100%)',
      'linear-gradient(135deg, #4DD5E5 0%, #1AA3B3 100%)',
      'linear-gradient(135deg, #D499FF 0%, #AA34FE 100%)',
      'linear-gradient(135deg, #E67F98 0%, #DA4367 100%)',
      'linear-gradient(135deg, #4DD5E5 0%, #1AA3B3 100%)',
      'linear-gradient(135deg, #9FA4DF 0%, #535BC6 100%)',
      'linear-gradient(135deg, #77CAB7 0%, #378D78 100%)',
      'linear-gradient(135deg, #DF9FDD 0%, #C653C1 100%)',
      'linear-gradient(135deg, #A7C0DD 0%, #4B7EB9 100%)',
      'linear-gradient(135deg, #D8C09C 0%, #B08545 100%)',
      'linear-gradient(135deg, #9CBFD8 0%, #5390BC 100%)',
      'linear-gradient(135deg, #D8C09C 0%, #B08545 100%)',
      'linear-gradient(135deg, #9CBFD8 0%, #5390BC 100%)',
    ];
  
    return gradients[ind] || 'white';
  };
  export default upperCardStyle;