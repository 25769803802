import React from 'react'

const MatchBracket = ({data}) => {
// console.log(data)

  return (
    <div className='winner-bracket pt-2'>
      <h4 className="font-size-16 fw-bold text-white text-capitalize text-center    "  >Tournament Brackets</h4>
      {
        data?.winnerBracket?.length>0?(
        <>
        <h4 className="font-size-16 fw-bold text-white text-capitalize text-center   mt-3 "  >Winner</h4>
        <div className='d-flex align-items-center  winner-bracket-container pe-3 '>
          

          {
            data?.winnerBracket?.map((round,index)=>(
              <div className={` ${index>0?"ms-3":"ms-3"} pe-3`} key={round?._id}>
                
                  {round?.map((match,ind)=>(<div className=' d-flex flex-column justify-content-between   winner_wrap pe-5 my-5 '>
                    <p className='bg-white text-center  px-3 me-3 mb-3 team-wrap text-blue'  content="-">
                    
                    {
                      match?.teamA?.users?.at(0)?.firstName||"-"
                    } 
                    </p>
                    
                    <span className='text-end text-white text-nowrap '> {index+1}</span>
                    <p className='bg-white text-center  me-3 text-nowrap mt-3 px-3 mb-0 team-wrap text-blue' content="-">
                    
                    {
                      match?.teamB?.users?.at(0)?.firstName||"-"
                    }
                    </p>
                    
                  </div>))}
              </div>
            ))
          }
          
          
          </div>
        </>
        ):(<div className='text-center text-light pb-1'>To be announced</div>)
      }
      {
        data?.loserBracket?.length>0?(
        <>
        <h4 className="font-size-16 fw-bold text-white text-capitalize text-center    "  >Loser</h4>
        <div className='d-flex align-items-center  winner-bracket-container pe-3 '>
          

          {
            data?.loserBracket?.map((round,index)=>(
              <div className={` ${index>0?"ms-3":"ms-3"} pe-3`} key={round?._id}>
                
                  {round?.map((match,ind)=>(<div className=' d-flex flex-column justify-content-between   winner_wrap pe-5 my-5 '>
                    <p className='bg-white text-center  px-3 me-3 mb-3 team-wrap text-blue'  content="-">
                    
                    {
                      match?.teamA?.users?.at(0).firstName||"-"
                    } 
                    </p>
                    
                    <span className='text-end text-white text-nowrap '> {index+1}</span>
                    <p className='bg-white text-center  me-3 text-nowrap mt-3 px-3 mb-0 team-wrap text-blue' content="-">
                    
                    {
                      match?.teamB?.users?.at(0).firstName||"-"
                    }
                    </p>
                    
                  </div>))}
              </div>
            ))
          }
          
          
          </div>
        </>
        ):""
      }
    </div>
    
  )
}

export default MatchBracket