import React, { useMemo, useState, useEffect } from "react";
import Endpoints from "api/endpoints";
import api from "api";
import { Link, useParams } from "react-router-dom";
import DeleteModal from "components/Common/DeleteModal";
import {
  Card,
  CardBody,
  Col,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Listing from "components/Common/Listing";
import { toastError } from "components/Common/toastify";
import { Rating } from "react-simple-star-rating";
import { findAllByDisplayValue } from "@testing-library/react";
import HtmlHead from "components/html-head/HtmlHead";
import banner from "assets/images/default-img.svg";
import TableContainer from "components/Common/TableContainer";
import TableSkeleton from "components/Common/TableSekeloton";
import PointIcon from "assets/icons/PointIcon";
import debounceHandler from "utils/debounceHandler";

const ParticipantsListing = () => {
  const title = "Participants Listing";
  const description = "Participants Page";

  const [isLoading, setIsLoading] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({

    searchValue: "",
  });
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { tournamentId } = useParams();

  const getParticipants = async (tournamentId) => {
    setIsLoading(true);

    try {
      // console.log("url ",`${Endpoints.participants}/${tournamentId}?page=1&limit=5`)

      const res = await api.get(
        `${Endpoints.team}/search?tournamentId=${tournamentId}&page=${filters?.searchValue===""?currentPage:1}&limit=10&search=${filters.searchValue}`
      );
      // res?.data?.data?.forEach(element => {
      //     element?.users?.forEach(user => (users.push(user)))
      // });
      setTotalPages(res?.data?.totalPages);
      setParticipants(res?.data?.data);
      setIsLoading(false);
    } catch (err) {
      toastError();
      setIsLoading(false);
    }
  };

  const clearFilterHandler = () => {
    setFilters({
    
      searchValue: "",
    });
  };

  useEffect(() => {
    getParticipants(tournamentId);
  }, [tournamentId,filters.searchValue]);

  const tData = useMemo(() => {
    if (participants.length > 0) {
      return participants.map((team, ind) => ({
        // checkbox: (
        //     <input type="checkbox" className="form-check-input" id="customCheck1" />
        //   ),
        participant: (
          <div className="d-flex flex-column">
            {team?.users?.map((user,index) => (
               <>
               <Link to={`/team-detail/${team?._id}`} className={`d-flex flex-nowrap align-items-center text-center ${(team?.users?.length>1&&index===0)?"ms-3":""}`}>
                 <img
                   src={user?.additionalInfo?.profileImage || banner}
                   className="avatar-xs rounded-circle me-1"
                 />
                 <span className="ms-md-2 d-inline-block">
                   {user?.firstName} {user?.lastName}{" "}
                 </span>
                 {/* <sup>{team?.isTeamLead === user?._id ?  "(Ct)" : ""}</sup> */}
               </Link>
             </>
            ))}
          </div>
        ),
        //   name: "John Doe",
        // rank: (
        //   <div className="d-flex">
        //     <Rating
        //       // onClick={handleRating}
        //       size={15}
        //       initialValue={4.5}
        //       readonly={true}
        //     />
        //     <div className="ms-2 text-muted" style={{ marginTop: "3px" }}>
        //       {4.5}
        //     </div>
        //   </div>
        // ),
        matchesPlayed: (
          <sapn className="text-center w-50 d-inline-block">
            {team?.wins + team?.loses}
          </sapn>
        ),
        wins: (<span className='text-center d-inline-block w-50'>{team?.wins}</span>),
        loses: (<span className='text-center d-inline-block w-50'>{team?.loses}</span>),
        point: (<span className='text-center d-inline-block w-50'>{team?.pointDiff}</span>),
        action: (
          <div className="d-flex  users align-items-center justify-content-center w-50">
            <ul className="list-inline font-size-20 contact-links  mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/team-detail/${team?._id}`}
                  className="text-primary"
                  onClick={() => {
                    //   const users = cellProps.row.original
                    //   handleUserClick(users)
                  }}
                >
                  <i
                    className="uil uil-eye font-size-18"
                    id={`edittooltip_view_${ind}`}
                  />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`edittooltip_view_${ind}`}
                  >
                    view
                  </UncontrolledTooltip>
                </Link>
              </li>
              {/* <li className="list-inline-item">
                          <Link
                            to="#"
                            className="text-danger"
                            onClick={() => {
                              const users = cellProps.row.original
                              onClickDelete(users)
                            }}
                          >
                            <i
                              className="uil uil-trash-alt font-size-18"
                              id={`deletetooltip_${ind}`}
                            />
                            <UncontrolledTooltip placement="bottom" target={`deletetooltip_${ind}`} >
                              Delete
                            </UncontrolledTooltip>
                          </Link>
                        </li> */}
            </ul>
          </div>
        ),
      }));
    } else {
      return [];
    }
  }, [participants]);

  const rows = useMemo(
    () =>
      tData.map((el) => ({
        // checkbox: el.checkbox,
        participant: el.participant,
        matchesPlayed: el.matchesPlayed,
        location: el.location,
        // rank: el.rank,
        wins: el.wins,
        loses: el.loses,
        point: el.point,
        action: el.action,
        // id: el._id,
      })),
    [tData]
  );

  const columns = useMemo(
    () => [
      //  {
      //    Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //    accessor: 'checkbox',
      //  },
      {
        Header: "Participants",
        accessor: "participant",
      },
      // {
      //   Header: "Rank",
      //   accessor: "rank",
      //   hasDescription: true,
      // },
      {
        Header: "Matches Played",
        accessor: "matchesPlayed",
        hasDescription: true,
      },
      {
        Header: "Points",
        accessor: "point",
        hasDescription: true,
      },
      {
        Header: "Wins",
        accessor: "wins",
        hasDescription: true,
      },
      {
        Header: "Loses",
        accessor: "loses",
        hasDescription: true,
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const listFilters = [
    // {
    //   title: "Ratings Filter",
    //   options: ["Sans Francisco", "New York"]
    // }
  ];
  return (
    <React.Fragment>
      <HtmlHead title={title} description={description} />
      <DeleteModal
      //   show={deleteModal}
      //   onDeleteClick={() => onClickDelete(user)}
      //   onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <div className="mb-3">
            <h4>Participants Listing</h4>
          </div>
          <Row>
            <Col xs="12">
              {/* <Listing
                title="Participants Listing"
                //   tabNames={tabNames}
                //   activeTab={activeTab}
                //   setActiveTab={setActiveTab}
                //   topBtn={"create"}
                //   topBtnLink={"/create-user"}
                searchBar={false}
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUsers={true}
                isAddTableBorderStrap={true}
                //  handleUserClicks={handleUserClicks}
                addPagination={true}
                topBar={listFilters}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                //   onSearch={onSearch}
                isLoading={isLoading}
                filters={filters}
                setFilters={setFilters}
                clearFilters={clearFilterHandler}
              /> */}

              <Card>
                <CardBody>
                  <Row>
                    <div className="d-flex ms-1  align-items-center ">
                      <button className={`border-0 bg-transparent pt-1`}>
                        <PointIcon
                          height="18px"
                          width="18px"
                          name="search"
                          className="mb-2"
                        />
                      </button>
                      <input
                        type="text"
                        className="bg-transparent border-0   topbar-search font-size-14"
                        placeholder="Search by name..."
                        // value={filters.searchValue}
                        onChange={(e) => {
                          e.preventDefault();
                          debounceHandler(
                            setFilters,
                            "searchValue",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </Row>
                  {isLoading ? (
                    <TableSkeleton entries={10} />
                  ) : (
                    <>
                      <TableContainer
                        columns={columns}
                        data={rows}
                        isGlobalFilter={true}
                        isAddUsers={true}
                        isAddTableBorderStrap={true}
                        // handleUserClicks={handleUserClicks}
                        isLoading={isLoading}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        addPagination={true}
                        totalPages={totalPages}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ParticipantsListing;
