import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  Table,
  CardTitle,
  Label,
  Input,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import banner from "assets/images/default-img.svg";
import TableContainer from "components/Common/TableContainer";
import PointIcon from "assets/icons/PointIcon";

import TableSekeloton from "./Common/TableSekeloton";

const UsersList = ({ data = [], title, link = "users",isLoading }) => {
  const rows = data.map((el, ind) => ({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    // ),
    name: (
      <Link
        to={`/user-profile/${el.id}`}
        className="d-flex  align-items-center text-center"
      >
        <img src={el.img || banner} className="avatar-xs rounded-circle me-2" />
        <span className="ms-md-2">{el.name || "--"}</span>
      </Link>
    ),
    //   name: "John Doe",
    email: (<p className="text-truncate mb-0 " style={{ maxWidth: "120px" }}>{el.email||"--"}</p>),
    location: (
      <p className="text-truncate mb-0 " style={{ maxWidth: "120px" }}>
        {el.location || "--"}
      </p>
    ),
    rating: <span className="text-nowrap">{el?.rating}</span>,
    winLose: <span className="ps-3">{el.winLose}</span>,
    userSince: el.userSince,

    // action: (
    //     <div className="d-flex gap-3 users">
    //         <ul className="list-inline font-size-20 contact-links mb-0">
    //             <li className="list-inline-item">
    //                 <Link
    //                     to={`/edit-user/${el.id}`}
    //                     className="text-primary"
    //                     onClick={() => {
    //                         //   const users = cellProps.row.original
    //                         //   handleUserClick(users)
    //                     }}
    //                     id={`edittooltip_${el.id}`}
    //                 >
    //                     <PointIcon name="pen" height='20px' width='20px' className=""  />
    //                     <UncontrolledTooltip placement="bottom" target={`edittooltip_${el.id}`}>
    //                         Edit
    //                     </UncontrolledTooltip>
    //                 </Link>
    //             </li>
    //             <li className="list-inline-item">
    //                 <Link
    //                     to="#"
    //                     className="text-danger"
    //                     onClick={() => {
    //                         //   const users = cellProps.row.original
    //                         //   onClickDelete(users)
    //                     }}
    //                     id={`deletetooltip_${el.id}`}
    //                 >
    //                     <i
    //                         className="uil uil-trash-alt font-size-18"

    //                     />
    //                     <UncontrolledTooltip placement="bottom" target={`deletetooltip_${el.id}`} >
    //                         Delete
    //                     </UncontrolledTooltip>
    //                 </Link>
    //             </li>

    //         </ul>
    //     </div>
    // )
  }));

  // const UserRows = useMemo(() => (tData.map(el => (
  //     {
  //         // checkbox: el.checkbox,
  //         name: el.name,
  //         email: el.email,
  //         location: el.location,
  //         rating: el.rating,
  //         winLose: el.winLose,
  //         userSince: el.userSince,
  //         time: el.time,
  //         court: el.court,
  //         action: el.action,
  //         // id: el._id,
  //     }
  // ))), [tData])

  const UserColumns = useMemo(
    () => [
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      // },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
        hasDescription: true,
      },
      {
        Header: "Location",
        accessor: "location",
        hasDescription: true,
      },
      {
        Header: "Rating",
        accessor: "rating",
        hasDescription: true,
      },
      {
        Header: "Win/Lose",
        accessor: "winLose",
        hasDescription: true,
      },
      {
        Header: "User Since",
        accessor: "userSince",
        hasDescription: true,
      },
      // {
      //     Header: 'Action',
      //     accessor: 'action',

      // },
    ],
    []
  );

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-4">
              <div className="d-flex justify-content-between">
                <span className="text-black fw-bold font-size-16">{title}</span>
                
                  <Link
                    to={title==="Group Members"?link:`/${link}-listing`}
                    className="ms-auto text-blue font-size-12 text-capitalize "
                  >
                    view all
                  </Link>
                
              </div>
            </CardTitle>
            <Row className="py-1  overflow-auto" style={{ height: "300px" }}>
              {isLoading ? (
                <TableSekeloton entries={5} />
              ) : (
                <>
                  <TableContainer
                    columns={UserColumns}
                    // rows={rows}
                    data={rows}
                    isGlobalFilter={true}
                    isAddUsers={true}
                    isAddTableBorderStrap={true}
                    // handleUserClicks={handleUserClicks}
                    addPagination={false}
                    // topBar={topBar}
                  />
                </>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UsersList;
