import React, { Fragment } from "react";
import PaginationButton from "./PaginationButton";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, Input, Spinner } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { Link } from "react-router-dom";

const TableContainer = ({
  addPagination,
  totalPages,
  currentPage,
  isLoading,
  
 
  
  setCurrentPage,

  columns,
  data,
  isAddTableWithoutBorderStrap,
  isAddTableBorderStrap
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      // initialState: {
      //   pageIndex: 0, pageSize: 10, sortBy: [
      //     {
      //       desc: false,
      //     },
      //   ]
      // }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  // const generateSortingIndicator = (column) => {
  //   return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  // };

  // const onChangeInInput = (event) => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };
const number={
  1:{f:1,e:10},
  2:{f:11,e:20},
}

  return (
    <Fragment>
  
    


      {isAddTableBorderStrap && (
        isLoading?(
          <div className="d-flex align-items-center  justify-content-center  w-100 ">
            <Spinner />
          </div>
        ):(
        
        <div className="table-responsive">
        {
          data?.length!==0?(<Table
            className="table-centered datatable dt-responsive nowrap table-card-list react_table"
            {...getTableProps()}
          >
            <thead className="table-nowrap">
              {headerGroups.map((headerGroup,ind) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id}
                    //  {...column.getSortByToggleProps()}
                    className={`${ind===2?"ps-5 ":""} py-1 py-md-2 text-nowrap px-3 `}
                    >
                        {column.render("Header")}
                        {/* {generateSortingIndicator(column)} */}
                     
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
  
          
                <tbody {...getTableBodyProps()}>
                {page?.map((row,ind) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr
                      key={ind}
                      >
                        {row?.cells?.map((cell) => {
                          return (
                            <td 
                            className="py-2 py-md-3 text-nowrap ps-3"
                            key={cell.id} {...cell.getCellProps()} 
                            
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
           
          </Table>):(<div className="text-center w-100  text-muted font-size-14 h-100 d-flex align-items-center justify-content-center"><p className="text-center">No record found</p></div>)
        }
      </div>)
      )}

{addPagination&&<Row>
        <div className="d-flex justify-content-center justify-content-sm-between align-items-center flex-column flex-sm-row ">
          <span className="col-12 col-sm-6 font-size-14 text-muted text-center text-sm-start">
            Showing {page.length > 0 ? (currentPage===1?currentPage:((currentPage-1)*10)+1) : 0} to {currentPage===totalPages?(10*currentPage)-page.length:(currentPage*page.length)} of {totalPages||1} entries
          </span>
          <div className='col-12 col-sm-6' >
            <div className=" d-flex justify-content-center justify-content-sm-end  mt-2 ">
              
                <PaginationButton 
                currentPage={currentPage}
                 setCurrentPage={setCurrentPage} 
                visiblePageButtons={3}
                totalPages={totalPages} />
              
             
            </div>
          </div>
        </div>
      </Row>}

    </Fragment>
  );
};

// Define a default UI for filtering
// function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter}) {

//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <React.Fragment>
//       <Col sm={4}>
//         <div className="search-box me-2 mb-2 d-inline-block">
//           <div className="position-relative">
//             <label htmlFor="search-bar-0" className="search-label">
//               <span id="search-bar-0-label" className="sr-only">
//                 Search this table
//               </span>
//               <input
//                 onChange={(e) => {
//                   setValue(e.target.value);
//                   onChange(e.target.value);
//                 }}
//                 id="search-bar-0"
//                 type="text"
//                 className="form-control"
//                 placeholder={`${count} records...`}
//                 value={value || ""}
//               />
//             </label>
//             <i className="bx bx-search-alt search-icon"></i>
//           </div>
//         </div>
//       </Col>
//     </React.Fragment>
//   );
// }

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
