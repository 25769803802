import api from "api";
import Endpoints from "api/endpoints";
import ByCities from "components/ByCities";
import { toastError } from "components/Common/toastify";
import Groups from "components/Groups";
import PieChart from "components/PieChart";
import TournamentsReport from "components/TournamentsReport";
import Users from "components/Users";
import HtmlHead from "components/html-head/HtmlHead";
import MiniWidget from "components/mini-widget";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import upperCardStyle from "utils/upperCardStyle";


const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
  },
];

const options1 = {
  fill: {
    colors: ["#5CA7DD"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter(seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

const series2 = [70];

const options2 = {
  fill: {
    colors: ["#B3DA06"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const series3 = [55];

const options3 = {
  fill: {
    colors: ["#5CA7DD"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const series4 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
  },
];

const options4 = {
  fill: {
    colors: ["#DCBF32"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter(seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

function GroupsPage() {
  const title = "Groups";
  const description = "Groups Page";

  const [dataState, setDataState] = useState({
    groupStats: {},
    groupPrivacy: {},
    // // tournamentReport:[],
    // tournamentFormat:[],

    // tournamentStatus:[],
    // tournamentFee:[],
    // matchesByCity:[],
    // mostTournamentCreated:[],
    // mostTournamentPlayed:[],
  });

  const reports = [
    {
      id: 1,
      icon: "mdi mdi-menu-down",
      title: "Total Groups",
      value: dataState?.groupStats["Total Groups"],
    },
    {
      id: 2,
      icon: "mdi mdi-menu-down",
      title: "All Group's Members",
      value: dataState?.groupStats["Group Members"],
    },
    {
      id: 3,
      icon: "mdi mdi-menu-up",
      title: "Public Group",
      value: dataState?.groupStats["Public Groups"],
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: "5.24%",
      color: "danger",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      icon: "mdi mdi-menu-up",
      title: "Private Group",
      value: dataState?.groupStats["Private Groups"],
      decimal: 2,
      prefix: "+",
      suffix: "%",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "5.24%",
      color: "success",
      series: series4,
      options: options4,
    },
    {
      id: 5,
      icon: "mdi mdi-menu-up",
      title: "Sanction Groups",
      value: dataState?.groupStats["Sanction Groups"],
      decimal: 2,
      prefix: "+",
      suffix: "%",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "5.24%",
      color: "success",
      series: series4,
      options: options4,
    },
    {
      id: 6,
      icon: "mdi mdi-menu-up",
      title: "Un-sanctioned Groups",
      value: dataState?.groupStats["Unsanction Groups"],
      decimal: 2,
      prefix: "+",
      suffix: "%",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "5.24%",
      color: "success",
      series: series4,
      options: options4,
    },
  ];

  const series = [
    {
      name: "Most Tournament Created",
      data: [31, 40, 28, 51, 42, 109],
    },
    {
      name: "Most Tournament Completed",
      data: [11, 32, 45, 32, 34, 5],
    },
  ];

  const options = {
    colors: ["#F69132", "#AA34FE"],
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "category",
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const userSeries = [
    {
      name: "Desktops",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ];

  const userOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      colors: ["#F69132"],
      width: 2,
    },
    grid: {
      row: {
        // colors: ["transparent"],
        opacity: 1,
        borderColor: "#E9E8EB",
        borderWidth: 1,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
  };

  const privateGroup = dataState?.groupPrivacy?.group?.find((el) =>
    el?.type === "private" ? el?.count : ""
  );
  const publicGroup = dataState?.groupPrivacy?.group?.find((el) =>
    el?.type === "public" ? el?.count : ""
  );

  const pieChartInfo = [
    {
      text: "Private Groups",
      color: "#FCE2C9",
      series: Math.round(
        (privateGroup?.count / (privateGroup?.count + publicGroup?.count)) * 100
      ),
    },
    {
      text: "Public Groups",
      color: "#F69132",
      series: Math.round(
        (publicGroup?.count / (privateGroup?.count + publicGroup?.count)) * 100
      ),
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const controller = new AbortController();

     

      const requests = Object.keys(dataState);

      try {
        let n = 0;

        const apiHitHandler = async (x) => {
          if (n < requests.length) {
            const signal = controller.signal;

            try {
              const res = await api.get(`${Endpoints[requests[x]]}`, {
                signal,
              });

              if (res?.status === 200) {
                const keyToUpdate = Object.keys(dataState)[x];

                // Check if the component is still mounted before updating the state
                if (!controller.signal.aborted) {
                  setDataState((prevDataState) => ({
                    ...prevDataState,
                    [keyToUpdate]: res?.data?.data,
                  }));
                }

                // Recursive call for the next request
                await apiHitHandler((n += 1));
              }
            } catch (err) {
              if (err.name === "AbortError") {
                console.log("Request aborted due to component unmount");
              } else {
                toastError();
                console.log(err);
              }
            }
          }
        };

        // Initial call to apiHitHandler
        await apiHitHandler(n);
      } catch (err) {
        console.error("Error during data fetching:", err);
      } finally {
        controller.abort(); // Abort any ongoing requests when component unmounts
      }
    };

    fetchData();

    // Cleanup function to cancel ongoing requests when the component unmounts
    return () => {
      // Abort any ongoing requests when the component unmounts
      // This is already handled in the `finally` block, so no need to repeat it here
    };
  }, []);
  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <div className="fw-bold font-size-24 mb-3 text-black">{title}</div>
          <Row className="row-cols-xl-6">
            <MiniWidget reports={reports} upperCardStyle={upperCardStyle} />
          </Row>
          <Row>
            <Col xl={12}>
              <TournamentsReport
                options={options}
                // series={series}
                endpoint={"groupTournamentStats"}
                point1="Most Created Tournament"
                point2="Most Completed Tournament"
                title="Most Tournament Created vs Completed"
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-xxl-4">
              <Users
                title="New Groups"
                options={userOptions}
                series={userSeries}
                endpoint={"newGroupChart"}
                link="groups"
              />
            </Col>
            <Col className="col-12 col-xxl-4">
              <ByCities
                cityText="Groups"
                link="groups"
                endpoint={"groupByCity"}
              />
            </Col>
            {/* <Col className="col-12 col-xxl-4">
              <NewMembers th={th} td={td} />
            </Col> */}
            <Col className="col-12 col-xxl-4">
              <PieChart title="Public vs Private" data={pieChartInfo} />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Groups title="Groups" link="/groups" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default GroupsPage;
