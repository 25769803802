import React from "react";
import { challengeStatus, challenges } from "utils/challengeStatus";
import banner from "assets/images/default-img.svg";

import moment from "moment/moment";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

function ChallengeCard({ data }) {
  const clientScreen = window.innerWidth;


  return (
    <Link to={`/challenge-details/${data?._id}`}>
      <Row className="d-flex   justify-content-between matches-wrap bg-white p-3 align-items-center">
        {/* data & time */}

        <Col className="  col-xl-1 d-none d-md-block">
          <div className="d-flex flex-column align-items-center align-items-md-start">
            <div className="fw-bold font-size-14 text-black  text-nowrap ">
              {moment(data?.challengeTime).format("hA")}
            </div>
            <div className="font-size-12 text-muted">
              {moment(data?.challengeDate).format(
                clientScreen < 992 ? "MMM Do YY" : "MMMM DD"
              ) || "--"}
            </div>
          </div>
        </Col>
        {/* Team A */}
        <Col className="col-12 col-sm-3 col-lg-3 col-xl-2 ">
          {data?.teamA?.length > 0 ? (
            <div
              className={`d-flex flex-row justify-content-${
                data?.teamA?.length > 1 ? "between" : "center"
              }  flex-sm-column `}
            >
              {data?.teamA?.map((mem, ind) => {
                return (
                  <div
                    className={`d-flex align-items-center   flex-column flex-md-row ${
                      ind < 1 ? "ms-md-3" : ""
                    } ${ind > 0 ? "d-sm-none d-md-flex" : ""} winner-team`}
                  >
                    {mem?._id === data?.winner?.at(ind) && (
                      <img
                        src="/img/global/batch.svg"
                        className="winner-label"
                        alt="batch"
                      />
                    )}
                    <img
                      src={mem?.additionalInfo?.profileImage || banner}
                      className="header-profile-user rounded-circle"
                      alt=""
                    />
                    <div className="ms-1">
                      <div className="font-size-14 fw-bold text-black  text-center text-md-start">
                        {mem?.firstName}
                        <sup></sup>
                        {/* {data?.teamA[0]?.lastName} */}
                      </div>
                      <span className="text-nowrap">
                        {/* <img src="/img/tournaments/small-cup.svg" alt="trophy" /> */}
                        <i className="uil-trophy text-muted font-size-14" />
                        <span className="ms-1 text-muted font-size-12">
                          #S:{" "}
                          {mem?.additionalInfo?.matchDetails?.single?.rating} D:{" "}
                          {mem.additionalInfo?.matchDetails?.double?.rating}
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="d-flex flex-column  ">
              <Row className="align-items-center  justify-content-md-around ">
                <Col className="col-12 col-md-2">
                  <Skeleton circle={true} className="img-skelton-sm" />
                </Col>
                <Col className="col-md-7 col-lg-10 ps-2">
                  <Skeleton
                    count={1}
                    className="match-skelton-t"
                    height={"10px"}
                  />
                  <Skeleton
                    count={1}
                    className=" match-skelton-b"
                    height={"10px"}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Col>
        {/* points */}
        <Col className="col-12 col-sm-auto col-xl-1">
          <div
            className="font-size-14 fw-bold match-score-container d-flex mx-auto  align-items-center justify-content-center text-black "
            style={{
              backgroundImage: "url(/img/global/score-bg.svg)",
            }}
          >
            {data?.matchScore?.length
              ? `${data?.matchScore[0]?.teamA}:${data?.matchScore[0]?.teamB}`
              : "--"}
          </div>
        </Col>

        {/* Team B*/}
        <Col className="col-12 col-sm-3 col-lg-3 col-xl-2 ">
          {data?.teamB?.length > 0 ? (
            <div
              className={`d-flex flex-row justify-content-${
                data?.teamB?.length > 1 ? "between" : "center"
              }  flex-sm-column `}
            >
              {data?.teamB?.map((mem, ind) => (
                <div
                  className={`d-flex align-items-center flex-column flex-md-row ${
                    ind < 1 ? "ms-md-3" : ""
                  } ${ind > 0 ? "d-sm-none d-md-flex" : ""} winner-team`}
                >
                  {mem?._id === data?.winner?.at(ind) && (
                    <img
                      src="/img/global/batch.svg"
                      className="winner-label"
                      alt="batch"
                    />
                  )}

                  <img
                    src={mem?.additionalInfo?.profileImage || banner}
                    className="header-profile-user rounded-circle"
                    alt=""
                  />
                  <div className="ms-1">
                    <div className="font-size-14 fw-bold text-black  text-center text-md-start">
                      {mem?.firstName}
                      {/* {data?.teamA[0]?.lastName} */}
                    </div>
                    <span className="text-nowrap font-size-12">
                      <i className="uil-trophy text-muted font-size-14" />
                      #S: {
                        mem?.additionalInfo?.matchDetails?.single?.rating
                      } D: {mem?.additionalInfo?.matchDetails?.double?.rating}{" "}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Row className="align-items-center  justify-content-md-around ">
              <Col className="col-12 col-md-2">
                <Skeleton circle={true} className="img-skelton-sm" />
              </Col>
              <Col className="col-md-7 col-lg-10 ps-2">
                <Skeleton
                  count={1}
                  className="match-skelton-t"
                  height={"10px"}
                />
                <Skeleton
                  count={1}
                  className=" match-skelton-b"
                  height={"10px"}
                />
              </Col>
            </Row>
          )}
        </Col>

        {}
        {/* location */}
        <Col className="col-xl-1 d-none d-xl-block ">
          <div className="d-flex flex-column align-items-center align-items-xl-start ps-1">
            <div className="font-size-12 text-muted  mb-1">Location</div>
            <div
              className="font-size-12 text-black  text-lg-center text-xl-start text-truncate  "
              style={{ maxWidth: "100px" }}
            >
              {data?.challengeCourt[0]?.courtId?.location?.description}
            </div>
          </div>
        </Col>

        {/* venue */}
        <Col className="col-xl-2">
          <div className="d-flex flex-column  align-items-center align-items-md-start ">
            <div className="font-size-12 text-muted mb-1 text-center ">
              Court
            </div>
            <div
              className="font-size-12 text-black text-truncate "
              style={{ maxWidth: "100px" }}
            >
              {data?.challengeCourt[0]?.courtId?.name}{" "}
              {data?.challengeCourt[0]?.courtAvailability}:
              {data?.challengeCourt[0]?.totalNumber}
            </div>
          </div>
        </Col>

        {/* status */}
        <Col className="col-xl-1 ">
          <div className="text-end text-md-center  text-lg-start ">
            <span
              className={`${challengeStatus(
                data?.status
              )}  px-2 py-1 rounded-3  font-size-12 text-nowrap text-center text-truncate d-block`}
              style={{
                maxWidth:"140px",
              }}
            >
              {challenges(data?.status)}
            </span>
          </div>
        </Col>
      </Row>
    </Link>
  );
}

export default ChallengeCard;
