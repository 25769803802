
import api from 'api'
import Endpoints from 'api/endpoints'
import queryParamBuilder from 'api/queryParamBuilder'
import TableContainer from 'components/Common/TableContainer'
import TableSkeleton from 'components/Common/TableSekeloton'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import banner from "assets/images/default-img.svg"
import { tournament } from 'utils/tournamentStatus'

const statusOptions = [
    {
      title:"All",
      status:"all",
    },
    {
      title:"Up coming",
      status:"upcoming" ,
    },
    {
      title:"Open",
      status:"applicationOpen" ,
    },
    {
      title:"Start",
      status:"start" ,
    },
    {
      title:"Cancel",
      status:"cancel" ,
    },
     
    {
      title:"Delete",
      status:"deleted" ,
    },
  ]



const TournamentList = ({}) => {
    
    const[data,setData]=useState([])
    const [status,setStatus]=useState(statusOptions[0].status)
    const [isLoading,setIsLoading]=useState(false)

    


    const rows=data?.map(el=>(
        {
            title: (
                <Link to={`/tournament-details/${el?._id}`} className="d-flex flex-row align-items-center text-center">
                    <img src={el.bannerImage||banner} className="avatar-xs rounded-circle me-2" />
                    <span className="ms-md-2 text-nowrap ">{el?.name || "--"}</span>
                </Link>
            ),
            //   name: "John Doe",
            category: el.isSanctioned ? 'Sanctioned' : 'Not Sanctioned',
            type: tournament(el?.format),
            privacy: el.isPrivate ? 'Private' : 'Public',
            location: (<span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.court?.at(0)?.courtId?.name||el?.location?.description || "--"}</span>),
          
           
            //   userSince: `${moment(el?.createdAt).format('MMMM DD, YYYY')||"--"}`,
            date: moment(el.date).format('MMMM DD, YYYY'),
            time: el.time ? el.time : 'No time',
            court:(<span className="ms-3">{ el.court.length||"-"}</span>),
            // id: el._id,

            // action: (
            //     <div className="d-flex flex-nowrap users">
            //         <ul className="list-inline font-size-20 contact-links mb-0">
            //             <li className="list-inline-item">
            //                 <Link
            //                     to={`/tournament-details/${el._id}`}
                                
            //                     className="text-primary"
            //                     onClick={() => {
            //                         //   const users = cellProps.row.original
            //                         //   handleUserClick(users)
            //                     }}
            //                 >
            //                     <i className="uil uil-eye font-size-18" id={`edittooltip_view_${ind}`} />
            //                     <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${ind}`}>
            //                         view
            //                     </UncontrolledTooltip>
            //                 </Link>
            //             </li>
            //             {/* <li className="list-inline-item">
            //                 <Link
            //                      to="#"
            //                      className="text-primary ms-3"
            //                      onClick={() => {
            //                        handleShowModal();
            //                        setTournamentID(el._id);
            //                      }}
               
            //                 >
            //                     <i className="uil uil-pen font-size-18" id={`edittooltip_edit_${ind}`} />
            //                     <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${ind}`}>
            //                         Edit
            //                     </UncontrolledTooltip>
            //                 </Link>
            //             </li> */}
            //             <li className="list-inline-item">
            //                 <Link
            //                     to="#"
            //                     className="text-danger"
            //                     onClick={() => {
                                    
            //                         handleDeleteTournament(el._id);
            //                       }}
                
                
            //                 >
            //                     <i
            //                         className="uil uil-trash-alt font-size-18 text-danger"
            //                         id={`deletetooltip_del_${ind}`}
            //                     />
            //                     <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${ind}`} >
            //                         Delete
            //                     </UncontrolledTooltip>
            //                 </Link>
            //             </li>
                       
            //         </ul>
            //     </div>
            // )
        }
    ))

    const cols= [
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'Category',
            accessor: 'category',
            hasDescription: true,
        },
        {
            Header: 'Type',
            accessor: 'type',
            hasDescription: true,
        },
        {
            Header: 'Privacy',
            accessor: 'privacy',
            hasDescription: true,
        },
        {
            Header: 'Location',
            accessor: 'location',
            hasDescription: true,
        },
        {
            Header: 'Date',
            accessor: 'date',
            hasDescription: true,
        },
        {
            Header: 'Time',
            accessor: 'time',
            hasDescription: true,
        },
        {
            Header: 'No. Courts',
            accessor: 'court',
            hasDescription: true,
        },
        // {
        //     Header: 'Action',
        //     accessor: 'action',
        // },
    ]
useEffect(()=>{
    const controller = new AbortController();
    const fetchData=async()=>{

        setIsLoading(true)
        try{
            const signal = controller.signal;
            const url=queryParamBuilder({
                endpoint:Endpoints.allTournaments,
                status:status
            })
            const res =await api.get(url,{signal})
            if(res?.status===200){
                setData(res.data.data)
                setIsLoading(false)
            }
        }
        catch(err){
            setIsLoading(false)
        }
        // finally{
        //     controller.abort(); // Abort any ongoing requests when component unmounts
        // }


    }
    fetchData()
    return ()=>{
        controller.abort()
    }
},[status])

  return (
    <Card className="section-card overflow-hidden  ">
      <CardBody className='pb-2 '>
        <CardTitle className="h4">
          <div className="d-flex justify-content-between">
            <span className="text-black fw-bold font-size-16 d-flex flex-nowrap ">Tournaments
            <UncontrolledDropdown>
                <DropdownToggle caret className='bg-transparent text-muted pt-0 text-nowrap  text-capitalize border-0 font-size-12 px-2'>
                {status}<i className='bx bx-caret-down ' />
                </DropdownToggle>
                <DropdownMenu>
                    {
                        statusOptions.map(el=>(
                            <DropdownItem key={el} onClick={()=>setStatus(el.status)} className=' text-capitalize '>
                                {el.title}
                                
                            </DropdownItem>
                        ))
                    }
                   
                </DropdownMenu>
            </UncontrolledDropdown>

            </span>

            <Link to={`/tournaments-listing`} className="text-blue font-size-12 fw-normal">View All</Link>
          </div>
        </CardTitle>
        <Row className="py-1  overflow-auto" style={{height:"300px"}}>
        {
          !isLoading?(<TableContainer
            columns={cols}
            // rows={rows}
            data={rows}
            isGlobalFilter={true}
            isAddUsers={true}
            isAddTableBorderStrap={true}
            // handleUserClicks={handleUserClicks}
            addPagination={false}
            // topBar={topBar}
          />):(<TableSkeleton entries={5}/>)
        }
        </Row>
       
      </CardBody>
    </Card>
  )
}

export default TournamentList