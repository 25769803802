import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from 'reactstrap';
import * as Yup from 'yup';
import {ErrorMessage, Formik} from 'formik';
import api from '../../../api';
// import AutoComplete from 'react-google-autocomplete';
import {toastSuccess, toastError} from 'components/Common/toastify';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import Endpoints from 'api/endpoints';
import {ToastContainer} from 'react-toastr';
import Loader from 'components/Common/Loader';
import AutoComplete from 'components/Common/AutoCompleteLocation';
// import DeleteModal from '../../../components/Common/DeleteModal';

function CreateUserForm() {
  // const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [prevDOB, setPrevDOB] = useState('');
  const [pswdShow, setPswdShow] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);

  const handleTogglePswd = e => {
    setPswdShow(prevPswdShow => !prevPswdShow);
  };
  const {id} = useParams();

  const getUserDetails = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`${Endpoints.getUserProfile}/${id}`);
      if (res.status === 200) {
        setUserDetails(res?.data?.data);
        setPrevDOB(res?.data?.data?.additionalInfo?.dateOfBirth);
        // toastSuccess('Loaded User');
        // navigate('/users-listing');
        setIsLoading(false);
      }
    } catch (error) {
      toastError();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getUserDetails();
    }
  }, [id]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Enter a valid Email')
      .required('Please Enter Your Email'),
    ...(!id && {
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long'),
    }),
    ...(!id && {
      confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords do not match'),
    }),
    firstName: Yup.string().required('Please Enter Your First Name'),
    // contactNo: Yup.string().required("Please Enter Your Contact Number"),
    lastName: Yup.string().required('Please Enter Your Last Name'),
    tagLine: Yup.string().required('Please Enter Your Tag line'),
    gender: Yup.string().required('Please Select Your Gender'),
    userRole: Yup.string().required('Please Select Your Role'),
    dateOfBirth: Yup.string().required('Please Select Your Date Of Birth'),
    location: Yup.object().required('Please Select Your Location'),
  });

  const uploadProfile = async (file, setFieldValue) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const res = await api.post(Endpoints.uploadSingleMedia, formData);

      if (res.status === 200) {
        setFieldValue('profileImage', res?.data?.url);
      }
    } catch (error) {
      toastError(error.response?.data?.message);
    }
  };

  const CreateUser = async values => {
    try {
      let res;
      if (id) {
        res = await api.put(`${Endpoints.editUser}${id}`, {
          profileImage: values?.profileImage,
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: values?.email,
          gender: values?.gender,
          userRole: values?.userRole,
          dateOfBirth: values?.dateOfBirth,
          tagLine: values?.tagLine,
          location: values?.location,
          mailingAddress: values?.mailingAddress,
          phone: values?.contactNo,
        });
        if (res.status === 200) {
          const currentUser = localStorage.getItem('user');

          if (currentUser === id && values.userRole !== 'admin') {
            localStorage.clear();
            navigate('/login');
          } else {
            navigate('/users-listing');
          }
        }
      } else {
        res = await api.post(Endpoints.createUser, {
          phone: values?.contactNo,
          profileImage: values?.profileImage,
          firstName: values?.firstName,
          lastName: values?.lastName,
          email: values?.email,
          gender: values?.gender,
          userRole: values?.userRole,
          dateOfBirth: values?.dateOfBirth,
          tagLine: values?.tagLine,
          password: values?.password,
          location: values?.location,
          mailingAddress: values?.mailingAddress,
        });
        if (res.status === 200) {
          toastSuccess('User created');
          navigate('/users-listing');
        }
      }
    } catch (error) {
      toastError();
    }
  };
  //
  const momentDate = moment(prevDOB);
  const formattedDate = momentDate.format('YYYY-MM-DD');
  //
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div class="fw-bold font-size-24 mb-3 text-black">
            {' '}
            {id ? 'Update' : 'Create'} User
          </div>
          {id && isLoading ? (
            <Loader />
          ) : (
            <Row>
              <Col className="col-lg-8 h-75 col">
                <Row className="create-user-form p-3 mb-4">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      contactNo: !id
                        ? ''
                        : userDetails?.additionalInfo?.phoneNumber,
                      profileImage: !id
                        ? ''
                        : userDetails?.additionalInfo?.profileImage,
                      firstName: !id ? '' : userDetails?.firstName,
                      lastName: !id ? '' : userDetails?.lastName,
                      email: !id ? '' : userDetails?.email,
                      gender: !id ? '' : userDetails?.additionalInfo?.gender,
                      userRole: !id ? '' : userDetails?.userRole,
                      dateOfBirth: !id ? '' : formattedDate,
                      tagLine: !id ? '' : userDetails?.additionalInfo?.tagLine,
                      prevPassword: !id ? '' : userDetails?.password,
                      password: '',
                      confirmPassword: '',
                      location: id ? userDetails?.additionalInfo?.location : '',
                      // ? {
                      //     description:
                      //       userDetails?.additionalInfo?.location
                      //         ?.description,
                      //     details:
                      //       userDetails?.additionalInfo?.location?.details,
                      //   }
                      // : "",
                      mailingAddress:
                        userDetails?.additionalInfo?.mailingAddress,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={CreateUser}>
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched,
                      setFieldValue,
                    }) => (
                      <Form className="form-horizontal" onSubmit={handleSubmit}>
                        {}
                        {values?.profileImage ? (
                          <Row>
                            <Col className="col-lg-6">
                              <div className="text-center">
                                <img
                                  src={values?.profileImage}
                                  height="150px"
                                  width="150px"
                                  alt="upload"
                                />
                              </div>
                              <i
                                onClick={() => {
                                  setFieldValue('profileImage', '');
                                }}
                                size={100}
                                height="80px"
                                width="80px"
                                className="uil-expand-arrows"
                                style={{
                                  color: 'red',
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                  height: 80,
                                  width: 80,
                                }}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <div
                            className="fw-bold font-size-16 text-black"
                            id="uploadContainer">
                            Upload User Image
                            <Col className="col-lg-6">
                              <label
                                htmlFor="imageInput"
                                className="upload-img d-flex flex-column justify-content-center align-items-center mt-3 cursor-pointer">
                                <div>
                                  <img src="/img/upload.svg" alt="upload" />
                                </div>
                                <span className="font-size-14 text-muted fw-normal mt-2">
                                  Upload image
                                </span>
                              </label>
                            </Col>
                            <input
                              type="file"
                              id="imageInput"
                              style={{display: 'none'}}
                              accept="image/*"
                              onChange={e => {
                                console.log('image field changed', e);
                                uploadProfile(e.target.files[0], setFieldValue);
                              }}
                            />
                          </div>
                        )}
                        <h4 className="fw-bold font-size-16 text-black mt-3 mb-0">
                          User Details
                        </h4>
                        <Row>
                          <Col className="col-lg-6 mt-2">
                            <div className="d-flex flex-column">
                              <label
                                htmlFor="fname"
                                className="fw-normal font-size-12 text-muted">
                                First name
                              </label>
                              <input
                                value={values?.firstName}
                                type="text"
                                name="firstName"
                                id="fname"
                                className="info "
                                placeholder="John"
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col className="col-lg-6 mt-2">
                            <div className="d-flex flex-column">
                              <label
                                htmlFor="lname"
                                className="fw-normal font-size-12 text-muted">
                                Last name
                              </label>
                              <input
                                value={values?.lastName}
                                type="text"
                                name="lastName"
                                id="lname"
                                className="info "
                                placeholder="Doe"
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col className="col-lg-6 mt-4 mt-md-2 mt-lg-4">
                            <div className="d-flex flex-column">
                              <label
                                htmlFor="lname"
                                className="fw-normal font-size-12 text-muted">
                                Email address
                              </label>
                              <input
                                value={values?.email}
                                type="text"
                                name="email"
                                id="lname"
                                className="info "
                                placeholder="johndoe@gmail.com"
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col className="col-lg-6 mt-4 mt-md-2 mt-lg-4">
                            <div className="d-flex flex-column">
                              <label
                                htmlFor="lname"
                                className="fw-normal font-size-12 text-muted">
                                Contact No.
                              </label>
                              <input
                                value={values?.contactNo}
                                type="text"
                                name="contactNo"
                                id="contactNo"
                                className="info "
                                placeholder="+321223243"
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="contactNo"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col className="col-lg-6 mt-4">
                            <div className="d-flex flex-column ">
                              <label
                                htmlFor="userRole"
                                className="fw-normal font-size-12 text-muted">
                                Role
                              </label>
                              <select
                                defaultValue={values?.userRole}
                                value={values?.userRole}
                                onChange={handleChange}
                                name="userRole"
                                class="form-select py-3 info "
                                aria-label="Default select example">
                                <option disabled>{'Select'}</option>
                                <option
                                  value="player"
                                  selected={
                                    values?.userRole === 'player' && 'selected'
                                  }>
                                  Player
                                </option>
                                <option
                                  value="admin"
                                  selected={
                                    values?.userRole === 'admin' && 'selected'
                                  }>
                                  Super Admin{' '}
                                  <sub> can play as player too!</sub>
                                </option>
                              </select>
                              {/* <input onChange={handleChange} type="text" name="gender" id="gender" list="genders" className="info place-black" placeholder="Male" />
                    <datalist id="genders">
                      <option value="male" />
                      <option value="female" />
                      <option value="other" />
                    </datalist> */}

                              <ErrorMessage
                                name="gender"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col className="col-lg-6 mt-4">
                            <div className="d-flex flex-column ">
                              <label
                                htmlFor="gender"
                                className="fw-normal font-size-12 text-muted">
                                Gender
                              </label>
                              <select
                                defaultValue={values?.gender}
                                value={values?.gender}
                                onChange={handleChange}
                                name="gender"
                                class="form-select py-3 info "
                                aria-label="Default select example">
                                <option disabled>{'Select'}</option>
                                <option
                                  value="male"
                                  selected={
                                    values?.gender === 'male' && 'selected'
                                  }>
                                  Male
                                </option>
                                <option
                                  value="female"
                                  selected={
                                    values?.gender === 'female' && 'selected'
                                  }>
                                  Female
                                </option>
                              </select>
                              {/* <input onChange={handleChange} type="text" name="gender" id="gender" list="genders" className="info place-black" placeholder="Male" />
                    <datalist id="genders">
                      <option value="male" />
                      <option value="female" />
                      <option value="other" />
                    </datalist> */}

                              <ErrorMessage
                                name="gender"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col className="col-lg-6 mt-4">
                            <div className="d-flex flex-column">
                              <label
                                htmlFor="dob"
                                className="fw-normal font-size-12 text-muted">
                                Date of birth
                              </label>
                              <input
                                value={values?.dateOfBirth}
                                type="date"
                                name="dateOfBirth"
                                id="dob"
                                className="info"
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="dateOfBirth"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col className="col-lg-6 col-12 mt-4">
                            <div className="d-flex flex-column">
                              <label
                                htmlFor="tagLine"
                                className="fw-normal font-size-12 text-muted">
                                Tagline
                              </label>
                              <input
                                type="text"
                                name="tagLine"
                                value={values?.tagLine}
                                onChange={handleChange}
                                id="tagline"
                                className="info"
                                placeholder="Write tagline (Max. 60 characters)"
                              />
                              <ErrorMessage
                                name="tagLine"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          {!id && (
                            <>
                              <Col className="col-lg-6 col-12 mt-4">
                                <div className="d-flex flex-column">
                                  <label
                                    htmlFor="passwordOne"
                                    className="fw-normal font-size-12 text-muted">
                                    Password
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      name="password"
                                      id="password"
                                      onChange={handleChange}
                                      className={`info w-100 ${
                                        !pswdShow && 'text-security'
                                      }`}
                                      placeholder="Password"
                                    />
                                    <i
                                      // name="password"
                                      className={`${
                                        pswdShow ? 'uil-eye' : 'uil-eye-slash'
                                      } font-size-20 cursor-pointer text-muted user-form-eye`}
                                      onClick={handleTogglePswd}
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Col>
                              <Col className="col-lg-6 col-12 mt-4">
                                <div className="d-flex flex-column">
                                  <label
                                    htmlFor="cPassword"
                                    className="fw-normal font-size-12 text-muted">
                                    Confirm Password
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      name="confirmPassword"
                                      id="cPassword"
                                      onChange={handleChange}
                                      className={`info  w-100 ${
                                        !pswdShow && 'text-security'
                                      }`}
                                      placeholder="Confirm Password"
                                    />
                                    <i
                                      name="cPassword"
                                      className={`${
                                        pswdShow ? 'uil-eye' : 'uil-eye-slash'
                                      } font-size-20 cursor-pointer text-muted user-form-eye`}
                                      onClick={handleTogglePswd}
                                    />
                                  </div>
                                  <ErrorMessage
                                    name="confirmPassword"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                          <Col className="col-lg-6 col-12 mt-4">
                            <div className="d-flex flex-column">
                              <label
                                htmlFor="location"
                                className="fw-normal font-size-12 text-muted mb-1">
                                Location
                                <span className="text-danger font-size-12">
                                  *
                                </span>
                              </label>
                              {/* <AutoComplete
                      name="location"
                      apiKey={process.env.REACT_APP_GOOGLE_PLACES}
                    
                      className="info"
                      placeholder="Provide your location"
                      defaultValue={values?.location?.description}
                      onPlaceSelected={(place) => {
                        if (id) {
                          setFieldValue('location', {
                            description: place?.formatted_address,
                            details: place,
                          });
                        } else {
                          setFieldValue('location', place);
                        }
                        // setGoogleLocation(place);
                      }}
                    /> */}
                              <AutoComplete
                                setFieldValue={setFieldValue}
                                className="info w-100 "
                                form={true}
                                defaultValue={values.location?.description}
                              />
                              <ErrorMessage
                                name="location"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>
                          <Col className="col-12 col-lg-6 mt-4">
                            <div className="d-flex flex-column">
                              <label
                                htmlFor="mailingAddress"
                                className="fw-normal font-size-12 text-muted">
                                Mailing address (Optional)
                              </label>
                              <input
                                value={values?.mailingAddress}
                                type="text"
                                name="mailingAddress"
                                id="mailingAddress"
                                className="info "
                                placeholder="Mailing address for free prizes"
                                onChange={handleChange}
                              />
                            </div>
                          </Col>
                          <div className="d-flex justify-content-end mt-4">
                            <Button
                              type="submit"
                              className="btn  font-size-14 fw-medium text-white create primary-gradient">
                              {id ? 'Update' : 'Create'} User
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateUserForm;
