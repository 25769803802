import { Helmet } from 'react-helmet';
import React from 'react';

function HtmlHead({ title, description }) {
  return (
    <Helmet>
      {title && <title>{title} | Point</title>}
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
}
export default HtmlHead;
