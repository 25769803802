import Endpoints from "api/endpoints";
import React, { memo, useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { toastError, toastSuccess } from "./Common/toastify";
import api from "api";
import moment from "moment";
import Loader from "./Common/Loader";

const Users = ({
  title,
  series = [],
  point = "",

  link,
  endpoint,
}) => {
  const [filter, setFilter] = useState("week");
  const [count, setCount] = useState([]);
  const [type, setType] = useState([]);

  const getData = useCallback(async () => {
    try {
      let type = [];
      let count = [];
      if (typeof endpoint != "undefined") {
        const res = await api.get(`${Endpoints[endpoint]}?type=${filter}`);
        if (res?.status === 200) {
          res?.data?.data?.forEach((el) => {
            count.push(el?.count);
            type.push(
              moment(el?.date).format(
                `${filter === "week" ? "ddd " : "MMM"}`
              ) || "--"
            );
          });

          setCount(count);
          setType(type);
        } else return;
      } else return;
      // toastSuccess()
    } catch (erer) {
  
    }
  }, [filter]);

  useEffect(() => {
    getData();
  }, [filter, getData]);

  const seriesOptions = [
    {
      name: title,
      data: count,
    },
  ];

  const userOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools: {
          download: false, // <== line to add
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      markers: {
        fillColors: ["#F69132", "#7AB500"],
        width: 10,
        height: 10,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 10,
      },
    },

    stroke: {
      curve: "straight",
      colors: ["#7AB500", "#F69132"],
      width: 2,
    },
    grid: {
      row: {
        opacity: 1,
        borderColor: "#E9E8EB",
        borderWidth: 1,
      },
    },
    xaxis: {
      categories:
        type?.length > 0 ? type : ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    },
    tooltip: {
      theme: false,
      style: {
        fontSize: "12px",
        fontFamily: "poppins",
      },
    },
  };

  return (
    <Card className="section-card card-height">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-2 h4 d-flex">
            <div className="d-flex align-items-center">
              <span className="font-size-16 fw-bold text-black">{title}</span>
              <UncontrolledDropdown
                // toggle={function noRefCheck(){}}
                direction="down"
              >
                <DropdownToggle className="bg-transparent text-muted  text-nowrap   border-0 font-size-12 px-2  text-capitalize">
                  {filter}
                  <i className="bx bx-caret-down " />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="font-size-12 text-capitalize"
                    onClick={() =>
                      setFilter(filter === "week" ? "month" : "week")
                    }
                  >
                    {filter === "week" ? "month" : "week"}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </CardTitle>
          {/* <Link to={link} className="font-size-12 text-blue">
            View All
          </Link> */}
        </div>
        <ReactApexChart
          options={userOptions}
          series={seriesOptions}
          type="line"
          height={248}
        />
        {/* <ul className="list-unstyled text-muted font-size-14 circle-chart-points d-flex justify-content-center flex-wrap">
          <li className="me-3">
            <i className="fas fa-circle cursor-pointer me-2" style={{ color: '#7AB500', fontSize: '10px' }} />
            <span>New downloads</span>
          </li>
          <li className="mx-md-0 verified-wrap">
            <i className="fas fa-circle verified cursor-pointer me-2" style={{ color: '#F69132', fontSize: '10px' }} />
            <span>New User</span>
          </li>
        </ul> */}
      </CardBody>
    </Card>
  );
};

export default memo(Users);
