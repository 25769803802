import { useState,useEffect } from "react";
import HtmlHead from "components/html-head/HtmlHead";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import api from "api";
import Endpoints from "api/endpoints";
import banner from "assets/images/default-img.svg"
import MatchesWraper from "./components/MatchesWraper";
import PlayerSkeleton from "./components/PlayerSkeleton";

const TeamDetail = () => {
  const title = "Team Riders Details";
  const description = "Team Riders Details Page";

  const [team,setTeam]=useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const {id}=useParams()

  const navigate = useNavigate();

 

  const getTeamDetails=async()=>{
    setIsLoading(true)
    try{
      const res=await api.get(`${Endpoints.team}/${id}`)
      setTeam(res?.data?.data)
      setIsLoading(false)
    }catch(e){
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    getTeamDetails()
  },[])

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <div className="fw-bold font-size-24 mb-4 mb-sm-3 text-black">
            {title}
          </div>
          <Row className="mt-4">
            {
             isLoading?(<PlayerSkeleton/>):( team?.users?.map((user,ind)=>{
              return <Col className={`${team?.users?.length>1?"col-12 col-xxl-6":"col-12"}`}>
              <div className="court-fee-wrap d-flex flex-column p-4">
                <div className="text-white d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                  <img src={user?.additionalInfo?.profileImage||banner} alt="player" className={`  img-skelton-sm rounded-circle `}/>
                    <div className="ms-2 flex-column d-flex">
                    <span className="font-size-14 fw-sb">{user?.firstName} {user?.lastName}</span>
                        <span className="font-size-12">{user?.additionalInfo?.tagLine||"N/A"}</span>
                    </div>
                  </div>
                  {
                    team?.users?.length>1&&<div className="text-white font-size-18 fw-sb">Player {ind+1}</div>
                  }
                </div>
                <div className="text-white d-flex align-content-center  justify-content-between justify-content-md-around  flex-wrap row-cols-2 row-cols-sm-4 row-cols-md-5 row-cols-ms-4 mt-3 ">
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12">Location</span>
                      <span className="font-size-16">{user?.additionalInfo?.location?.description}</span>
                    </div>
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12">Local Ranking</span>
                      <span className="font-size-16">{user?.additionalInfo?.ranking?.local||0}</span>
                    </div>
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12">Matches Played</span>
                      <span className="font-size-16">S:{(user?.additionalInfo?.matchDetails?.single?.win||0)+(user?.additionalInfo?.matchDetails?.single?.lose||0)}/D:{(user?.additionalInfo?.matchDetails?.double?.win||0)+(user?.additionalInfo?.matchDetails?.double?.lose||0)}</span>
                    </div>
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12">Wins</span>
                      <span className="font-size-16">S:{(user?.additionalInfo?.matchDetails?.single?.win||0)}/D:{(user?.additionalInfo?.matchDetails?.single?.win||0)}</span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="font-size-12">Losses</span>
                      <span className="font-size-16">S:{(user?.additionalInfo?.matchDetails?.single?.lose||0)}/D:{(user?.additionalInfo?.matchDetails?.single?.lose||0)}</span>
                    </div>
                  </div>
              </div>
            </Col>
            }))
            }
            
            <Col
              className="col-12 mt-3 mt-xxl-4 mb-3"
             
            >
              <div className="court-fee-wrap d-flex flex-column p-4 bg-white">
                <div>
                  <div className="font-size-16 fw-sb text-dark">
                    Tournament Details
                  </div>
                  <div className="text-white d-flex justify-content-between mt-3 flex-column flex-md-row">
                    {/* <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12 text-muted">Location</span>
                      <span className="font-size-16 text-dark">
                        Madison Ave, NY
                      </span>
                    </div> */}
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12 text-muted">
                        Matches Played
                      </span>
                      <span className="font-size-16 text-dark">{(team?.wins+team?.loses)||0}</span>
                    </div>
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12 text-muted">
                        Matches Won
                      </span>
                      <span className="font-size-16 text-dark">{team?.wins||0}</span>
                    </div>
                    <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12 text-muted">
                        Matches Lost
                      </span>
                      <span className="font-size-16 text-dark">{team?.loses||0}</span>
                    </div>
                    {/* <div className="d-flex flex-column mb-3 mb-md-0">
                      <span className="font-size-12 text-muted">
                        Matches Draw
                      </span>
                      <span className="font-size-16 text-dark">15</span>
                    </div> */}
                    <div className="d-flex flex-column">
                      <span className="font-size-12 text-muted">Points</span>
                      <span className="font-size-16 text-dark">{team?.pointDiff}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
           <MatchesWraper id={id}/>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TeamDetail;
