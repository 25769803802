import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import DatePicker from './Common/DatePicker';
import { toastError } from './Common/toastify';
import api from 'api';
import Endpoints from 'api/endpoints';
import moment from 'moment/moment';
import Loader from './Common/Loader';

function Challenges() {

  const [isLoading,setIsLoading]=useState(false)
  const [filter,setFilter]=useState("week")
  const [challengeCount,setChallengeCount]=useState({})

useEffect(()=>{
  const controller=new AbortController();
    const signal=controller.signal;
  const getChallengeCount=async()=>{
    let requested={
      type:[],
      data:[],
    }
    let accepted={
      type:[],
      data:[],
    }
    let completed={
      type:[],
      data:[],
    }
    try{
      setIsLoading(true);
      const res=await api.get(`${Endpoints.challengesStatus}?type=${filter}`,{signal})
      res?.data?.data?.Requested?.forEach((el) => {
        requested?.data.push(el?.count);
    
        requested?.type.push(
          moment(el?.date).format(
            `${filter === "week" ? "ddd" : "MMM"}`
          ) 
        );
      });
    res?.data?.data?.Closed?.forEach((el) => {
        completed?.data.push(el?.count);
        completed?.type.push(
          moment(el?.date).format(
            `${filter === "week" ? "ddd" : "MMM"}`
          ) 
        );
      });
    res?.data?.data?.Accepted?.forEach((el) => {
        accepted?.data.push(el?.count);
        accepted?.type.push(
          moment(el?.date).format(
            `${filter === "week" ? "ddd" : "MMM"}`
          ) 
        );
      });
   
      setChallengeCount({
        type:[...new Set([...requested?.type,...requested?.type,...completed?.type])],
        data:{
          completed:completed.data,
          requested:requested.data,
          accepted:accepted.data
        }
      })
      setIsLoading(false)
    }catch(e){
 
      setIsLoading(false)
    }
  }
  getChallengeCount()
  return ()=>controller.abort()
},[filter])



  const series = [
    {
      name: 'Requested',
      type: 'column',
      data: challengeCount?.data?.requested?.length>0?challengeCount?.data?.requested:[1],
    },
    {
      name: 'Accepted',
      type: 'area',
      data: challengeCount?.data?.accepted?.length>0?challengeCount?.data?.accepted:[1],
    },
    {
      name: 'Completed',
      type: 'line',
      data: challengeCount?.data?.completed?.length>0?challengeCount?.data?.completed:[1],
    },
  ];



  const options = {
    colors:["#F69132","#378D78","#E9E8EB"],
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        show: true,
        tools:{
          download:false // <== line to add
        }
      }
    },
    stroke: {
      width: [0, 2, 2],
      curve: 'smooth',
      
    },
    legend: {
        show:true,
        horizontalAlign: 'left',
        position: 'top',
        markers: {
          
            radius: 12,
            // fillColors: ['#F69132','#83A201'],
            
            height:10,
            width:10,
            
        },
        onItemClick: {
          toggleDataSeries: false
        },
        onItemHover: {
          highlightDataSeries: false
        }
      },
    plotOptions: {
      bar: {
        columnWidth: '20%',
        borderRadius: 4,
        
      },
    },
    dataLabels: {
        enabled: true, // Set this to false to hide the numbers on the bars
        style:{
            colors:["#F69132","#378D78","#E9E8EB"],
        }
      },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: challengeCount?.type?.length>0?challengeCount?.type:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },
    yaxis: {
      min: 0,
      labels: {
        formatter(value) {
          return value?.toFixed();
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter(y) {
          if (typeof y !== 'undefined') {
       
            return `${y?.toFixed(0)} challenges`;
          }
          return y;
        },
      },
    },
  };

  return (
    <Card className="section-card card-height">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-2 h4 d-flex">
            <div className="d-flex align-items-center">
              <span className="font-size-16 fw-bold text-black">Challenges</span>
              <UncontrolledButtonDropdown
                    // toggle={function noRefCheck(){}}
                    direction="down"
                  >
                    <DropdownToggle className="bg-transparent text-muted  text-nowrap   border-0 font-size-12 px-2  text-capitalize pt-1 ">
                      {filter}
                      <i className="bx bx-caret-down " />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        className="font-size-12 text-capitalize "
                        onClick={() =>
                          setFilter(filter === "week" ? "month" : "week")
                        }
                      >
                        {filter === "week" ? "month" : "week"}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
            </div>
          </CardTitle>
          {/* <DatePicker /> */}
        </div>

        {
          isLoading?(<Loader/>):(<ReactApexChart options={options} series={series} type="bar" height={255} />)
        }
      </CardBody>
    </Card>
  );
}

export default Challenges;
