import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import point from "../../assets/images/point-white.png";
import VerificationInput from "react-verification-input";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../api";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import Endpoints from "api/endpoints";
import { toastSuccess } from "components/Common/toastify";
import { toastError } from "components/Common/toastify";

const ResetPassword = (props) => {
  document.title =
    " Recover Password | Minible - Responsive Bootstrap 5 Admin Dashboard";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      resetToken: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Password"),
      confirmPassword: Yup.string()
        .required("Please Enter Password")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      // setSubmitting(true);
      const body = { ...values, resetToken: values.resetToken.toUpperCase() };
      try {
        const resetPassword = await api.put(Endpoints.passwordReset, body);
        if (resetPassword?.error?.response?.data?.error) {
          toastError();
          // showToast(toast, resetPassword?.error.response.data.error, "error");
        }
        if (resetPassword?.data?.success) {
          toastSuccess("Recovered");
          navigate("/login");
        }
      } catch (error) {
        // showToast(toast, error?.response?.data?.error, 'error');
        toastError(error?.response?.data?.error);
      } finally {
        // setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const selectForgotPasswordState = (state) => state.ForgetPassword;
  const ForgotPasswordProperties = createSelector(
    selectForgotPasswordState,
    (forgetPassword) => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    })
  );

  const { forgetError, forgetSuccessMsg } = useSelector(
    ForgotPasswordProperties
  );

  return (
    <React.Fragment>
      <div className="">
        <div className="account-pages ">
          <Container fluid className="bg-image">
                <div className="d-flex flex-column justify-content-center h-100 align-items-center">
                <div className="text-center mb-3">
                <Link to="/" className="d-block  mb-3 ">
                  <img
                    src={point}
                    alt=""
                    height="70"
                    className="logo logo-dark"
                  />
                  <img
                    src={point}
                    alt=""
                    height="70"
                    className="logo logo-light"
                  />
                </Link>
              </div>
            
     
                <Card className="col-12 col-sm-9 col-md-9 col-lg-8 col-xl-6 col-xxl-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Verification</h5>
                    </div>

                    <div className="p-2 mt-4">
                      <div
                        className="alert alert-success text-center mb-4"
                        role="alert"
                      >
                        Enter the 6 digit code sent to your email
                      </div>
                      {forgetError && forgetError ? (
                        <Alert
                          color="danger"
                          className="text-center mb-4"
                          style={{ marginTop: "13px" }}
                        >
                          {forgetError}
                        </Alert>
                      ) : null}
                      {forgetSuccessMsg ? (
                        <Alert
                          color="success"
                          className="text-center mb-4"
                          style={{ marginTop: "13px" }}
                        >
                          {forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <VerificationInput
                            classNames={{
                              // container: "container",
                              character: "uppercase-text",
                              // characterInactive: "character--inactive",
                              // characterSelected: "character--selected",
                            }}
                            onComplete={(val) => {
                              validation.setFieldValue("resetToken", val);
                            }}
                          />
                        </div>
                        <div className="mb-3 mt-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            className="form-control"
                            placeholder="Enter password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 mt-2">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Enter confirm password"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirmPassword}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-0">
                          <Col xs={12} className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Next
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-2  text-center">
                  <p className="cl-white">
                  © {new Date().getFullYear()} Point. Design & Develop by Pixarsart Studios
                  </p>
                </div>
                </div>
               
             
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

ResetPassword.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
};

export default withRouter(ResetPassword);
