import React,{useState,useEffect, useMemo} from 'react';

import Endpoints from 'api/endpoints';
import ByCities from 'components/ByCities';
import TournamentsReport from 'components/TournamentsReport';
import Users from 'components/Users';
import HtmlHead from 'components/html-head/HtmlHead';
import MiniWidget from 'components/mini-widget';

import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import upperCardStyle from 'utils/upperCardStyle';
import MostUsedCourts from './components/MostUsedCourts';
import Courts from './components/Courts';
import api from 'api';
import { toastError } from 'components/Common/toastify';







const courtSeries = [
    {
        name: 'New Courts',
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
];




const CourtDashboard = () => {
    const title = 'Courts';
    const description = 'Courts Page';

    const [isLoading,setIsLoading]=useState({
        courtsByMostChallenges:false,
        courtsByMostTournaments:false,
        courtStats:false
    })
    const [dataState,setDataState]=useState({
        courtsByMostChallenges:[],
        courtsByMostTournaments:[],
        courtStats:{}
    })



    const reports = [
        {
            id: 1,
            icon: 'mdi mdi-menu-down',
            title: 'Total Courts',
            value: dataState?.courtStats?.totalCourts,
            // prefix: '$',
            // suffix: '',
            // badgeValue: '5.24%',
            // decimal: 0,
            // charttype: 'bar',
            // chartheight: 40,
            // chartwidth: 70,
            // color: 'success',
            // series: series1,
            // options: options1,
        },
        {
            id: 2,
            icon: 'mdi mdi-menu-down',
            title: 'Paid Courts',
            value: dataState?.courtStats?.paidCourt,
            // decimal: 0,
            // charttype: 'radialBar',
            // chartheight: 45,
            // chartwidth: 45,
            // prefix: '',
            // suffix: '',
            // badgeValue: '5.24%',
            // color: 'danger',
            // series: series2,
            // options: options2,
        },
        {
            id: 3,
            icon: 'mdi mdi-menu-up',
            title: 'Free Courts',
            value: dataState?.courtStats?.freeCourt,
            // decimal: 0,
            // prefix: '',
            // suffix: '',
            // charttype: 'radialBar',
            // chartheight: 45,
            // chartwidth: 45,
            // badgeValue: '5.24%',
            // color: 'danger',
            // series: series3,
            // options: options3,
        },
        {
            id: 4,
            icon: 'mdi mdi-menu-up',
            title: 'Membership Courts',
            value: dataState?.courtStats?.membershipCourt,
            // decimal: 2,
            // prefix: '+',
            // suffix: '%',
            // charttype: 'bar',
            // chartheight: 40,
            // chartwidth: 70,
            // badgeValue: '5.24%',
            // color: 'success',
            // series: series4,
            // options: options4,
        },
        {
            id: 5,
            icon: 'mdi mdi-menu-up',
            title: 'Non-Membership Courts',
            value: dataState?.courtStats?.nonmembershipCourt,
            // decimal: 2,
            // prefix: '+',
            // suffix: '%',
            // charttype: 'bar',
            // chartheight: 40,
            // chartwidth: 70,
            // badgeValue: '5.24%',
            // color: 'success',
            // series: series4,
            // options: options4,
        },
        {
            id: 6,
            icon: 'mdi mdi-menu-up',
            title: 'Out Door Courts',
            value: dataState?.courtStats?.outDoorPlayCourt,
            // decimal: 2,
            // prefix: '+',
            // suffix: '%',
            // charttype: 'bar',
            // chartheight: 40,
            // chartwidth: 70,
            // badgeValue: '5.24%',
            // color: 'success',
            // series: series4,
            // options: options4,
        },
        {
            id: 6,
            icon: 'mdi mdi-menu-up',
            title: 'In Door Courts',
            value: dataState?.courtStats?.inDoorPlayCourt,
            // decimal: 2,
            // prefix: '+',
            // suffix: '%',
            // charttype: 'bar',
            // chartheight: 40,
            // chartwidth: 70,
            // badgeValue: '5.24%',
            // color: 'success',
            // series: series4,
            // options: options4,
        },
    ];


    useEffect(() => {
        const fetchData = async () => {

          setIsLoading({
            courtsByMostChallenges:true,
            courtsByMostTournaments:true,
            courtStats:true
        })

          const controller = new AbortController();
    
         
        
          const requests= Object.keys(dataState)
    
          try {
            let n = 0;
    
            const apiHitHandler = async (x) => {
              if (n < requests.length) {
                const signal = controller.signal;
              
                try {
                  const res = await api.get(`${Endpoints[requests[x]]}`, { signal });
    
                  if (res?.status === 200) {
                    const keyToUpdate = Object.keys(dataState)[x];
    
                    // Check if the component is still mounted before updating the state
                    if (!controller.signal.aborted) {
                      setDataState((prevDataState) => ({
                        ...prevDataState,
                        [keyToUpdate]: res?.data?.data,
                      }));
                      setIsLoading((prevDataState) => ({
                        ...prevDataState,
                        [keyToUpdate]: false,
                      }));
                    }
    
                    // Recursive call for the next request
                    await apiHitHandler((n += 1));
                  }
                } catch (err) {
                  if (err.name === 'AbortError') {
                    console.log('Request aborted due to component unmount');
                  } 
                }
              }
            };
    
            // Initial call to apiHitHandler
            await apiHitHandler(n);
          } catch (err) {
          
            setIsLoading({
              courtsByMostChallenges:false,
              courtsByMostTournaments:false,
              courtStats:false
          })
          } finally {
            controller.abort(); // Abort any ongoing requests when component unmounts
            setIsLoading({
              courtsByMostChallenges:false,
              courtsByMostTournaments:false,
              courtStats:false
          })
          }
        };
    
        fetchData();
    
        // Cleanup function to cancel ongoing requests when the component unmounts
        return () => {
          // Abort any ongoing requests when the component unmounts
          // This is already handled in the `finally` block, so no need to repeat it here
        };
      }, []);

      const statsReport = useMemo(() => {
        return Object.keys(dataState?.courtStats).map((key) => {
          return {
            title: key,
            value: dataState?.courtStats[key],
          };
        });
      }, [dataState?.courtStats]);


    return (
        <>
            <HtmlHead title={title} description={description} />
            <div className='page-content'>
                <Container fluid>
                    <div className='d-flex align-items-center justify-content-between '>
                        <p className="fw-bold font-size-24 mb-3 text-black">{title}</p>
                        <Link to="/add-court" className='primary-gradient text-white text-capitalize font-size-14 px-3 py-2'>Add Court</Link>
                    </div>
                    {/* top min cards */}
                    <Row className="row-cols-xl-4">
                        <MiniWidget reports={statsReport.length===0?[[],[],[],[],[]]:statsReport} upperCardStyle={upperCardStyle} />
                    </Row>
                    {/* available courts and new courts */}
                    <Row>
                        {/* <Col xl={6} xxl={8}>
                            <TournamentsReport
                                //   options={options}
                                series={series} title="Available Courts vs Booked Courts" />
                        </Col> */}
                        <Col lg={6}>
                        <ByCities cityText="Courts" link="/courts-listing" endpoint={"courtsByCity"}/>
                        </Col>
                        <Col lg={6}>
                            <Users title="New Courts"
                                //   options={userOptions}
                                point={"New court"}
                                endpoint={"newCourtChart"}
                                series={courtSeries} link="/courts-listing" />
                        </Col>
                    </Row>
                    {/* courts by citites and most courts */}
                    <Row>
                        
                        <Col className='col-12 col-xl-6'>
                            <MostUsedCourts title="Courts With Most Challenges" data={dataState?.courtsByMostChallenges} />
                        </Col>
                        <Col className='col-12 col-xl-6'>
                            <MostUsedCourts title="Courts With Most Tournament" data={dataState?.courtsByMostTournaments} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Courts/>
                        </Col>
                    </Row>
                </Container>

            </div>

        </>
    )
}

export default CourtDashboard