import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import point from "../../assets/images/point-white.png";
import VerificationInput from "react-verification-input";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../api";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import Endpoints from "api/endpoints";
import { toastSuccess } from "components/Common/toastify";
import { toastError } from "components/Common/toastify";

const UpdatePassword = (props) => {
  document.title =
    " Recover Password | Minible - Responsive Bootstrap 5 Admin Dashboard";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Please Enter Password"),
      confirmPassword: Yup.string()
        .required("Please Enter Password")
        .oneOf([Yup.ref("newPassword")], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      // setSubmitting(true);

      try {
        const UpdatePassword = await api.put(Endpoints.updatePassword,
          {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
          }
          );
        if (UpdatePassword?.error?.response?.data?.error) {
          toastError();
          // showToast(toast, UpdatePassword?.error.response.data.error, "error");
        }
        if (UpdatePassword?.data?.success) {
          toastSuccess("Recovered");
          navigate("/");
        }
      } catch (error) {
        // showToast(toast, error?.response?.data?.error, 'error');
        toastError(error?.response?.data?.error);
      } finally {
        // setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const selectForgotPasswordState = (state) => state.ForgetPassword;
  const ForgotPasswordProperties = createSelector(
    selectForgotPasswordState,
    (forgetPassword) => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    })
  );

  const { forgetError, forgetSuccessMsg } = useSelector(
    ForgotPasswordProperties
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="fw-bold font-size-24 mb-3 text-black">
          <h5>Update Password</h5>
        </div>
        <Container fluid>
          <Row className="align-items-center">
            <Card className="col-12 col-sm-9 col-md-9 col-lg-8">
              <CardBody className="p-4">
                <div className="p-2 mt-2">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3 mt-3">
                      <Label className="fw-normal font-size-12 text-muted">
                        Old Password
                      </Label>
                      <Input
                        name="oldPassword"
                        className="info"
                        placeholder="Enter password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.oldPassword || ""}
                        invalid={
                          validation.touched.oldPassword &&
                          validation.errors.oldPassword
                            ? true
                            : false
                        }
                      />
                      {validation.touched.oldPassword &&
                      validation.errors.oldPassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.oldPassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 mt-3">
                      <Label className="fw-normal font-size-12 text-muted">
                        New Password
                      </Label>
                      <Input
                        name="newPassword"
                        className="form-control info"
                        placeholder="Enter password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.newPassword || ""}
                        invalid={
                          validation.touched.newPassword &&
                          validation.errors.newPassword
                            ? true
                            : false
                        }
                      />
                      {validation.touched.newPassword &&
                      validation.errors.newPassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.newPassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 mt-2">
                      <Label className="fw-normal font-size-12 text-muted">
                        Confirm Password
                      </Label>
                      <Input
                        name="confirmPassword"
                        className=" info"
                        placeholder="Enter confirm password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirmPassword || ""}
                        invalid={
                          validation.touched.confirmPassword &&
                          validation.errors.confirmPassword
                            ? true
                            : false
                        }
                      />
                      {validation.touched.confirmPassword &&
                      validation.errors.confirmPassword ? (
                        <FormFeedback type="invalid">
                          {validation.errors.confirmPassword}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <Row className="mb-0 mt-2">
                      <Col xs={12} className="text-end">
                        <button
                          className="btn  font-size-14 fw-medium text-white create primary-gradient"
                          type="submit"
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

UpdatePassword.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
};

export default withRouter(UpdatePassword);
