export const challengeStatus = (arg) =>

  ({
    verifiedScore: 'light-grey grey',
    requested:'light-green green',
    accepted: 'light-green green',
    Active: 'light-green green',
    rejected: 'light-red red',
    verifiedScoreRejected: 'light-red red',
    closed: 'light-red red',
  })[arg] || 'light-grey grey';
export const challenges = (arg) =>
  ({
    verifiedScore: 'Verified Score',
    accepted: 'Accepted',
    Active: 'Active',
    rejected: 'Rejected',
    verifiedScoreRejected: 'Verified Score Rejected',
    closed: 'Closed',
    requested: 'Requested',
    played:'Played'
  })[arg];
