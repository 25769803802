import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import api from 'api';
import Endpoints from 'api/endpoints';
import DetailsTopBar from 'components/Common/DetailTop';
import PointIcon from 'assets/icons/PointIcon';
import DatePicker from 'components/Common/DatePicker';
import Matches from 'components/Matches';
import TournamentsReport from 'components/TournamentsReport';
import Users from 'components/Users';
import HtmlHead from 'components/html-head/HtmlHead';
import MatchesWraper from 'pages/Tournaments/components/MatchesWraper';
import ChallengesWraper from 'pages/Challenges/components/ChallengesWraper';
import ChallengesReport from 'components/ChallengesReport';
import ChallengesGraph from 'components/ChallengesGraph';

function CourtDetails() {
  const [courtInfo, setCourtInfo] = useState({});
  const { id } = useParams();

  const title = 'Court Details';
  const description = 'Court Details Page';

  const getCourtByID = useCallback(async () => {
    try {
      const res = await api.get(`${Endpoints.court}/${id}`);
      setCourtInfo(res.data.data);

    } catch (err) {
      console.log(err);
    }
  }, [id]);

  useEffect(() => {
    getCourtByID();
  }, [getCourtByID]);

  const series = [
    {
      name: 'Challenges Created',
      data: [31, 40, 28, 51, 42, 109],
    },
    {
      name: 'Challenges Completed',
      data: [11, 32, 45, 32, 34, 5],
    },
  ];


  const userSeries = [
    {
      name: 'Desktops',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ];

  const userOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      colors: ['#F69132'],
      width: 2,
    },
    grid: {
      row: {
        // colors: ["transparent"],
        opacity: 1,
        borderColor: '#E9E8EB',
        borderWidth: 1,
      },
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
      ],
    },
  };

  const topBar = [
    {
      title: 'Location Filter',
      options: ['Sans Francisco', 'New York'],
    },
    {
      title: 'Status Filter',
      options: ['Win', 'Lose'],
    },
  ];

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <DetailsTopBar title={title} />
          <Row>
            <Col className="col-12 col-xl-8 ">
              
                <Row className="p-3 font-size-14 d-flex text-white d-flex flex-column flex-md-row  align-items-md-center primary-gradient court-info court-bio mb-2 me-xl-1">
                <h4 className="text-white font-size-16 fw-bold">
                        {courtInfo?.name||"N/A"}
                      </h4>
                  <div className="col-12 ">
                    <div className=" d-flex flex-wrap justify-conten-between row-cols-md-3 row-cols-lg-4 row-cols-xl-4  ">
                     
                      <div className="">
                        <PointIcon name="location" height="20px" width="20px" />
                        &nbsp;&nbsp;{courtInfo?.location?.description}
                      </div>
                      <div>
                      <span className="me-3 ms-1">
                        <PointIcon name="calender" height="20px" width="20px" />
                        &nbsp;&nbsp;
                        {moment(courtInfo?.createdAt).format('MMMM DD, YYYY')}
                      </span>
                      </div>
                     
                      
                    <div className="d-flex  ">
                     <span> Matches played in court:</span>
                      <span className="">{courtInfo?.matchCount} </span>
                    </div>
                    <div className="">
                      Cancelled matches:
                      <span className="ms-2">20</span>
                    </div>
                    <div className="d-flex  ">
                     <span> Membership:</span>
                      <span className="ms-2">
                        {courtInfo?.memberShip ? 'Yes' : 'No'}
                      </span>
                    </div>
                    <div className="">
                      Environment:
                      <span className="ms-2 text-capitalize">
                        {courtInfo?.playLocation}
                      </span>
                    </div>
                    <div className=" ms-xl-2">
                      Cost:
                      <span className="ms-2 text-capitalize">
                        {courtInfo?.price}
                      </span>
                    </div>
                    </div>
                  </div>
                  
                  
                  {/* <Col className="col-md-4 mt-3 mt-md-0">
                    <div className="mb-2">Added by:</div>
                    <div className="mb-2">
                    <i className='fa-light fa-user text-white'/>
                      <span className="ms-2">John Mark</span>
                    </div>
                  </Col> */}
                </Row>
    
            </Col>
            <Col className='col-12 col-xl-4 '>
              <div className="bg-white p-3 court-bio mb-2">
                <h4 className="fw-bold font-size-16 text-black">Contact Information</h4>
                <div>

                  <i className='fa-light fa-user gray' />
                  <span className="ms-2">{courtInfo?.ownerName||"N/A"}</span>

                </div>
                <div>
                  <PointIcon name="phone" height="20" width="20"/>
                  {/* <i className='fa-light fa-user gray' /> */}
                  <span className="ms-1">{courtInfo?.ownerNumber}</span>

                </div>

              </div>
            </Col>
            <Row className="pe-0">
              <Col xxl={4}>
               <ChallengesGraph id={`courtId=${id}`} />
              </Col>
              <Col xxl={8}>
                <ChallengesReport
              
                  id={`courtId=${id}`}
                />
              </Col>
            </Row>
           <Row className='mb-3'>
            <div className='d-flex align-items-center justify-content-between '>
              <span className="font-size-16 fw-bold text-black d-flex ">Matches</span>
              <Link to={`/court/matches/${id}`} className='text-blue font-size-12'> View All</Link>
            </div>
           <MatchesWraper id={id} link={`/court/matches/${id}`} /> 
            </Row>   
      
          <ChallengesWraper id={id} link={`/court/challenges/${id}`} /> 
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CourtDetails;
