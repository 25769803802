import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"
import profile from "assets/images/default-img.svg"
import PointIcon from "assets/icons/PointIcon"

const topPlayersData=(data,deleteUserHandler)=>{
    const topPlayersRows =data?.map((el)=>({
      // checkbox: (
      //     <input type="checkbox" className="form-check-input" id="customCheck1" />
      //   ),
        name: (
          <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-2 text-capitalize  ">{el?.firstName||"-"}      {el?.lastName||"-"}</span>
          </Link>
        ),
        email: `${el?.email || "--"}`,
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.additionalInfo?.location?.description || "--"}</span>,
        winLose: `${el?.additionalInfo?.matchDetails?.single?.win+el?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.additionalInfo?.matchDetails?.single?.loss+ el?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
        rank:(<span>S:{el?.additionalInfo?.matchDetails?.single?.rating}/D:{el?.additionalInfo?.matchDetails?.double?.rating}</span>),
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      deleteUserHandler(el?._id)
                    }}
                    id={`deletetooltip_del_${el?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
  }))
  
  const topPlayersCols=[
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      //   },
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
            Header:"Email",
            accessor:"email",
            hasDescription:true
        },
        {
            Header:"Location",
            accessor:"location",
            hasDescription:true
        },
        {
          Header:'Rank',
          accessor:'rank'
        },
        {
            Header:"Win/Lose",
            accessor:"winLose",
            hasDescription:true
        },
        {
        Header: 'Action',
        accessor: 'action',
        }
  ]
  return {
    cols:topPlayersCols,
    rows:topPlayersRows
  }
  }


// most matches played 
const mostMatchesPlayedData=(data,deleteUserHandler)=>{
    const mostPlayedMatchesRows =data?.map((el)=>({
      // checkbox: (
      //     <input type="checkbox" className="form-check-input" id="customCheck1" />
      //   ),
        name: (
          <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-1 text-capitalize ">{el?.firstName||"-"} {el?.lastName||"="}</span>
          </Link>
        ),
        matchPlayed:<span className='ms-4 text-center'>{el?.totalMatch||"-"}</span>,
        email: `${el?.email || "--"}`,
        // winLos:,
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.additionalInfo?.location?.description || "--"}</span>,
        winLose: `${el?.additionalInfo?.matchDetails?.single?.win+el?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.additionalInfo?.matchDetails?.single?.loss+ el?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
               
                      deleteUserHandler(el?._id)
                    }}
                    id={`deletetooltip_del_${el?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
  }))
  const mostPlayedMatchesCols=[
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      //   },
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
            Header:"Email",
            accessor:"email",
            hasDescription:true
        },
        {
            Header:"Location",
            accessor:"location",
            hasDescription:true
        },
        {
          Header:'Match Played',
          accessor:'matchPlayed'
        },
        {
            Header:"Action",
            accessor:"action",
        },
  ]
  
    return {
      rows:mostPlayedMatchesRows,
      cols:mostPlayedMatchesCols
    }
  }  
// most friends
const mostFriendsData=(data,deleteUserHandler)=>{
    const mostFriendsRow=data?.map((el)=>({
      // checkbox: (
      //     <input type="checkbox" className="form-check-input" id="customCheck1" />
      //   ),
        name: (
          <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-2 text-capitalize  ">{el?.userInfo?.firstName||"-"} {el?.userInfo?.lastName||"-"}</span>
          </Link>
        ),
        friends:el?.count||0,
        email: `${el?.userInfo?.email || "--"}`,
        // winLos:,
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.userInfo?.additionalInfo?.location?.description || "--"}</span>,
        winLose: `${el?.userInfo?.additionalInfo?.matchDetails?.single?.win+el?.userInfo?.additionalInfo?.matchDetails?.double?.win}/${el?.userInfo?.additionalInfo?.matchDetails?.single?.loss+ el?.userInfo?.additionalInfo?.matchDetails?.double?.loss}`,
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
               
                      deleteUserHandler(el?._id)
                    }}
                    id={`deletetooltip_del_${el?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
  }))
  
  const mostFriendsCol=[
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      //   },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
          Header:"Email",
          accessor:"email",
          hasDescription:true
      },
      {
          Header:"Location",
          accessor:"location",
          hasDescription:true
      },
      {
        Header:"Friends",
        accessor: 'friends',
        hasDescription: true,
      },
      {
        Header:"Wins/Lose",
        accessor:"winLose",
        hasDescription:true
      },
      {
          Header:"Action",
          accessor:"action",
      },
        
  ]
  
  return   {
    rows:mostFriendsRow,
    cols:mostFriendsCol
  }
  }

  // most profile viewed
const mostProfileViewData=(data,deleteUserHandler)=>{
    const mostProfileViewedRows=data?.map((el)=>({
      // checkbox: (
      //     <input type="checkbox" className="form-check-input" id="customCheck1" />
      //   ),
        name: (
          <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-2 text-capitalize ">{el?.firstName||"-"} {el?.lastName||"-"} </span>
          </Link>
        ),
        profileView:(<span className="text-center w-100">{el?.additionalInfo?.profileViewCounter||0}</span>),
        email: `${el?.email || "--"}`,
        // winLos:,
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.additionalInfo?.location?.description || "--"}</span>,
        winLose: `${el?.additionalInfo?.matchDetails?.single?.win+el?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.additionalInfo?.matchDetails?.single?.loss+ el?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
               
                      deleteUserHandler(el?._id)
                    }}
                    id={`deletetooltip_del_${el?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
  }))
  
  const mostProfileViewedCols=[
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      //   },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
          Header:"Email",
          accessor:"email",
          hasDescription:true
      },
      {
          Header:"Location",
          accessor:"location",
          hasDescription:true
      },
      {
        Header:"Profile Views",
        accessor: 'profileView',
        hasDescription: true,
      },
      {
        Header:"Wins/Lose",
        accessor:"winLose",
        hasDescription:true
      },
      {
          Header:"Action",
          accessor:"action",
      },
        
  ]
  
  return {
    rows:mostProfileViewedRows,
    cols:mostProfileViewedCols
  }
  }

  // most tournament participants
const mostTournamentPlayedData=(data,deleteUserHandler)=>{
    const mostTournamentPlayedRows=data?.map((el)=>({
      // checkbox: (
      //     <input type="checkbox" className="form-check-input" id="customCheck1" />
      //   ),
        name: (
          <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-2 text-capitalize  ">{el?.userInfo?.firstName} {el?.userInfo?.lastName}</span>
          </Link>
        ),
        tournaments:el?.count||0,
        email:el?.userInfo?.email||"--",
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.userInfo?.additionalInfo?.location?.description || "--"}</span>,
        winLose: `${el?.userInfo?.additionalInfo?.matchDetails?.single?.win+el?.userInfo?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.userInfo?.additionalInfo?.matchDetails?.single?.loss+ el?.userInfo?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
               
                      deleteUserHandler(el?._id)
                    }}
                    id={`deletetooltip_del_${el?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
  }))
  
  const mostTournamentPlayedCols=[
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      //   },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
          Header:"Email",
          accessor:"email",
          hasDescription:true
      },
      {
          Header:"Location",
          accessor:"location",
          hasDescription:true
      },
      {
        Header:"Tournaments",
        accessor: 'tournaments',
        hasDescription: true,
      },
      {
        Header:"Wins/Lose",
        accessor:"winLose",
        hasDescription:true
      },
      {
          Header:"Action",
          accessor:"action",
      },
       
  ]
  return {
    rows:mostTournamentPlayedRows,
    cols:mostTournamentPlayedCols
  }
  }
// Most Invite Code Sent


const mostInviteCodeSentData=(data,deleteUserHandler)=>{
    const mostInviteCodeSentRows=data?.map((el)=>({
      // checkbox: (
      //     <input type="checkbox" className="form-check-input" id="customCheck1" />
      //   ),
        name: (
          <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.userDetails?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-2 text-capitalize  ">{el?.userDetails?.firstName||"-"} {el?.userDetails?.firstName||"-"}</span>
          </Link>
        ),
        codeSent:el?.totalUsers||0,
        accepted:Math.floor(Math.random() * (el?.totalUsers - 0 + 1)) + 0,
        email: `${el?.userDetails?.email || "--"}`,
        // winLos:,
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.userDetails?.additionalInfo?.location?.description || "--"}</span>,
        winLose: `${el?.userDetails?.additionalInfo?.matchDetails?.single?.win+el?.userDetails?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.userDetails?.additionalInfo?.matchDetails?.single?.loss+ el?.userDetails?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?.userDetails?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?.userDetails?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?.userDetails?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?.userDetails?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                    id={`edittooltip_edit_${el?.userDetails?._id}`}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?.userDetails?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
               
                      deleteUserHandler(el?.userDetails?._id)
                    }}
                    id={`deletetooltip_del_${el?.userDetails?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?.userDetails?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
  }))
  const mostInviteCodeSentCols=[
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      //   },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
          Header:"Email",
          accessor:"email",
          hasDescription:true
      },
      {
          Header:"Location",
          accessor:"location",
          hasDescription:true
      },
      {
        Header:"Code Sent",
        accessor: 'codeSent',
        hasDescription: true,
      },
      {
        Header:"Accepted",
        accessor: 'accepted',
        hasDescription: true,
      },
      {
        Header:"Wins/Lose",
        accessor:"winLose",
        hasDescription:true
      },
      {
          Header:"Action",
          accessor:"action",
      },
        
  
  ]
  
  return {
    rows:mostInviteCodeSentRows,
    cols:mostInviteCodeSentCols
  }
  }
  // Most Invite code Accept
const mostInvitCodeAcceptedData=(data,deleteUserHandler)=>{
    const mostInviteCodeAcceptRows=data?.map((el)=>({
     
        name: (
          <Link to={`/user-profile/${el?.user?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.user?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-2 text-capitalize  ">{el?.user?.firstName||"-"} {el?.user?.firstName||"-"}</span>
          </Link>
        ),
        codeSent:el?.totalAcceptedInvites||0,
        accepted:Math.floor(Math.random() * (el?.totalAcceptedInvites - 0 + 1)) + 0,
        email: `${el?.user?.email || "--"}`,
        // winLos:,
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.user?.additionalInfo?.location?.description || "--"}</span>,
        winLose: `${el?.user?.additionalInfo?.matchDetails?.single?.win+el?.user?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.user?.additionalInfo?.matchDetails?.single?.loss+ el?.user?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?.user?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?.user?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?.user?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?.user?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?.user?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?.user?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
               
                      deleteUserHandler(el?.user?._id)
                    }}
                    id={`deletetooltip_del_${el?.user?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?.user?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
  }))
  const mostInviteCodeAcceptCols=[
   
    {
        Header: 'Name',
        accessor: 'name',
      },
      {
          Header:"Email",
          accessor:"email",
          hasDescription:true
      },
      {
          Header:"Location",
          accessor:"location",
          hasDescription:true
      },
      {
        Header:"Code Sent",
        accessor: 'codeSent',
        hasDescription: true,
      },
      {
        Header:"Accepted",
        accessor: 'accepted',
        hasDescription: true,
      },
      {
        Header:"Wins/Lose",
        accessor:"winLose",
        hasDescription:true
      },
      {
          Header:"Action",
          accessor:"action",
      },
       
  
  ]
  
  return {
    rows:mostInviteCodeAcceptRows,
    cols:mostInviteCodeAcceptCols
  }
  }

  //  Most Tournaments Created



const mostTournamentCreatedData=(data,deleteUserHandler)=>{
    const mostTourCreatedRows=data?.map((el)=>({
      // checkbox: (
      //     <input type="checkbox" className="form-check-input" id="customCheck1" />
      //   ),
        name: (
          <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-2 text-capitalize  ">{el?.userInfo?.firstName||"-"}     {el?.userInfo?.lastName||"-"}</span>
          </Link>
        ),
        created:el?.count||"--",
        email: `${el?.userInfo?.email || "--"}`,
        // winLos:,
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.userInfo?.additionalInfo?.location?.description || "--"}</span>,
        winLose: `${el?.userInfo?.additionalInfo?.matchDetails?.single?.win+el?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.userInfo?.additionalInfo?.matchDetails?.single?.loss+ el?.userInfo?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
               
                      deleteUserHandler(el?._id)
                    }}
                    id={`deletetooltip_del_${el?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
    
  }))
  const mostTourCreatedCols=[
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      //   },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
          Header:"Email",
          accessor:"email",
          hasDescription:true
      },
      {
          Header:"Location",
          accessor:"location",
          hasDescription:true
      },
      {
        Header:"Created",
        accessor: 'created',
        hasDescription: true,
      },
      {
        Header:"Wins/Lose",
        accessor:"winLose",
        hasDescription:true
      },
      {
          Header:"Action",
          accessor:"action",
      },
       
       
  
  ]
  
  return {
    rows:mostTourCreatedRows,
    cols:mostTourCreatedCols
  }
  
  }
  //  Most Challenges 
const mostChallengesSentData=(data,deleteUserHandler)=>{
    const mostChallengesRows=data?.map((el)=>({
      // checkbox: (
      //     <input type="checkbox" className="form-check-input" id="customCheck1" />
      //   ),
        name: (
          <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-2 text-capitalize ">{el?.userInfo?.firstName||"-"}{el?.userInfo?.lastName||"-"}</span>
          </Link>
        ),
        challengeSent:el?.count,
        email: `${el?.userInfo?.email || "--"}`,
        // winLos:,
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.userInfo?.additionalInfo?.location?.description || "--"}</span>,
        winLose: `${el?.userInfo?.additionalInfo?.matchDetails?.single?.win+el?.userInfo?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.userInfo?.additionalInfo?.matchDetails?.single?.loss+ el?.userInfo?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
               
                      deleteUserHandler(el?._id)
                    }}
                    id={`deletetooltip_del_${el?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
    
  }))
  const mostChallengesCols=[
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      //   },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
          Header:"Email",
          accessor:"email",
          hasDescription:true
      },
      {
          Header:"Location",
          accessor:"location",
          hasDescription:true
      },
      {
        Header:"Sent",
        accessor: 'challengeSent',
        hasDescription: true,
      },
      {
        Header:"Wins/Lose",
        accessor:"winLose",
        hasDescription:true
      },
      {
          Header:"Action",
          accessor:"action",
      },
        
       
  
  ]
  
  return {
    rows:mostChallengesRows,
    cols:mostChallengesCols
  }
  }
  // Most Challenges Accepted


const mostChallengesRecievedData=(data,deleteUserHandler)=>{

    const mostChallengesAcceptedRows=data?.map((el)=>({
      // checkbox: (
      //     <input type="checkbox" className="form-check-input" id="customCheck1" />
      //   ),
        name: (
          <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
            <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
            <span className="ms-md-2 text-capitalize ">{el?.userInfo?.firstName||"-"} {el?.userInfo?.lastName||"-"}</span>
          </Link>
        ),
        
        accepted:(<span className="text-center d-inline-block w-50">{el?.count||0}</span>),
        received:(<span className="text-center d-inline-block w-50">{Math.floor(Math.random() * (el?.count - 0 + 1)) + 0}</span>),
        email: `${el?.userInfo?.email || "--"}`,
        // winLos:,
        location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.userInfo?.additionalInfo?.location?.description || "--"}</span>,
        winLose: (<span className="text-center d-inline-block w-50">{`${el?.userInfo?.additionalInfo
          ?.matchDetails
          ?.single?.win+el?.userInfo?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.userInfo?.additionalInfo?.matchDetails?.single?.loss+ el?.userInfo?.additionalInfo?.matchDetails?.double?.loss || "-"}`}</span>),
        action: (
            <div className="d-flex flex-nowrap users">
              <ul className="list-inline font-size-20 contact-links mb-0 d-flex align-items-center">
                <li className="list-inline-item">
                  <Link
                    to={`/user-profile/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                    <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?._id}`} />
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
               
                      deleteUserHandler(el?._id)
                    }}
                    id={`deletetooltip_del_${el?._id}`}
                  >
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                    
                    />
                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?._id}`} >
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
                
              </ul>
            </div>
          )
    
  }))
  const mostChallengesAcceptedCols=[
      // {
      //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //     accessor: 'checkbox',
      //   },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
          Header:"Email",
          accessor:"email",
          hasDescription:true
      },
      {
          Header:"Location",
          accessor:"location",
          hasDescription:true
      },
      {
        Header:"Received",
        accessor: 'received',
        hasDescription: true,
      },
      {
        Header:"Accepted",
        accessor: 'accepted',
        hasDescription: true,
      },
      {
        Header:"Wins/Lose",
        accessor:"winLose",
        hasDescription:true
      },
      {
          Header:"Action",
          accessor:"action",
      },
        
       
  
  ]
  return {
    rows:mostChallengesAcceptedRows,
    cols:mostChallengesAcceptedCols
  }
  }

const  dynamicUserListingData=(cardData,data,deleteUserHandler)=>({
    
    userRanking:topPlayersData(data,deleteUserHandler),
   
    mostMatchesPlayed:mostMatchesPlayedData(data,deleteUserHandler),
  
    mostFriends:mostFriendsData(data,deleteUserHandler),
    mostProfileView:mostProfileViewData(data),
    mostTournamentPlayed:mostTournamentPlayedData(data,deleteUserHandler),
    mostInvitedSent:mostInviteCodeSentData(data,deleteUserHandler),

    mostInvitedAccept:mostInvitCodeAcceptedData(data,deleteUserHandler),
    mostTournamentCreated:mostTournamentCreatedData(data,deleteUserHandler),
  
    mostChallengSent:mostChallengesSentData(data,deleteUserHandler),
    mostChallengRevieved:mostChallengesRecievedData(data,deleteUserHandler),
    


})[cardData]


export default dynamicUserListingData;