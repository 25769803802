import React from 'react';
import { Button } from 'reactstrap';


const  SubscriptionPlan=()=> {
  return (
    <div className="primary-gradient section-card p-3 d-flex flex-column justify-content-between">
      <div>
        <div className="current-plan pb-1 mb-3">
          <h4 className="font-size-16 fw-bold text-black text-white mb-4">Subscription Plan</h4>
          <h6 className="font-size-12 text-white mb-0 fw-normal">Current Plan</h6>
          <h6 className="font-size-18 fw-bold text-black text-white mb-0">Premium Plan</h6>
          <h6 className="font-size-18 fw-bold text-black text-white">
            $499.00
            <span className="font-size-12 text-white fw-normal">/mon</span>
          </h6>
        </div>
        <div>
          <h6 className="font-size-12 text-white mb-0 fw-normal mb-2">Next payment</h6>
          <h6 className="font-size-14 text-white mb-0 fw-normal">February 28, 2023</h6>
        </div>
      </div>
      <div className="d-flex justify-content-between px-1">
        <Button className="secondary-gradient cancel-plan-btn font-size-14 px-3">Cancel Plan</Button>
        <Button className="change-plan-btn font-size-14 px-3">Change Plan</Button>
      </div>
    </div>
  );
}

export default SubscriptionPlan;
