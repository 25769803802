import React, { useEffect, useState } from "react";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";

import { useParams } from "react-router";
import PointIcon from "assets/icons/PointIcon";
import TournamentsReport from "components/TournamentsReport";
import DetailsTopBar from "components/Common/DetailTop";
import HtmlHead from "components/html-head/HtmlHead";
import api from "api";
import Endpoints from "api/endpoints";
import EarningsCard from "components/Common/EarningsCard";
import { toastError } from "components/Common/toastify";
import { Link } from "react-router-dom";
import Loader from "components/Common/Loader";
import GroupTournament from "./components/GroupTournaments";
import UsersList from "components/UsersList";
import moment from "moment/moment";
import profile from "assets/images/default-img.svg";
import groupBanner from "assets/images/default-banner-3.png";

function GroupDetails() {
  const [groupInfo, setGroupInfo] = useState({});
  const [admins, setAdmins] = useState([]);
  const [groupMember, setGroupMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const title = "Group Details";
  const description = "Group Details Page";

  const getGroupByID = async () => {
    setIsLoading(true);
    const req1 = api.get(`${Endpoints.group}/${id}`);
    // const req2 = api.get(`${Endpoints.groupAdmin}/${id}/admin`)
    const req3 = api.get(`${Endpoints.groupMember}/${id}`);
    Promise.all([req1, req3])
      .then((rep) => {
        setGroupInfo(rep[0]?.data?.data);
        setAdmins(rep[1]?.data?.data?.groupAdmin);

        // group members
        const members = rep[1]?.data?.data?.users?.map((user) => ({
          id: user?.userId?._id,
          img: user?.userId?.additionalInfo?.profileImage,
          name: `${user?.userId?.firstName} ${user?.userId?.lastName}`,
          location: user?.userId?.additionalInfo?.location?.description,
          winLose: `${
            user?.userId?.additionalInfo?.matchDetails?.single?.win +
              user?.userId?.additionalInfo?.matchDetails?.double?.win || "-"
          }/${
            user?.userId?.additionalInfo?.matchDetails?.single?.loss +
              user?.additionalInfo?.matchDetails?.double?.loss || "-"
          }`,
          rating: `S:${user?.userId?.additionalInfo?.matchDetails?.single?.rating} D:${user?.userId?.additionalInfo?.matchDetails?.double?.rating}`,
          userSince: ` ${
            moment(user?.userId?.createdAt).format("MMMM DD, YYYY") || "--"
          }`,
          email: user?.userId?.email,
        }));

        console.log(
          "------group members-------",
          rep[1]?.data?.data?.users?.map((user) => ({
            id: user?.userId?._id,
            img: user?.userId?.additionalInfo?.profileImage,
            name: `${user?.userId?.firstName} ${user?.userId?.lastName}`,
            location: user?.userId?.additionalInfo?.location?.description,
            winLose: `${
              user?.userId?.additionalInfo?.matchDetails?.single?.win +
                user?.userId?.additionalInfo?.matchDetails?.double?.win || "-"
            }/${
              user?.userId?.additionalInfo?.matchDetails?.single?.loss +
                user?.additionalInfo?.matchDetails?.double?.loss || "-"
            }`,
            rating: `S:${user?.userId?.additionalInfo?.matchDetails?.single?.rating} D:${user?.userId?.additionalInfo?.matchDetails?.double?.rating}`,
            userSince: ` ${
              moment(user?.userId?.createdAt).format("MMMM DD, YYYY") || "--"
            }`,
            email: user?.userId?.email,
          }))
        );

        setGroupMember(members);
        setIsLoading(false);
      })
      .catch((err) => {
        toastError();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getGroupByID();
  }, []);

  const earningCard = [
    {
      title: "Group Earnings",
      val: "$1.00k",
    },
    {
      title: "Commission",
      val: "$200.00",
    },
  ];

  const series = [
    {
      name: "Tournament Created",
      data: [31, 40, 28, 51, 42, 109],
    },
    {
      name: "Tournamet Completed",
      data: [11, 32, 45, 32, 34, 5],
    },
  ];

  const options = {
    colors: ["#F69132", "#AA34FE"],
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "category",
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <DetailsTopBar title={title} />
          <Row className="mb-2">
            <Col className="col-12 col-md-8">
              <div
                className="tournament-detail py-3 ps-3"
                style={{
                  backgroundImage: `url(${
                    groupInfo?.profileImage || groupBanner
                  })`,
                }}
              >
                <div className="d-flex justify-content-end flex-column h-100">
                  <div>
                    <h6 className="text-white font-size-16 fw-bold mb-2">
                      {groupInfo?.name}
                    </h6>

                    <div className="font-size-14 text-white mt-3">
                      <div className="mb-2">
                        <PointIcon name="location" height="20" width="20" />
                        &nbsp;&nbsp;{groupInfo?.location?.description}
                      </div>
                      <span className="me-3">
                        <PointIcon name="calender" height="20" width="20" />
                        &nbsp;&nbsp;
                        {moment(groupInfo?.createdAt).format("MMMM DD, YYYY")}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <PointIcon name="password" height="20px" width="20px" />
                      <div>
                        <span className="ms-3 text-white">
                          {groupInfo?.password || "none"}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <PointIcon name="privacy" height="20px" width="20px" />
                      <div>
                        <span className="ms-3 text-white">
                          {groupInfo?.type}
                        </span>
                      </div>
                    </div>
                    <div className=" d-flex align-items-center">
                      <PointIcon name="groups" height="20px" width="20px" className="mt-2" />
                      <div>
                        <span className="ms-3 text-white">
                          {groupInfo?.users?.length}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="col-12 col-md-4 mt-3 mt-md-0">
              <div className="winner p-3">
                <h4 className="fw-bold font-size-16 text-black">Group Bio</h4>
                <p className="font-size-14 text-muted">
                  {groupInfo?.description}
                </p>
              </div>
            </Col>
            {/* <Col className="col-6 court-fee-wrap my-3">his</Col>
            <Col className="col-6 court-fee-wrap my-3">his</Col> */}
          </Row>
          <Row>
            {/* <Col className="col-12 col-xl-12">
            <div className="court-fee-wrap p-3 my-3">
                  <Row>
                    <Col className="col-9">
                      <Row className="d-flex justify-content-between flex-column flex-sm-row">
                        <Col className="col-4">
                          <div>
                            <div className="d-flex">
                              <PointIcon
                                name="privacy"
                                height="20px"
                                width="20px"
                              />
                              <div>
                                <span className="ms-2 text-muted">
                                  Privacy:
                                </span>
                                <span className="ms-3 text-white">
                                  {groupInfo?.type}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-4">
                          <div className=" d-flex">
                            <PointIcon
                              name="groups"
                              height="20px"
                              width="20px"
                            />
                            <div>
                              <span className="ms-2 text-muted">Members:</span>
                              <span className="ms-3 text-white">
                                {groupInfo?.users?.length}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-4">
                          <div className="d-flex">
                            <PointIcon
                              name="password"
                              height="20px"
                              width="20px"
                            />
                            <div>
                              <span className="ms-2 text-muted">Password:</span>
                              <span className="ms-3 text-white">
                                {groupInfo?.password || "none"}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
            </Col> */}
            {/* <Col className="col-12 col-xl-6 mt-3 mb-3">
              <EarningsCard title="Earnings" data={earningCard} />
            </Col> */}

            <Col className="mb-3 col-12 col-md-4">
              <div className="section-card p-3 bg-white">
                <h4 className="font-size-16 fw-bold text-black mb-3">
                  Admins
                  <span className="text-muted font-size-12 fw-normal ms-1">
                    {groupInfo?.groupAdmin?.length}
                  </span>
                </h4>
                <div className="winner-wrap">
                  {admins?.map((user) => (
                    <div
                      key={user?._id}
                      className="d-flex justify-content-between mb-2 pb-2"
                      style={{ borderBottom: "1px solid #E9E8EB" }}
                    >
                      <div className="d-flex align-items-start">
                        <Link
                          to={`/user-profile/${user?._id}`}
                          className="d-flex flex-nowrap  align-items-center"
                        >
                          <img
                            src={user?.additionalInfo?.profileImage || profile}
                            alt="group admin"
                            className="avatar-xs rounded-circle"
                          />
                          <div className="d-flex flex-column ms-3">
                            <div className="font-size-14 text-black">{`${user?.firstName} ${user?.lastName}`}</div>
                            <div className="text-muted font-size-12 d-flex align-items-center">
                              <PointIcon
                                name="trophy"
                                height="18px"
                                width="18px"
                                style={{ fill: "#A7AEBA" }}
                              />
                              <span className="ms-2">
                                {" "}
                                #S:
                                {
                                  user?.additionalInfo?.matchDetails?.single
                                    ?.rating
                                }{" "}
                                D:
                                {
                                  user?.additionalInfo?.matchDetails?.double
                                    ?.rating
                                }{" "}
                                Rating
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={`/user-profile/${user._id}`}
                          id={`deletetooltip_edit${user?._id}`}
                        >
                          <i className="uil-eye font-size-20 cursor-pointer text-muted" />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={`deletetooltip_edit${user?._id}`}
                          >
                            view
                          </UncontrolledTooltip>
                        </Link>

                        {/* <i
                            className="uil-trash-alt font-size-20 text-danger   cursor-pointer  ms-2"
                            id={`deletetooltip_del`}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target={`deletetooltip_del`}
                          >
                            Delete
                          </UncontrolledTooltip> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col className="col-12 col-md-8">
              <TournamentsReport
                // options={options}
                endpoint={`groupTournamentStats`}
                groupId={id}
                point1="Most Created Tournament"
                point2="Most Completed Tournament"
                title="Most Tournament Created vs Completed"
              />
            </Col>
            <Col className="col-12">
              <UsersList
                title={"Group Members"}
                data={groupMember}
                isLoading={isLoading}
                link={`/group-members/${id}`}
              />
            </Col>
            <Col className="col-12">
              <GroupTournament link="groups" id={id} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default GroupDetails;
