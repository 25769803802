import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardTitle } from 'reactstrap';
import DateRangePicker from './Common/DatePicker';

function PieChart({ title, data }) {
  const series = data.map((el) => el.series);
  const options = {
    labels: data.map((el) => el.text),
    colors: data.map((el) => el.color),
    subtitle: {
      text: undefined,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize:  '12px',
        fontWeight:  'normal',
        fontFamily:  undefined,
        color:  '#9699a2'
      },
  },
    legend: {
      show: !0,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: !1,
      fontSize: '14px',
      offsetX: 0,
      markers: {
        size: 0,
        width:8,
        height:8,
        
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  };

  return (
    <Card className="section-card card-height">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-2 h4 d-flex">
            <div className="d-flex align-items-center">
              <span className="font-size-16 fw-bold text-black">{title}</span>
              {/* <span className="text-muted ms-3 fw-normal font-size-14">
                weekly
                <i className="mdi mdi-chevron-down ms-1" />
              </span> */}
            </div>
          </CardTitle>
          {/* <DateRangePicker /> */}
        </div>
        <div className="d-flex align-items-center justify-content-center circle-chart flex-column">
          <div className="mt-3">
            <ReactApexChart options={options} series={series} type="pie" height={270} className="apex-charts" />
          </div>
          {/* <ul className="list-unstyled d-flex justify-content-center flex-wrap">
            {data.map(({ text, color }, ind) => (
              <li className="text-muted mt-2 me-3" key={ind}>
                <i className="fas fa-circle cursor-pointer me-2" style={{ color, fontSize: '10px' }} />
                {text}
              </li>
            ))}
          </ul> */}
        </div>
      </CardBody>
    </Card>
  );
}

export default PieChart;
