import React from 'react';
import { Card, CardBody } from 'reactstrap';

const TopCard=({ desc, num ,icon}) =>{
  return (
    <Card>
    <CardBody className="card-layout py-5  user-top-card  d-flex justify-content-center flex-column align-items-center">
      <div className='icon-container d-flex align-items-center justify-content-center p-5 ' style={{
        backgroundImage:"url(/img/users/topCardsCircle.svg)"
      }}>

      <img src={icon} alt="" />
      </div>
      <h6 className="font-size-12 text-muted mb-0 mt-2  text-center fw-normal">{desc}</h6>
      <h4 className="font-size-24 fw-bold mb-0 mt-1 text-black">{num}</h4>
    </CardBody>
    </Card>
  );
}

export default TopCard;
