import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import MiniWidget from "components/mini-widget";
import MatchCard from "components/MatchCard";
import TournamentsReport from "components/TournamentsReport";
import ScoreCard from "components/ScoreCard";
import ByCities from "components/ByCities";
import TournamentsCategory from "components/TournamentsCategory";
import TournamentPrivacy from "components/TournamentPrivacy";
import TournamentCompletion from "components/TournamentCompletion";
import HtmlHead from "components/html-head/HtmlHead";
import upperCardStyle from "utils/upperCardStyle";
import EntryFeeChart from "./components/EntryFeeChart";
import RefundRequests from "./components/RefundRequests";
import Endpoints from "api/endpoints";
import api from "api";
import { toastError } from "components/Common/toastify";
import UserInfoCard from "pages/Users/components/UserInfoCard";
import TournamentList from "./components/TournamentList";
import queryParamBuilder from "api/queryParamBuilder";
import MyTournamentCard from "pages/Users/components/MyTournamentCard";
import TournamentsWraper from "./components/TournamentsWraper";

// import RefundRequests from './components/RefundRequests';
// import EntryFeeChart from './components/EntryFeeChart';

const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
  },
];

const options1 = {
  fill: {
    colors: ["#5CA7DD"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter(seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

const series2 = [70];

const options2 = {
  fill: {
    colors: ["#B3DA06"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const series3 = [55];

const options3 = {
  fill: {
    colors: ["#5CA7DD"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const series4 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
  },
];

const options4 = {
  fill: {
    colors: ["#DCBF32"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter(seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

function Tournaments() {
  const title = "Tournaments";
  const description = "Tournaments Page";

  const [isLoading, setIsLoading] = useState({
    tournamentStats: false,
    // tournamentReport:[],
    tournamentFormat: false,

    tournamentStatus: false,
    tournamentFee:false,
    matchesByCity: false,
    mostTournamentCreated:false,
    mostTournamentPlayed: false,
  });
  const [dataState, setDataState] = useState({
    tournamentStats: {},
    // tournamentReport:[],
    tournamentFormat: [],

    tournamentStatus: [],
    tournamentFee: [],
    matchesByCity: [],
    mostTournamentCreated: [],
    mostTournamentPlayed: [],
  });

  // const reports = [
  //   {
  //     id: 5,
  //     icon: "mdi mdi-menu-up",
  //     title: "Open Tournament",
  //     value: dataState?.tournamentStats?.openTournament,
  //     // decimal: 2,
  //     // prefix: '+',
  //     // suffix: '%',
  //     // charttype: 'bar',
  //     // chartheight: 40,
  //     // chartwidth: 70,
  //     // badgeValue: '5.24%',
  //     // color: 'success',
  //     // series: series4,
  //     // options: options4,
  //   },

  //   {
  //     id: 2,
  //     icon: "mdi mdi-menu-down",
  //     title: "In Progress Tournaments",
  //     value: dataState?.tournamentStats?.inProgressTournament || 0,
  //     // decimal: 0,
  //     // charttype: 'radialBar',
  //     // chartheight: 45,
  //     // chartwidth: 45,
  //     // prefix: '',
  //     // suffix: '',
  //     // badgeValue: '5.24%',
  //     // color: 'danger',
  //     // series: series2,
  //     // options: options2,
  //   },
  //   {
  //     id: 3,
  //     icon: "mdi mdi-menu-up",
  //     title: "Completed",
  //     value: dataState?.tournamentStats?.completeTournament,
  //     // decimal: 0,
  //     // prefix: '',
  //     // suffix: '',
  //     // charttype: 'radialBar',
  //     // chartheight: 45,
  //     // chartwidth: 45,
  //     // badgeValue: '5.24%',
  //     // color: 'danger',
  //     // series: series3,
  //     // options: options3,
  //   },
  //   {
  //     id: 4,
  //     icon: "mdi mdi-menu-up",
  //     title: "Canceled",
  //     value: dataState?.tournamentStats?.canceledTournament,
  //     // decimal: 2,
  //     // prefix: '+',
  //     // suffix: '%',
  //     // charttype: 'bar',
  //     // chartheight: 40,
  //     // chartwidth: 70,
  //     // badgeValue: '5.24%',
  //     // color: 'success',
  //     // series: series4,
  //     // options: options4,
  //   },

  //   {
  //     id: 1,
  //     icon: "mdi mdi-menu-down",
  //     title: "Total Tournaments",
  //     value: dataState?.tournamentStats?.totalTournament || 0,
  //     // prefix: '$',
  //     // suffix: '',
  //     // badgeValue: '5.24%',
  //     // decimal: 0,
  //     // charttype: 'bar',
  //     // chartheight: 40,
  //     // chartwidth: 70,
  //     // color: 'success',
  //     // series: series1,
  //     // options: options1,
  //   },
  //   {
  //     id: 6,
  //     icon: "mdi mdi-menu-up",
  //     title: "Soft Deleted Tournaments",
  //     value: dataState?.tournamentStats?.softDeletedTournament,
  //     decimal: 2,
  //     prefix: "+",
  //     suffix: "%",
  //     charttype: "bar",
  //     chartheight: 40,
  //     chartwidth: 70,
  //     badgeValue: "5.24%",
  //     color: "success",
  //     series: series4,
  //     options: options4,
  //   },
  // ];

  const series = [
    {
      name: "Sanctioned",
      data: [31, 40, 28, 51, 42, 109],
    },
    {
      name: "Unsanctioned",
      data: [11, 32, 45, 32, 34, 5],
    },
  ];

  const options = {
    colors: ["#F69132", "#AA34FE"],
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "category",
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const feeSeries = [
    {
      name: "Amount",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(
        {
          tournamentStats: true,
          // tournamentReport:[],
          tournamentFormat: true,
      
          tournamentStatus: true,
          tournamentFee:true,
          matchesByCity: true,
          mostTournamentCreated:true,
          mostTournamentPlayed: true,
        }
      )
      const controller = new AbortController();

      

      const requests = Object.keys(dataState);

      try {
        let n = 0;

        const apiHitHandler = async (x) => {
          if (n < requests.length) {
            const signal = controller.signal;

            try {
              const res = await api.get(`${Endpoints[requests[x]]}`, {
                signal,
              });

              if (res?.status === 200) {
                const keyToUpdate = Object.keys(dataState)[x];

                // Check if the component is still mounted before updating the state
                if (!controller.signal.aborted) {
                  setDataState((prevDataState) => ({
                    ...prevDataState,
                    [keyToUpdate]: res?.data?.data,
                  }));
                  setIsLoading((prevDataState) => ({
                    ...prevDataState,
                    [keyToUpdate]: false,
                  }));
                }

                // Recursive call for the next request
                await apiHitHandler((n += 1));
              }
            } catch (err) {
              if (err.name === "AbortError") {
                console.log("Request aborted due to component unmount");
              } 
            }
          }
        };

        // Initial call to apiHitHandler
        await apiHitHandler(n);
      } catch (err) {
        setIsLoading(
          {
            tournamentStats: false,
            // tournamentReport:[],
            tournamentFormat: false,
        
            tournamentStatus: false,
            tournamentFee:false,
            matchesByCity: false,
            mostTournamentCreated:false,
            mostTournamentPlayed: false,
          }
        )
      } finally {
        controller.abort(); // Abort any ongoing requests when component unmounts
        setIsLoading(
          {
            tournamentStats: false,
            // tournamentReport:[],
            tournamentFormat: false,
        
            tournamentStatus: false,
            tournamentFee:false,
            matchesByCity: false,
            mostTournamentCreated:false,
            mostTournamentPlayed: false,
          }
        )
      }
    };

    fetchData();

    // Cleanup function to cancel ongoing requests when the component unmounts
    return () => {
      // Abort any ongoing requests when the component unmounts
      // This is already handled in the `finally` block, so no need to repeat it here
    };
  }, []); // Ensure the effect runs only once on mount

  // tournament completion
  let totalTournemnts = dataState?.tournamentStatus?.tournament?.reduce(
    (acc, cur) => (acc += cur?.count),
    0
  );
  let tournamentCompletionSeries = dataState?.tournamentStatus?.tournament?.map(
    (el) => Math.round((el?.count / totalTournemnts) * 100)
  );

  // console.log(dataState?.allTournaments)
  // top cards
  const statsReport = useMemo(() => {
    return Object.keys(dataState?.tournamentStats).map((key) => {
      return {
        title: key,
        value: dataState?.tournamentStats[key],
      };
    });
  }, [dataState?.tournamentStats]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <div className="fw-bold font-size-24 mb-3 text-black">{title}</div>
          {/* <Row className="row-cols-md-6"> */}
          <Row className="row-cols-xl-4">
            <MiniWidget reports={statsReport.length===0?[[],[],[],[],[],[]]:statsReport} upperCardStyle={upperCardStyle} />
          </Row>

          <Row className="mb-3">
            <TournamentsWraper />
          </Row>

          <Row>
            <Col>
              <TournamentsReport
                //   options={options}
                //  series={series}
                endpoint="tournamentReport"
                point1="Unsanction"
                point2="Sanction"
                title="Tournaments Report"
              />
            </Col>

            {/* <Col xl={12} xxl={4}>
              <ScoreCard />
            </Col> */}
          </Row>
          <Row>
            <Col xl={6}>
              <EntryFeeChart
                title="Entry Fee"
                endpoint="tournamentAmount"
                series={feeSeries}
              />
            </Col>
            <Col xl={6}>
              <ByCities
                cityText="Matches"
                link="/tournaments-listing"
                endpoint={"matchesByCity"}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-xl-6 col-xxl-4">
              <TournamentsCategory
                title="Tournaments Category"
                // series={[3,5,2]}
                series={dataState?.tournamentFormat}
                p1="Single"
                p2="Double"
                p3="Round Robin"
                col1="#4B7EB9"
                col2="#82A6CE"
                col3="#B9CDE4"
              />
            </Col>
            <Col className="col-12 col-xl-6 col-xxl-4">
              <TournamentPrivacy />
            </Col>
            <Col className="col-12 col-xxl-4">
              <TournamentCompletion
                title={"Tournament Status"}
                series={tournamentCompletionSeries}
                totalPlayed={totalTournemnts}
                labels={["Open", "Start", "Closed", "Cancel"]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <TournamentList />
            </Col>
          </Row>
          <Row>
            {/* <Col className="col-12">
              <RefundRequests title="Refund Requests" />
            </Col> */}
            <Col className="col-12 col-lg-6 ">
              <UserInfoCard
                title="Most Tournament Created"
                endpoint={""}
                data={dataState?.mostTournamentCreated}
                isLoading={isLoading?.mostTournamentCreated}
                btnNavigat={"mostTournamentCreated"}
                cardData={"mostTournamentCreated"}
              />
            </Col>
            {/* Most Tournaments Played*/}
            <Col className="col-12 col-lg-6 ">
              <UserInfoCard
                title="Most Tournaments Played"
                endpoint={""}
                data={dataState?.mostTournamentPlayed}
                isLoading={isLoading?.mostTournamentPlayed}
                btnNavigat={"mostTournamentPlayed"}
                cardData={"mostTournamentPlayed"}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Tournaments;
