import PropTypes from "prop-types";
import React, { useContext, useEffect,useState,useRef } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";

// Redux
import { Link, Navigate, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//Api
import api from "../../api";
//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginSuccess, loginUser, socialLogin } from "../../store/actions";

// import images
import logo from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import point from "../../assets/images/point-white.png";

//Import config
import { facebook, google } from "../../config";
import Endpoints from "api/endpoints";
import { toastError, toastSuccess } from "components/Common/toastify";
import { toast } from "react-toastify";
import { setCurrentUser } from "store-point/slices/authSlice";
import UserContext from "globalContext/userContext";
import { getFcmToken } from "../../firebase";
import Cookies from "js-cookie";

const Login = (props) => {
  document.title = " Login | Point Admin Dashboard";
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  const { login } = useContext(UserContext);
  const [isSubmitting,setSubmitting]=useState(true)


const authCredentials=typeof Cookies.get('auth')!='undefined'? JSON.parse(Cookies.get('auth')):'';

const [check,setCheck] = useState( authCredentials !="") 
const [notifyPermission, setNotifyPermission] = useState(
  Notification.permission
);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    
    initialValues: {
      email: authCredentials?.email|| "",
      password:authCredentials?.password||  "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values, { resetForm ,setSubmitting}) => {
      if (notifyPermission === "granted" || notifyPermission === "default") {
        setSubmitting(false);
        try {
          const res = await api.post(Endpoints.login, {
            email: values.email,
            password: values.password,
          });
          if (res.status === 200) {
            dispatch(loginUser(res.data?.data));
            setSubmitting(true);

            if (res?.data?.data?.userRole === "admin") {
              if (check) {
                var now = new Date();
                var time = now.getTime();
                var expireTime = time + 1000 * 36000;
                Cookies.set("auth", JSON.stringify(values), {
                  expires: expireTime,
                });
              }
              localStorage.setItem("accessToken", res.data.token);
              localStorage.setItem("userRole", res?.data?.data?.userRole);

              if (res.data.token) {
                const fcmToken = await getFcmToken();
                if (fcmToken) {
                  const res = await api.put(`${Endpoints?.addFcm}`, {
                    fcmWebApp: fcmToken,
                  });

                  localStorage.setItem("fcmToken", fcmToken);
                  localStorage.setItem("user", res?.data?.data?._id);
                  toastSuccess("Login");
                  navigate("/dashboard");
                }
              }
            } else {
              toastError("Don't have permissions");
            }
          }
        } catch (error) {
          // await dispatch(setCurrentUser(error));

          toastError(error?.response?.data?.error);
          setSubmitting(true);
          // toast.error(currentUser?.response?.data?.error || currentUser?.message);
        }
      } else {
        alert(
          `Notifications are blocked. Please open your browser preferences or click the lock near the address bar to change your notification preferences. After granting permission please once re-load page`
        );
      }

      // resetForm();

      // Navigate("/dashboard");
      // navigate("/dashboard");
      // dispatch(loginUser(values, props.router.navigate));
    },
  });


  const selectLoginState = (state) => state.Login;
  const LoginProperties = createSelector(selectLoginState, (login) => ({
    error: login.error,
  }));

  const { error } = useSelector(LoginProperties);

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, "google");
  };

  //handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, "facebook");
  };

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className=" ">
        <div className="account-pages ">
          <Container fluid className="bg-image">
            {/* <Row className="justify-content-center align-items-center">
              
            </Row> */}
            
             
              <div className="d-flex flex-column justify-content-center h-100 align-items-center">
              <div className="text-center mb-4" >
                  <Link to="/" className="d-block  mb-3 ">
                    <img
                      src={point}
                      alt="point"
                      height="70"
                      // className="logo logo-dark"
                    />
                    <img
                      src={point}
                      alt="point"
                      height="70"
                      className="logo logo-light"
                    />
                  </Link>
                </div>
          
              
                <Card className="col-12 col-sm-9 col-md-9 col-lg-8 col-xl-6 col-xxl-4">
                  <CardBody className="p-2 p-md-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to Point.
                      </p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot password?
                            </Link>
                          </div>
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                           defaultChecked={authCredentials}
                           onChange={()=>setCheck(true)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          disabled={
                            validation.isSubmitting
                          }
                        >
                         {
                        validation?.isSubmitting?(<><Spinner size="sm">
                        Loading...
                      </Spinner>
                      <span>
                        {' '}Loading
                      </span></>):("Log in")
                         }
                        </button>
                      </div>


                        {/* <div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">Sign in with</h5>

                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <FacebookLogin
                                appId={facebook.APP_ID}
                                autoLoad={false}
                                callback={facebookResponse}
                                render={(renderProps) => (
                                  <Link
                                    to="#"
                                    className="social-list-item bg-primary text-white border-primary"
                                    onClick={renderProps.onClick}
                                  >
                                    <i className="mdi mdi-facebook" />
                                  </Link>
                                )}
                              />
                            </li>
                            {google.CLIENT_ID !== "" && (
                              <li className="list-inline-item">
                                <GoogleLogin
                                  clientId={google.CLIENT_ID}
                                  render={(renderProps) => (
                                    <Link
                                      to="#"
                                      className="social-list-item bg-danger text-white border-danger"
                                      onClick={renderProps.onClick}
                                    >
                                      <i className="mdi mdi-google" />
                                    </Link>
                                  )}
                                  onSuccess={googleResponse}
                                  onFailure={() => {}}
                                />
                              </li>
                            )}
                          </ul>
                        </div> */}

                        {/* <div className="mt-4 text-center">
                          <p className="mb-0">
                            Don&apos;t have an account ?{" "}
                            <a
                              href="/register"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Signup now{" "}
                            </a>{" "}
                          </p>
                        </div> */}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-2  text-center">
                  <p className="cl-white mb-0 ">
                    © {new Date().getFullYear()} Point. Design & Develop by Pixarsart Studios
                  </p>
                </div>
              </div>
                
            
          
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};
