import React, { useState, useEffect, useMemo } from 'react';

import api from 'api';
import Endpoints from 'api/endpoints';
import { toastError } from 'components/Common/toastify';
import HtmlHead from 'components/html-head/HtmlHead';

import { Col, Container, Row } from 'reactstrap'
import Loader from 'components/Common/Loader';
import MiniWidget from 'components/mini-widget';
import upperCardStyle from 'utils/upperCardStyle';
import TournamentsReport from 'components/TournamentsReport';
import PieChart from 'components/PieChart';
import Users from 'components/Users';
import ByCities from 'components/ByCities';
import FastestRank from './components/FastestRank';
import UsersList from 'components/UsersList';

import UserInfoCard from './components/UserInfoCard';
import queryParamBuilder from 'api/queryParamBuilder';
import { toastSuccess } from 'components/Common/toastify';
import moment from 'moment';
import ReportedUser from './components/ReportedUser';
// Dummy Data 



const UserDasboard = () => {


  const title = 'Users';
  const description = 'Users Page';

  // dynamic data states

  
  // const [users,setUsers]=useState([])

  const [isLoading,setIsLoading]=useState({
    users:false,
    counts:false,
    topPlayers:false,
    mostTournamentCreated:false,
    mostMatchesPlayed:false,
    mostFriends:false,
    mostProfileView:false,
    mostChallengSent:false,
    mostChallengRevieved:false,
    mostInvitedSent:false,
    mostTournamentPlayed:false,
    mostInvitedAccept:false,
    getBlockedUsers:false
  })
  const [dataState,setDataState]=useState({
    users:[],
    counts:[],
    topPlayers:[],
    mostTournamentCreated:[],
    mostMatchesPlayed:[],
    mostFriends:[],
    mostProfileView:[],
    mostChallengSent:[],
    mostChallengRevieved:[],
    mostInvitedSent:[],
    mostTournamentPlayed:[],
    mostInvitedAccept:[],
    getBlockedUsers:[]
  })

 
  
  const fetchData = async () => {
    // users endpoint
    const users = queryParamBuilder({
      endpoint: Endpoints.getAll,
      limit: "5",
    });
    // topplayers endpoint
    const topPlayers = queryParamBuilder({
      endpoint: Endpoints.userRanking,
      limit: "5",
      filter:"world"
    });
    // most tournament created endpoint
    const mostTournamentCreated = queryParamBuilder({
      endpoint: Endpoints.mostTournamentCreated,
      limit: "5",
      
    });
    // most matches played endpoint
    const mostMatchesPlayed=Endpoints?.mostMatchesPlayed

    const mostFriends=Endpoints?.mostFriends;
    const mostProfileView=Endpoints?.mostProfileView;
    const counts = Endpoints.userCount;
    const mostChallengSent=Endpoints?.mostChallengSent;
    const mostChallengRevieved=Endpoints?.mostChallengRevieved;
    const mostInvitedSent=Endpoints?.mostInvitedSent;
    const mostTournamentPlayed=Endpoints?.mostTournamentPlayed;
    const mostInvitedAccept=Endpoints?.mostInvitedAccept;

    const getBlockedUsers=queryParamBuilder({
      endpoint: Endpoints.getBlockedUsers,
      limit: "10",
      // type:"report"
    });

    // all request array
    const requests = [users, counts,topPlayers,mostTournamentCreated,mostMatchesPlayed,mostFriends,mostProfileView,
      mostChallengSent,
      mostChallengRevieved,
      mostInvitedSent,
      mostTournamentPlayed,
      mostInvitedAccept,
      getBlockedUsers,
    ];
    
    // loading states
    setIsLoading({
      users:true,
    counts:true,
    topPlayers:true,
    mostTournamentCreated:true,
    mostMatchesPlayed:true,
    mostFriends:true,
    mostProfileView:true,
    mostChallengSent:true,
    mostChallengRevieved:true,
    mostInvitedSent:true,
    mostTournamentPlayed:true,
    mostInvitedAccept:true,
    getBlockedUsers:true
    })

    let n = 0;
    const apiHitHandler = async (x) => {
      const keyToUpdate = Object.keys(dataState)[x];
     
      if (n < requests.length) {
        try {
          const res = await api.get(requests[x]);
          if(res?.status === 200){
  
          setDataState((prevDataState) => ({
            ...prevDataState,
            [keyToUpdate]: res?.data?.data,
          }));
          setIsLoading((prevDataState) => ({
            ...prevDataState,
            [keyToUpdate]: false,
          }))
          // Recursive call for the next request
          await apiHitHandler(n += 1);
          }
        } catch (err) {
          setIsLoading((prevDataState) => ({
            ...prevDataState,
            [keyToUpdate]: false,
          }))
          // toastError();
        }
      }
    };
  
    // Initial call to apiHitHandler
    await apiHitHandler(n);
    setIsLoading({
      users:false,
      counts:false,
      topPlayers:false,
      mostTournamentCreated:false,
      mostMatchesPlayed:false,
      mostFriends:false,
      mostProfileView:false,
      mostChallengSent:false,
      mostChallengRevieved:false,
      mostInvitedSent:false,
      mostTournamentPlayed:false,
      mostInvitedAccept:false,
      getBlockedUsers:false
    })
  };

  useEffect(() => {
    
    fetchData();
  }, [])






  
  // const reports = [
  //   {
  //     id: 1,
  //     // icon: 'mdi mdi-menu-down',
  //     title: 'Total Users',
  //     value: dataState?.counts?.totalUser,
  //     // prefix: '$',
  //     // suffix: '',
  //     // badgeValue: '5.24%',
  //     decimal: 0,
  //     // charttype: 'bar',
  //     // chartheight: 40,
  //     // chartwidth: 70,
  //     color: 'success',
  //     // series: series1,
  //     // options: options1,
  //   },
  //   {
  //     id: 2,
  //     // icon: 'mdi mdi-menu-down',
  //     title: 'Paid Users',
  //     value: dataState?.counts?.paidUser,
  //     // decimal: 0,
  //     // charttype: 'radialBar',
  //     // chartheight: 45,
  //     // chartwidth: 45,
  //     // prefix: '',
  //     // suffix: '',
  //     // badgeValue: '5.24%',
  //     color: 'danger',
  //     // series: series2,
  //     // options: options2,
  //   },
  //   {
  //     id: 3,
  //     icon: 'mdi mdi-menu-up',
  //     title: 'Free Users',
  //     value: dataState?.counts?.freeUser,
  //     // decimal: 0,
  //     // prefix: '',
  //     // suffix: '',
  //     // charttype: 'radialBar',
  //     // chartheight: 45,
  //     // chartwidth: 45,
  //     // badgeValue: '5.24%',
  //     color: 'danger',
  //     // series: series3,
  //     // options: options3,
  //   },
  //   {
  //     id: 4,
  //     icon: 'mdi mdi-menu-up',
  //     title: "Stripe Connect",
  //     value: dataState?.counts?.stripeConnect,
  //     // decimal: 2,
  //     // prefix: '+',
  //     // suffix: '%',
  //     // charttype: 'bar',
  //     // chartheight: 40,
  //     // chartwidth: 70,
  //     // badgeValue: '5.24%',
  //     // color: 'success',
  //     // series: series4,
  //     // options: options4,
  //   },
  //   {
  //     id: 5,
  //     // icon: 'mdi mdi-menu-up',
  //     title: 'Deleted Users',
  //     value: dataState?.counts?.deletedUser,
  //     // decimal: 2,
  //     // prefix: '+',
  //     // suffix: '%',
  //     // charttype: 'bar',
  //     // chartheight: 40,
  //     // chartwidth: 70,
  //     // badgeValue: '5.24%',
  //     // color: 'success',
  //     // series: series4,
  //     // options: options4,
  //   },
  //   {
  //     id: 6,
  //     icon: 'mdi mdi-menu-up',
  //     title: 'Blocked Users',
  //     value: dataState?.counts?.blockUser,
  //     // decimal: 2,
  //     // prefix: '+',
  //     // suffix: '%',
  //     // charttype: 'bar',
  //     // chartheight: 40,
  //     // chartwidth: 70,
  //     // badgeValue: '5.24%',
  //     // color: 'success',
  //     // series: series4,
  //     // options: options4,
  //   },
  // ];
  const statsReport = useMemo(() => {
    return Object.keys(dataState?.counts).map((key) => {
      return {
        title: key,
        value: dataState?.counts[key],
      };
    });
  }, [dataState?.counts]);

  // not verified scores
  const pieChartInfo = [
    {
      text: 'Total Users',
      color: '#FCE2C9',
      series: 55,
    },
    {
      text: "Didn't Verify Scores",
      color: '#F69132',
      series: 44,
    },
  ];
 
  // new users
  // const userSeries = [{
  //   name: 'New user',
  //   data: [30, 14, 90, 21, 29, 67],
  // },]
   //   // users destruction
      const userSData=dataState?.users?.map(el=>({
        id:el._id,
        img:el?.additionalInfo?.profileImage,
        email:el?.email,
        name:`${el?.firstName} ${el?.lastName}`,
        rating: 
       ` S:${el?.additionalInfo?.matchDetails?.single?.rating}/D:${el?.additionalInfo?.matchDetails?.double?.rating}`
        ,
        location:el?.additionalInfo?.location?.description,
        winLose:`${el?.additionalInfo?.matchDetails?.single?.win+el?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.additionalInfo?.matchDetails?.single?.loss+ el?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
        userSince: `${moment(el?.createdAt).format('MMMM DD, YYYY') || "--"}`,
      }))
  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content'>
        <Container fluid>
            <div className="fw-bold font-size-24 mb-3 text-black  ">{title}</div>
          {/* -------- UPPER CARDS --------- */}
          <Row className= ' row-cols-auto row-cols-xl-6'>
            {
           
                
                 
                  
                  <MiniWidget reports={statsReport.length===0?[[],[],[],[],[],[]]:statsReport} upperCardStyle={upperCardStyle} />


                
              
            }

          </Row>
          {/* 2nd row  */}
          <Row>
            <Col xl={12}>
              <TournamentsReport
                // series={series}
                endpoint={"freePaid"}
                point1="Free Users"
                point2="Paid Users"
                title="Free vs Paid Users" />
            </Col>

            {/* <Col xl={4}>
              <PieChart title="Users Did not Verify Scores" data={pieChartInfo} />
            </Col> */}
          </Row>
          {/* 3rd row */}
          <Row>
            <Col lg={6}>
              <Users title="New Users"
                //   options={userOptions}
                // series={userSeries} 
                endpoint={"newUsers"}
                link="/users-listing" />
            </Col>
            {/* <Col xl={6} xxl={4}>
              <FastestRank />
            </Col> */}
            <Col lg={6}>
              <ByCities cityText="Users" link="/users-listing" endpoint={"getUserByCity"} />
            </Col>
          </Row>
          {/* all users container */}
          <Row>
            <Col className='col-12'>
              <UsersList isLoading={isLoading?.users} 
              data={userSData}
               title="Users" />
            </Col>
          </Row>
          <Row>
            {/* not verified users */}
            {/* <Col className='col-12 col-xxl-4'>
              <UserInfoCard
                title="Top Users That Do Not Verify Scores"
                endpoint={""}
                btnNavigat={"#"}
                cardData={"notverifyscores"} />
            </Col> */}
            {/* Top Players */}
            <Col className='col-12 col-lg-6 col-xl-4 col-xxl-4'>
              <UserInfoCard
                title="Top Players"
                // endpoint={Endpoints.userRanking}
                data={dataState?.topPlayers}
                isLoading={isLoading?.topPlayers}
                btnNavigat={"userRanking"}
                cardData={"userRanking"} 
                
                filter='world'
                
                />
                
            </Col>
            {/* Most Played matches */}
            <Col className='col-12 col-lg-6 col-xl-4 col-xxl-4'>
              <UserInfoCard
                title="Most Matches Played"
                endpoint={""}
                data={dataState?.mostMatchesPlayed}
                isLoading={isLoading?.mostMatchesPlayed}
                btnNavigat={"mostMatchesPlayed"}
                cardData={"mostMatchesPlayed"} />
            </Col>
             {/* Most Friends*/}
             <Col className='col-12 col-lg-6 col-xl-4 col-xxl-4'>
              <UserInfoCard
                title="Most Friends"
                endpoint={""}
                data={dataState?.mostFriends}
                isLoading={isLoading?.mostFriends}
                btnNavigat={"mostFriends"}
                cardData={"mostFriends"} />
            </Col>
             {/* Most Profile viewed*/}
             <Col className='col-12 col-lg-6 col-xl-4 col-xxl-4'>
              <UserInfoCard
                title="Most Profile viewed"
                endpoint={""}
                data={dataState?.mostProfileView}
                isLoading={isLoading?.mostProfileView}
                btnNavigat={"mostProfileView"}
                cardData={"mostProfileViewed"} />
            </Col>
             {/* Most Tournament Created */}
             <Col className='col-12 col-lg-6 col-xl-4 col-xxl-4'>
              <UserInfoCard
                title="Most Tournament Created"
                endpoint={""}
                data={dataState?.mostTournamentCreated}
                isLoading={isLoading?.mostTournamentCreated}
                btnNavigat={"mostTournamentCreated"}
                cardData={"mostTournamentCreated"} />
            </Col>
            {/* Most Tournaments Played*/}
            <Col className='col-12 col-lg-6 col-xl-4 col-xxl-4'>
              <UserInfoCard
                title="Most Tournaments Played"
                endpoint={""}
                data={dataState?.mostTournamentPlayed}
                isLoading={isLoading?.mostTournamentPlayed}
                btnNavigat={"mostTournamentPlayed"}
                cardData={"mostTournamentPlayed"} />
            </Col>
            {/* Fastes Climb Ranked */}
            {/* <Col className='col-12 col-xxl-4'>
              <UserInfoCard
                title="Fastest Climb Ranked"
                endpoint={""}
                btnNavigat={"#"}
                cardData={"fastClimbRank"} />
            </Col> */}
            {/* reported users container */}
            <Col className='col-12'>
              {/* <UserInfoCard
                title="Reported Users"
                endpoint={""}
                cardData="reportedUsers"
                btnNavigat="#"
              /> */}
              <ReportedUser 
              title={"Reported Users"}
              data={dataState?.getBlockedUsers}
              isLoading={isLoading?.getBlockedUsers}
              
              />
            </Col>
            {/* Most Matches Played in row is Removed*/}
            
            {/* Most Matches Won In a Row */}
            {/* <Col className='col-12 col-xxl-8'>
              <UserInfoCard
                title="Most Matches Won In a Row"
                endpoint={""}
                btnNavigat={"#"}
                cardData={"mostMatchesWonInRow"} />
            </Col> */}
           
           
              
            {/* Most Invite Code Sent*/}
            <Col className='col-12 col-lg-6 '>
              <UserInfoCard
                title="Most Invite Code Sent"
                endpoint={""}
                data={dataState?.mostInvitedSent}
                isLoading={isLoading?.mostInvitedSent}
                btnNavigat={"mostInvitedSent"}
                cardData={"mostInviteCodeSent"} />
            </Col>
            {/* Most Invite Code Accepted*/}
            <Col className='col-12 col-lg-6'>
              <UserInfoCard
                title="Most Invite Code Accepted"
                endpoint={""}
                data={dataState?.mostInvitedAccept}
                isLoading={isLoading?.mostInvitedAccept}
                btnNavigat={"mostInvitedAccept"}
                cardData={"mostInviteCodeAccepted"} />
            </Col>
         
            {/* Most Tournament Completed  removed*/}
            {/* <Col className='col-12 col-xxl-4'>
              <UserInfoCard
                title="Most Tournament Completed"
                endpoint={""}
                btnNavigat={"#"}
                cardData={"mostTournamentCompleted"} />
            </Col> */}
            {/* Most Challenges */}
            <Col className='col-12 col-lg-6 col-xl-4'>
              <UserInfoCard
                title="Most Challenges Sent"
                endpoint={""}
                data={dataState?.mostChallengSent}
                isLoading={isLoading?.mostChallengSent}
                btnNavigat={"mostChallengSent"}
                cardData={"mostChallenges"} />
            </Col>
            {/* Most Challenges  Accepted */}
            <Col className='col-12 col-lg-6 col-xl-8 '>
              <UserInfoCard
                title="Most Challenges  Accepted "
                endpoint={""}
                data={dataState?.mostChallengRevieved}
                isLoading={isLoading?.mostChallengRevieved}
                btnNavigat={"mostChallengRevieved"}
                cardData={"mostChallengesAccepted"} />
            </Col>
            {/* Increase Rating by 2 points */}
            {/* <Col className='col-12 col-xxl-6'>
              <UserInfoCard
                title="Increase Rating by 2 points"
                endpoint={""}
                btnNavigat={"#"}
                cardData={"increaseRatingPoints"} />
            </Col> */}
          </Row>


        </Container>

      </div>
    </>
  )
}

export default UserDasboard