import { toast } from 'react-toastify';

export const toastSuccess = (arg) =>{
   
   return  toast.success(`${arg} Successfully`,{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    zIndex: 9999,
    })};
export const toastError = (arg,id) => toast.error(`${typeof arg === "undefined"?"Somthing went wrong":arg}`,
{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    },{
        toastId: id,
    });
