import React from "react";
 
 
const PaginationButton = ({currentPage=0,visiblePageButtons=0, totalPages=0, setCurrentPage}) => {
   const pageButtons = [];
    
   let startPage = Math.max(1, currentPage - 1); // Ensure start page is at least 1
   let endPage = Math.min(startPage + visiblePageButtons - 1, totalPages); // Limit end page to the total number of pages
 
   // Adjust start and end pages if the total pages are less than the visiblePageButtons
   if (endPage - startPage + 1 < visiblePageButtons) {
     startPage = Math.max(1, totalPages - visiblePageButtons + 1);
     endPage = totalPages;
   }
 
   // Previous button logic
   if (startPage >= 1) {
     pageButtons.push(
       <button
         key="prev"
         onClick={() => (startPage!==1?setCurrentPage(currentPage - 1):startPage)}
         className="me-2 bg-transparent px-0 px-sm-auto border-0 text-muted fs-14  cursor-pointer listing-btn"
       >
         Previous
       </button>
     );
   }
 
   for (let i = startPage; i <= endPage; i++) {
     pageButtons.push(
       <button
         key={i}
         onClick={() => setCurrentPage(i)}
         className={`me-2 border-0 fs-14  cursor-pointer px-2  pagination_btn ${currentPage===i?"active-page":""}`}
       >
         {i}
       </button>
     );
   }
 
   // Next button logic
   if (endPage < totalPages) {
     pageButtons.push(
       <button
         key="next"
         onClick={() => setCurrentPage(currentPage + 1)}
         className="border-0 bg-transparent text-muted fs-14  cursor-pointer listing-btn"
       >
         Next
       </button>
     );
   }
 
   return pageButtons;
 };
 export default PaginationButton;