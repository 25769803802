import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import HtmlHead from '../html-head/HtmlHead';
import queryParamBuilder from 'api/queryParamBuilder';
import api from 'api';
import Endpoints from 'api/endpoints';
import { toastError } from './toastify';
import UserMatchListingTable from './UserMatchListingTable';
import Matches from 'components/Matches';
import MyTournamentCard from 'pages/Users/components/MyTournamentCard';
import ChallengeCard from 'components/ChallengeCard';

const challengesStatusOptions = [
  "all" ,"upcoming"  ,"received" ,"verify" ,"post" ,"homeFeed" ,"post" ,"delete"
]
const tournamentStatusOptions = [
  "all",
  "upcoming" ,
  "applicationOpen",
  "start", 
  "cancel",
   
   "deleted"
]



const MatchesListing=()=> {

  const param=useParams();
  const {pathname} = useLocation()
  const lastPoint=pathname.split("/")[1]
  const userId = param.userId;

  const title =  `My ${lastPoint[0].toUpperCase() + lastPoint.slice(1)}`;
  const description = `My ${lastPoint[0].toUpperCase() + lastPoint.slice(1)} page`;
  const defaultStatus=lastPoint==="challenge"?challengesStatusOptions[0]:tournamentStatusOptions[0]
  const statusOptions=lastPoint==="challenge"?challengesStatusOptions:tournamentStatusOptions
//   data states
const [isLoading,setIsLoading]=useState(false)
const [currentPage,setCurrentPage] =useState(1);
const [totalPages,setTotalPages] = useState(null);
const [filters,setFilters]=useState({
  status:defaultStatus,
  searchValue:""
})

const [fetchedData,setFetchedData] = useState([]);
// const [searchValue,setSearchValue] = useState("")





const fetchDatahandler=async (userId)=>{

    const getAllChallenges= queryParamBuilder({
        endpoint:Endpoints[lastPoint],
        userId:userId,
        limit:10,
        page:currentPage,
        search:filters.searchValue,
        status:filters.status
      })
      try{
        setIsLoading(true);
        const res =   await api.get(getAllChallenges)
        setTotalPages(res?.data?.totalPages)
        setFetchedData(res?.data?.data)
        setIsLoading(false)
      }catch(err) {
        toastError()
        
        setIsLoading(false)
      }
  
}

// const searchValueHandler =(e)=>{
//     e.preventDefault();
//     setSearchValue(e.target.value);
// }

const clearFilters=()=>{
  setFilters({
    status:defaultStatus,
    searchValue:""
  })
}


useEffect(()=>{
    fetchDatahandler(userId)
},[userId,currentPage,filters.searchValue,filters.status])



//   table data
 
const tData=fetchedData?.map((el,ind)=>lastPoint==="challenge"?<ChallengeCard data={el} key={ind} />:<MyTournamentCard data={el} key={ind} />)


  const rows = useMemo(() => {
    return tData?.map((el) => ({
      card: el,
    }));
    return [];
  }, [tData]);

//   columns
  const columns = useMemo(
    () => [
      {
        Header: 'Card',
        accessor: 'card',
      },
    ],
    []
  );

  // tables top bar
  const topBar = [
    {
      textContent: 'Court filter',
      img: '/img/tournaments/Drop Down.svg',
    },
    {
      textContent: 'Location filter',
      img: '/img/tournaments/Drop Down.svg',
    },
    {
      textContent: 'Status filter',
      img: '/img/tournaments/Drop Down.svg',
    },
  ];

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <div className="fw-bold font-size-24 mb-3 text-black">{title}</div>
          <div className="mb-4">
            <UserMatchListingTable
              columns={columns}
              data={rows}
              isGlobalFilter
              isAddInvoice
              isAddTableBorderStrap
            //   handleAdd={handleAdd}
              tableData={tData}
              topBar={topBar}
              createShow={false}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              //  onSearch={searchValueHandler} 
               isLoading={isLoading}
               filters={filters}
               setFilters={setFilters}
               statusOptions={statusOptions}
               clearFilters={clearFilters}
               showLocation={false}

              // addBtn="FAQ"
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default MatchesListing;
