import { Link } from 'react-router-dom';

import { matchStatus,statusText } from 'utils/matchStatus';
import TableContainer from 'components/Common/TableContainer';
import tableDataGenerater from './tableDataGenerater';
import TableSkeleton from 'components/Common/TableSekeloton';
import api from 'api';
import reportedUserDataGenerator from './reportedUserData';
import { Card, CardBody, CardTitle, Row, UncontrolledTooltip } from 'reactstrap';
import profile from 'assets/images/default-img.svg'



function ReportedUser({ cardData,title,data=[],btnNavigat,isLoading }) {

  const reptUsersRows=data?.map((el,ind)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?.blockedUsersDetails?._id}`} className="d-flex flex-nowrap  align-items-center text-center">
          <img src={el?.blockedUsersDetails?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize  ">{el?.blockedUsersDetails?.firstName||"-"} {el?.blockedUsersDetails?.lastName||"-"}</span>
        </Link>
      ),
      email:el?.blockedUsersDetails?.email,
      reportedBy:<Link to={`/user-profile/${el?.blockedUsersDetails?._id}`} className=' font-size-14 text-blue fw-bold '>{`${el?.count} users`}</Link>,
      location:<p className='text-truncate' style={{maxWidth:"120px"}}>{el?.blockedUsersDetails?.additionalInfo?.location?.description}</p>,
    
      status:<div className='d-flex flex-nowrap align-items-center'>
        <span className={`${matchStatus("blocked")} match-status`}>{statusText("blocked")}</span>
        
      </div>  ,
      rating:` S:${el?.blockedUsersDetails?.additionalInfo?.matchDetails?.single?.ratings||0}/D:${el?.blockedUsersDetails?.additionalInfo?.matchDetails?.double?.ratings||0}`,
      //  action: (
      //   <div className="d-flex gap-3 users">
      //     <ul className="list-inline font-size-20 contact-links mb-0">
      //     <li className="list-inline-item">
      //     <Link
      //       to={`/user-profile/${el?.blockedUsersDetails?._id}`}
      //       className="text-primary"
      //       onClick={() => {
      //       //   const users = cellProps.row.original
      //       //   handleUserClick(users)
      //       }}
      //     >
      //       <i className="uil uil-eye font-size-18" id={`edittooltip_view_${el?.blockedUsersDetails?._id}`} />
      //       <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${el?.blockedUsersDetails?._id}`}>
      //         view
      //       </UncontrolledTooltip>
      //     </Link>
      //   </li>
      //   <li className="list-inline-item">
      //     <Link
      //       to="#"
      //       className="text-danger"
      //       onClick={() => {
      //       //   const users = cellProps.row.original
      //       // setDeleteModal(true);
      //       // setUser(el?._id)
      //       }}
      //     >
      //       <i
      //         className="uil uil-trash-alt font-size-18"
      //         id={`deletetooltip_del_${el?.blockedUsersDetails?._id}`}
      //       />
      //       <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?.blockedUsersDetails?._id}`} >
      //         Delete
      //       </UncontrolledTooltip>
      //     </Link>
      //   </li>
            
      //     </ul>
      //   </div>
      // )
    }))
const reptUserCols=[
    // {
    //   Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //   accessor: 'checkbox',
    // },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
      hasDescription: true,
    },
    {
      Header:'Location',
      accessor: 'location',
      hasDescription: true,
    },
    {
      Header: 'Reported by',
      accessor: 'reportedBy',
      hasDescription: true,
    },
   
    {
      Header: 'Status',
      accessor: 'status',
      hasDescription: true,
    },
    
    {
      Header: 'Rating',
      accessor: 'rating',
      hasDescription: true,
    },
    // {
    //   Header: 'Action',
    //   accessor: 'action',
      
    // },
  ]
 

   
  return (
    <Card className="section-card ">
      <CardBody className='pb-2 '>
        <CardTitle className="h4">
          <div className="d-flex justify-content-between">
            <span className="text-black fw-bold font-size-16">{title}</span>
            <Link to={`/users-listing/report`} className="text-blue font-size-12 fw-normal">View all</Link>
          </div>
        </CardTitle>
        <Row className="py-1  overflow-auto" style={{height:"300px"}}>
        {
          !isLoading?(<TableContainer
            columns={reptUserCols}
            // rows={rows}
            data={reptUsersRows}
            isGlobalFilter={true}
            isAddUsers={true}
            isAddTableBorderStrap={true}
            // handleUserClicks={handleUserClicks}
            addPagination={false}
            // topBar={topBar}
          />):(<TableSkeleton entries={5}/>)
        }
        </Row>
       
      </CardBody>
    </Card>
  );
}

export default ReportedUser;