import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {

  const role=localStorage.getItem('userRole')

  if (!localStorage.getItem("accessToken")&&!(role==="admin"||typeof role==="undefined")) {
    return <Navigate to={{ pathname: "/login" }} />;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
