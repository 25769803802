import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

function RefundModal({ show, handleClose, handleCloseRefund }) {
  return (
    <>
      {show && <div className="blurred-background" />}
      <Modal isOpen={show} toggle={handleClose} backdrop={false}>
        <ModalHeader className="p-0" toggle={handleClose} />
        <h2 className="fw-bold font-size-16 text-black mx-auto">Refund to Participants</h2>
        <ModalBody className="font-size-12 text-muted text-center">
          <div>Are you sure? You want to refund the amount to all the tournament participants participants.</div>
          <Button className="primary-gradient text-white tournament-modal-btn mt-3" onClick={handleCloseRefund}>
            Refund Now
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
}

export default RefundModal;
