import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyAGJAn7RDdypb9iub6gjyIEGB14vEmfMT8',

  authDomain: 'point-390420.firebaseapp.com',

  databaseURL: 'https://point-390420-default-rtdb.firebaseio.com',

  projectId: 'point-390420',

  storageBucket: 'point-390420.appspot.com',

  messagingSenderId: '754167089635',

  appId: '1:754167089635:web:1dec44546d31d476ecf8d6',

  measurementId: 'G-J1MYTK27Q3',
};
console.log(firebaseConfig);
initializeApp(firebaseConfig);

//...

const messaging = getMessaging();

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });

export const getFcmToken = async () => {
  return await getToken(messaging, {vapidKey: process.env.REACT_APP_VAP_ID})
    .then(currentToken => {
      if (currentToken) {
        localStorage.setItem('fcmToken', currentToken);
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
    });
};
