import api from "api";
import Endpoints from "api/endpoints";
import HtmlHead from "components/html-head/HtmlHead";
import MiniWidget from "components/mini-widget";
import React, { useEffect, useMemo, useState } from "react";
import { toastError } from "components/Common/toastify";
import { Container, Row, Col } from "reactstrap";
import ByCities from "components/ByCities";
import TournamentCompletion from "components/TournamentCompletion";
import PieChart from "components/PieChart";
import PolarChart from "components/PolarChart";
import ChallengesWraper from "./components/ChallengesWraper";

const ChallengesDashboard = () => {
  const title = "Challenges";
  const description = "Challenges Page";

  const [dataState, setDataState] = useState({
    challengeStats: {},
    challengesFormat: {},
    challengesStatus: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      const controller = new AbortController();

      const requests = Object.keys(dataState);

      try {
        let n = 0;

        const apiHitHandler = async (x) => {
          if (n < requests.length) {
            const signal = controller.signal;

            try {
              const res = await api.get(`${Endpoints[requests[x]]}`, {
                signal,
              });

              if (res?.status === 200) {
                const keyToUpdate = Object.keys(dataState)[x];

                // Check if the component is still mounted before updating the state
                if (!controller.signal.aborted) {
                  setDataState((prevDataState) => ({
                    ...prevDataState,
                    [keyToUpdate]: res?.data?.data,
                  }));
                }

                // Recursive call for the next request
                await apiHitHandler((n += 1));
              }
            } catch (err) {
              if (err.name === "AbortError") {
                console.log("Request aborted due to component unmount");
              } 
            }
          }
        };

        // Initial call to apiHitHandler
        await apiHitHandler(n);
      } catch (err) {
        console.error("Error during data fetching:", err);
      } finally {
        controller.abort(); // Abort any ongoing requests when component unmounts
      }
    };

    fetchData();

    // Cleanup function to cancel ongoing requests when the component unmounts
    return () => {
      // Abort any ongoing requests when the component unmounts
      // This is already handled in the `finally` block, so no need to repeat it here
    };
  }, []);

  const statsReport = useMemo(() => {
    return Object.keys(dataState?.challengeStats).map((key) => {
      return {
        title: key,
        value: dataState?.challengeStats[key],
      };
    });
  }, [dataState?.challengeStats]);

  const challengesCompletionData = useMemo(() => {
    const totalChallenges = dataState?.challengesFormat?.challenge?.reduce(
      (acc, cur) => (acc += cur?.count),
      0
    );
    const series = dataState?.challengesFormat?.challenge?.map((el) =>
      Math.round((el?.count / totalChallenges) * 100)
    );

    return {
      totalChallenges: totalChallenges,
      series: series,
    };
  }, [dataState?.challengesFormat]);
  const socialPlatforms = [
    {
      text: "Facebook",
      color: "#2171CC",
      series: 55,
    },
    {
      text: "Twitter",
      color: "#B8DD00",
      series: 44,
    },
    {
      text: "Instagram",
      color: "#7AB500",
      series: 67,
    },
    {
      text: "TikTok",
      color: "#4B7EB9",
      series: 75,
    },
  ];

  const challengesStatusData = useMemo(() => {
    const colors = [
      "#2171CC",
      "#B8DD00",
      "#7AB500",
      "#4B7EB9",
      "#2171CC",
      "#B8DD00",
      "#7AB500",
      "#4B7EB9",
    ];
    // console.log(dataState?.challengesStatus?.challenge)
    const total = dataState?.challengesStatus?.challenge?.reduce(
      (acc, cur) => (acc += cur?.count),
      0
    );
   
    return dataState?.challengesStatus?.challenge?.map((el, ind) => {
      return {
        text: el?.status,
        color: colors[ind],
        series: Math.round((el?.count / total) * 100),
      };
    });
  }, [dataState?.challengesStatus]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-content">
        <Container fluid>
          <div className="fw-bold font-size-24 mb-3 text-black  ">{title}</div>
          <Row className="row-cols-xl-4">
            <MiniWidget reports={statsReport.length===0?[[],[],[],[]]:statsReport} />
          </Row>
          <Row>
            <Col className="col-12 col-lg-4">
              <ByCities
                cityText={"Challenges"}
                endpoint={"getchallengebycity"}
                link={"/challenges-listings"}
              />
            </Col>
            <Col className="col-12 col-lg-4">
              <TournamentCompletion
                title={"Challenges Completion"}
                series={challengesCompletionData.series}
                totalPlayed={challengesCompletionData.totalChallenges}
                labels={["Double", "Mix Double", "Double"]}
              />
            </Col>
            <Col className="col-12 col-lg-4">
              <PieChart
                title="Challenges Status"
                data={challengesStatusData || []}
              />
              {/* <PolarChart title="Challenges" data={challengesStatusData}/> */}
            </Col>
          </Row>
          <Row>
            <ChallengesWraper />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ChallengesDashboard;
