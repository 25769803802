import React, {useState} from 'react';
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Row,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import TableContainer from './TableContainer';
import {Link} from 'react-router-dom';
import DatePicker from './DatePicker';
import MultiRangeSlider, {ChangeResult} from 'multi-range-slider-react';
import PointIcon from 'assets/icons/PointIcon';
import TableSkeleton from './TableSekeloton';
import debounceHandler from 'utils/debounceHandler';
import AutoComplete from './AutoCompleteLocation';
import {elementClosest} from '@fullcalendar/core/internal';
import SearchComponent from './SearchComponent';

const Listing = ({
  isLoading,
  searchBar = true,
  topBtnLink,
  topBtn="",
  tabNames = [],
  columns,
  data,
  totalPages,
  activeTab,
  setActiveTab,
  setCurrentPage,
  currentPage,
  topBar = [],
  onSearch,
  filters,
  setFilters,
  clearFilters,
  rankingFilter = false,
  courtsFilter = false,
  setRankFilterValues,
  rankFilterValues,
  courtFilterValues,
  setCourtFilterValues,
}) => {
  const [rankFilter, setRankFilter] = useState(false);
  const [courtFilter, setCourtFilter] = useState(false);
  const [selectedValueGender, setSelectedValueGender] = useState('');
  const [searchType, setSearchType] = useState('user');
  // User Ranking Filters
  const handleSelectChangeGender = val => {
    setRankFilterValues(prev => ({...prev, gender: val}));
  };
  const handleSelectChangeRange = val => {
    setRankFilterValues(prev => ({...prev, range: val}));
  };
  const handleSelectChangeFormat = val => {
    setRankFilterValues(prev => ({...prev, type: val}));
  };
  const handleSelectChangeAge = val => {
    const [min = '', max = ''] = val?.split('-');

    setRankFilterValues(prev => ({...prev, minAge: min, maxAge: max}));
  };
  const handleSelectChangeRating = val => {
    const [min = '', max = ''] = val?.split('-');

    setRankFilterValues(prev => ({...prev, minRating: min, maxRating: max}));
  };

  // Courts Filters
  const handleCourtCost = val => {
    setCourtFilterValues(prev => ({...prev, cost: val}));
  };
  const handleCourtMembership = val => {
    setCourtFilterValues(prev => ({...prev, membership: val}));
  };
  const handleCourtIndoor = val => {
    setCourtFilterValues(prev => ({...prev, indoor: val}));
  };
  return  (
      <>
        {tabNames.length > 0 && (
          <ul className="list-unstyled listing-menu d-flex  flex-wrap gap-2  mb-4 ">
            {tabNames.map((tabName, ind) => (
              <li
                key={tabName.title}
                className={`${
                  ind > 0 ? '' : ''
                } pb-1 px-2 text-muted cursor-pointer text-nowrap  ${
                  activeTab.title === tabName.title ? 'active' : ''
                }`}
                onClick={() => setActiveTab(tabName)}>
                {tabName.title}
              </li>
            ))}
          </ul>
        )}
  
        <Card className=" overflow-hidden ">
          <CardBody>
            {/* List actions container */}
            <Row className="g-2 mb-3 justify-content-between align-items-center">
            {topBtn && ( <Col className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2  col-xxl-1">
             
                  <Link
                    to={topBtnLink}
                    className="primary-gradient text-white text-capitalize font-size-14 px-2 py-2">
                    {topBtn}
                  </Link>
                
              </Col>)}
              <Col className={`col-12 col-sm-6 col-md-4 col-lg-4 col-xl-${topBtn!==""?"5":"2"}  col-xxl-${topBtn!==""?"4":"5"}   text-center`}>
             
                <SearchComponent setFilters={setFilters} filters={filters} />
                
                
              </Col>
              <Col className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-5   col-xxl-7 text-end">
                {rankingFilter && (
                  <button
                    // to={topBtnLink}
                    onClick={() => {
                      setRankFilter(!rankFilter);
                      clearFilters();
                    }}
                    className={`${
                      rankFilter
                        ? 'outlined-button-active text-white '
                        : 'outlined-button text-blue '
                    } mx-1 text-capitalize font-size-14  p-2 px-md-3 py-md-2`}>
                    Filter by Ranking
                  </button>
                )}
                {courtsFilter && (
                  <Link
                    onClick={() => {
                      setCourtFilter(!courtFilter);
                      clearFilters();
                    }}
                    className={`${
                      courtFilter
                        ? 'outlined-button-active text-white '
                        : 'outlined-button text-blue '
                    } mx-2  text-capitalize font-size-14 p-2 px-md-3 py-md-2`}>
                    Filter by Court Specifications
                  </Link>
                )}
                <button
                  onClick={() => {
                    clearFilters();
                    setRankFilter(false);
                    setCourtFilter(!courtsFilter);
                  }}
                  className="outlined-button-active text-white text-capitalize font-size-14 p-2 px-md-3 py-md-2">
                  Clear filters
                </button>
              </Col>
            </Row>
            {rankingFilter && rankFilter && (
              <Row className="g-2 justify-content-end w-100 mb-3">
                <Col className="col-6 col-sm-4 col-md-3 col-xl-2 col-xxl-1">
                  <select
                    value={rankFilterValues?.gender}
                    onChange={e =>
                      handleSelectChangeGender(e.target.value, 'gender')
                    }
                    class="form-select"
                    aria-label="Default select example">
                    <option selected value="male">
                      Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </Col>
  
                <Col className="col-6 col-sm-4 col-md-3 col-xl-2 col-xxl-1">
                  <select
                    value={
                      rankFilterValues?.minAge + '-' + rankFilterValues?.maxAge
                    }
                    onChange={e =>
                      handleSelectChangeAge(e.target.value, 'minAge', 'maxAge')
                    }
                    class="form-select"
                    aria-label="Default select example">
                    <option selected value="">
                      Age
                    </option>
                    <option value="0-15">0 - 15</option>
                    <option value="16-20">16 - 20</option>
                    <option value="21-25">21 - 25</option>
                    <option value="26-30">26 - 30</option>
                    <option value="31-40">31 - 40</option>
                    <option value="41-55">41 - 55</option>
                    <option value="55-100">55+</option>
                  </select>
                </Col>
                <Col className="col-6 col-sm-4 col-md-3 col-xl-2 col-xxl-1">
                  <select
                    value={
                      rankFilterValues?.minRating +
                      '-' +
                      rankFilterValues?.maxRating
                    }
                    onChange={e =>
                      handleSelectChangeRating(
                        e.target.value,
                        'minRating',
                        'maxRating',
                      )
                    }
                    class="form-select"
                    aria-label="Default select example">
                    <option selected value="">
                      Rating
                    </option>
                    <option value="1.0-1.9">1.0 - 1.9</option>
                    <option value="2.0-2.9">2.0 - 2.9</option>
                    <option value="3.0-3.9">3.0 - 3.9</option>
                    <option value="4.0-4.9">4.0 - 4.9</option>
                    <option value="5.0-7.0">5.0+</option>
                  </select>
                </Col>
                <Col className="col-6 col-sm-4 col-md-3 col-xl-2 col-xxl-1">
                  <select
                    value={rankFilterValues?.type}
                    onChange={e => handleSelectChangeFormat(e.target.value)}
                    class="form-select"
                    aria-label="Default select example">
                    <option selected value="">
                      Format{' '}
                    </option>
                    <option value="single">Singles</option>
                    <option value="double">Doubles</option>
                    <option value="mixDouble">Mixed Doubles</option>
                  </select>
                </Col>
                <Col className="col-6 col-sm-4 col-md-3 col-xl-2 col-xxl-1">
                  <select
                    value={rankFilterValues?.range}
                    onChange={e => handleSelectChangeRange(e.target.value)}
                    class="form-select"
                    aria-label="Default select example">
                    <option selected value="">
                      Range
                    </option>
                    <option value="100">100 Miles</option>
                    <option value="200">200 Miles</option>
                    <option value="500">500 Miles</option>
                    <option value="1000">1000 Miles</option>
                  </select>
                </Col>
              </Row>
            )}
            {courtsFilter && courtFilter && (
              <Row className="justify-content-end w-100 mb-3">
                <Col className="col-6 col-sm-4 col-md-3 col-xl-2">
                  <select
                    value={courtFilterValues?.cost}
                    onChange={e => handleCourtCost(e.target.value, 'gender')}
                    class="form-select"
                    aria-label="Default select example">
                    <option selected value="">
                      Cost
                    </option>
                    <option value="paid">Paid</option>
                    <option value="free">Free</option>
                  </select>
                </Col>
                <Col className="col-6 col-sm-4 col-md-3 col-xl-2 ">
                  <select
                    value={courtFilterValues?.indoor}
                    onChange={e => handleCourtIndoor(e.target.value)}
                    class="form-select"
                    aria-label="Default select example">
                    <option selected value="">
                      Environment{' '}
                    </option>
                    <option value="true">Indoor</option>
                    <option value="false">Outdoor</option>
                  </select>
                </Col>
                <Col className="col-6 col-sm-4 col-md-3 col-xl-2 ">
                  <select
                    value={courtFilterValues?.membership}
                    onChange={e => handleCourtMembership(e.target.value)}
                    class="form-select"
                    aria-label="Default select example">
                    <option selected value="">
                      Membership{' '}
                    </option>
                    <option value="True">Yes</option>
                    <option value="False">No</option>
                  </select>
                </Col>
              </Row>
            )}
  
            {isLoading ? (
              <TableSkeleton entries={10} />
            ) : (
              <>
                <TableContainer
                  columns={columns}
                  data={data}
                  isGlobalFilter={true}
                  isAddUsers={true}
                  isAddTableBorderStrap={true}
                  // handleUserClicks={handleUserClicks}
                  isLoading={isLoading}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  addPagination={true}
                  totalPages={totalPages}
                />
              </>
            )}
          </CardBody>
        </Card>
      </>
    );
  
  
};

export default Listing;
