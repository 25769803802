import React,{useMemo,useState,useEffect} from 'react'
// import { getActiveElement } from '@testing-library/user-event/dist/types/utils'
import api from 'api'
import Endpoints from 'api/endpoints'
import Loader from 'components/Common/Loader'
import TableContainer from 'components/Common/TableContainer'
import { toastError } from 'components/Common/toastify'

import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, Input, Row, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment/moment'
import PointIcon from 'assets/icons/PointIcon'
import DeleteModal from 'components/Common/DeleteModal'
import { toastSuccess } from 'components/Common/toastify'
import TableSkeleton from 'components/Common/TableSekeloton'




const Courts = () => {

    const [isLoading,setIsLoading]=useState(false);
    const [courts,setCourts]=useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [courtId, setCourtId] = useState(null)

    const getAllCourts=async()=>{

        try{
            setIsLoading(true)
            const rep=await api.get(`${Endpoints.court}?page=1&limit=10&search=`)
            
            setCourts(rep?.data?.data)
            setIsLoading(false)
        }catch(err){
            toastError()
            setIsLoading(false);
        }
       
    }

    useEffect(()=>{
        getAllCourts()
    },[])

    const onClickDelete = async courtId => {

        try {
          const res = await api.delete(`${Endpoints.court}/${courtId}`)
    
          if(res.status ===200){
            toastSuccess("Court Deleted")
            getAllCourts()
          }
          
        } catch (err) {
          toastError(err?.response?.data?.message)
    
        }
        setDeleteModal(false)
      }

    const tData = useMemo(()=>{
        if(courts?.length>0){
            return courts?.map((el, ind) => (
                {
                    // checkbox: (
                    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
                    // ),
                    name: (
                        <Link to={`/court-details/${el?._id}`}>
                        <p className=' text-truncate ' style={{maxWidth:"100px"}}>{el?.name}</p>
                        </Link>
                    ),
                    //   name: "John Doe",
                    location:( <p className=' text-truncate ' style={{maxWidth:"100px"}}>{el?.location?.description}</p>),
        
                    matchesPlayed: el?.matchCount,
                    businessHours:(<p>{(el?.startTime&&el?.endTime)?"07:00 am - 10:00 pm":"N/A"}</p>),
                    addedOn: `${moment(el?.updatedAt).format('MMMM DD, YYYY') || "--"}`,
        
                    action: (
                        <div className="d-flex gap-3 users">
                            <ul className="list-inline font-size-20 contact-links mb-0">
                            <li className="list-inline-item">
                                    <Link
                                        to={`/court-details/${el?._id}`}
                                        className="text-primary"
                                        onClick={() => {
                                            //   const users = cellProps.row.original
                                            //   onClickDelete(users)
                                        }}
                                    >
                                        <i
                                            className="uil uil-eye font-size-18"
                                            id={`delettooltip_${el?._id}`}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={`delettooltip_${el?._id}`} >
                                            view
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link
                                        to={`/edit-court/${el?._id}`}
                                        className="text-primary"
                                        onClick={() => {
                                            //   const users = cellProps.row.original
                                            //   handleUserClick(users)
                                        }}
                                    >
                                     <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_${el?._id}`}/>
                                        <UncontrolledTooltip placement="bottom" target={`edittooltip_${el?._id}`}>
                                            Edit
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                               
                                <li className="list-inline-item">
                                    <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() => {
                                    
                                            setDeleteModal(true);
                                            setCourtId(el?._id)
                                          }}
                                    >
                                        <i
                                            className="uil uil-trash-alt font-size-18 text-danger "
                                            id={`deletetooltip_del_${el?._id}`}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?._id}`} >
                                            Delete
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    )
        
                }
            ))
        }
        else {
            return []
        }
    },[courts])






    const rows = useMemo(() => (tData.map(el => (
        {
            // checkbox: el.checkbox,
            name: el.name,

            location: el.location,

            matchesPlayed: el.matchesPlayed,
            addedOn:el.addedOn,
            businessHours:el.businessHours,


            action: el.action,
            // id: el._id,
        }
    ))), [tData])

    const cols = useMemo(() => [
        // {
        //         Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
        //         accessor: 'checkbox',
        //     },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Location',
                accessor: 'location',
                hasDescription: true,
            },
            {
                Header: 'Matches Played',
                accessor: 'matchesPlayed',
                hasDescription: true,
            },
            {
                Header: 'Business Hours',
                accessor: 'businessHours',
                hasDescription: true,
            },
            {
                Header: 'Added on',
                accessor: 'addedOn',
                hasDescription: true,
            },



            {
                Header: 'Action',
                accessor: 'action',

            },
        ],
        [rows]
    );

    return (
        <>
          <DeleteModal
        show={deleteModal}
        onDeleteClick={() => onClickDelete(courtId)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card className="section-card ">
            <CardBody className='pb-2 '>
                <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                        <span className="text-black fw-bold font-size-16">Courts</span>
                        <Link to={"/courts-listing"} className="text-blue font-size-12 fw-normal">View All</Link>
                    </div>
                </CardTitle>
                <Row className="py-1  overflow-auto" style={{ height: "300px" }}>
                    {
                        isLoading?(<TableSkeleton entries={5}/>):(
                            <>
                            <TableContainer
                        columns={cols}
                        // rows={rows}
                        data={rows}
                        isGlobalFilter={true}
                        isAddUsers={true}
                        isAddTableBorderStrap={true}
                        // handleUserClicks={handleUserClicks}
                        addPagination={false}
                    // topBar={topBar}
                    />
                            </>
                        )
                    }
                </Row>

            </CardBody>
        </Card>
        </>
        
    )
}

export default Courts