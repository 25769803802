// import avt from "assets/images/users/avatar-1.jpg";
// import avt2 from "assets/images/users/avatar-2.jpg";
// import avt3 from "assets/images/users/avatar-3.jpg";
// import avt4 from "assets/images/users/avatar-4.jpg";
// import avt5 from "assets/images/users/avatar-5.jpg";
// import avt6 from "assets/images/users/avatar-6.jpg";
// import avt7 from "assets/images/users/avatar-7.jpg";
import { Link } from "react-router-dom";
import { Input, UncontrolledTooltip } from "reactstrap";
import profile from "assets/images/default-img.svg"
import { matchStatus,statusText } from 'utils/matchStatus';


// dummy data
// const users=["John","Ella","Joanna Avinas","Cris ","Jonas","Maxmillian","Alex jhonsan","Nekodemas","Warner","Smith"]
// const avatars=[avt6,avt7,avt,avt2,avt3,avt4,avt5,avt,avt6,avt7,avt,avt2,avt3,avt4,avt5,avt]
// reported users
// const reptUsersRows=Array.from({length:10}).map((_,ind)=>({
//     id:ind,
//     img:avatars[ind],
//     name:users[ind],
//     email:"johndoe@gmail.com",
//     reportedBy:`${ind} users`,
//     reason:"Misbehaving",
//     status:"blocked",
//     activation:"Activate",
//     // date:"Aug 23, 2023",
//     // time:"05:30 pm",
//     // court:"Court Jersey 1",
//   })).map((el,ind)=>({
//     checkbox: (
//         <input type="checkbox" className="form-check-input" id="customCheck1" />
//       ),
//       name: (
//         <div className="d-flex flex-nowrap  align-items-center text-center">
//           <img src={el.img} className="avatar-xs rounded-circle me-2" />
//           <span className="ms-md-2 text-capitalize  text-nowrap">{el.name}</span>
//         </div>
//       ),
//       email:el.email,
//       reportedBy:<span className='text-primary font-size-14'>{el.reportedBy}</span>,
//       reason:el.reason,
//       status:<div className='d-flex flex-nowrap align-items-center'>
//         <span className={`${matchStatus(el.status)} match-status`}>{statusText(el.status)}</span>
//         <span className='cl-blue fw-medium font-size-12 ms-2'>{el.activation}</span>
//       </div>  ,
//        action: (
//         <div className="d-flex gap-3 users">
//           <ul className="list-inline font-size-20 contact-links mb-0">
//           <li className="list-inline-item">
//           <Link
//             to={`/user-profile/${el?._id}`}
//             className="text-primary"
//             onClick={() => {
//             //   const users = cellProps.row.original
//             //   handleUserClick(users)
//             }}
//           >
//             <i className="uil uil-eye font-size-18" id={`edittooltip_view_${ind}`} />
//             <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${ind}`}>
//               view
//             </UncontrolledTooltip>
//           </Link>
//         </li>
//         <li className="list-inline-item">
//           <Link
//             to="#"
//             className="text-danger"
//             onClick={() => {
//             //   const users = cellProps.row.original
//             // setDeleteModal(true);
//             // setUser(el?._id)
//             }}
//           >
//             <i
//               className="uil uil-trash-alt font-size-18"
//               id={`deletetooltip_del_${ind}`}
//             />
//             <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${ind}`} >
//               Delete
//             </UncontrolledTooltip>
//           </Link>
//         </li>
            
//           </ul>
//         </div>
//       )
//     }))
// const reptUserCols=[
//     {
//       Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
//       accessor: 'checkbox',
//     },
//     {
//       Header: 'Name',
//       accessor: 'name',
//     },
//     {
//       Header: 'Email',
//       accessor: 'email',
//       hasDescription: true,
//     },
//     {
//       Header: 'Reported by',
//       accessor: 'reportedBy',
//       hasDescription: true,
//     },
//     {
//       Header: 'Reason',
//       accessor: 'reason',
//       hasDescription: true,
//     },
//     {
//       Header: 'Status',
//       accessor: 'status',
//       hasDescription: true,
//     },
    
    
//     {
//       Header: 'Action',
//       accessor: 'action',
      
//     },
//   ]
 
// not verify scores
// const notVerifyUsersRows=Array.from({length:6}).map((_,ind)=>({
//     id:ind,
//     name:users[ind],
//     img:avatars[ind],
//     status:`${ind%2!==0 ?"win":"lose"}`
// })).map((el)=>({
//     checkbox: (
//         <input type="checkbox" className="form-check-input" id="customCheck1" />
//       ),
//     name: (
//         <div className="d-flex flex-nowrap align-items-center text-center">
//           <img src={el.img} className="avatar-xs rounded-circle me-2" />
//           <span className="ms-md-2">{el.name}</span>
//         </div>
//       ),
//      status: <span className={`${matchStatus(el.status)} match-status d-inline-block  ms-3`}>{statusText(el.status)}</span>
   
   
// }))
// const notVerifiedScoreCols=[
//     {
//         Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
//         accessor: 'checkbox',
//       },
//       {
//         Header:"Name",
//         accessor: 'name',
//       },
//       {
//         Header:"Match Status",
//         accessor: 'status',
//       }
// ]

// top players


const topPlayersData=(data)=>{
  const topPlayersRows =data?.map((el)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize  ">{el?.firstName||"-"}      {el?.lastName||"-"}</span>
        </Link>
      ),
      rank:(<span>S:{el?.additionalInfo?.matchDetails?.single?.rating}/D:{el?.additionalInfo?.matchDetails?.double?.rating}</span>)
}))

const topPlayersCols=[
    // {
    //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //     accessor: 'checkbox',
    //   },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header:'Rank',
        accessor:'rank'
      }
]
return {
  cols:topPlayersCols,
  rows:topPlayersRows
}
}
// most matches played

const mostMatchesPlayedData=(data)=>{
  const mostPlayedMatchesRows =data?.map((el)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-1 text-capitalize ">{el?.firstName||"-"} {el?.lastName||"="}</span>
        </Link>
      ),
      matchPlayed:<span className=' text-center w-50 d-inline-block'>{el?.totalMatch||"-"}</span>
}))
const mostPlayedMatchesCols=[
    // {
    //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //     accessor: 'checkbox',
    //   },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header:'Match Played',
        accessor:'matchPlayed'
      }
]

  return {
    rows:mostPlayedMatchesRows,
    cols:mostPlayedMatchesCols
  }
}
// Most Matches Won In a Row
// const matchesWonInRows = Array.from({length:10}).map((_,ind)=>({
//     id:ind,
//     img:avatars[ind],
//     name:users[ind],
//     playedInRow:ind>0?ind*=10:23,
//     wonInRow:ind>0?ind*=5:3,
//     rankIcreased:ind>3?(ind-+1.3):(ind+1.3),
// })).map(el=>(
//     {
//         checkbox: (
//             <input type="checkbox" className="form-check-input" id="customCheck1" />
//           ),
//           name: (
//             <div className="d-flex flex-nowrap align-items-center text-center">
//               <img src={el.img} className="avatar-xs rounded-circle me-2" />
//               <span className="ms-md-2 text-capitalize  text-nowrap">{el.name}</span>
//             </div>
//           ),
//           playedInRow:el.playedInRow,
//           wonInRow:el.wonInRow,
//           rankIcreased:el.rankIcreased,
//     }
// ))
// const matchesWonInRowCols=[
//     {
//         Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
//         accessor: 'checkbox',
//       },
//       {
//         Header:"Name",
//         accessor: 'name',
//       },
//       {
//         Header:"Played In Row",
//         accessor: 'playedInRow',
//         hasDescription:true
//       },
//       {
//         Header:"Won In Row",
//         accessor: 'wonInRow',
//         hasDescription:true
//       },
//       {
//         Header:"Rank increased",
//         accessor: 'rankIcreased',
//         hasDescription:true
//       },
// ]
// most friends

const mostFriendsData=(data)=>{
  const mostFriendsRow=data?.map((el)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize  ">{el?.userInfo?.firstName||"-"} {el?.userInfo?.lastName||"-"}</span>
        </Link>
      ),
      friends:<span className="d-inline-block w-50 text-center ">{el?.count||0}</span>,
}))

const mostFriendsCol=[
    // {
    //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //     accessor: 'checkbox',
    //   },
      {
        Header: "Name",
        accessor: 'name',
      },
      {
        Header:"Friends",
        accessor: 'friends',
        hasDescription: true,
      }
]

return   {
  rows:mostFriendsRow,
  cols:mostFriendsCol
}
}
// most profile viewed
const mostProfileViewData=(data)=>{
  const mostProfileViewedRows=data?.map((el)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize ">{el?.firstName||"-"} {el?.lastName||"-"} </span>
        </Link>
      ),
      profileView:(<span className="text-center d-inline-block w-50">{el?.additionalInfo?.profileViewCounter||0}</span>),
}))

const mostProfileViewedCols=[
    // {
    //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //     accessor: 'checkbox',
    //   },
      {
        Header: "Name",
        accessor: 'name',
      },
      {
        Header:"Profile Views",
        accessor: 'profileView',
        hasDescription: true,
      }
]

return {
  rows:mostProfileViewedRows,
  cols:mostProfileViewedCols
}
}
// most tournament participants
const mostTournamentPlayedData=(data)=>{
  const mostTournamentPlayedRows=data?.map((el)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize  ">{el?.userInfo?.firstName} {el?.userInfo?.lastName}</span>
        </Link>
      ),
      tournaments:<span className="d-inline-block text-center w-50 ">{el?.count||0}</span>,
}))

const mostTournamentPlayedCols=[
    // {
    //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //     accessor: 'checkbox',
    //   },
      {
        Header: "Name",
        accessor: 'name',
      },
      {
        Header:"Tournaments",
        accessor: 'tournaments',
        hasDescription: true,
      }
]
return {
  rows:mostTournamentPlayedRows,
  cols:mostTournamentPlayedCols
}
}
// Most Invite Code Sent


const mostInviteCodeSentData=(data)=>{
  const mostInviteCodeSentRows=data?.map((el)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.userDetails?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize  ">{el?.userDetails?.firstName||"-"} {el?.userDetails?.firstName||"-"}</span>
        </Link>
      ),
      codeSent:<span className="d-inline-block w-50 text-center">{el?.totalUsers||0}</span>,
      accepted:<span className="d-inline-block w-50 text-center">{Math.floor(Math.random() * (el?.totalUsers - 0 + 1)) + 0}</span>,
}))
const mostInviteCodeSentCols=[
    // {
    //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //     accessor: 'checkbox',
    //   },
      {
        Header:"Name",
        accessor: 'name',
      },
      {
        Header:"Code Sent",
        accessor: 'codeSent',
        hasDescription: true,
      },
      {
        Header:"Accepted",
        accessor: 'accepted',
        hasDescription: true,
      },

]

return {
  rows:mostInviteCodeSentRows,
  cols:mostInviteCodeSentCols
}
}

// Most Invite code Accept
const mostInvitCodeAcceptedData=(data)=>{
  const mostInviteCodeAcceptRows=data?.map((el)=>({
   
      name: (
        <Link to={`/user-profile/${el?.user?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.user?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize  ">{el?.user?.firstName||"-"} {el?.user?.firstName||"-"}</span>
        </Link>
      ),
      codeSent:<span className="d-inline-block w-50 text-center">{el?.totalAcceptedInvites||0}</span>,
      accepted:<span className="d-inline-block w-50 text-center">{Math.floor(Math.random() * (el?.totalAcceptedInvites - 0 + 1)) + 0}</span>,
}))
const mostInviteCodeAcceptCols=[
 
      {
        Header:"Name",
        accessor: 'name',
      },
      {
        Header:"Code Sent",
        accessor: 'codeSent',
        hasDescription: true,
      },
      {
        Header:"Accepted",
        accessor: 'accepted',
        hasDescription: true,
      },

]

return {
  rows:mostInviteCodeAcceptRows,
  cols:mostInviteCodeAcceptCols
}
}
//  Most Tournaments Created



const mostTournamentCreatedData=(data)=>{
  const mostTourCreatedRows=data?.map((el)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize  ">{el?.userInfo?.firstName||"-"}     {el?.userInfo?.lastName||"-"}</span>
        </Link>
      ),
      created:<span className="d-inline-block text-center w-25 ">{el?.count||"--"}</span>,
  
}))
const mostTourCreatedCols=[
    // {
    //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //     accessor: 'checkbox',
    //   },
      {
        Header:"Name",
        accessor: 'name',
      },
      {
        Header:"Created",
        accessor: 'created',
        hasDescription: true,
      },
     

]

return {
  rows:mostTourCreatedRows,
  cols:mostTourCreatedCols
}

}

// Most Tournaments Completed
// const mostTourCompletedRows=Array.from({length:5}).map((_,ind)=>({
//     id:ind,
//     name:users[ind],
//     img:avatars[ind],
//     completed:ind+1,
    
// })).map((el)=>({
//     checkbox: (
//         <input type="checkbox" className="form-check-input" id="customCheck1" />
//       ),
//       name: (
//         <div className="d-flex flex-nowrap align-items-center text-center">
//           <img src={el.img} className="avatar-xs rounded-circle me-2" />
//           <span className="ms-md-2 text-capitalize  text-nowrap">{el.name}</span>
//         </div>
//       ),
//       completed:el.completed,
  
// }))
// const mostTourCompletedCols=[
//     {
//         Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
//         accessor: 'checkbox',
//       },
//       {
//         Header:"Name",
//         accessor: 'name',
//       },
//       {
//         Header:"Completed",
//         accessor: 'completed',
//         hasDescription: true,
//       },
     

// ]
//  Most Challenges 
const mostChallengesSentData=(data)=>{
  const mostChallengesRows=data?.map((el)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize ">{el?.userInfo?.firstName||"-"} {el?.userInfo?.lastName||"-"}</span>
        </Link>
      ),
      challengeSent:<span className="d-inline-block text-center w-25 ">{el?.count}</span>,
  
}))
const mostChallengesCols=[
    // {
    //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //     accessor: 'checkbox',
    //   },
      {
        Header:"Name",
        accessor: 'name',
      },
      {
        Header:"Sent",
        accessor: 'challengeSent',
        hasDescription: true,
      },
     

]

return {
  rows:mostChallengesRows,
  cols:mostChallengesCols
}
}
// Most Challenges Accepted


const mostChallengesRecievedData=(data)=>{
  const mostChallengesAcceptedRows=data?.map((el)=>({
    // checkbox: (
    //     <input type="checkbox" className="form-check-input" id="customCheck1" />
    //   ),
      name: (
        <Link to={`/user-profile/${el?._id}`} className="d-flex flex-nowrap align-items-center text-center">
          <img src={el?.userInfo?.additionalInfo?.profileImage||profile} className="avatar-xs rounded-circle me-2" />
          <span className="ms-md-2 text-capitalize ">{el?.userInfo?.firstName||"-"} {el?.userInfo?.lastName||"-"}</span>
        </Link>
      ),
      
      accepted:<span className="d-inline-block w-50 text-center">{el?.count||0}</span>,
      received:<span className="d-inline-block w-50 text-center">{Math.floor(Math.random() * (el?.count - 0 + 1)) + 0}</span>,
  
}))
const mostChallengesAcceptedCols=[
    // {
    //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
    //     accessor: 'checkbox',
    //   },
      {
        Header:"Name",
        accessor: 'name',
      },
      {
        Header:"Received",
        accessor: 'received',
        hasDescription: true,
      },
      {
        Header:"Accepted",
        accessor: 'accepted',
        hasDescription: true,
      },
     

]
return {
  rows:mostChallengesAcceptedRows,
  cols:mostChallengesAcceptedCols
}
}



// Increase Rating by 2 points
// const increaseRatingPointsRows=Array.from({length:5}).map((_,ind)=>({
//     id:ind,
//     name:users[ind],
//     img:avatars[ind],
//     ratingBefore:(ind+1),
//     ratingAfter:(ind+3),
    
// })).map((el)=>({
//     checkbox: (
//         <input type="checkbox" className="form-check-input" id="customCheck1" />
//       ),
//       name: (
//         <div className="d-flex flex-nowrap align-items-center text-center">
//           <img src={el.img} className="avatar-xs rounded-circle me-2" />
//           <span className="ms-md-2 text-capitalize  text-nowrap">{el.name}</span>
//         </div>
//       ),
//       ratingBefore:el.ratingBefore,
//       ratingAfter:el.ratingAfter,
  
// }))
// const increaseRatingPointsCols=[
//     {
//         Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
//         accessor: 'checkbox',
//       },
//       {
//         Header:"Name",
//         accessor: 'name',
//       },
//       {
//         Header:"Rating Before",
//         accessor: 'ratingBefore',
//         hasDescription: true,
//       },
//       {
//         Header:"Rating After",
//         accessor: 'ratingAfter',
//         hasDescription: true,
//       },
     

// ]


const  tableDataGenerater=(cardData,data)=>({
    // reportedUsers:{
    //     rows:reptUsersRows,
    //     cols:reptUserCols
    // },
    // notverifyscores:{
    //     rows:notVerifyUsersRows,
    //     cols:notVerifiedScoreCols,
    // },
    userRanking:topPlayersData(data),
    // fastClimbRank:{
    //     // rows:topPlayersRows,
    //     // cols:topPlayersCols
    // },
    mostMatchesPlayed:mostMatchesPlayedData(data),
    // mostMatchesWonInRow:{
    //     rows:matchesWonInRows,
    //     cols:matchesWonInRowCols,
    // },
    mostFriends:mostFriendsData(data),
    mostProfileView:mostProfileViewData(data),
    mostTournamentPlayed:mostTournamentPlayedData(data),
    mostInvitedSent:mostInviteCodeSentData(data),
    // repeated from mostInviteCodeSent
    mostInvitedAccept:mostInvitCodeAcceptedData(data),
    mostTournamentCreated:mostTournamentCreatedData(data),
    // mostTournamentCompleted:{
    //     rows:mostTourCompletedRows,
    //     cols:mostTourCompletedCols,
    // },
    mostChallengSent:mostChallengesSentData(data),
    mostChallengRevieved:mostChallengesRecievedData(data),
    // increaseRatingPoints:{
    //     rows:increaseRatingPointsRows,
    //     cols:increaseRatingPointsCols
    // }


})[cardData]


export default tableDataGenerater;