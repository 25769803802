import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "reactstrap";

const MatchesSkelton = () => {
  return (
    <Row className=" justify-content-evenly matches-wrap bg-white p-3 align-items-center mb-3">
      {/* data & time */}

  
      {/* Team A */}
      <Col className="col-4 col-sm-2 col-md-3 col-lg-2">
    
       
            <div className="d-flex flex-column  ">
              <Row className="align-items-center  justify-content-md-around ">
                <Col className="col-12 col-md-2">
                  <Skeleton circle={true} className="img-skelton-sm" />
                </Col>
                <Col className="col-md-7 col-lg-10 ps-4">
                  <Skeleton
                    count={1}
                    className="match-skelton-t"
                    height={"10px"}
                  />
                  <Skeleton
                    count={1}
                    className=" match-skelton-b"
                    height={"10px"}
                  />
                </Col>
              </Row>
            </div>
          

      </Col>
      {/* points */}
      <Col className="col-4 col-sm-2 col-md-1 ">
        <div
          className="font-size-14 fw-bold match-score-container mx-auto  d-flex align-items-center justify-content-center text-black   "
          style={{
            backgroundImage: "url(/img/global/score-bg.svg)",
          }}
        >
          {" "}
         
        </div>
      </Col>

      {/* Team B*/}
      <Col className="col-4 col-sm-2 col-md-3 col-lg-3">
       
            <div className="d-flex flex-column  ">
              <Row className="align-items-center  justify-content-md-around ">
                <Col className="col-12 col-md-2">
                  <Skeleton circle={true} className="img-skelton-sm" />
                </Col>
                <Col className="col-md-7 col-lg-10 ps-4">
                  <Skeleton
                    count={1}
                    className="match-skelton-t"
                    height={"10px"}
                  />
                  <Skeleton
                    count={1}
                    className=" match-skelton-b"
                    height={"10px"}
                  />
                </Col>
              </Row>
            </div>
        
      </Col>

     

      {/* court */}
      <Col className="col-6 col-sm-2 col-lg-1">
        <div className="d-flex flex-column  align-items-start align-items-md-start ">
          <div className="font-size-12 text-muted mb-1">  <Skeleton
            width={100}
            
          /></div>
          <div
            className="font-size-12 text-black text-truncate "
            style={{ maxWidth: "100px" }}
          >
             <Skeleton
            width={100}
            
          />
          </div>
        </div>
      </Col>

      {/* status */}
      <Col className="col-6 col-sm-2 col-lg-2 text-end">
        <span
          className={`  mt-3 mt-md-0 px-2 py-1 rounded-3  font-size-12  d-inline-block text-truncate`}
        >
        <Skeleton
            width={100}
            
          />
        </span>
      </Col>
    </Row>
  );
};

export default MatchesSkelton;
