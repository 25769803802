import React, { Fragment, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";
import DatePicker from "./DatePicker";
import PaginationButton from "./PaginationButton";

import debounceHandler from "utils/debounceHandler";
import { locations } from "common/data/utility";
import AutoComplete from "./AutoCompleteLocation";
import PointIcon from "assets/icons/PointIcon";
import MatchesSkelton from "components/MatchesSkelton";

function UserMatchListingTable({
  showLocation= true,
  columns,
  setFilters,
  clearFilters,
  filtersHandler,
  statusOptions,
  filters,
  isLoading,
  data,
  tableData,
  currentPage,
  setCurrentPage,
  totalPages,
  isGlobalFilter,
  isAddOrder,
  isAddTableWithoutBorderStrap,
  handleOrderClicks,
  isAddUsers,
  handleUserClicks,
  isAddTableBorderStrap,
  isAddInvoice,
  topBar,
  createShow,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        // sortBy: [
        //   {
        //     desc: false,
        //   },
        // ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const [selected, setSelected] = useState(null);

  return (
    <>
      <Row className="mb-3">
        <Col className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex align-items-center col-12 col-sm-4">
            {/* <img src="/img/global/Search.svg" alt="search" /> */}
            <PointIcon
              height="18px"
              width="18px"
              name="search"
              className="mb-2"
              style={{stroke: "#a9a9ab"}}
            />
            <input
              type="text"
              className="ms-1 bg-transparent border-0 topbar-search font-size-16"
              placeholder="Search..."
              // onChange={onSearch}
              onChange={(e) => {
                e.preventDefault();
                debounceHandler(setFilters, "searchValue", e.target.value);
                // setFilters(pre=>({...pre,searchValue:e.target.value}))
              }}
            />
          </div>

          <div className="d-flex align-items-center col-12 col-sm-4 justify-content-end ">
            <UncontrolledDropdown>
              <DropdownToggle
                caret
                className="bg-transparent text-muted  text-nowrap   border-0 font-size-12 px-2  text-capitalize"
              >
                {filters.status}
                <i className="bx bx-caret-down " />
              </DropdownToggle>
              <DropdownMenu right>
                {statusOptions?.map((el, ind) => (
                  <DropdownItem
                    key={ind}
                    onClick={() => {
                      setFilters((pre) => ({ ...pre, status: el.status }));
                    }}
                    className="text-capitalize font-size-12"
                  >
                    {el.title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
           {showLocation&& <AutoComplete setSelected={setFilters} className="px-2 py-1 rounded-2 pe-0" />}
            <button type='button' onClick={() => clearFilters()} className='bg-transparent border-0 p-1'
              id='filters-button'
            >
              <i class="mdi mdi-filter-variant-minus" />
              <UncontrolledTooltip placement='bottom' placeholder='Clear filters' target={`filters-button`}> Clear Filters </UncontrolledTooltip>
            </button>
          </div>
        </Col>
      </Row>

      <div className="table-responsive">
        {isLoading ? (
        Array.from({length:10}).map((_,ind)=><MatchesSkelton key={ind}/>)
        ) : (
          <Table
            className="table-centered datatable dt-responsive nowrap table-card-list react_table pt-0"
            {...getTableProps()}
            style={{
              borderCollapse: "separate",
              borderSpacing: "0 15px",
            }}
          >
            <tbody {...getTableBodyProps()}>
              {page.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="text-muted font-size-14 bg-transparent "
                            key={cell.id}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
      <Row>
        <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
          <span className="font-size-14 text-muted">
            Showing {page.length > 0 ? 1 : 0} to {page.length} of {totalPages}{" "}
            entries
          </span>
          <div>
            <div className="d-flex align-items-center mt-2 mt-md-0">
              <PaginationButton
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                visiblePageButtons={3}
                totalPages={totalPages}
              />
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}

export default UserMatchListingTable;
