import PropTypes from 'prop-types';
import React from "react";
import { Col, Card, CardBody } from "reactstrap";
// import CountUp from 'react-countup';
import ReactApexChart from "react-apexcharts";
import * as shortNumber from 'short-number'
import upperCardStyle from '../utils/upperCardStyle';
import PointIcon from 'assets/icons/PointIcon';

const MiniWidget = ({reports}) => {
  return (
    
      <>
      {reports?.map((report, key) => {
        return <Col key={key} className="col-12  col-sm-6 col-md-4">
          <Card
          //  className="upper-card upper-card-height"
          
          style={{ background: upperCardStyle(key),
          minHeight: "100px",
          maxHeight:"100px"
          }}>
            <CardBody>
              {/* <div className="float-end mt-2">
                <ReactApexChart
                  options={report.options}
                  series={report.series}
                  type={report.charttype}
                  height={report.chartheight}
                  width={report.chartwidth}
                />
              </div> */}
              <p className="text-light mb-0 card-text text-truncate ">{report?.title}</p>
              <div>
                <h4 className="mb-1 mt-1 text-light fw-bold font-size-24">
                  <span>
                  {shortNumber(report?.value||0)}
                    {/* <CountUp
                      end={report.value}
                      separator=","
                      prefix={report.prefix}
                      suffix={report.suffix}
                      decimals={report.decimal}
                    /> */}
                  </span>
                </h4>
              </div>
               {/* <p className="mt-2 p-0  mb-0 card-text text-light text-end ">
                <span className=" top-card-badge font-size-12  p-2">
                  <i className={ `font-size-16  ${report.icon}`} /> 
                  {report.badgeValue} 
                  <PointIcon name="user" width="30" height="30"/>
                </span>{' '}
              </p>  */}
            </CardBody>
          </Card>
        </Col>
})}
    </>

  );
};

export default MiniWidget;

MiniWidget.propTypes = {
  reports: PropTypes.array
};