import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  CardBody,
  Table,
  CardTitle,
  Label,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Endpoints from "api/endpoints";
import queryParamBuilder from "api/queryParamBuilder";
// import ViewEditDelete from './ViewEditDelete';
import { toastError, toastSuccess } from "components/Common/toastify";

import TableContainer from "components/Common/TableContainer";
import api from "api";
import moment from "moment/moment";
import banner from "assets/images/default-img.svg"
// import api from 'api';

function GroupTournament({ id }) {
  const [grouptournamentData, setGroupTournamentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getGroupTournament = async () => {
    const url = queryParamBuilder({
      endpoint: Endpoints.tournament,
      page: 1,
      limit: 10,
    });
    try {
      const res = await api.get(`${url}&groupId=${id}`);
      setIsLoading(false);
      setGroupTournamentData(res?.data?.data);
    } catch (error) {
      toastError();
      console.log(error.message);
    }
  };

  useEffect(() => {
    getGroupTournament();
  }, []);

  const handleDeleteTournament = async (id) => {
    try {
      await api.delete(`${Endpoints.tournament}/${id}`);
      toastSuccess("Deleted");
      getGroupTournament();
    } catch (error) {
      toastError();
      console.log(error);
    }
  };
  const tData = useMemo(() => {
    if (grouptournamentData) {
      return grouptournamentData?.map((el, ind) => ({
        // checkbox: (
        //   <input
        //     type="checkbox"
        //     className="form-check-input"
        //     id="customCheck1"
        //   />
        // ),
        title: (
          <Link
            to={`/tournament-details/${el?._id}`}
            className="d-flex flex-row align-items-center text-center"
          >
            <img
              src={el.bannerImage||banner}
              className="avatar-xs rounded-circle me-2"
            />
            <span className="ms-md-2 text-nowrap ">{el?.name || "--"} </span>
          </Link>
        ),
        //   name: "John Doe",
        category: el.isSanctioned ? "Sanctioned" : "Not Sanctioned",
        type: el.status,
        privacy: el.isPrivate ? "Private" : "Public",
        location: (
          <span
            className=" text-truncate d-inline-block"
            style={{ maxWidth: "100px" }}
          >
            {el.location?.description || "--"}
          </span>
        ),

        //   userSince: `${moment(el?.createdAt).format('MMMM DD, YYYY')||"--"}`,
        date: moment(el.date).format("YYYY-MM-DD"),
        time: el.time ? el.time : "No time",
        court: <span className="ms-3">{el.court.length || "-"}</span>,
        id: el._id,

        action: (
          <div className="d-flex flex-nowrap users">
            <ul className="list-inline font-size-20 contact-links mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/tournament-details/${el._id}`}
                  className="text-primary"
                  onClick={() => {
                    //   const users = cellProps.row.original
                    //   handleUserClick(users)
                  }}
                >
                  <i
                    className="uil uil-eye font-size-18"
                    id={`edittooltip_view_${ind}`}
                  />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`edittooltip_view_${ind}`}
                  >
                    view
                  </UncontrolledTooltip>
                </Link>
              </li>
              {/* <li className="list-inline-item">
                            <Link
                                 to="#"
                                 className="text-primary ms-3"
                                 onClick={() => {
                                   handleShowModal();
                                   setTournamentID(el._id);
                                 }}
               
                            >
                                <i className="uil uil-pen font-size-18" id={`edittooltip_edit_${ind}`} />
                                <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${ind}`}>
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                        </li> */}
              {/* <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    handleDeleteTournament(el._id);
                  }}
                >
                  <i
                    className="uil uil-trash-alt font-size-18 text-danger"
                    id={`deletetooltip_del_${ind}`}
                  />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`deletetooltip_del_${ind}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li> */}
              <li className="list-inline-item "></li>
            </ul>
          </div>
        ),
      }));
    }
  }, [grouptournamentData]);

  const rows = useMemo(() => {
    return tData?.map((el) => ({
      // checkbox: el.checkbox,
      title: el.title,
      category: el.category,
      type: el.type,
      privacy: el.privacy,
      location: el.location,
      date: el.date,
      time: el.time,
      court: el.court,
      id: el.id,
      action: el.action,
    }));
  }, [tData]);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <Input
      //       type="checkbox"
      //       className="form-check-input"
      //       id="customCheck1"
      //     />
      //   ),
      //   accessor: "checkbox",
      // },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Category",
        accessor: "category",
        hasDescription: true,
      },
      {
        Header: "Status",
        accessor: "type",
        hasDescription: true,
      },
      {
        Header: "Privacy",
        accessor: "privacy",
        hasDescription: true,
      },
      {
        Header: "Location",
        accessor: "location",
        hasDescription: true,
      },
      {
        Header: "Date",
        accessor: "date",
        hasDescription: true,
      },
      {
        Header: "Time",
        accessor: "time",
        hasDescription: true,
      },
      {
        Header: "No. Courts",
        accessor: "court",
        hasDescription: true,
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  return (
    <Row>
      <ToastContainer />
      <Col lg={12}>
        <Card className="section-card ">
          <CardBody>
            <CardTitle className="h4 mb-2">
              <div className="d-flex justify-content-between">
                <CardTitle className="mb-2 h4 d-flex">
                  <span className="font-size-16 fw-bold text-black">
                    Group Tournaments
                  </span>
                </CardTitle>
                <Link
                  className="text-blue font-size-12 fw-normal"
                  to={`/group-tournament/${id}`}
                >
                  View all
                </Link>
              </div>
            </CardTitle>
            <Row className="overflow-auto" style={{ height: "280px" }}>
              {isLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "220px" }}
                >
                  <Spinner />
                </div>
              ) : (
                <TableContainer
                  columns={columns}
                  // rows={rows}
                  data={rows}
                  isGlobalFilter={true}
                  isAddUsers={true}
                  isAddTableBorderStrap={true}
                  // handleUserClicks={handleUserClicks}
                  addPagination={false}
                  // topBar={topBar}
                />
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default GroupTournament;
