import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: {},
  authenticated: false,
  authenticating: false,
};

const authSlice = createSlice({
  name: "authPoint",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      console.log("---currentUser reducers",action?.payload)
      state.currentUser = action.payload;
      state.authenticated = true;
      state.authenticating = false;
    },
    forgotUserPassword: (state, action) => {
      state.forgotPswd = action.payload;
    },

    logout: (state) => {
      state.currentUser = null;
      state.loggedInUser = null;
      state.forgotPswd = null;
    },
    verifyUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
  },
});

export const { setCurrentUser, forgotUserPassword, logout, verifyUser } =
  authSlice.actions;

export default authSlice.reducer;
