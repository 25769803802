import { debounce } from 'lodash';


const debounceHandler = debounce((setter, key, value) => {
    setter((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  }, 500);
 
 export default debounceHandler 