import React, { useState, useEffect, memo, useCallback, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Card,
  CardBody,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DateRangePicker from "./Common/DatePicker";
import moment from "moment";
import api from "api";
import { toastError } from "./Common/toastify";
import Endpoints from "api/endpoints";
import Loader from "./Common/Loader";

const TournamentsReport = ({
  series = [],
  endpoint,
  title,
  point1,
  point2,
  groupId = null,
}) => {
  const [filter, setFilter] = useState("week");
  const [count, setCount] = useState([]);
  const [type, setType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
  
    setIsLoading(true);

    try {
      let p1 = {
        name: point1,
        data: [],
        type: [],
      };
      let p2 = {
        name: point2,
        data: [],
        type: [],
      };
      if (typeof endpoint !== "undefined") {
        const res = await api.get(
          `${Endpoints[endpoint]}?type=${filter}${
            groupId ? `&groupId=${groupId}` : ""
          }`
        );

        if (res?.status === 200) {
          if (endpoint === "freePaid") {
            res?.data?.data?.free?.forEach((el) => {
              p1?.data.push(el?.count);
              p1?.type.push(
                moment(el?.date).format(
                  `${filter === "week" ? "dddd" : "MMM"}`
                ) || "--"
              );
            });
            res?.data?.data?.Paid?.forEach((el) => {
              p2?.data.push(el?.count);
              p2?.type.push(
                moment(el?.date).format(
                  `${filter === "week" ? "dddd" : "MMM"}`
                ) || "--"
              );
            });
          } else if (endpoint === "tournamentReport") {
            res?.data?.data?.unsanction?.forEach((el) => {
              p1?.data.push(el?.count);
              p1?.type.push(
                moment(el?.date).format(
                  `${filter === "week" ? "ddd" : "MMM"}`
                ) || "--"
              );
            });
            res?.data?.data?.sanction?.forEach((el) => {
              p2?.data.push(el?.count);
              p2?.type.push(
                moment(el?.date).format(
                  `${filter === "week" ? "ddd" : "MMM"}`
                ) || "--"
              );
            });
          } else if (endpoint === "groupTournamentStats") {
            res?.data?.data?.created?.forEach((el) => {
              p1?.data.push(el?.count);
              p1?.type.push(
                moment(el?.date).format(
                  `${filter === "week" ? "ddd" : "MMM"}`
                ) || "--"
              );
            });
            res?.data?.data?.completed?.forEach((el) => {
              p2?.data.push(el?.count);
              p2?.type.push(
                moment(el?.date).format(
                  `${filter === "week" ? "ddd" : "MMM"}`
                ) || "--"
              );
            });
          }
        }

        setCount([
          {
            name: p1?.name,
            data: p1?.data,
          },
          {
            name: p2?.name,
            data: p2?.data,
          },
        ]);
        setType([
          ...p1?.type?.map((el, ind) => (el === p2?.type[ind] ? el : "")),
        ]);
        setIsLoading(false);
      } else{
        setIsLoading(false);
      }
      // toastSuccess()
    } catch (err) {
    
      setIsLoading(false);
    }

  }, [filter]);

  useEffect(() => {
    getData();
  }, [filter, getData]);
console.log("-----------loading stae----",isLoading)
  const options = useMemo(
    () => ({
      colors: ["#AA34FE", "#F69132"],
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: true,
          tools: {
            download: false, // <== line to add
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        // labels: [point1, point2],
        marker: {
          height: 10,
          width: 10,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "category",
        categories:
          type.length > 0 ? type : ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    }),
    [type]
  );

  return (
    <Card className="section-card card-height">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-2 h4 d-flex">
            <div className="d-flex ">
              <span className="font-size-16 fw-bold text-black">{title}</span>
              <UncontrolledDropdown
                // toggle={function noRefCheck(){}}
                direction="down"
              >
                <DropdownToggle className="bg-transparent text-muted  text-nowrap   border-0 font-size-12 px-2  text-capitalize pt-1 ">
                  {filter}
                  <i className="bx bx-caret-down " />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="font-size-12 text-capitalize "
                    onClick={() =>
                      setFilter(filter === "week" ? "month" : "week")
                    }
                  >
                    {filter === "week" ? "month" : "week"}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </CardTitle>
          {/* <DateRangePicker /> */}
        </div>

        {/* 
        <ul className="list-unstyled d-flex flex-wrap">
          <li className="font-size-12 text-muted">
            <i className="fas fa-circle font-size-10 cursor-pointer me-2 my-2" style={{ color: '#F69132' }} />
            <span className="font-size-14">{point1}</span>
          </li>
          <li className="font-size-12 text-muted ms-3">
            <i className="fas fa-circle font-size-10 cursor-pointer me-2 my-2" style={{ color: '#AA34FE' }} />
            <span className="font-size-14">{point2}</span>
          </li>
        </ul> */}
        {isLoading ? (
          <Loader />
        ) : (
          <ReactApexChart
            options={options}
            series={series.length > 0 ? series : count}
            type="area"
            height={255}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default memo(TournamentsReport);
