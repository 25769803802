import React, {useEffect, useMemo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import banner from 'assets/images/default-img.svg';
//import components

import {Col, Row, UncontrolledTooltip, Input} from 'reactstrap';
import DeleteModal from '../../components/Common/DeleteModal';
import Listing from 'components/Common/Listing';
import Endpoints from 'api/endpoints';
import api from 'api';
import {toastError} from 'components/Common/toastify';
import moment from 'moment/moment';
import {Rating} from 'react-simple-star-rating';
import {ToastContainer} from 'react-toastr';
import {toastSuccess} from 'components/Common/toastify';
import queryParamBuilder from 'api/queryParamBuilder';
import HtmlHead from 'components/html-head/HtmlHead';
import PointIcon from 'assets/icons/PointIcon';

function UsersListing() {
  const title = 'Users Listing';
  const description = 'Users Page';

  const tabNames = [
    {
      title: 'All Groups',
      endpoint: Endpoints.group,
      type: '',
    },
    // {
    //   title: "Sanctioned",
    //   endpoint: Endpoints.getBlockedUsers,
    //   type: "report",
    // },
  ];

  // List tabes
  const [activeTab, setActiveTab] = useState(tabNames.at(0));

  const [fetchList, setFetchlist] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // paginaion states
  const [currentPage, setCurrentPage] = useState(1);
  // const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({
    searchValue: '',
    status: 'all',
    location: {
      lat: 0,
      long: 0,
    },
  });
  const [totalPages, setTotalPages] = useState(null);

  // fetching data
  const fetchListHandler = async () => {
    setIsLoading(true);

    try {
      const resp = await api.get(
        `${activeTab.endpoint}?page=${
          filters?.searchValue === '' ? currentPage : 1
        }&limit=10&filter=${filters.status}&search=${filters.searchValue}&lat=${
          filters.location?.lat
        }&long=${filters.location?.long}`,
      );

      setFetchlist(resp?.data?.data);
      setTotalPages(resp?.data?.totalPages);
    } catch (error) {
      console.log(error);
      toastError();
    }
    setIsLoading(false);
  };

  // const onSearch = (e) => {
  //   e.preventDefault();
  //   setSearchValue(e.target.value);
  // };

  const clearFiltersHandler = () => {
    setFilters({
      searchValue: '',
      status: 'all',
      location: {lat: 0, long: 0},
    });
  };

  useEffect(() => {
    fetchListHandler();
  }, [
    currentPage,
    filters.searchValue,
    filters.location,
    filters.status,
    activeTab,
  ]);

  //delete user
  const [deleteModal, setDeleteModal] = useState(false);
  const [groupId, setGroupId] = useState(null);

  const onClickDelete = async groupId => {
    try {
      const res = await api.delete(
        `${Endpoints.superAdminDeleteGroup}/${groupId}`,
      );

      toastSuccess('group Deleted');
      fetchListHandler();
    } catch (err) {
      toastError();
    }
    setDeleteModal(false);
  };

  // data destruction
  const tData = useMemo(() => {
    if (fetchList) {
      return fetchList?.map((el, ind) => {
        return {
          // checkbox: (
          //   <input type="checkbox" className="form-check-input" id="customCheck1" />
          // ),
          title: (
            <Link
              to={`/group-details/${el?._id}`}
              className="d-flex flex-row align-items-center text-center">
              <img
                src={el?.profileImage || banner}
                className="avatar-xs rounded-circle me-2"
              />
              <span className="ms-md-2 text-nowrap ">{el?.name || '--'}</span>
            </Link>
          ),
          //   name: "John Doe",
          priority: `${el?.email || '--'}`,
          location: (
            <span
              className=" text-truncate d-inline-block"
              style={{maxWidth: '100px'}}>
              {el?.location?.description || '--'}
            </span>
          ),
          status: (
            <span className="text-nowrap">
              S:{el?.additionalInfo?.matchDetails?.single?.rating}/D:
              {el?.additionalInfo?.matchDetails?.double?.rating}
            </span>
          ),
          privacy: `${el?.type === 'public' ? 'Public' : 'Private'}`,
          createdOn: `${moment(el?.createdAt).format('MMMM DD, YYYY') || '--'}`,

          password: `${el?.additionalInfo?.password || '--'}`,
          members: (
            <span className="d-inline-block text-center w-50">
              {el?.users?.length || '--'}
            </span>
          ),
          action: (
            <div className="d-flex flex-nowrap users justify-content-center pe-4">
              <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item">
                  <Link
                    to={`/group-details/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}>
                    <i
                      className="uil uil-eye font-size-18"
                      id={`edittooltip_view_${el?._id}`}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`edittooltip_view_${el?._id}`}>
                      view
                    </UncontrolledTooltip>
                  </Link>
                </li>
                {/* <li className="list-inline-item">
                  <Link
                    to={`/edit-user/${el?._id}`}
                    className="text-primary"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      //   handleUserClick(users)
                    }}
                  >
                   <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${el?._id}`}/>
                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${el?._id}`}>
                      Edit
                    </UncontrolledTooltip>
                  </Link>
                </li> */}
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      //   const users = cellProps.row.original
                      setDeleteModal(true);
                      setGroupId(el?._id);
                    }}>
                    <i
                      className="uil uil-trash-alt font-size-18 text-danger "
                      id={`deletetooltip_del_${el?._id}`}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`deletetooltip_del_${el?._id}`}>
                      Delete
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </ul>
            </div>
          ),
        };
      });
    } else {
      return [];
    }
  }, [fetchList]);

  const rows = useMemo(
    () =>
      tData?.map(el => ({
        // checkbox: el.checkbox,
        title: el.title,
        // priority: el.priority,
        privacy: el.privacy,
        members: el.members,
        createdOn: el.createdOn,
        location: el.location,
        password: el.password,
        // type: el.type,
        action: el.action,
        // id: el._id,
      })),
    [tData],
  );

  const columns = useMemo(
    () => [
      // {
      //   Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
      //   accessor: 'checkbox',
      // },
      {
        Header: 'Title',
        accessor: 'title',
      },
      // {
      //   Header: "Priority",
      //   accessor: "priority",
      //   hasDescription: true,
      // },
      {
        Header: 'Location',
        accessor: 'location',
        hasDescription: true,
      },
      {
        Header: 'Members',
        accessor: 'members',
        hasDescription: true,
      },
      {
        Header: 'Created',
        accessor: 'createdOn',
        hasDescription: true,
      },
      {
        Header: 'Password',
        accessor: 'password',
        hasDescription: true,
      },
      {
        Header: 'Privacy',
        accessor: 'privacy',
        hasDescription: true,
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
    ],
    [],
  );

  const listFilters = [
    // {
    //   title: "status",
    //   options: ["all","join", "admin" ,"friend","other", "myGroup", ],
    // },
    // {
    //   title: "Pivacy",
    //   options: ["Public", "Private"],
    // },
    // {
    //   title: "Location",
    //   options: ["Sans Francisco", "New York"],
    // },
    // {
    //   title: "Priority",
    //   options: ["Sans Francisco", "New York"],
    // },
  ];

  return (
    <React.Fragment>
      <HtmlHead title={title} description={description} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => onClickDelete(groupId)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <div className="mb-3">
            <h4>Groups Listing</h4>
          </div>
          <Row>
            <Col xs="12">
              <Listing
                searchBar={true}
                tabNames={tabNames}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                columns={columns}
                data={rows}
                isGlobalFilter={true}
                isAddUsers={true}
                isAddTableBorderStrap={true}
                //  handleUserClicks={handleUserClicks}
                addPagination={true}
                topBar={listFilters}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                // onSearch={onSearch}
                isLoading={isLoading}
                filters={filters}
                setFilters={setFilters}
                clearFilters={clearFiltersHandler}
              />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
UsersListing.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default UsersListing;
