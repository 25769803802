import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getDetails,
} from 'use-places-autocomplete';
// import useOnclickOutside from "react-cool-onclickoutside";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';

const AutoComplete = ({
  setSelected = null,
  className = '',
  form = false,
  setFieldValue = null,
  defaultValue = '',
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: {status, data},
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'initMap',
    defaultValue: defaultValue,
  });

  const handleSelect = async address => {
    setValue(address, false);
    clearSuggestions();

    if (!form) {
      const results = await getGeocode({address});
      const {lat, lng} = await getLatLng(results[0]);
      // setSelected({ lat, lng });
      setSelected(pre => ({...pre, location: {lat: lat, long: lng}}));
    } else {
      const parameter = {
        // Use the "place_id" of suggestion from the dropdown (object), here just taking the first suggestion for brevity
        placeId: data[0].place_id,
      };

      getDetails(parameter)
        .then(details => {
          console.log('Details: ', details);
          setFieldValue('location', {
            description: details?.formatted_address,
            details: details,
          });
        })
        .catch(error => {
          console.log('Error: ', error);
        });
    }
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={e => setValue(e.target.value)}
        disabled={!ready}
        className={`mt-1 combobox-input  font-size-14 ${className}`}
        placeholder="Search by location..."
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === 'OK' &&
            data.map(({place_id, description}) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
export default AutoComplete;
