// export default (Endpoint,page,limit,search="",status="",home=false,userId="")=>{
    export default (url)=>{

        const st=url.status?`&status=${url.status}`:"";
        const uId=url.userId?`&userId=${url.userId}`:"";
        const hm=url.home?`&home=${url.home}`:"";
        const srch=url.search?`${url.search}`:"";
        const typ=url.type?`/${url.type}`:"";
        const filter=url.filter?`&${url.filter}`:"";
        
    
        return (`${url.endpoint}${typ}?page=${url.page||1}&limit=${url.limit||10}&search=${srch}${st}${uId}${hm}${filter}&latitude=${url?.location?.lat||0}&longitude=${url?.location?.long||0}`)
    }