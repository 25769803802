import api from 'api';
import React, { useEffect ,useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { toastSuccess,toastError } from './Common/toastify';
import Endpoints from 'api/endpoints';
import Loader from './Common/Loader';

function ByCities({ cityText, link,endpoint }) {

  const [cities,setCities] = useState([]);
  const [userCounts,setUserCounts] = useState([]);
  const [byCities,setByCities] = useState([{
    x:[''],
    y:[0]
  }]);
  const [isLoading,setIsLoading]=useState(false)
 

  const getUserByCity = async () =>{
  setIsLoading(true)
  try {
    
     const res= await api.get(`${Endpoints[endpoint]}`)
      if (res?.status || res?.status === 200) {
      
        // toastSuccess();
        if(endpoint==="matchesByCity"){
          let matchesByCity=[]
          const {tournament} =res?.data?.data
          tournament.forEach((_,ind)=>{

           if(ind<=10){
            matchesByCity.push({
              x:tournament?.at(ind)?._id,
              y:tournament?.at(ind)?.matchCount
            })
  
           } else return
            // cities.push(users?.at(ind)?._id);
            // counts.push(users?.at(ind)?.userCount)
          })
          setByCities([{data:matchesByCity}])
        }else if(endpoint==="getUserByCity"){
          let userByCity=[]
          const {users} =res?.data?.data
          users.forEach((_,ind)=>{

            if(ind<=10){
              userByCity.push({
                x:users?.at(ind)?._id,
                y:users?.at(ind)?.userCount
              })
            } else return
  
            // cities.push(users?.at(ind)?._id);
            // counts.push(users?.at(ind)?.userCount)
          })
          setByCities([{data:userByCity}])
        }else if (endpoint==="groupByCity"){
          let groupByCity=[]
          const {group} =res?.data?.data
          group.forEach((_,ind)=>{

           if(ind<=10){
            groupByCity.push({
              x:group?.at(ind)?._id,
              y:group?.at(ind)?.groupCount
            })
           } else return
  
            // cities.push(users?.at(ind)?._id);
            // counts.push(users?.at(ind)?.userCount)
          })
          setByCities([{data:groupByCity}])
        } else if (endpoint==="courtsByCity"){
          let courtByCity=[]
          const {court} =res?.data?.data
         court.forEach((_,ind)=>{

           if(ind<=10){
            courtByCity.push({
              x:court?.at(ind)?._id,
              y:court?.at(ind)?.courtCount
            })
           } else return
  
            // cities.push(users?.at(ind)?._id);
            // counts.push(users?.at(ind)?.userCount)
          })
          setByCities([{data:courtByCity}])
        } if (endpoint==="challengesByCity"){
          let challengeByCity=[]
          const {challenges} =res?.data?.data
          challenges.forEach((_,ind)=>{

            if(ind<=10){
              challengeByCity.push({
                x:challenges?.at(ind)?._id,
                y:challenges?.at(ind)?.matchCount
              })
            } else return
            
  
            // cities.push(users?.at(ind)?._id);
            // counts.push(users?.at(ind)?.userCount)
          })
          setByCities([{data:challengeByCity}])
        }

      
        // setCities(cities)  
        // setUserCounts(counts)   

      }
      setIsLoading(false)
    } catch (err) {
      // if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
      
        setIsLoading(false)
      //   toastError();
      // } 

    } 
  }


  useEffect(()=>{
    getUserByCity()
  },[])



 

  const options = {
    chart: {
      type: 'bar',
      height: 200,
      toolbar:{
        
        tools:{
            download:false // <== line to add
          }
    },
    },
   
    plotOptions: {
      bar: {
        // borderRadius: 4,
        horizontal: true,
        barHeight: 10,
        colors: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: '#D499FF',
            },
            {
              from: 101,
              to: 1000,
              color: '#AA34FE',
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    // xaxis: {
    //   categories: cities,
    // },
  };

  return (
    <Card className="section-card card-height">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-2 h4 d-flex">
            <div className="d-flex align-items-center">
              <span className="font-size-16 fw-bold text-black">{cityText} by Cities</span>
              {/* <span className="text-muted ms-3 fw-normal font-size-14">
                weekly
                <i className="mdi mdi-chevron-down ms-1" />
              </span> */}
            </div>
          </CardTitle>
          {/* <Link to={link} className="text-blue font-size-12">
            View All
          </Link> */}
        </div>
        {isLoading?(<Loader/>):(<ReactApexChart options={options} series={byCities} type="bar" height={300} />)}
      </CardBody>
    </Card>
  );
}

export default ByCities;
