import React, { useEffect, useMemo, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { Link, useParams } from "react-router-dom"
import  banner from "assets/images/default-img.svg"

//import components




import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
// import DeleteModal from "../../components/Common/DeleteModal"
import Listing from "components/Common/Listing"
import Endpoints from "api/endpoints"
import api from "api"
import { toastError } from "components/Common/toastify"
import moment from "moment/moment"
import { Rating } from "react-simple-star-rating"
import { ToastContainer } from "react-toastr"
import { toastSuccess } from "components/Common/toastify"
import queryParamBuilder from "api/queryParamBuilder"
import HtmlHead from "components/html-head/HtmlHead"
import PointIcon from "assets/icons/PointIcon"
import tableDataGenerater from "./tableDataGenerater"
import dynamicUserListingData from "./dynamicUserListingData"
import DeleteModal from "components/Common/DeleteModal"


const titleGenerator={
    userRanking:"Top Playesrs",
   
    mostMatchesPlayed:"Most Matches Played",
  
    mostFriends:"Most Friends",
    mostProfileView:"Most Profile View",
    mostTournamentPlayed:"Most Tournament Played",
    mostInvitedSent:"Most Invited Sent Users",

    mostInvitedAccept:"Most Invitet Accepted",
    mostTournamentCreated:"Most Tournament Created",
  
    mostChallengSent:"Most Challenge Sent",
    mostChallengRevieved:"Most Challenge Received"
}

function UsersListing() {
    const {endpoint} = useParams()
  const title = `${titleGenerator[endpoint]}`;
    const description = 'Users Page';

//   const tabNames = [{
//     title: 'All Users',
//     endpoint: Endpoints.getAll,
//     type: ""
//   }, {
//     title: 'Reported Users',
//     endpoint: Endpoints.getBlockedUsers,
//     type: "report"
//   }, {
//     title: 'Blocked Users',
//     endpoint: Endpoints.getBlockedUsers,
//     type: "block"
//   }];
 



  // List tabes
//   const [activeTab, setActiveTab] = useState(tabNames.at(0));




  const [fetchList, setFetchlist] = useState([]);
 
  const [isLoading, setIsLoading] = useState(false)



  // paginaion states 
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    searchValue:null,
    location:null,
  })
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(null);



  // fetching data
  const fetchListHandler = async () => {
    setIsLoading(true)
    const url = queryParamBuilder({
      page: filters?.searchValue===""?currentPage:1,
      endpoint: Endpoints[`${endpoint}`],
      limit: 10,
      location:filters.location,
    //   type: activeTab.type,
      search: filters.searchValue,

    })

    try {
      const resp = await api.get(url);

      setFetchlist(resp?.data?.data)
      setTotalPages(resp?.data?.totalPages)


    } catch (error) {
      toastError();

    }
    setIsLoading(false)
  }
  
  const onSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value)
  
  }
  // clear filters
  const clearFiltersHandler = () => {
    setFilters({
      searchValue:null,
      location:null,
    })
    setSearchValue("")
    setCurrentPage(1)
    fetchListHandler()
  }

  useEffect(() => {
    fetchListHandler()


  }, [endpoint,currentPage, searchValue,filters.searchValue,filters.location])




  //delete user
  const [deleteModal, setDeleteModal] = useState(false)
  const [user, setUser] = useState(null)

  const onClickDelete = async userId => {

    try {
      const res = await api.delete(`${Endpoints.deleteUser}/${userId}`)

      toastSuccess("User Deleted")
      fetchListHandler()
    } catch (err) {
      toastError()

    }
    setDeleteModal(false)
  }
const deleteUserHandler=(userId)=>{
    setDeleteModal(true)
    setUser(userId)
}
  // data destruction
//   const tData = useMemo(() => {
//     if (fetchList) {
//       return fetchList?.map((el, ind) => {
//         return {
//           checkbox: (
//             <input type="checkbox" className="form-check-input" id="customCheck1" />
//           ),
//           name: (
//             <Link to={`/user-profile/${el?._id}`} className="d-flex flex-row align-items-center text-center">
//               <img src={el?.additionalInfo?.profileImage||banner} className="avatar-xs rounded-circle me-2" />
//               <span className="ms-md-2 text-nowrap ">{el?.firstName || "-"}  {el?.lastName}</span>
//             </Link>
//           ),
//           //   name: "John Doe",
//           email: `${el?.email || "--"}`,
//           location: <span className=" text-truncate d-inline-block" style={{ maxWidth: "100px" }}>{el?.additionalInfo?.location?.description || "--"}</span>,
//           rating: (
//             <span className="text-nowrap">
//             S:{el?.additionalInfo?.matchDetails?.single?.rating}/D:{el?.additionalInfo?.matchDetails?.double?.rating}
//             </span>
//           ),
//           winLose: `${el?.additionalInfo?.matchDetails?.single?.win+el?.additionalInfo?.matchDetails?.double?.win|| "-"}/${el?.additionalInfo?.matchDetails?.single?.loss+ el?.additionalInfo?.matchDetails?.double?.loss || "-"}`,
//           userSince: `${moment(el?.createdAt).format('MMMM DD, YYYY') || "--"}`,
         
//           court: `${el?.additionalInfo?.tagLine || "--"}`,
//           action: (
//             <div className="d-flex flex-nowrap users">
//               <ul className="list-inline font-size-20 contact-links mb-0">
//                 <li className="list-inline-item">
//                   <Link
//                     to={`/user-profile/${el?._id}`}
//                     className="text-primary"
//                     onClick={() => {
//                       //   const users = cellProps.row.original
//                       //   handleUserClick(users)
//                     }}
//                   >
//                     <i className="uil uil-eye font-size-18" id={`edittooltip_view_${ind}`} />
//                     <UncontrolledTooltip placement="bottom" target={`edittooltip_view_${ind}`}>
//                       view
//                     </UncontrolledTooltip>
//                   </Link>
//                 </li>
//                 <li className="list-inline-item">
//                   <Link
//                     to={`/edit-user/${el?._id}`}
//                     className="text-primary"
//                     onClick={() => {
//                       //   const users = cellProps.row.original
//                       //   handleUserClick(users)
//                     }}
//                   >
//                    <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_edit_${ind}`}/>
//                     <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${ind}`}>
//                       Edit
//                     </UncontrolledTooltip>
//                   </Link>
//                 </li>
//                 <li className="list-inline-item">
//                   <Link
//                     to="#"
//                     className="text-danger"
//                     onClick={() => {
//                       //   const users = cellProps.row.original
//                       setDeleteModal(true);
//                       setUser(el?._id)
//                     }}
//                   >
//                     <i
//                       className="uil uil-trash-alt font-size-18 text-danger "
//                       id={`deletetooltip_del_${ind}`}
//                     />
//                     <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${ind}`} >
//                       Delete
//                     </UncontrolledTooltip>
//                   </Link>
//                 </li>
//                 <li className="list-inline-item ">

//                 </li>
//               </ul>
//             </div>
//           )

//         }
//       })
//     }
//     else {
//       return []
//     }
//   }, [fetchList])



//   const rows = useMemo(() => (
//     tData?.map(el => (
//       {
//         checkbox: el.checkbox,
//         name: el.name,
//         email: el.email,
//         location: el.location,
//         rating: el.rating,
//         winLose: el.winLose,
//         userSince: el.userSince,
//         time: el.time,
//         court: el.court,
//         action: el.action,
//         // id: el._id,
//       }
//     ))
//   ), [tData])

//   const columns = useMemo(
//     () => [
//       {
//         Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
//         accessor: 'checkbox',
//       },
//       {
//         Header: 'Name',
//         accessor: 'name',
//       },
//       {
//         Header: 'Email',
//         accessor: 'email',
//         hasDescription: true,
//       },
//       {
//         Header: 'Location',
//         accessor: 'location',
//         hasDescription: true,
//       },
//       {
//         Header: 'Rating',
//         accessor: 'rating',
//         hasDescription: true,
//       },
//       {
//         Header: 'Win/Lose',
//         accessor: 'winLose',
//         hasDescription: true,
//       },
//       {
//         Header: 'User Since',
//         accessor: 'userSince',
//         hasDescription: true,
//       },
//       {
//         Header: 'Action',
//         accessor: 'action',

//       },
//     ],
//     []
//   );

const data=dynamicUserListingData(endpoint,fetchList,deleteUserHandler)



  const listFilters = [
    // {
    //   title: "Location Filter",
    //   options: ["Sans Francisco", "New York"]
    // },
    // {
    //   title: "Ratings Filter",
    //   options: ["Sans Francisco", "New York"]
    // }
  ]

  return (
    <React.Fragment>
    <HtmlHead title={title} description={description}/>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => onClickDelete(user)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <div className="mb-3">
            <h4>{titleGenerator[endpoint]} </h4>
          </div>
          <Row>
            <Col xs="12">
              <Listing
                // title="Users Listing"
                searchBar={true}
                // tabNames={tabNames}
                // activeTab={activeTab}
                // setActiveTab={setActiveTab}
                // topBtn={"create"}
                // topBtnLink={"/create-user"}
                columns={data?.cols}
                data={data?.rows}
                isGlobalFilter={true}
                isAddUsers={true}
                isAddTableBorderStrap={true}
                //  handleUserClicks={handleUserClicks}
                addPagination={true}
                topBar={listFilters}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                onSearch={onSearch}
                isLoading={isLoading}
                filters={filters}
                clearFilters={clearFiltersHandler}
                setFilters={setFilters}

              />

            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  )
}
UsersListing.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default UsersListing
