import api from 'api';
import Endpoints from 'api/endpoints';
import { toastError } from 'components/Common/toastify';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Card, CardBody,CardTitle, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const EntryFeeChart=({ title, endpoint, series, link }) =>{
  const [filter,setFilter]=useState("week");
  const [count,setCount]=useState([])
  const [type,setType]=useState([])

  const getData=useCallback(async()=>{
 

   

  

    try{
      let type=[]
      let count=[]
     if(typeof endpoint != "undefined"){
      
      const res = await api.get(`${Endpoints[endpoint]}?type=${filter}`)
      if(res?.status===200){
        res?.data?.data?.forEach(el => {
          count.push(el?.totalAmount)
          type.push(moment(el?.date).format(`${filter==="week"?'dddd':"MMM"}`)||"--")
         });
         const seriesData=[{
          name:"amount",
          // data:!count.length>0 ? count:[10, 41, 35, 51, 49, 62, 69, 91, 148]
          data:count

         }]
         setCount(seriesData)
         setType(type)
      } else return
      
     }
     else return
      // toastSuccess()

    }catch(erer){
      toastError()
    }


 
},[filter])


useEffect(()=>{
  getData()
},[filter,getData])
    const feeOptions = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools:{
              download:false // <== line to add
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          colors: ['#7AB500'],
          width: 2,
        },
        grid: {
            show: true,
            borderColor: '#E9E8EB',
            strokeDashArray: 4,
            position: 'back',
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
        
        xaxis: {
          categories: type?.length>0?type:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        
          title: {
            text: 'Tournaments',
          },
        },
        yaxis: {
          title: {
            text: 'Amount',
          },
          labels: {
            formatter: function (value) {
              return `$${value}`;
            },
          },
        },
        tooltip: {
        //   theme: 'dark',
        y: {
            formatter: function (value) {
              return `$${value}`;
            },
          },
        },
      };
 
  return (
    <Card className="section-card card-height">
      <CardBody>
        {/* <div className="font-size-12 text-muted">Amount</div> */}
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-2 h4 d-flex">
            <span className="font-size-16 fw-bold text-black">{title}</span>
            <UncontrolledDropdown
                                                        
                                                        // toggle={function noRefCheck(){}}
                                                        direction="down"

                                                    >
                                                        <DropdownToggle


                                                            className='bg-transparent text-muted  text-nowrap   border-0 font-size-12 px-2 pt-1  text-capitalize'
                                                        >
                                                           {filter}
                                                            <i className='bx bx-caret-down ' />
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                        <DropdownItem
                                                                          
                                                                            className='font-size-12 text-capitalize'
                                                                            onClick={() => (setFilter(filter==="week"?"month":"week"))}
                                                                            >
                                                                            {filter==="week"?"month":"week"}
                                                                        </DropdownItem>
                                                       
                                                        </DropdownMenu>
                                                        </UncontrolledDropdown>
          </CardTitle>
          <Link to={link} className="font-size-12 text-blue">
            View All
          </Link>
        </div>
        <div className="mt-3">
          <ReactApexChart options={feeOptions} series={count} type="line" height={250} />
        </div>
      </CardBody>
    </Card>
  );
}

export default memo(EntryFeeChart);
