import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardTitle } from 'reactstrap';
import * as shortNumber from 'short-number';
import DateRangePicker from './Common/DatePicker';

const  TournamentCompletion =({series=[0,0,0,0],totalPlayed=0,labels,title}) =>{
  
 
  
  const options = {
    chart: {
      height: 490,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '25%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: ['#F69132', '#F9C08A', '#FCE2C9',"#F9C08A"],
    labels: labels,
    legend: {
      show: true,
    //   floating: true,
     
      fontSize: '16px',
      position: 'bottom',
    //   offsetX: 160,
    //   offsetY: 15,
      labels: {
        useSeriesColors: false,
        mouseEnter: null,
        mouseLeave: null,
      },
      markers: {
        size: 0,
        width:10,
        height:10,
      },
    //   formatter(seriesName, opts) {
    //     return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`;
    //   },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <Card className="section-card card-height">
      <CardBody>
        <div className="d-flex justify-content-between">
          <CardTitle className="mb-2 h4 d-flex">
            <div className="d-flex ">
              <span className="font-size-16 fw-bold text-black">{title}</span>
              {/* <span className="text-muted ms-3 fw-normal text-nowrap mb-2 me-2 me-sm-0 mb-sm-0 font-size-14">
                weekly
                <i className="mdi mdi-chevron-down ms-1" />
              </span> */}
            </div>
          </CardTitle>
          {/* <DateRangePicker /> */}
        </div>
        <div className="d-flex align-items-center justify-content-center circle-chart flex-column flex-sm-nowrap ">
          <div className="d-flex align-items-center">
            <div className="mt-2 mtsm-3 d-flex flex-column align-items-center position-relative px-4">
              <span
                className="chart-total-val  position-absolute  font-size-10 fw-normal text-muted"
                style={{
                  top: '42%',
                  left: '50%',
                  transform: 'translate(-50%, -35%)',
                }}
              >
                Total {shortNumber(totalPlayed)}
              </span>
              <ReactApexChart options={options} series={series} type="radialBar" height={283} />
            </div>
          </div>
          {/* <ul className="list-unstyled pb-1 pb-sm-0  text-muted font-size-14 circle-chart-points d-flex flex-row   flex-wrap justify-content-between ">
            <li className="me-2 me-lg-0 me-xl-4  text-nowrap  mb-2">
              <i className="fas fa-circle cursor-pointer mx-2 completion-points" style={{ color: '#F69132', fontSize: '10px' }} />
              Single Loses
            </li>
            <li className="me-2  me-lg-0 me-xl-0  text-nowrap  mb-2">
              <i className="fas fa-circle cursor-pointer mx-2 completion-points" style={{ color: '#F9C08A', fontSize: '10px' }} />
              Double Loses
            </li>
            <li className="me-2 me-lg-0 me-xl-3  text-nowrap  mb-2">
              <i className="fas fa-circle cursor-pointer mx-2 completion-points" style={{ color: '#F69132', fontSize: '10px' }} />
              Single  Wins
            </li>
            <li className="me-2  me-lg-0 me-xl-0 pe-1  text-nowrap  mb-2">
              <i className="fas fa-circle cursor-pointer mx-2 completion-points" style={{ color: '#F9C08A', fontSize: '10px' }} />
              Double  Wins
            </li>
            
          </ul> */}
        </div>
      </CardBody>
    </Card>
  );
}

export default TournamentCompletion;
