import api from 'api'
import Endpoints from 'api/endpoints'
import PointIcon from 'assets/icons/PointIcon'
import DeleteModal from 'components/Common/DeleteModal'
import TableContainer from 'components/Common/TableContainer'
import { toastError } from 'components/Common/toastify'
import { toastSuccess } from 'components/Common/toastify'
import React, { useState } from 'react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardTitle, Input, Row, UncontrolledTooltip } from 'reactstrap'




const MostUsedCourts = ({data,title}) => {
    const [deleteModal,setDeleteModal]=useState(false)
    const [courtId,setCourtId]=useState()


    const tData = title==="Courts With Most Challenges"?data?.map((el, ind) => (
        {
            // checkbox: (
            //     <input type="checkbox" className="form-check-input" id="customCheck1" />
            // ),
            name: (
                <Link to={`/court-details/${el?.courtDetails?._id}`}>{el?.courtDetails?.name}</Link>
            ),
            
            //   name: "John Doe",
            location: (<p className=' text-truncate ' style={{maxWidth:"120px"}}>{el?.courtDetails?.location?.description}</p>),
            price:el?.courtDetails?.price,

            challenges: el?.totalUsage,

            action: (
                <div className="d-flex gap-3 users">
                    <ul className="list-inline font-size-20 contact-links mb-0">
                    <li className="list-inline-item">
                            <Link
                                to={`/court-details/${el?.courtDetails?._id}`}
                                className="text-primary"
                                onClick={() => {
                                    //   const users = cellProps.row.original
                                    //   onClickDelete(users)
                                }}
                                id={`viewTooltip_${el?.courtDetails?._id}`}
                            >
                                <i
                                    className="uil uil-eye font-size-18"
                                   
                                />
                                <UncontrolledTooltip placement="bottom" target={`viewTooltip_${el?.courtDetails?._id}`} >
                                    view
                                </UncontrolledTooltip>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link
                                to={`/edit-court/${el?.courtDetails?._id}`}
                                className="text-primary"
                                onClick={() => {
                                    //   const users = cellProps.row.original
                                    //   handleUserClick(users)
                                }}
                            >
                                <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_${el?.courtDetails?._id}`}/>
                                <UncontrolledTooltip placement="bottom" target={`edittooltip_${el?.courtDetails?._id}`}>
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                        </li>
                       
                        {/* <li className="list-inline-item">
                            <Link
                                to="#"
                                className="text-danger"
                            onClick={() => {
                              //   const users = cellProps.row.original
                              setCourtId(el?.courtId)
                              setDeleteModal(true);
                            }}
                            id={`deletetooltip_del_${el?.courtDetails?._id}`}
                            >
                                <i
                                    className="uil uil-trash-alt font-size-18 text-danger "
                                   
                                />
                                <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?.courtDetails?._id}`} >
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            )

        }
    )):(
        data?.map((el, ind) => (
            {
                // checkbox: (
                //     <input type="checkbox" className="form-check-input" id="customCheck1" />
                // ),
                name: (
                    <Link to={`/court-details/${el?.courtDetails?._id}`}>{el?.courtDetails?.name}</Link>
                ),
                
                //   name: "John Doe",
                location: (<p className=' text-truncate ' style={{maxWidth:"120px"}}>{el?.courtDetails?.location?.description}</p>),
                price:el?.courtDetails?.price,
    
                Tournaments: el?.totalUsage,
    
                action: (
                    <div className="d-flex gap-3 users">
                        <ul className="list-inline font-size-20 contact-links mb-0">
                        <li className="list-inline-item">
                                <Link
                                    to={`/court-details/${el?.courtDetails?._id}`}
                                    className="text-primary"
                                    onClick={() => {
                                        //   const users = cellProps.row.original
                                        //   onClickDelete(users)
                                    }}
                                    id={`viewTooltip_${el?.courtDetails?._id}`}
                                >
                                    <i
                                        className="uil uil-eye font-size-18"
                                       
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`viewTooltip_${el?.courtDetails?._id}`} >
                                        view
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link
                                    to={`/edit-court/${el?.courtDetails?._id}`}
                                    className="text-primary"
                                    onClick={() => {
                                        //   const users = cellProps.row.original
                                        //   handleUserClick(users)
                                    }}
                                >
                                    <PointIcon name="pen" height='20px' width='20px' className="" id={`edittooltip_${el?.courtDetails?._id}`}/>
                                    <UncontrolledTooltip placement="bottom" target={`edittooltip_${el?.courtDetails?._id}`}>
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                           
                            {/* <li className="list-inline-item">
                                <Link
                                    to="#"
                                    className="text-danger"
                                onClick={() => {
                                  //   const users = cellProps.row.original
                                  setCourtId(el?.courtId)
                                  console.log(el?.courtId)
                                  setDeleteModal(true);
                                }}
                                id={`deletetooltip_del_${el?.courtDetails?._id}`}
                                >
                                
                                    <i
                                        className="uil uil-trash-alt font-size-18 text-danger "
                                       
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`deletetooltip_del_${el?.courtDetails?._id}`} >
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                )
    
            }
        ))
    
    )






  

    const cols = title==="Courts With Most Challenges"?([
        // {
        //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
        //     accessor: 'checkbox',
        // },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Location',
            accessor: 'location',
            hasDescription: true,
        },
        {
            Header: 'Price',
            accessor: 'price',
            hasDescription: true,
        },
        {
            Header: 'Challenges',
            accessor: 'challenges',
            hasDescription: true,
        },



        {
            Header: 'Action',
            accessor: 'action',

        },
    ]):([
        // {
        //     Header: <Input type="checkbox" className="form-check-input" id="customCheck1" />,
        //     accessor: 'checkbox',
        // },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Location',
            accessor: 'location',
            hasDescription: true,
        },
        {
            Header: 'Price',
            accessor: 'price',
            hasDescription: true,
        },
        {
            Header: 'Tournaments',
            accessor: 'Tournaments',
            hasDescription: true,
        },



        {
            Header: 'Action',
            accessor: 'action',

        },
    ])
    const handleDeleteCourt = async (id) => {
        console.log(id)
        try {
          await api.delete(`${Endpoints.court}/${id}`);
          toastSuccess('Deleted');
            
        } catch (error) {
          toastError();
          console.log(error);
        }
        setDeleteModal(false)
      };
    
    return (
       <>
       <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteCourt(courtId)}
        onCloseClick={() => setDeleteModal(false)}
      />
       <Card className="section-card ">
            <CardBody className='pb-2 '>
                <CardTitle className="h4">
                    <div className="d-flex justify-content-between">
                        <span className="text-black fw-bold font-size-16">{title}</span>
                        <Link to="/courts-listing" className="text-blue font-size-12 fw-normal">View All</Link>
                    </div>
                </CardTitle>
                <Row className="py-1  overflow-auto" style={{ height: "300px" }}>
                    <TableContainer
                        columns={cols}
                        // rows={rows}
                        data={tData}
                        isGlobalFilter={true}
                        isAddUsers={true}
                        isAddTableBorderStrap={true}
                        // handleUserClicks={handleUserClicks}
                        addPagination={false}
                    // topBar={topBar}
                    />
                </Row>

            </CardBody>
        </Card>
       </>
    )
}

export default MostUsedCourts