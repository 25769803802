import _ from './endPointBuilder';

const Endpoints = {
  login: _('auth/login'),
  addFcm: _('user/addfcm'),
  notification: _('notification'),
  markedAllNotification: _('notification/all'),
  forgotPassword: _('auth/forgotpassword'),
  verifyUser: _('auth/verify'),
  passwordReset: _('auth/passwordreset'),
  updatePassword: _('user/updatepassword'),
  court: _('court'),
  courtFilters: _('court/dashboard/courtfilter'),
  courtStats: _('court/dashboard/stats'),
  newCourtChart: _('court/dashboard/newcourt'),
  courtsByCity: _('court/dashboard/getcourtbycites'),
  courtsByMostChallenges: _('court/dashboard/challengecourt'),
  courtsByMostTournaments: _('court/dashboard/tournamentcourt'),
  faq: _('faq'),
  tournament: _('tournament'),
  //groups
  group: _('group'),
  groupStats: _('group/dashboard/statscard'),
  groupTournamentStats: _('group/dashboard/tournamentstats'),
  groupPrivacy: _('group/dashboard/getbyprivate'),
  groupAdmin: _('group/getadmin'),
  groupMember: _('group/getmember'),
  newGroupChart: _('group/dashboard/getnewgroupcount'),
  groupByCity: _('group/dashboard/getgroupbycities'),
  superAdminDeleteGroup: _('group/dashboard/deletegroup'),

  // media upload
  uploadSingleMedia: _('media/upload'),
  //  challenges
  getAllChellenges: _('challenge'),
  // users
  createUser: _('user/create'),
  reportByUsers: _('user/userreportby'),
  editUser: _('user/edit/'),
  userDisable: _('user/disable'),
  userSoftDelete: _('user/delete'),
  // unblockUser:_("user/unblocked"),
  deleteUser: _('user/deletepermanently'),
  // user counts

  userCount: _('user/counts'),
  userPayStatus: _('user/freepaidstatus'),
  userCountsByMonth: _('user/newuserchart'),
  // users / dashboard
  getAll: _('user/getall'),
  checkUserName: _('user/checkusername'),
  getUserProfile: _('user/get'),
  getUserPersonalStats: _('user/stats'),
  getBlockedUser: _('user/getblockuser'),
  getBlockedUsers: _('user/getblockusers'),

  freePaid: _('user/freepaidstatus'),
  newUserCount: _('user/newuserchart'),
  userRanking: _('user/ranking'),
  userRank: _('user/rank'),
  mostTournamentCreated: _('user/tournamentcreated'),
  mostTournamentPlayed: _('user/tournamentplay'),
  mostMatchesPlayed: _('user/totalmatches'),
  mostFriends: _('user/mostfriend'),

  mostProfileView: _('user/userprofileviews'),
  mostInvitedSent: _('user/mostinvited'),
  mostInvitedAccept: _('user/inviteaccept'),
  mostChallengRevieved: _('user/challengereceived'),
  mostChallengSent: _('user/challengesend'),

  // user
  newUsers: _('user/newuserchart'),
  searchByUser: _('user/search'),
  getUserByCity: _('user/bycity'),
  getUserByDay: _('user/newuserchart'),
  //
  team: _('team'),
  // ranking
  ranking: _('user/ranking'),
  // challenge
  challenge: _('challenge'),
  challengeStats: _('challenge/dashboard/stats'),
  challengesByCity: _('challenge/dashboard/getchallengebycity'),
  challengesFormat: _('challenge/dashboard/getchallengebyformat'),
  challengesStatus: _('challenge/dashboard/getchallengebystatus'), //challenge/dashboard/getchallengebystatus
  getChallengesDashboard: _('challenge/dashboard/getall'),
  challengesByCourt: _('challenge/dashboard/bycourt'),
  challengesStatsByCourt: _('challenge/dashboard/bycourtstats'),
  challengesStatsByUser: _('challenge/dashboard/byuserstats'),
  // tournaments
  tournament: _('tournament'),
  allTournaments: _('tournament/dashboard/getall'),
  tournamentStats: _('tournament/dashboard/statscard'),
  tournamentReport: _('tournament/dashboard/sanctionunsnactionstatus'),
  tournamentFormat: _('tournament/dashboard/getbyformat'),
  tournamentPrivacy: _('tournament/dashboard/getbyprivate'),
  tournamentStatus: _('tournament/dashboard/getbystatus'),
  tournamentFee: _('tournament/dashboard/getamount'),
  tournamentAmount: _('tournament/dashboard/getamount'),
  matchesByCity: _('tournament/dashboard/getmatchbycity'),
  superAdminDeleteTournament: _('tournament/dashboard/deletetournament'),

  // get all matches
  match: _('match'),
  teamMatch: _('match/team'),
  courtMatch: _('match/court'),
  // get all tournament teams
  participants: _('team/all'),
};

export default Endpoints;
