import React,{useMemo,useState,useEffect, useCallback} from 'react'
import PointIcon from 'assets/icons/PointIcon';
import TableContainer from 'components/Common/TableContainer';
import TableSkeleton from 'components/Common/TableSekeloton';
import { debounce } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import Endpoints from 'api/endpoints';
import api from 'api';
import { toastError } from 'components/Common/toastify';
import { Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import moment from 'moment';
import queryParamBuilder from 'api/queryParamBuilder';
import banner from "assets/images/default-img.svg"

const GrouoMemberListing = () => {

    const title = "Group Members";
    const description = "Group Page";

    const {id}=useParams();

    const [groupMemberData, setGroupMembers] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [currentPage,setCurrentPage]=useState(1)
    const [totalPages,setTotalPages]=useState()
    const [isLoading, setIsLoading] = useState(true);
    
    const groupMembers = async () => {
        const url = queryParamBuilder({
          endpoint: Endpoints.groupMember,
          page: 1,
          limit: 10,
          search:searchValue
        });
        try {
          const res = await api.get(`${Endpoints.groupMember}/${id}?search=${searchValue}&limit=10&page=${searchValue?1:currentPage}`);
          setGroupMembers(res?.data?.data?.users);
          setTotalPages(res?.data?.totalPages);
          setIsLoading(false);
        } catch (error) {
          toastError(error?.message);
         
        }
      };
    
  
      useEffect(() => {
       
          groupMembers()
       
      }, [searchValue]);
    //   const debounceFn = useCallback(debounce(groupMembers, 1000), []);

      const tData = useMemo(() => {
        if (groupMemberData) {
          return groupMemberData?.map((user, ind) => ({
            // checkbox: (
            //   <input
            //     type="checkbox"
            //     className="form-check-input"
            //     id="customCheck1"
            //   />
            // ),
            name: (
              <Link
                to={`/user-profile/${user?.userId?._id}`}
                className="d-flex flex-row align-items-center text-center"
              >
                <img
                  src={user?.userId?.additionalInfo?.profileImage||banner}
                  className="avatar-xs rounded-circle me-2"
                />
                <span className="ms-md-2 text-nowrap ">{`${user?.userId?.firstName||"-"} ${user?.userId?.lastName||"-"}`} </span>
              </Link>
            ),
            //   name: "John Doe",
            rating: `S:${user?.userId?.additionalInfo?.matchDetails?.single?.rating||"-"} D:${user?.userId?.additionalInfo?.matchDetails?.double?.rating||"-"}`,
              email: (
              <span
                className=" text-truncate d-inline-block"
                style={{ maxWidth: "100px" }}
              >
                {user?.userId?.email|| "--"}
              </span>
            ),
            winLose: (
              <span
                className=" text-truncate d-inline-block"
                style={{ maxWidth: "100px" }}
              >
                {`${
            user?.userId?.additionalInfo?.matchDetails?.single?.win +
              user?.userId?.additionalInfo?.matchDetails?.double?.win || "-"
          }/${
            user?.userId?.additionalInfo?.matchDetails?.single?.loss +
              user?.additionalInfo?.matchDetails?.double?.loss || "-"
          }`}
              </span>
            ),
            location: (
              <span
                className=" text-truncate d-inline-block"
                style={{ maxWidth: "100px" }}
              >
                {user?.userId?.additionalInfo?.location?.description || "--"}
              </span>
            ),
            userSince: (
              <span
                className="  d-inline-block"
                // style={{ maxWidth: "100px" }}
              >
                {moment(user?.userId?.createdAt).format("MMMM DD, YYYY") || "--"}
              </span>
            ),
    
            // date: moment(el.date).format("MMMM DD, YYYY"),
            
            // court: <span className="ms-3">{el.court.length || "-"}</span>,
            // id: el._id,
    
            action: (
              <div className="d-flex flex-nowrap users">
                <ul className="list-inline font-size-20 contact-links mb-0">
                  <li className="list-inline-item">
                    <Link
                      to={`/user-profile/${user?.userId?._id}`}
                      className="text-primary"
                      onClick={() => {
                        //   const users = cellProps.row.original
                        //   handleUserClick(users)
                      }}
                    >
                      <i
                        className="uil uil-eye font-size-18"
                        id={`edittooltip_view_${ind}`}
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`edittooltip_view_${ind}`}
                      >
                        view
                      </UncontrolledTooltip>
                    </Link>
                  </li>
                  {/* <li className="list-inline-item">
                                <Link
                                     to="#"
                                     className="text-primary ms-3"
                                     onClick={() => {
                                       handleShowModal();
                                       setTournamentID(el._id);
                                     }}
                   
                                >
                                    <i className="uil uil-pen font-size-18" id={`edittooltip_edit_${ind}`} />
                                    <UncontrolledTooltip placement="bottom" target={`edittooltip_edit_${ind}`}>
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li> */}
                  {/* <li className="list-inline-item">
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => {
                        handleDeleteTournament(el._id);
                      }}
                    >
                      <i
                        className="uil uil-trash-alt font-size-18 text-danger"
                        id={`deletetooltip_del_${ind}`}
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`deletetooltip_del_${ind}`}
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </li> */}
                  <li className="list-inline-item "></li>
                </ul>
              </div>
            ),
          }));
        }
      }, [groupMemberData]);
    
      const rows = useMemo(() => {
        return tData?.map((el) => ({
          // checkbox: el.checkbox,
          name: el.name,
          email: el.email,
          location: el.location,
          userSince: el.userSince,
          winLose: el.winLose,
          rating: el.rating
        //   id: el.id,
      
        }));
      }, [tData]);
    
      const columns = useMemo(
        () => [
          // {
          //   Header: (
          //     <Input
          //       type="checkbox"
          //       className="form-check-input"
          //       id="customCheck1"
          //     />
          //   ),
          //   accessor: "checkbox",
          // },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Email",
            accessor: "email",
            hasDescription: true,
          },
          {
            Header: "Location",
            accessor: "location",
            hasDescription: true,
          },
          {
            Header: "Rating",
            accessor: "rating",
            hasDescription: true,
          },
          {
            Header: "Win/Lose",
            accessor: "winLose",
            hasDescription: true,
          },
          {
            Header: "User Since",
            accessor: "userSince",
            hasDescription: true,
          },
         
        ],
        []
      );
  return (
    <>
     <HtmlHead title={title} description={description} />
    <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <div className="mb-3">
            <h4>Group Members Listing</h4>
          </div>
          <Row>
            <Col xs="12">
             
              <Card>
                <CardBody>
                  <Row>
                    <div className="d-flex ms-1  align-items-center ">
                      <button className={`border-0 bg-transparent pt-1`}>
                        <PointIcon
                          height="18px"
                          width="18px"
                          name="search"
                          className="mb-2"
                        />
                      </button>
                      <input
                        type="text"
                        className="bg-transparent border-0   topbar-search font-size-14"
                        placeholder="Search by name..."
                        // value={filters.searchValue}
                        onChange={(e) => {
                          e.preventDefault();
                          setSearchValue(e.target.value);
                          // debounceLoadData(); 
                        //   debounceFn()
                        //   debounceHandler(
                        //     setFilters,
                        //     "searchValue",
                        //     e.target.value
                        //   );
                        }}
                      />
                    </div>
                  </Row>
                  {isLoading ? (
                    <TableSkeleton entries={10} />
                  ) : (
                    <>
                      <TableContainer
                        columns={columns}
                        data={rows}
                        isGlobalFilter={true}
                        isAddUsers={true}
                        isAddTableBorderStrap={true}
                        // handleUserClicks={handleUserClicks}
                        isLoading={isLoading}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        addPagination={true}
                        totalPages={totalPages}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default GrouoMemberListing