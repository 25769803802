import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Col, Row } from 'reactstrap'

const TableSkeletonSm = ({entries=5}) => {
    return (
        <>
            {
                Array.from({ length: entries }).map((_, ind) => (

                    <>
                        <Row className='justify-content-between align-itmes-center my-1' key={ind} >

                            <Col className="col-3 col-sm-3 col-md-2">
                                <Skeleton circle={true} className='img-skelton ' />
                            </Col>
                            <Col className='col-9 col-sm-10 col-md-10  '>
                                <Row className='align-items-center mt-2'>

                              
                                <Col className='col-6 '>
                                <Skeleton count={1}   />
                                <Skeleton count={1} className='w-75'   />
                                </Col>
                                <Col className='col-6 '>
                                <Skeleton count={1}   />
                                <Skeleton count={1} className='w-75'   />
                                </Col>
                                
                                </Row>
                               

                            </Col>
                        </Row>
                    </>

                ))
            }
        </>
    )
}

export default TableSkeletonSm